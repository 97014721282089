import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import FormGroup from 'components/FormGroup/FormGroup';
import InputForm from 'components/InputForm/InputForm';
import SelectForm from 'components/SelectForm/SelectForm';
import Loader from 'components/Loader/Loader';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { getAdminUser, getAdminUsersRoles, editAdminUser } from 'actions';

const AdminUser = ({ roles, dispatch, match }) => {
  const [userName, setUserName] = useState('');
  const [userNumber, setUserNumber] = useState('');
  const [userRole, setUserRole] = useState(null);
  const [userStage, setUserStage] = useState('');
  const [userDepartment, setUserDepartment] = useState('');
  const [userOp, setUserOp] = useState('');
  const [userLogin, setUserLogin] = useState('');
  const [userPassword, setUserPassword] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  /** Запрашиваем роли для юзеров и данные по выбранному юзеру */
  useEffect(() => {
    Promise.all([dispatch(getAdminUsersRoles()), dispatch(getAdminUser(match.params.userId))])
      .then(result => {
        const usersRoles = result && result[0];
        const userData = result && result[1];

        if (result) {
          setIsLoading(false);
        }

        if (userData && userData.name) {
          setUserName(userData.name);
        }

        if (userData && userData.number) {
          setUserNumber(userData.number);
        }

        if (usersRoles && userData && userData.role_sel) {
          setUserRole(
            usersRoles
              .map(role => ({
                value: role.id,
                label: role.name,
              }))
              .filter(role => role.value === userData.role_sel)[0],
          );
        }

        if (userData && userData.stage) {
          setUserStage(userData.stage);
        }

        if (userData && userData.department) {
          setUserDepartment(userData.department);
        }

        if (userData && userData.op) {
          setUserOp(userData.op);
        }

        if (userData && userData.login) {
          setUserLogin(userData.login);
        }

        if (userData && userData.password) {
          setUserPassword(userData.password);
        }
      })
      .catch(error => {
        if (error) {
          setIsLoading(false);
        }
      });
  }, [dispatch, match.params.userId]);

  const submitUser = () => {
    setIsSubmitLoading(true);

    dispatch(
      editAdminUser(
        match.params.userId,
        userName,
        userNumber,
        userRole.value,
        userStage,
        userDepartment,
        userOp,
        userLogin,
        userPassword,
      ),
    )
      .then(() => {
        if (window && window.location && window.location.href) {
          window.location.href = `/admin-users`;
        }
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsSubmitLoading(false);
      });
  };

  return (
    <>
      {isLoading && <Loader className="loader_page" />}

      {!isLoading && roles && Array.isArray(roles) && roles.length > 0 && (
        <div className="admin-users">
          <div className="admin-users__container container">
            <h2 className="admin-users__title">Редактирование пользователя {userName}</h2>

            <div className="admin-users__body">
              <FormGroup title="Фамилия И.О." error={fieldErrors.name}>
                <InputForm
                  type="text"
                  value={userName}
                  onChange={({ target }) => setUserName(target.value)}
                  placeholder="Например, Иванов Д.И."
                />
              </FormGroup>

              <FormGroup title="Табельный номер" error={fieldErrors.number}>
                <InputForm
                  type="text"
                  value={userNumber}
                  onChange={({ target }) => setUserNumber(target.value)}
                />
              </FormGroup>

              <FormGroup title="Роль" error={fieldErrors.role}>
                <SelectForm
                  selectedValue={userRole}
                  options={roles.map(item => ({ value: item.id, label: item.name }))}
                  onChange={selectedOption => setUserRole(selectedOption)}
                />
              </FormGroup>

              <FormGroup title="Разряд" error={fieldErrors.stage}>
                <InputForm
                  type="text"
                  value={userStage}
                  onChange={({ target }) => setUserStage(target.value)}
                />
              </FormGroup>

              <FormGroup title="Принадлежность к участку" error={fieldErrors.department}>
                <InputForm
                  type="text"
                  value={userDepartment}
                  onChange={({ target }) => setUserDepartment(target.value)}
                />
              </FormGroup>

              <FormGroup title="Принадлежность к ОП" error={fieldErrors.op}>
                <InputForm
                  type="text"
                  value={userOp}
                  onChange={({ target }) => setUserOp(target.value)}
                />
              </FormGroup>

              <FormGroup title="Логин (E-mail)" error={fieldErrors.login}>
                <InputForm
                  type="text"
                  value={userLogin}
                  onChange={({ target }) => setUserLogin(target.value)}
                />
              </FormGroup>

              <FormGroup title="Пароль" error={fieldErrors.password}>
                <InputForm
                  type="password"
                  value={userPassword}
                  onChange={({ target }) => setUserPassword(target.value)}
                />
              </FormGroup>
            </div>

            {responseError && (
              <ModalError className="admin-users__error-msg">{responseError}</ModalError>
            )}

            <div className="admin-users__footer">
              <SubmitButton
                isLoading={isSubmitLoading}
                isValid={userName === '' || userNumber === '' || !userRole}
                isLoadingText="Сохраняю..."
                className="btn btn-primary"
                clickHandler={submitUser}
              >
                Сохранить
              </SubmitButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

AdminUser.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string,
    }),
  }).isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
};

const mapStateToProps = state => ({
  roles: state.mainReducer.adminUsersRoles,
});

export default connect(mapStateToProps)(AdminUser);
