import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import SelectForm from 'components/SelectForm/SelectForm';
import FormGroup from 'components/FormGroup/FormGroup';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import Loader from 'components/Loader/Loader';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { addRmmEmploymentWorker, getRmmEmploymentUsers, getRmmEmploymentWorkers } from 'actions';

const AddUserModal = ({ closeHandler, dispatch }) => {
  const [groupedOptions, setGroupedOptions] = useState(null);
  const [user, setUser] = useState(null);

  const [isModalLoading, setIsModalLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  useEffect(() => {
    dispatch(getRmmEmploymentUsers())
      .then(result => {
        if (
          result &&
          result &&
          result.item1 &&
          Array.isArray(result.item1) &&
          result.item2 &&
          Array.isArray(result.item2) &&
          result.item3 &&
          Array.isArray(result.item3)
        ) {
          setGroupedOptions([
            {
              label: 'РММ',
              options: result.item1.map(item => ({
                value: item.id,
                label: `${item.name} (${item.role_1})`,
              })),
            },
            {
              label: 'Цех ЗРА',
              options: result.item2.map(item => ({
                value: item.id,
                label: `${item.name} (${item.role_1})`,
              })),
            },
            {
              label: 'Все работники',
              options: result.item3.map(item => ({
                value: item.id,
                label: `${item.name} (${item.role_1})`,
              })),
            },
          ]);

          setUser(
            (result.item1.length > 0 &&
              result.item1.map(item => ({
                value: item.id,
                label: `${item.name} (${item.role_1})`,
              }))[0]) ||
              (result.item2.length > 0 &&
                result.item2.map(item => ({
                  value: item.id,
                  label: `${item.name} (${item.role_1})`,
                }))[0]) ||
              (result.item3.length > 0 &&
                result.item3.map(item => ({
                  value: item.id,
                  label: `${item.name} (${item.role_1})`,
                }))[0]),
          );
        }

        setIsModalLoading(false);
      })
      .catch(() => setIsModalLoading(false));
  }, [dispatch]);

  const submitWorker = () => {
    setIsSubmitLoading(true);

    dispatch(addRmmEmploymentWorker(user.value))
      .then(() => dispatch(getRmmEmploymentWorkers()))
      .then(() => {
        setIsSubmitLoading(false);

        closeHandler();
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsSubmitLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Добавить работника" />

      {isModalLoading && <Loader className="loader_modal" />}

      {!isModalLoading && groupedOptions && user && (
        <ModalBody>
          <FormGroup title="Работник:" error={fieldErrors.user}>
            <SelectForm
              selectedValue={user}
              options={groupedOptions}
              onChange={selectedOption => setUser(selectedOption)}
            />
          </FormGroup>
        </ModalBody>
      )}

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isLoading={isSubmitLoading}
          isValid={!user}
          isLoadingText="Добавление..."
          clickHandler={submitWorker}
        >
          Добавить
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

AddUserModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(AddUserModal);
