import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Styles
import './OverdueListReport.scss';

const OverdueListReport = ({ overdue }) => {
  return (
    <ul className="overdue-list-report">
      {overdue &&
        Array.isArray(overdue) &&
        overdue.map(item => (
          <li key={item.id} className="overdue-list-report__item">
            <div className="overdue-list-report__item-info">
              <Link to={`/repair/${item.id}`} className="overdue-list-report__item-link">
                {item.ksname} {item.gpa} №{item.stationnumber}
              </Link>{' '}
              {item.prolongdate_1 && (
                <span className="label label_danger">{item.prolongdate_1}</span>
              )}
              {!item.prolongdate_1 && (
                <span className="label label_danger">{item.factdateend_1}</span>
              )}
            </div>
            <div className="overdue-list-report__item-tools">
              <span className="label label_primary">{item.master_1}</span>
            </div>
          </li>
        ))}
    </ul>
  );
};

OverdueListReport.propTypes = {
  overdue: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      ksname: PropTypes.string,
      gpa: PropTypes.string,
      stationnumber: PropTypes.string,
      prolongdate_1: PropTypes.string,
      factdateend_1: PropTypes.string,
      master_1: PropTypes.string,
    }),
  ),
};

OverdueListReport.defaultProps = {
  overdue: null,
};

export default OverdueListReport;
