import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import TopNavigation from 'components/TopNavigation/TopNavigation';
import UserNavigation from 'components/UserNavigation/UserNavigation';

// Actions
import { getHeaderData } from 'actions';

// Styles
import './Header.scss';

const Header = ({ headerData, dispatch }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(getHeaderData())
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch]);

  return (
    <>
      {!isLoading && headerData && (
        <header className="header">
          <div className="header__container container">
            <div className="header__nav-wrap">
              <TopNavigation isSuper={headerData.isSuper} orderMen={headerData.ordermen} />
            </div>
            <div className="header__user-wrap">
              <UserNavigation
                emul={false}
                isSuper={headerData.isSuper}
                userData={headerData.user}
                period={headerData.period}
              />
            </div>
          </div>
        </header>
      )}
    </>
  );
};

Header.propTypes = {
  headerData: PropTypes.shape({
    isSuper: PropTypes.bool,
    user: PropTypes.shape({}),
    period: PropTypes.shape({}),
    ordermen: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  headerData: state.mainReducer.headerData,
});

export default connect(mapStateToProps)(Header);
