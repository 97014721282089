import React from 'react';
import PropTypes from 'prop-types';
// import cx from 'classnames';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

// Icons
import { FaShare, FaCheck, FaLevelUpAlt, FaPaperclip, FaFileAlt } from 'react-icons/fa';

// Components
import Checkbox from 'components/Checkbox/Checkbox';

// Utils
import * as propTypes from 'utils/propTypes';
import { USER_ROLES, DESPATCH_STATUSES, DESPATCH_COMBINED_STATUSES_SHORT_HASH } from 'utils/constants';

// Styles
import './TableDespatch.scss';

const TableDespatchRow = ({
  showTools,
  userRole,
  despatchInfo,
  classNameSuffix,
  onDespatchPdoApproved,
  onDespatchOmtsApproved,
  onReturnProblem,
  onProblemSolved,
}) => {

  const handleDespatchPdoApproved = React.useCallback(e => {
    e.stopPropagation();

    if (onDespatchPdoApproved && despatchInfo) {
      onDespatchPdoApproved(despatchInfo.id);
    }
  }, [despatchInfo, onDespatchPdoApproved]);

  const handleDespatchOmtsApproved = React.useCallback(e => {
    e.stopPropagation();

    if (onDespatchOmtsApproved && despatchInfo) {
      onDespatchOmtsApproved(despatchInfo.id);
    }
  }, [despatchInfo, onDespatchOmtsApproved]);

  const handleReturnProblemClick = React.useCallback(e => {
    e.stopPropagation();

    if (onReturnProblem && despatchInfo) {
      onReturnProblem(despatchInfo.id);
    }
  }, [despatchInfo, onReturnProblem]);

  const handleProblemSolvedClick = React.useCallback(e => {
    e.stopPropagation();

    if (onProblemSolved && despatchInfo) {
      onProblemSolved(despatchInfo.id);
    }
  }, [despatchInfo, onProblemSolved]);

  const history = useHistory();

  return (
    <tr
      className={`table-despatch-tr table-despatch-tr__${classNameSuffix}${despatchInfo.isAdditional ? ' table-despatch-tr__plus' : ''}`}
      onClick={() => history.push(`/despatch/${despatchInfo.id}/details`)}
    >
      <td className="table-despatch-td">
        <Link to={`/despatch/${despatchInfo.id}/details`}><span className="label label_tr">{despatchInfo.isAdditional ? <FaLevelUpAlt className="icon" style={{ transform: 'rotate(90deg)' }} /> : despatchInfo.id}</span></Link>
      </td>
      {despatchInfo.isAdditional ? (
        <>
          <td colSpan="2" className="table-despatch-td">
            <Link to={`/despatch/${despatchInfo.id}/details`}>
              <span className="label label_tr mr-1">{despatchInfo.id}</span>
            </Link>
            {despatchInfo.dateCreation_1}
          </td>
        </>
      ) : (
        <td colSpan="2" className="table-despatch-td" style={{ textAlign: 'left' }}>
          {despatchInfo.dateCreation_1}
        </td>
      )}
      <td className="table-despatch-td">{despatchInfo.dateReady_1}</td>
      <td className="table-despatch-td">{despatchInfo.dateStart_1}</td>
      <td className="table-despatch-td">{despatchInfo.dateEnd_1}</td>
      <td className="table-despatch-td">{despatchInfo.dateFact ? despatchInfo.dateFact_1 : 'Не назнач.'}</td>
      <td className="table-despatch-td">
        {despatchInfo.startLocation && despatchInfo.startLocation.city}
      </td>
      <td className="table-despatch-td">
        {despatchInfo.destLocation && despatchInfo.destLocation.city}
      </td>
      <td className="table-despatch-td text-overflow">{despatchInfo.createdByFullName}</td>
      <td className="table-despatch-td">{despatchInfo.size}</td>
      <td className="table-despatch-td">{despatchInfo.weight}</td>
      <td className="table-despatch-td">
        {despatchInfo.nomenclature}
        <div className="upload-docs">
          {despatchInfo.nomenclatureUrl && (
            <a
              href={despatchInfo.nomenclatureUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="label label_info"
            >
              <FaPaperclip className="icon" /> {despatchInfo.shortNomenclature}
            </a>
          )}
        </div>
      </td>
      <td className="table-despatch-td">{despatchInfo.urgency}</td>
      <td className="table-despatch-td text-overflow">{DESPATCH_STATUSES[despatchInfo.status]}</td>
      <td className="table-despatch-td text-overflow">{despatchInfo.other}</td>
      {showTools && (
        <td>
          <Checkbox
            onClick={handleDespatchPdoApproved}
            checked={despatchInfo.isPdoApproved}
            disabled={false}
            ariaLabel="Поставить отметку, что заявка одобрена ПДО"
            style={{ marginRight: 4 }}
          />

          <Checkbox
            onClick={handleDespatchOmtsApproved}
            checked={despatchInfo.isOmtsApproved}
            disabled={false}
            ariaLabel="Поставить отметку, что заявка одобрена ОМТС"
            style={{ marginRight: 4 }}
          />

          <React.Fragment>
            {(userRole === USER_ROLES.ROLE_PDO ||
              userRole === USER_ROLES.ROLE_OMTS ||
              userRole === USER_ROLES.ROLE_BOSSES || 
              userRole === USER_ROLES.ROLE_ADMIN) &&
              despatchInfo.status !== 'RETURNED_FOR_REV' && (
              <button
                // disabled={despatchInfo.isPdoApproved && despatchInfo.isOmtsApproved}
                type="button"
                className="btn btn-xs btn-danger"
                title="Вернуть проблему постановщику"
                onClick={handleReturnProblemClick}
              >
                <FaShare className="icon" />
              </button>
            )}
            {(userRole === USER_ROLES.ROLE_PDO ||
              userRole === USER_ROLES.ROLE_OMTS ||
              userRole === USER_ROLES.ROLE_BOSSES || 
              userRole === USER_ROLES.ROLE_ADMIN) &&
              despatchInfo.status === 'RETURNED_FOR_REV' && (
              <button
                type="button"
                className="btn btn-xs btn-warning"
                title="Снять проблему"
                onClick={handleProblemSolvedClick}
              >
                <FaCheck className="icon" />
              </button>
            )}
          </React.Fragment>
        </td>
      )}
    </tr>
  );
};

TableDespatchRow.propTypes = {
  showTools: PropTypes.bool,
  despatchInfo: propTypes.despatchInfo.isRequired,
  classNameSuffix: PropTypes.string,
  onDespatchPdoApproved: PropTypes.func.isRequired,
  onDespatchOmtsApproved: PropTypes.func.isRequired,
  onReturnProblem: PropTypes.func.isRequired,
};

TableDespatchRow.defaultProps = {
  showTools: true,
  classNameSuffix: null,
};

const TableDespatch = ({
  showTools,
  userRole,
  despatches,
  despatchFilter,
  onPdoApproved,
  onOmtsApproved,
  onReturnProblem,
  onProblemSolved,
}) => {
  const applyFilterByDespatch = despatch => {
    let isFilterPassed = true;

    if (despatchFilter.status.length > 0 && isFilterPassed) {
      isFilterPassed = despatchFilter.status.includes(despatch.status);
    }

    return isFilterPassed;
  };

  const handleDespatchPdoApproved = (despatchId) => {
    onPdoApproved && onPdoApproved(despatchId, 'pdo');
  };

  const handleDespatchOmtsApproved = (despatchId) => {
    onOmtsApproved && onOmtsApproved(despatchId, 'omts');
  };

  return (
    <table className="table table-bordered table-despatch">
      <thead>
        <tr className="info">
          <th className="table-despatch-th">
            <span className="label label_default">
              <FaFileAlt className="icon" />
            </span>
          </th>
          <th colSpan="2" className="table-despatch-th">
            <span className="label label_default">Дата создания</span>
          </th>
          <th className="table-despatch-th">
            <span className="label label_default">Дата исполнения</span>
          </th>
          <th className="table-despatch-th">
            <span className="label label_default">Дата готовности к отгрузке</span>
          </th>
          <th className="table-despatch-th">
            <span className="label label_default">Срок проведения перевозки</span>
          </th>
          <th className="table-despatch-th">
            <span className="label label_default">Фактический срок перевозки</span>
          </th>
          <th className="table-despatch-th">
            <span className="label label_default">Место отправления</span>
          </th>
          <th className="table-despatch-th">
            <span className="label label_default">Место назначения</span>
          </th>
          <th className="table-despatch-th">
            <span className="label label_default">Составитель заявки</span>
          </th>
          <th className="table-despatch-th">
            <span className="label label_default">Габариты (м)</span>
          </th>
          <th className="table-despatch-th">
            <span className="label label_default">Вес (кг)</span>
          </th>
          <th className="table-despatch-th">
            <span
              className="label label_default"
              style={{ display: 'block', overflow: 'hidden', 'textOverflow': 'ellipsis' }}
            >
              Номенкулатурный перечень
            </span>
          </th>
          <th className="table-despatch-th">
            <span className="label label_default">Срочность</span>
          </th>
          <th className="table-despatch-th">
            <span className="label label_default">Статус</span>
          </th>
          <th className="table-despatch-th">
            <span className="label label_default">Дополнительная информация</span>
          </th>
          {showTools && (
            <th className="table-despatch-th">
              <span className="label label_default">Отметка о выполнении</span>
            </th>
          )}
          {/*canEdit && active && !isCompleted && <th> </th>*/}
        </tr>
      </thead>

      <colgroup>
        <col style={{ width: '56px' }} />
        <col style={{ width: '56px' }} />
        <col style={{ width: '108px' }} />
        <col style={{ width: '120px' }} />
        <col style={{ width: '96px' }} />
        <col style={{ width: '128px' }} />
        <col style={{ width: '128px' }} />
        <col style={{ width: '150px' }} />
        <col style={{ width: '140px' }} />
        <col style={{ width: '140px' }} />
        <col style={{ width: '96px' }} />
        <col style={{ width: '56px' }} />
        <col style={{ width: '96px' }} />
        <col style={{ width: '80px' }} />
        <col style={{ width: '139px' }} />
        <col style={{ width: '108px' }} />
        <col style={{ width: '152px' }} />
      </colgroup>

      <tbody>
        {despatches &&
          Array.isArray(despatches) &&
          despatches.length > 0 &&
          despatches
            .filter(applyFilterByDespatch)
            .map(despatch => (
              <React.Fragment key={despatch.id}>
                <TableDespatchRow
                  userRole={userRole}
                  despatchInfo={despatch}
                  classNameSuffix={DESPATCH_COMBINED_STATUSES_SHORT_HASH[despatch.status]}
                  showTools
                  onDespatchPdoApproved={handleDespatchPdoApproved}
                  onDespatchOmtsApproved={handleDespatchOmtsApproved}
                  onReturnProblem={onReturnProblem}
                  onProblemSolved={onProblemSolved}
                />
                {despatch.additionalDespatches &&
                  despatch.additionalDespatches.length &&
                  despatch.additionalDespatches.map(d => (
                    <TableDespatchRow
                      userRole={userRole}
                      despatchInfo={d}
                      classNameSuffix={DESPATCH_COMBINED_STATUSES_SHORT_HASH[d.status]}
                      showTools
                      onDespatchPdoApproved={handleDespatchPdoApproved}
                      onDespatchOmtsApproved={handleDespatchOmtsApproved}
                      onReturnProblem={onReturnProblem}
                      onProblemSolved={onProblemSolved}
                    />
                  ))}
              </React.Fragment>
            ))}
      </tbody>
    </table>
  );
};

TableDespatch.propTypes = {
  showTools: PropTypes.bool,
  userRole: PropTypes.number.isRequired,
  despatches: PropTypes.arrayOf(propTypes.despatchInfo),
  despatchFilter: PropTypes.shape({
    status: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  onPdoApproved: PropTypes.func.isRequired,
  onOmtsApproved: PropTypes.func.isRequired,
  onReturnProblem: PropTypes.func.isRequired,
  onProblemSolved: PropTypes.func.isRequired,
};

TableDespatch.defaultProps = {
  despatches: [],
  showTools: true,
};

export default connect()(TableDespatch);
