import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Icons
import { FaPaperclip, FaTrashAlt } from 'react-icons/fa';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import Checkbox from 'components/Checkbox/Checkbox';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { changeRmmDocNoNeed, uploadRMMDocument } from 'actions';

// Misc
import { browseServer } from 'helpers/file-manager';

// Styles
import './docs-node.scss';

const DocsNodeModal = ({ closeHandler, userRole, canEdit, node, dispatch }) => {
  const [responseError, setResponseError] = useState(false);

  if (!node) {
    return null;
  }

  const selectFileForDocument = type => {
    browseServer({
      onSuccess: fileUrl => {
        dispatch(uploadRMMDocument(node.id, type, fileUrl)).catch(() => {});
      },
    });
  };

  const deleteFileForDocument = type => {
    dispatch(uploadRMMDocument(node.id, type, 'fake', 'delete')).catch(() => {});
  };

  const handleChangeDocNoNeed = type => {
    dispatch(changeRmmDocNoNeed(node.id, type)).catch(error => {
      if (error && error.error) {
        setResponseError(error.error);
      } else {
        setResponseError('Произошла неизвестная ошибка');
      }
    });
  };

  const isAllowedToChangeNoNeed =
    userRole === 10 || userRole === 14 || userRole === 6 || userRole === 8;

  return (
    <React.Fragment>
      <ModalHeader title="Сопроводительные документы" />

      <ModalBody>
        <div className="docs-node">
          <div className="docs-node__content">
            <div className="docs-node__title">Акт выдачи из ремонта</div>

            <div className="upload-docs">
              {canEdit && (
                <React.Fragment>
                  <button
                    type="button"
                    className="btn btn-xs btn-primary"
                    onClick={() => {
                      selectFileForDocument('actfinish');
                    }}
                  >
                    <FaPaperclip className="icon" />
                  </button>{' '}
                </React.Fragment>
              )}

              {node.actfinish && (
                <a
                  href={node.actfinish}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="label label_info"
                >
                  {node.shortactfinish}
                </a>
              )}

              {Boolean(node.actfinish && canEdit) && (
                <React.Fragment>
                  {' '}
                  <button
                    type="button"
                    className="btn btn-danger btn-xs"
                    onClick={() => {
                      deleteFileForDocument('actfinish');
                    }}
                  >
                    <FaTrashAlt className="icon" />
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>

          {isAllowedToChangeNoNeed && (
            <div className="docs-node__checkbox">
              <div className="docs-node__title">Необ.</div>

              <Checkbox
                onClick={() => handleChangeDocNoNeed('actfinish')}
                checked={node.noneedactfinish}
                ariaLabel="Акт выдачи из ремонта"
              />
            </div>
          )}
        </div>

        <div className="docs-node">
          <div className="docs-node__content">
            <div className="docs-node__title">Карточка ремонта</div>

            <div className="upload-docs">
              {canEdit && (
                <React.Fragment>
                  <button
                    type="button"
                    className="btn btn-xs btn-primary"
                    onClick={() => {
                      selectFileForDocument('cardrepair');
                    }}
                  >
                    <FaPaperclip className="icon" />
                  </button>{' '}
                </React.Fragment>
              )}

              {node.cardrepair && (
                <a
                  href={node.cardrepair}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="label label_info"
                >
                  {node.shortcardrepair}
                </a>
              )}

              {Boolean(node.cardrepair && canEdit) && (
                <React.Fragment>
                  {' '}
                  <button
                    type="button"
                    className="btn btn-danger btn-xs"
                    onClick={() => {
                      deleteFileForDocument('cardrepair');
                    }}
                  >
                    <FaTrashAlt className="icon" />
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>

          {isAllowedToChangeNoNeed && (
            <div className="docs-node__checkbox">
              <div className="docs-node__title">Необ.</div>

              <Checkbox
                onClick={() => handleChangeDocNoNeed('cardrepair')}
                checked={node.noneedcardrepair}
                ariaLabel="Карточка ремонта"
              />
            </div>
          )}
        </div>

        <div className="docs-node">
          <div className="docs-node__content">
            <div className="docs-node__title">Ремонтный формуляр</div>

            <div className="upload-docs">
              {canEdit && (
                <React.Fragment>
                  <button
                    type="button"
                    className="btn btn-xs btn-primary"
                    onClick={() => {
                      selectFileForDocument('formular');
                    }}
                  >
                    <FaPaperclip className="icon" />
                  </button>{' '}
                </React.Fragment>
              )}

              {node.formular && (
                <a
                  href={node.formular}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="label label_info"
                >
                  {node.shortformular}
                </a>
              )}

              {Boolean(node.formular && canEdit) && (
                <React.Fragment>
                  {' '}
                  <button
                    type="button"
                    className="btn btn-danger btn-xs"
                    onClick={() => {
                      deleteFileForDocument('formular');
                    }}
                  >
                    <FaTrashAlt className="icon" />
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>

          {isAllowedToChangeNoNeed && (
            <div className="docs-node__checkbox">
              <div className="docs-node__title">Необ.</div>

              <Checkbox
                onClick={() => handleChangeDocNoNeed('formular')}
                checked={node.noneedformular}
                ariaLabel="Ремонтный формуляр"
              />
            </div>
          )}
        </div>

        <div className="docs-node">
          <div className="docs-node__content">
            <div className="docs-node__title">Акт балансировки</div>

            <div className="upload-docs">
              {canEdit && (
                <React.Fragment>
                  <button
                    type="button"
                    className="btn btn-xs btn-primary"
                    onClick={() => {
                      selectFileForDocument('actballance');
                    }}
                  >
                    <FaPaperclip className="icon" />
                  </button>{' '}
                </React.Fragment>
              )}

              {node.actballance && (
                <a
                  href={node.actballance}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="label label_info"
                >
                  {node.shortactballance}
                </a>
              )}

              {Boolean(node.actballance && canEdit) && (
                <React.Fragment>
                  {' '}
                  <button
                    type="button"
                    className="btn btn-danger btn-xs"
                    onClick={() => {
                      deleteFileForDocument('actballance');
                    }}
                  >
                    <FaTrashAlt className="icon" />
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>

          {isAllowedToChangeNoNeed && (
            <div className="docs-node__checkbox">
              <div className="docs-node__title">Необ.</div>

              <Checkbox
                onClick={() => handleChangeDocNoNeed('actballance')}
                checked={node.noneedactballance}
                ariaLabel="Акт балансировки"
              />
            </div>
          )}
        </div>
      </ModalBody>

      {responseError && <ModalError>{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler} />
    </React.Fragment>
  );
};

DocsNodeModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number,
    actfinish: PropTypes.string,
    shortactfinish: PropTypes.string,
    noneedactfinish: PropTypes.bool,
    cardrepair: PropTypes.string,
    shortcardrepair: PropTypes.string,
    noneedcardrepair: PropTypes.bool,
    formular: PropTypes.string,
    shortformular: PropTypes.string,
    noneedformular: PropTypes.bool,
    actballance: PropTypes.string,
    shortactballance: PropTypes.string,
    noneedactballance: PropTypes.bool,
  }).isRequired,
  userRole: PropTypes.number.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default connect()(DocsNodeModal);
