import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Utils
import { USER_ROLES } from 'utils/constants';

// Styles
import './DespatchProblemsList.scss';

const DespatchProblemsList = ({ despatchProblems, closePanel, userRole }) => {
  return (
    <ul className="despatch-problems-list">
      {Array.isArray(despatchProblems) &&
        despatchProblems.map(problem => (
          <li key={problem.createdBy} className="despatch-problems-list__item">
            <div className="despatch-problems-list__item-info">
              <span className="label label_primary">{problem.date}</span> - {' '}
              <span className="label label_danger">{problem.dateEnd}</span> - {' '}
                {(userRole === USER_ROLES.ROLE_PDO || userRole === USER_ROLES.ROLE_OMTS) && (
                  <span>Перевозка </span>
                )}
                {problem.startLocation && problem.startLocation.city} - {problem.destLocation && problem.destLocation.city} &nbsp;
                  <Link
                    to={problem.url ? `/${problem.url}` : `/despatch/${problem.despatchId}/details`}
                    className="despatch-problems-list__item-link"
                    onClick={closePanel}
                  >
                      {problem.name}
                      <span>{' '}(Коментарий - {problem.comment})</span>
                  </Link>
            </div>
            <div className="despatch-problems-list__item-user">
                <span className="label label_danger">создатель заявки - {problem.createdBy}</span>{' - '}
                <span className="label label_danger">ответственный - {problem.responsible}</span>
            </div>
          </li>
        ))}
    </ul>
  );
};

DespatchProblemsList.propTypes = {
  closePanel: PropTypes.func.isRequired,
  userRole: PropTypes.number.isRequired,
  despatchProblems: PropTypes.arrayOf(
    PropTypes.shape({
      comment: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
      createdby: PropTypes.string,
      despatchid: PropTypes.number,
      responsible: PropTypes.string,
      responsibleComment: PropTypes.string,
      date: PropTypes.date,
      dateEnd: PropTypes.date,
      startLocation: PropTypes.string,
      destLocation: PropTypes.string,
    }),
  ),
};

DespatchProblemsList.defaultProps = {
    despatchProblems: null,
};

export default DespatchProblemsList;
