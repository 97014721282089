import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Icons
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

// Components
import Modal from 'components/Modal/Modal';
import RemoveOrderManConfirmModal from 'components/RemoveOrderManConfirmModal/RemoveOrderManConfirmModal';
import EditOrderManModal from 'components/EditOrderManModal/EditOrderManModal';

// Styles
import './TableAdminOrderMan.scss';

const TableAdminOrderMan = ({ orderMan }) => {
  const [isRemoveOrderManConfirmActive, setRemoveOrderManConfirmActive] = useState(false);
  const [isEditOrderManActive, setEditOrderManActive] = useState(false);

  const [currentOrderMan, setCurrentOrderMan] = useState(null);

  const activateRemoveOrderManConfirmModal = item => {
    setCurrentOrderMan(item);
    setRemoveOrderManConfirmActive(true);
  };

  const deactivateRemoveOrderManConfirmModal = () => {
    setRemoveOrderManConfirmActive(false);
  };

  const activateEditOrderManModal = item => {
    setCurrentOrderMan(item);
    setEditOrderManActive(true);
  };

  const deactivateEditOrderManModal = () => {
    setEditOrderManActive(false);
  };

  return (
    <table className="table table-bordered table-striped table-admin-orderMan">
      <tbody>
        {orderMan.map(item => (
          <tr key={item.id}>
            <td>
              <p className="table-admin-orderMan__name">{item.name}</p>
            </td>
            <td>
              <button
                type="button"
                className="btn btn-primary btn-xs"
                title="Редактировать"
                onClick={() => activateEditOrderManModal(item)}
              >
                <FaEdit className="icon" />
              </button>{' '}
              <button
                type="button"
                className="btn btn-danger btn-xs"
                title="Удалить"
                onClick={() => activateRemoveOrderManConfirmModal(item)}
              >
                <FaTrashAlt className="icon" />
              </button>
            </td>
          </tr>
        ))}

        {isRemoveOrderManConfirmActive && (
          <Modal title="Удаление заказчика" closeHandler={deactivateRemoveOrderManConfirmModal}>
            <RemoveOrderManConfirmModal
              closeHandler={deactivateRemoveOrderManConfirmModal}
              orderManId={currentOrderMan.id}
            />
          </Modal>
        )}

        {isEditOrderManActive && (
          <Modal title="Редактирование заказчика" closeHandler={deactivateEditOrderManModal}>
            <EditOrderManModal
              closeHandler={deactivateEditOrderManModal}
              orderMan={currentOrderMan}
            />
          </Modal>
        )}
      </tbody>
    </table>
  );
};

TableAdminOrderMan.propTypes = {
  orderMan: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
};

export default TableAdminOrderMan;
