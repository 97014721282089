import * as types from 'actions/action-types';

const initialState = {
  /** Данные по странице отправления (информация в шапке???) */
  locations: [], // Store normal and custom locations
  customLocations: [], // Store custom locations
  transport: [], // Store all cars
  drivers: [], // Store all drivers
};

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_LOCATIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.LOAD_LOCATIONS_SUCCESS:
      return action.meta.isCustomSearch
        ? {
            ...state,
            customLocations: action.payload.resp.content,
            isFetching: false,
          }
        : {
            ...state,
            locations: action.payload.resp.content,
            isFetching: false,
          };

    case types.LOAD_LOCATIONS_FAIL:
      return {
        ...state,
        isFetching: false,
      };

    case types.LOAD_TRANSPORT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case types.LOAD_TRANSPORT_SUCCESS:
      return {
        ...state,
        transport: action.payload.resp.content,
        isFetching: false,
      };

    case types.LOAD_TRANSPORT_FAIL:
      return {
        ...state,
        isFetching: false,
      };

    case types.LOAD_DRIVERS_SUCCESS:
      return {
        ...state,
        canEdit: action.payload.resp.canEdit,
        drivers: action.payload.resp.content,
        isFetching: false,
      };

    case types.LOAD_DRIVERS_FAIL:
      return {
        ...state,
        isFetching: false,
      };

    case types.ADD_DRIVER_SUCCESS:
      return {
        ...state,
        canEdit: action.payload.resp.canEdit,
        drivers: action.payload.resp.content,
        isFetching: false,
      };

    case types.ADD_DRIVER_FAIL:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
};

export default commonReducer;
