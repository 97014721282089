import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';

// Components
import GpaObjectContent from 'components/GpaObjectContent/GpaObjectContent';

// Styles
import './GpaObject.scss';

const GpaObject = ({ unit, needCheck }) => (
  <React.Fragment>
    {unit.canGoFurther ? (
      <Link
        to={`/repair/${unit.id}`}
        className={cx('gpa-object', {
          'gpa-object_canview_open': needCheck && !unit.finished && unit.open,
          'gpa-object_canview': needCheck && !unit.finished && !unit.open,
          'gpa-object_canview_finished': needCheck && unit.finished,
          'gpa-object_open': !needCheck && !unit.finished && unit.open,
          'gpa-object_finished': !needCheck && unit.finished,
          'gpa-object_open_avar': unit.open && !unit.finished && unit.type_s === 'АР',
          'gpa-object_open_garant': unit.open && !unit.finished && unit.type_s === 'ГР',
          'gpa-object_finished_avar': unit.open && unit.finished && unit.type_s === 'АР',
          'gpa-object_finished_garant': unit.open && unit.finished && unit.type_s === 'ГР',
          'gpa-object_pseudo-open_other': unit.open && unit.finished && unit.toother,
          'gpa-object_avar': !unit.open && unit.type_s === 'АР',
          'gpa-object_garant': !unit.open && unit.type_s === 'ГР',
          'gpa-object_closed': unit.closed,
        })}
      >
        <GpaObjectContent unit={unit} />
      </Link>
    ) : (
      <div
        className={cx('gpa-object', {
          'gpa-object_open': !unit.finished && unit.open,
          'gpa-object_finished': unit.finished,
          'gpa-object_open_avar': unit.open && !unit.finished && unit.type_s === 'АР',
          'gpa-object_open_garant': unit.open && !unit.finished && unit.type_s === 'ГР',
          'gpa-object_finished_avar': unit.open && unit.finished && unit.type_s === 'АР',
          'gpa-object_finished_garant': unit.open && unit.finished && unit.type_s === 'ГР',
          'gpa-object_pseudo-open_other': unit.open && unit.finished && unit.toother,
          'gpa-object_avar': !unit.open && unit.type_s === 'АР',
          'gpa-object_garant': !unit.open && unit.type_s === 'ГР',
          'gpa-object_closed': unit.closed,
        })}
      >
        <GpaObjectContent unit={unit} />
      </div>
    )}
  </React.Fragment>
);

GpaObject.propTypes = {
  needCheck: PropTypes.bool.isRequired,
  unit: PropTypes.shape({
    id: PropTypes.number,
    canGoFurther: PropTypes.bool,
    finished: PropTypes.bool,
    open: PropTypes.bool,
    type_s: PropTypes.string,
    closed: PropTypes.bool,
    toother: PropTypes.bool,
  }).isRequired,
};

export default GpaObject;
