import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import FormGroup from 'components/FormGroup/FormGroup';
import Checkbox from 'components/Checkbox/Checkbox';
import SelectForm from 'components/SelectForm/SelectForm';
import InputForm from 'components/InputForm/InputForm';
import TextArea from 'components/TextArea/TextArea';
import InputDate from 'components/InputDate/InputDate';
import Modal from 'components/Modal/Modal';
import ModalError from 'components/ModalError/ModalError';
import RemoveRepairConfirmModal from 'components/RemoveRepairConfirmModal/RemoveRepairConfirmModal';
import Loader from 'components/Loader/Loader';
import SubmitButton from 'components/SubmitButton/SubmitButton';

// Actions
import { getAdminRepairDictionary, getAdminRepair, editAdminRepair } from 'actions';

// Helpers
import { formatDateYYYYmmDD } from 'helpers/formatDateYYYYmmDD';

// Styles
import './RepairAdmin.scss';

const RepairAdmin = ({
  match,
  gpaList,
  privodList,
  blowerList,
  typeList,
  masterList,
  nuList,
  dispatch,
}) => {
  const [outOfPlan, setOutOfPlan] = useState(false);
  const [noPrintProblems, setNoPrintProblems] = useState(false);

  const [gpa, setGpa] = useState(null);
  const [privod, setPrivod] = useState(null);
  const [blower, setBlower] = useState(null);

  const [invNumber, setInvNumber] = useState('');
  const [stationNumber, setStationNumber] = useState('');

  const [mark, setMark] = useState({
    value: null,
    label: 'Выберите примечение при необходимости',
  });
  const [markHand, setMarkHand] = useState('');
  const [isMarkTextAreaVisible, setMarkTextAreaVisible] = useState(false);

  const [type, setType] = useState(null);

  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  const [master, setMaster] = useState(null);
  const [master2, setMaster2] = useState({
    value: '',
    label: 'Нет',
  });

  const [newMaster, setNewMaster] = useState(null);
  const [newMaster2, setNewMaster2] = useState(null);

  const [nu, setNu] = useState(null);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  /** Запрашиваем словари и данные по выбранному ремонту */
  useEffect(() => {
    Promise.all([
      dispatch(getAdminRepairDictionary()),
      dispatch(getAdminRepair(match.params.repairId)),
    ])
      .then(result => {
        const gpas = result && result[0] && result[0].gpa;
        const privods = result && result[0] && result[0].privod;
        const blowers = result && result[0] && result[0].blower;
        const types = result && result[0] && result[0].types;
        const masters = result && result[0] && result[0].masterList;
        const nus = result && result[0] && result[0].nuList;
        const repairData = result && result[1];

        if (result) {
          setIsLoading(false);
        }

        if (gpas && Array.isArray(gpas) && repairData.gpa_sel) {
          setGpa(
            gpas
              .map(item => ({ value: item.id, label: item.name }))
              .find(item => item.value === Number(repairData.gpa_sel)),
          );
        }

        if (privods && Array.isArray(privods) && repairData.privod_sel) {
          setPrivod(
            privods
              .map(item => ({ value: item.id, label: item.name }))
              .find(item => item.value === Number(repairData.privod_sel)),
          );
        }

        if (blowers && Array.isArray(blowers) && repairData.blower_sel) {
          setBlower(
            blowers
              .map(item => ({ value: item.id, label: item.name }))
              .find(item => item.value === Number(repairData.blower_sel)),
          );
        }

        if (types && Array.isArray(types) && repairData.type_sel) {
          setType(
            types
              .map(item => ({ value: item.id, label: item.name }))
              .find(item => item.value === Number(repairData.type_sel)),
          );
        }

        if (nus && Array.isArray(nus) && repairData.nu_sel) {
          setNu(
            nus
              .map(item => ({ value: item.id, label: item.name }))
              .find(item => item.value === Number(repairData.nu_sel)),
          );
        }

        if (masters && Array.isArray(masters) && repairData.master_sel) {
          setMaster(
            masters
              .map(item => ({ value: item.id, label: item.name }))
              .find(item => item.value === Number(repairData.master_sel)),
          );
        }

        if (repairData.master2_sel && repairData.master2) {
          setMaster2({ value: repairData.master2_sel, label: repairData.master2 });
        }

        if (repairData.datestart) {
          setDateStart(new Date(repairData.datestart));
        }

        if (repairData.dateend) {
          setDateEnd(new Date(repairData.dateend));
        }

        if (repairData.invnumber) {
          setInvNumber(repairData.invnumber);
        }

        if (repairData.stationnumber) {
          setStationNumber(repairData.stationnumber);
        }

        if (
          repairData.mark &&
          (repairData.mark === 'с ЭПБ ЦБН' || repairData.mark === 'с ЭПБ ГТУ')
        ) {
          setMark({ value: repairData.mark, label: repairData.mark });
        }

        if (
          repairData.mark &&
          !(repairData.mark === 'с ЭПБ ЦБН' || repairData.mark === 'с ЭПБ ГТУ')
        ) {
          setMarkTextAreaVisible(true);
          setMarkHand(repairData.mark);
          setMark({
            value: 'hand',
            label: 'Ввести примечание вручную',
          });
        }

        if (repairData.outofplan) {
          setOutOfPlan(repairData.outofplan);
        }

        if (repairData.noprintproblems) {
          setNoPrintProblems(repairData.noprintproblems);
        }

        if (repairData.newmaster_1) {
          setNewMaster(repairData.newmaster_1);
        }

        if (repairData.newmaster2_1) {
          setNewMaster2(repairData.newmaster2_1);
        }
      })
      .catch(error => {
        if (error) {
          setIsLoading(false);
        }
      });
  }, [dispatch, match.params.repairId]);

  const [isRemoveRepairConfirmActive, setRemoveRepairConfirmActive] = useState(false);

  const activateRemoveRepairConfirm = () => {
    setRemoveRepairConfirmActive(true);
  };

  const deactivateRemoveRepairConfirm = () => {
    setRemoveRepairConfirmActive(false);
  };

  const submitRepair = () => {
    setIsSubmitLoading(true);

    dispatch(
      editAdminRepair(
        gpa.value,
        privod.value,
        blower.value,
        invNumber,
        stationNumber,
        type.value,
        formatDateYYYYmmDD(dateStart),
        formatDateYYYYmmDD(dateEnd),
        +match.params.repairId,
        +match.params.ksId,
        +match.params.orderManId,
        +match.params.month,
        master.value,
        master2.value,
        nu.value,
        outOfPlan,
        noPrintProblems,
        mark.value,
        markHand,
      ),
    )
      .then(() => {
        if (window && window.location && window.location.href) {
          window.location.href = `/admin/period/${match.params.periodId}`;
        }
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsSubmitLoading(false);
      });
  };

  return (
    <>
      {isLoading && <Loader className="loader_page" />}

      {!isLoading && gpaList && privodList && blowerList && typeList && masterList && nuList && (
        <div className="repair-admin">
          <div className="repair-admin__container container">
            <div className="repair-admin__inner-wrap">
              <h2 className="repair-admin__title">Редактирование объекта</h2>

              <div className="repair-admin__row">
                <div className="repair-admin__col">
                  <FormGroup title="Внеплановый объект" error={fieldErrors.isOutOfPlan}>
                    <div>
                      <Checkbox
                        ariaLabel="Внеплановый объект"
                        checked={outOfPlan}
                        onClick={() => setOutOfPlan(!outOfPlan)}
                      />
                    </div>
                  </FormGroup>
                </div>

                <div className="repair-admin__col">
                  <FormGroup title="Не выводить проблемы" error={fieldErrors.noPrintProblems}>
                    <div>
                      <Checkbox
                        ariaLabel="Не выводить проблемы"
                        checked={noPrintProblems}
                        onClick={() => setNoPrintProblems(!noPrintProblems)}
                      />
                    </div>
                  </FormGroup>
                </div>
              </div>

              <FormGroup title="Тип ГПА" error={fieldErrors.gpa}>
                <SelectForm
                  selectedValue={gpa}
                  options={gpaList.map(item => ({ value: item.id, label: item.name }))}
                  onChange={selectedOption => setGpa(selectedOption)}
                />
              </FormGroup>

              <FormGroup title="Тип привода" error={fieldErrors.privod}>
                <SelectForm
                  selectedValue={privod}
                  options={privodList.map(item => ({ value: item.id, label: item.name }))}
                  onChange={selectedOption => setPrivod(selectedOption)}
                />
              </FormGroup>

              <FormGroup title="Тип нагнетателя" error={fieldErrors.blower}>
                <SelectForm
                  selectedValue={blower}
                  options={blowerList.map(item => ({ value: item.id, label: item.name }))}
                  onChange={selectedOption => setBlower(selectedOption)}
                />
              </FormGroup>

              <FormGroup title="Инвентарный номер" error={fieldErrors.invnumber}>
                <InputForm
                  type="text"
                  value={invNumber}
                  onChange={({ target }) => setInvNumber(target.value)}
                />
              </FormGroup>

              <FormGroup title="Станционный номер" error={fieldErrors.stationnumber}>
                <InputForm
                  type="text"
                  value={stationNumber}
                  onChange={({ target }) => setStationNumber(target.value)}
                />
              </FormGroup>

              <FormGroup title="Примечание" error={fieldErrors.mark}>
                <SelectForm
                  selectedValue={mark}
                  placeholder="Выберите примечение при необходимости"
                  options={[
                    {
                      value: 'с ЭПБ ЦБН',
                      label: 'с ЭПБ ЦБН',
                    },
                    {
                      value: 'с ЭПБ ГТУ',
                      label: 'с ЭПБ ГТУ',
                    },
                    {
                      value: 'hand',
                      label: 'Ввести примечание вручную',
                    },
                  ]}
                  onChange={selectedOption => {
                    setMarkTextAreaVisible(selectedOption.value === 'hand');
                    setMark(selectedOption);
                  }}
                />
              </FormGroup>

              {isMarkTextAreaVisible && (
                <FormGroup title="Текст примечания" error={fieldErrors.markHand}>
                  <TextArea value={markHand} onChange={({ target }) => setMarkHand(target.value)} />
                </FormGroup>
              )}

              <FormGroup title="Вид ремонта" error={fieldErrors.type}>
                <SelectForm
                  selectedValue={type}
                  options={typeList.map(item => ({ value: item.id, label: item.name }))}
                  onChange={selectedOption => setType(selectedOption)}
                />
              </FormGroup>

              <FormGroup title="Дата начала" error={fieldErrors.datestart}>
                <InputDate
                  selectedDate={dateStart}
                  onChange={date => setDateStart(date)}
                  notInModal
                />
              </FormGroup>

              <FormGroup title="Дата окончания" error={fieldErrors.dateend}>
                <InputDate selectedDate={dateEnd} onChange={date => setDateEnd(date)} notInModal />
              </FormGroup>

              <FormGroup title="Мастер" error={fieldErrors.master}>
                <SelectForm
                  selectedValue={master}
                  options={masterList.map(item => ({ value: item.id, label: item.name }))}
                  onChange={selectedOption => setMaster(selectedOption)}
                />
              </FormGroup>

              {newMaster && (
                <FormGroup title="Новый мастер">
                  <div>{newMaster}</div>
                </FormGroup>
              )}

              <FormGroup title="Второй мастер" error={fieldErrors.master2}>
                <SelectForm
                  selectedValue={master2}
                  options={[
                    {
                      value: '',
                      label: 'Нет',
                    },
                    ...masterList.map(item => ({ value: item.id, label: item.name })),
                  ]}
                  onChange={selectedOption => setMaster2(selectedOption)}
                />
              </FormGroup>

              {newMaster2 && (
                <FormGroup title="Новый мастер">
                  <div>{newMaster2}</div>
                </FormGroup>
              )}

              <FormGroup title="Начальник участка" error={fieldErrors.nu}>
                <SelectForm
                  selectedValue={nu}
                  options={nuList.map(item => ({ value: item.id, label: item.name }))}
                  onChange={selectedOption => setNu(selectedOption)}
                />
              </FormGroup>

              {responseError && (
                <ModalError className="repair-admin__error-msg">{responseError}</ModalError>
              )}

              <div className="repair-admin__footer">
                <SubmitButton
                  isLoading={isSubmitLoading}
                  isValid={
                    !gpa ||
                    !privod ||
                    !blower ||
                    invNumber === '' ||
                    stationNumber === '' ||
                    !type ||
                    !dateStart ||
                    !dateEnd ||
                    !master ||
                    !master2 ||
                    !nu ||
                    !mark
                  }
                  isLoadingText="Сохраняю..."
                  className="btn btn-primary"
                  clickHandler={submitRepair}
                >
                  Сохранить
                </SubmitButton>{' '}
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={activateRemoveRepairConfirm}
                >
                  Удалить
                </button>
              </div>

              {isRemoveRepairConfirmActive && (
                <Modal title="Удалить ремонт" closeHandler={deactivateRemoveRepairConfirm}>
                  <RemoveRepairConfirmModal
                    periodId={+match.params.periodId}
                    orderManId={+match.params.orderManId}
                    ksId={+match.params.ksId}
                    month={+match.params.month}
                    repairId={+match.params.repairId}
                    closeHandler={deactivateRemoveRepairConfirm}
                  />
                </Modal>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

RepairAdmin.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      periodId: PropTypes.string,
      repairId: PropTypes.string,
      ksId: PropTypes.string,
      orderManId: PropTypes.string,
      month: PropTypes.string,
    }),
  }).isRequired,
  gpaList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  privodList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  blowerList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  typeList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  masterList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  nuList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
};

RepairAdmin.defaultProps = {
  gpaList: null,
  privodList: null,
  blowerList: null,
  typeList: null,
  masterList: null,
  nuList: null,
};

const mapStateToProps = state => ({
  gpaList: state.mainReducer.adminRepairDictionary.gpa,
  privodList: state.mainReducer.adminRepairDictionary.privod,
  blowerList: state.mainReducer.adminRepairDictionary.blower,
  typeList: state.mainReducer.adminRepairDictionary.types,
  masterList: state.mainReducer.adminRepairDictionary.masterList,
  nuList: state.mainReducer.adminRepairDictionary.nuList,
});

export default connect(mapStateToProps)(RepairAdmin);
