import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const TaskComment = ({ comment, labelType, className }) => {
  return (
    <div
      className={cx('panel', {
        panel_default: !comment.self,
        panel_primary: comment.self,
        [className]: className,
      })}
    >
      <div className="panel__heading">
        <div className="panel__heading-inner">
          {comment.name} <span className="label label_danger">{comment.datetime_2}</span>
        </div>
        <div className="panel__to-right">
          <span
            className={cx('label', {
              label_primary: !labelType,
              [`label_${labelType}`]: labelType,
            })}
          >
            {comment.rolename}
          </span>
        </div>
      </div>
      <div className="panel__body">{comment.text}</div>
    </div>
  );
};

TaskComment.propTypes = {
  labelType: PropTypes.string,
  className: PropTypes.string,
  comment: PropTypes.shape({
    self: PropTypes.bool,
    name: PropTypes.string,
    datetime_2: PropTypes.string,
    rolename: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
};

TaskComment.defaultProps = {
  className: null,
  labelType: null,
};

export default TaskComment;
