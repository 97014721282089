import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import UsersSearch from 'components/UsersSearch/UsersSearch';
import EmploymentTable from 'components/EmploymentTable/EmploymentTable';
import Loader from 'components/Loader/Loader';

// Actions
import { getEmployment } from 'actions';

// Styles
import './Employment.scss';

const Employment = ({ dispatch, employmentData }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  /** Запрашиваем информацию по диспетчерскому отчету */
  useEffect(() => {
    dispatch(getEmployment())
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch]);

  return (
    <>
      {isLoading && <Loader className="loader_page" />}

      {!isLoading && employmentData && (
        <div className="employment">
          <div className="employment__container container">
            <h1 className="employment__title">Занятость работников</h1>

            <div className="employment__search">
              <UsersSearch
                name="employment-search"
                value={searchQuery}
                changeHandler={({ target }) => setSearchQuery(target.value)}
                placeholder="Поиск по пользователям"
              />
            </div>

            {employmentData.priority &&
              employmentData.deps &&
              Array.isArray(employmentData.deps) &&
              employmentData.deps.length > 0 && (
                <EmploymentTable
                  canEdit={employmentData.canEdit}
                  priority={employmentData.priority}
                  departments={employmentData.deps}
                  searchQuery={searchQuery.toLowerCase()}
                />
              )}
          </div>
        </div>
      )}
    </>
  );
};

Employment.propTypes = {
  dispatch: PropTypes.func.isRequired,
  employmentData: PropTypes.shape({
    canEdit: PropTypes.bool,
    priority: PropTypes.number,
    deps: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

const mapStateToProps = state => ({
  employmentData: state.mainReducer.employmentData,
});

export default connect(mapStateToProps)(Employment);
