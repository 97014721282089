import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import SelectForm from 'components/SelectForm/SelectForm';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { emulateUser } from 'actions';

const EmulateUserModal = ({ closeHandler, users, dispatch }) => {
  const [emulateTarget, setEmulateTarget] = useState(
    users.map(user => ({ value: user.id, label: `${user.name_1} (${user.rolename})` }))[0],
  );

  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const handleEmulateUser = () => {
    setIsLoading(true);

    dispatch(emulateUser(emulateTarget.value))
      .then(() => {
        setIsLoading(false);

        if (window && window.location && window.location.href) {
          window.location.href = '/';
        }

        closeHandler();
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Выберите пользователя для эмуляции" />

      <ModalBody>
        <FormGroup title="Пользователь" error={fieldErrors.user}>
          <SelectForm
            options={users.map(user => ({
              value: user.id,
              label: `${user.name_1} (${user.rolename})`,
            }))}
            selectedValue={emulateTarget}
            onChange={selectedOption => setEmulateTarget(selectedOption)}
          />
        </FormGroup>
      </ModalBody>

      {responseError && <ModalError>{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isLoading={isLoading}
          isValid={!emulateTarget}
          isLoadingText="Эмулирую..."
          clickHandler={handleEmulateUser}
        >
          Эмулировать
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

EmulateUserModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      rolename: PropTypes.string,
      name_1: PropTypes.string,
    }),
  ).isRequired,
};

const mapStateToProps = state => ({
  users: state.mainReducer.headerData.users,
});

export default connect(mapStateToProps)(EmulateUserModal);
