import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { deleteAdminRepair } from 'actions';

const RemoveRepairConfirmModal = ({
  closeHandler,
  periodId,
  orderManId,
  ksId,
  month,
  repairId,
  dispatch,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseError, setResponseError] = useState(false);

  const submitDeleteRepair = () => {
    setIsLoading(true);

    dispatch(deleteAdminRepair(orderManId, ksId, month, repairId))
      .then(() => {
        if (window && window.location && window.location.href) {
          window.location.href = `/admin/period/${periodId}`;
        }
      })
      .catch(error => {
        if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader className="modal-header_has-sub-title">
        <h4 className="modal-header__title">Вы уверены,</h4>
        <h5 className="modal-header__sub-title">что хотите безвозвратно удалить этот ремонт?</h5>
      </ModalHeader>

      {responseError && <ModalError>{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Отмена
        </button>

        <SubmitButton
          isLoading={isLoading}
          isLoadingText="Удаляю..."
          clickHandler={submitDeleteRepair}
        >
          Удалить
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

RemoveRepairConfirmModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  periodId: PropTypes.number.isRequired,
  orderManId: PropTypes.number.isRequired,
  ksId: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  repairId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(RemoveRepairConfirmModal);
