import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import TableRepairCoop from 'components/TableRepairCoop/TableRepairCoop';
import Modal from 'components/Modal/Modal';
import AddCoopWorkModal from 'components/AddCoopWorkModal/AddCoopWorkModal';
import Loader from 'components/Loader/Loader';
import Warning from 'components/Warning/Warning';

// Actions
import { getRepairCoWorks } from 'actions/index';

const RepairCoop = ({ canEdit, repairId, dispatch, coWorks }) => {
  const [isAddCoopWorkActive, setAddCoopWorkActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const activateAddCoopWorkModal = () => {
    setAddCoopWorkActive(true);
  };

  const deactivateAddCoopWorkModal = () => {
    setAddCoopWorkActive(false);
  };

  /** Запрашиваем информацию по ремонту (Соисполнители) */
  useEffect(() => {
    dispatch(getRepairCoWorks(repairId))
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch, repairId]);

  return (
    <React.Fragment>
      <div className="repair-content__header">
        <h2 className="repair-content__title">Соисполнители</h2>

        {canEdit && (
          <div className="repair-content__tools">
            <button type="button" className="btn btn-primary" onClick={activateAddCoopWorkModal}>
              Добавить работу
            </button>

            {isAddCoopWorkActive && (
              <Modal title="Добавление работы" closeHandler={deactivateAddCoopWorkModal}>
                <AddCoopWorkModal closeHandler={deactivateAddCoopWorkModal} repairId={repairId} />
              </Modal>
            )}
          </div>
        )}
      </div>

      {isLoading && <Loader />}

      {!isLoading &&
        coWorks &&
        Array.isArray(coWorks) &&
        coWorks.map(maker => (
          <TableRepairCoop key={maker.id} canEdit={canEdit} coWork={maker} repairId={repairId} />
        ))}

      {!isLoading && coWorks && Array.isArray(coWorks) && coWorks.length === 0 && (
        <Warning msg="Пока не добавлено работ" />
      )}
    </React.Fragment>
  );
};

RepairCoop.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  repairId: PropTypes.number.isRequired,
  coWorks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = state => ({
  coWorks: state.mainReducer.repairCoWorks,
});

export default connect(mapStateToProps)(RepairCoop);
