import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Styles
import './NoReceiveList.scss';

const NoReceiveList = ({ noReceive, closePanel }) => {
  return (
    <ul className="no-receive-list">
      {Array.isArray(noReceive) &&
        noReceive.map(item => (
          <li key={item.id} className="no-receive-list__item">
            <div className="no-receive-list__item-info">
              <span className="label label_danger">Необходимо принять узел из ремонта</span> -{' '}
              <Link
                to={`/repair/${item.repairid}/node`}
                className="no-receive-list__item-link"
                onClick={closePanel}
              >
                {item.name}
              </Link>
            </div>
          </li>
        ))}
    </ul>
  );
};

NoReceiveList.propTypes = {
  closePanel: PropTypes.func.isRequired,
  noReceive: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      repairid: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

NoReceiveList.defaultProps = {
  noReceive: null,
};

export default NoReceiveList;
