import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Styles
import './OverdueList.scss';

const OverdueList = ({ overdue, closePanel }) => {
  return (
    <ul className="overdue-list">
      {overdue &&
        Array.isArray(overdue) &&
        overdue.map(item => (
          <li key={item.id} className="overdue-list__item">
            <div className="overdue-list__item-info">
              <span className="label label_danger">Просрочен ремонт</span> -{' '}
              <Link
                to={`/repair/${item.id}`}
                className="overdue-list__item-link"
                onClick={closePanel}
              >
                {item.ksname} {item.gpa} №{item.stationnumber}
              </Link>
            </div>
          </li>
        ))}
    </ul>
  );
};

OverdueList.propTypes = {
  closePanel: PropTypes.func.isRequired,
  overdue: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      ksname: PropTypes.string,
      gpa: PropTypes.string,
      stationnumber: PropTypes.string,
    }),
  ),
};

OverdueList.defaultProps = {
  overdue: null,
};

export default OverdueList;
