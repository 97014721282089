import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import SelectForm from 'components/SelectForm/SelectForm';
import AsyncSelectForm from 'components/AsyncSelectForm/AsyncSelectForm';
import InputForm from 'components/InputForm/InputForm';
import Checkbox from 'components/Checkbox/Checkbox';
import SubmitButton from 'components/SubmitButton/SubmitButton';

// Helpers
import { fetchWrapper } from 'helpers/fetch-wrapper';

// Actions
import { setMtrFilter } from 'actions';

const debounce = (func, ms) => {
  let timer;
  return function a(...args) {
    return new Promise(resolve => {
      const callback = () => {
        resolve(func.apply(this, args));
        timer = null;
      };
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(callback, ms);
    });
  };
};

const getMtrNomenclatures = debounce(inputValue => {
  const url = `${process.env.REACT_APP_API_URL}mtr?term=${inputValue}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        resolve(
          data.results.map(item => ({
            value: +item.id,
            label: item.text,
          })),
        );
      })
      .catch(error => {
        reject(error);
      });
  });
}, 500);

const MtrFiltersModal = ({
  closeHandler,
  gpaOptions,
  objectOptions,
  deliveryOptions,
  specExistOptions,
  filter,
  dispatch,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [gpa, setGpa] = useState(null);
  const [object, setObject] = useState(null);
  const [delivery, setDelivery] = useState(null);

  const [specExist, setSpecExist] = useState(null);
  const [uns, setUns] = useState('');
  const [nomenclature, setNomenclature] = useState(null);

  const [received, setReceived] = useState(false);
  const [included, setIncluded] = useState(false);
  const [charged, setCharged] = useState(false);

  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (filter) {
      setGpa(filter.gpa);
      setObject(filter.object);
      setDelivery(filter.delivery);
      setSpecExist(filter.specExist);
      setUns(filter.uns);
      setNomenclature(filter.nomenclature);
      setReceived(filter.received);
      setIncluded(filter.included);
      setCharged(filter.charged);
    } else {
      if (gpaOptions.length) {
        setGpa(gpaOptions[0]);
      }
      if (objectOptions.length) {
        setObject(objectOptions[0]);
      }
      if (deliveryOptions.length) {
        setDelivery(deliveryOptions[0]);
      }
      if (specExistOptions.length) {
        setSpecExist(specExistOptions[0]);
      }
    }
  }, [filter, gpaOptions, objectOptions, deliveryOptions, specExistOptions]);

  const submitMtrFilter = () => {
    setIsLoading(true);

    setRedirect(true);

    dispatch(
      setMtrFilter(
        {
          gpa,
          object,
          uns,
          nomenclature,
          delivery,
          specExist,
          received,
          included,
          charged,
        },
        {
          temp: true,
        },
      ),
    );
  };

  if (redirect) {
    return <Redirect to="/mtr/list/0" />;
  }

  return (
    <React.Fragment>
      <ModalHeader title="Подобрать по параметрам" />

      <ModalBody>
        <FormGroup title="Тип ГПА">
          <SelectForm
            isClearable
            selectedValue={gpa}
            options={gpaOptions}
            onChange={selectedOption => setGpa(selectedOption)}
          />
        </FormGroup>

        <FormGroup title="Объект">
          <SelectForm
            isClearable
            selectedValue={object}
            options={objectOptions}
            onChange={selectedOption => setObject(selectedOption)}
          />
        </FormGroup>

        <FormGroup title="УНС">
          <InputForm type="text" value={uns} onChange={({ target }) => setUns(target.value)} />
        </FormGroup>

        <FormGroup title="Номенклатура">
          <AsyncSelectForm
            selectedValue={nomenclature}
            onChange={selectedOption => setNomenclature(selectedOption)}
            onLoadOptions={getMtrNomenclatures}
          />
        </FormGroup>

        <FormGroup title="Способ доставки">
          <SelectForm
            isClearable
            selectedValue={delivery}
            options={deliveryOptions}
            onChange={selectedOption => setDelivery(selectedOption)}
          />
        </FormGroup>

        <FormGroup title="Наличие спецификации">
          <SelectForm
            isClearable
            selectedValue={specExist}
            options={specExistOptions}
            onChange={selectedOption => setSpecExist(selectedOption)}
          />
        </FormGroup>

        <FormGroup title="Дополнительная информация">
          <div style={{ marginBottom: '10px' }}>
            <Checkbox
              checked={received}
              ariaLabel="Деталь получена"
              onClick={() => setReceived(prev => !prev)}
              labelRight="Деталь получена"
            />
          </div>

          <div style={{ marginBottom: '10px' }}>
            <Checkbox
              checked={included}
              ariaLabel="Деталь включена в КС-2"
              onClick={() => setIncluded(prev => !prev)}
              labelRight="Деталь включена в КС-2"
            />
          </div>

          <div style={{ marginBottom: '10px' }}>
            <Checkbox
              checked={charged}
              ariaLabel="Деталь списана"
              onClick={() => setCharged(prev => !prev)}
              labelRight="Деталь списана"
            />
          </div>
        </FormGroup>
      </ModalBody>

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isValid={!gpa || !object || !delivery}
          isLoading={isLoading}
          isLoadingText="Поиск..."
          className="btn btn-primary"
          clickHandler={submitMtrFilter}
        >
          Искать
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

const optionType = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

MtrFiltersModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  gpaOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  objectOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deliveryOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  specExistOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filter: PropTypes.shape({
    gpa: optionType,
    object: optionType,
    delivery: optionType,
    uns: PropTypes.string,
    specExist: optionType,
    nomenclature: optionType,
    received: optionType,
    included: optionType,
    charged: optionType,
  }),
};

MtrFiltersModal.defaultProps = {
  filter: null,
};

const mapStateToProps = state => ({
  filter: state.mainReducer.mtrFilter,
});

export default connect(mapStateToProps)(MtrFiltersModal);
