import { fetchWrapper } from 'helpers/fetch-wrapper';
import { generateMtrUrl } from 'helpers/generateMtrUrl';
import * as types from 'actions/action-types';

export const logIn = (login, password) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}users/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        login,
        password,
      }),
    })
      .then(user => {
        dispatch({
          type: types.LOG_IN_SUCCESS,
          payload: {
            user,
          },
        });
        resolve(user);
      })
      .catch(error => {
        dispatch({
          type: types.LOG_IN_FAIL,
        });
        reject(error);
      });
  });
};

export const logOut = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}users/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
    })
      .then(user => {
        dispatch({
          type: types.LOG_OUT_SUCCESS,
          payload: {
            user,
          },
        });
        resolve(user);
      })
      .catch(error => {
        dispatch({
          type: types.LOG_OUT_FAIL,
        });
        reject(error);
      });
  });
};

export const disposeUser = () => ({
  type: types.DISPOSE_USER,
});

export const getUser = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}users/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(user => {
        dispatch({
          type: types.GET_USER_SUCCESS,
          payload: {
            user,
          },
        });
        resolve(user);
      })
      .catch(error => {
        dispatch({
          type: types.GET_USER_FAIL,
        });
        reject(error);
      });
  });
};

export const getRepairsList = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPAIRS_LIST_SUCCESS,
          payload: data,
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPAIRS_LIST_FAIL,
        });
        reject(error);
      });
  });
};

export const getRepairInfo = id => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_ainfo_b${id}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPAIR_INFO_SUCCESS,
          payload: data,
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPAIR_INFO_FAIL,
        });
        reject(error);
      });
  });
};

export const getRepairWorkProgress = id => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aworks_b${id}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPAIR_WORK_PROGRESS_SUCCESS,
          payload: data,
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPAIR_WORK_PROGRESS_FAIL,
        });
        reject(error);
      });
  });
};

export const getRepairProblems = id => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aproblems_b${id}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPAIR_PROBLEMS_SUCCESS,
          payload: data,
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPAIR_PROBLEMS_FAIL,
        });
        reject(error);
      });
  });
};

export const getRepairProblemTargets = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aproblemsTargets/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPAIR_PROBLEM_TARGETS_SUCCESS,
          payload: data,
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPAIR_PROBLEM_TARGETS_FAIL,
        });
        reject(error);
      });
  });
};

export const addRepairWork = (repairId, date, name, accordance) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_awork_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        date,
        name,
        accordance,
      }),
    })
      .then(data => {
        dispatch({
          type: types.ADD_REPAIR_WORK_SUCCESS,
          payload: data,
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_REPAIR_WORK_FAIL,
        });
        reject(error);
      });
  });
};

export const deleteRepairWork = (repairId, workId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_awork_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
      body: JSON.stringify({
        id: workId,
      }),
    })
      .then(data => {
        dispatch({
          type: types.DELETE_REPAIR_WORK_SUCCESS,
          payload: data,
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.DELETE_REPAIR_WORK_FAIL,
        });
        reject(error);
      });
  });
};

export const editRepairWork = (name, date, accordance, repairId, workId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_awork_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        name,
        date,
        accordance,
        id: workId,
      }),
    })
      .then(data => {
        dispatch({
          type: types.EDIT_REPAIR_WORK_SUCCESS,
          payload: data,
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.EDIT_REPAIR_WORK_FAIL,
        });
        reject(error);
      });
  });
};

export const setRepairWorkWeek = (week, repairId, text) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aweekWork_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        week,
        text,
      }),
    })
      .then(data => {
        dispatch({
          type: types.SET_REPAIR_WORK_WEEK_SUCCESS,
          payload: data,
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.SET_REPAIR_WORK_WEEK_FAIL,
        });
        reject(error);
      });
  });
};

export const setOpenRepair = (repairId, datestart, dateend) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aopenRepair_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        datestart,
        dateend,
      }),
    })
      .then(data => {
        dispatch({
          type: types.SET_OPEN_REPAIR_SUCCESS,
          payload: data,
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.SET_OPEN_REPAIR_FAIL,
        });
        reject(error);
      });
  });
};

export const setFinishRepair = (repairId, date) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_afinishRepair_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        date,
      }),
    })
      .then(data => {
        dispatch({
          type: types.SET_FINISH_REPAIR_SUCCESS,
          payload: {
            finished: true,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.SET_FINISH_REPAIR_FAIL,
        });
        reject(error);
      });
  });
};

export const setCloseRepair = repairId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_acloseRepair_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
    })
      .then(data => {
        dispatch({
          type: types.SET_CLOSE_REPAIR_SUCCESS,
          payload: {
            closed: true,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.SET_CLOSE_REPAIR_FAIL,
        });
        reject(error);
      });
  });
};

export const setNoDispatch = (repairId, value) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_anoDispatch_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
    })
      .then(data => {
        dispatch({
          type: types.SET_NO_DISPATCH_SUCCESS,
          payload: {
            noDispatch: value,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.SET_NO_DISPATCH_FAIL,
        });
        reject(error);
      });
  });
};

export const setRepairComment = (repairId, comment) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_acomment_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        comment,
      }),
    })
      .then(data => {
        dispatch({
          type: types.SET_REPAIR_COMMENT_SUCCESS,
          payload: {
            comment,
            showComment: true,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.SET_REPAIR_COMMENT_FAIL,
        });
        reject(error);
      });
  });
};

export const deleteRepairProblem = (repairId, problemId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aproblems_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
      body: JSON.stringify({
        id: problemId,
      }),
    })
      .then(data => {
        dispatch({
          type: types.DELETE_REPAIR_PROBLEM_SUCCESS,
          payload: {
            problemId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.DELETE_REPAIR_PROBLEM_FAIL,
        });
        reject(error);
      });
  });
};

export const addRepairProblem = (repairId, name, target, dateEnd) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aproblems_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        name,
        target,
        dateEnd,
      }),
    })
      .then(data => {
        dispatch({
          type: types.ADD_REPAIR_PROBLEM_SUCCESS,
          payload: {
            problem: data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_REPAIR_PROBLEM_FAIL,
        });
        reject(error);
      });
  });
};

export const setRepairProblemSolved = (repairId, problemId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aproblemsSolved_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        id: problemId,
      }),
    })
      .then(data => {
        dispatch({
          type: types.SET_REPAIR_PROBLEM_SOLVED_SUCCESS,
          payload: {
            problemId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.SET_REPAIR_PROBLEM_SOLVED_FAIL,
        });
        reject(error);
      });
  });
};

export const setRepairReturnProblem = (repairId, problemId, comment) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aproblemsReturn_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        id: problemId,
        comment,
      }),
    })
      .then(problem => {
        dispatch({
          type: types.SET_REPAIR_RETURN_PROBLEM_SUCCESS,
          payload: {
            problemId,
            problem,
          },
        });
        resolve(problem);
      })
      .catch(error => {
        dispatch({
          type: types.SET_REPAIR_RETURN_PROBLEM_FAIL,
        });
        reject(error);
      });
  });
};

export const setRepairTakeoffProblem = (repairId, problemId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aproblemsTakeOff_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        id: problemId,
      }),
    })
      .then(data => {
        dispatch({
          type: types.SET_REPAIR_TAKEOFF_PROBLEM_SUCCESS,
          payload: {
            problemId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.SET_REPAIR_TAKEOFF_PROBLEM_FAIL,
        });
        reject(error);
      });
  });
};

export const uploadProblemDocument = (
  repairId,
  problemId,
  documentIndex,
  fileUrl,
  action = 'add',
) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aproblemsMultiDocs_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        id: problemId,
        index: documentIndex,
        url: fileUrl,
        action,
      }),
    })
      .then(data => {
        dispatch({
          type: types.UPLOAD_PROBLEM_DOCUMENT_SUCCESS,
          payload: {
            problemId,
            files: data,
          },
        });
        resolve();
      })
      .catch(error => {
        dispatch({
          type: types.UPLOAD_PROBLEM_DOCUMENT_FAIL,
        });
        reject(error);
      });
  });
};

/** Repair Additional Works */
export const getRepairAddWorks = repairId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aadditional_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPAIR_ADDITIONAL_WORKS_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPAIR_ADDITIONAL_WORKS_FAIL,
        });
        reject(error);
      });
  });
};

export const addRepairAdditionalWork = (repairId, name, date) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aadditional_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        name,
        date,
      }),
    })
      .then(additionalWork => {
        dispatch({
          type: types.ADD_REPAIR_ADDITIONAL_WORK_SUCCESS,
          payload: {
            additionalWork,
          },
        });
        resolve(additionalWork);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_REPAIR_ADDITIONAL_WORK_FAIL,
        });
        reject(error);
      });
  });
};

export const editRepairAdditionalWork = (repairId, workId, name, date) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aadditional_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        repairId,
        id: workId,
        name,
        date,
      }),
    })
      .then(additionalWork => {
        dispatch({
          type: types.EDIT_REPAIR_ADDITIONAL_WORK_SUCCESS,
          payload: {
            additionalWork,
            workId,
          },
        });
        resolve(additionalWork);
      })
      .catch(error => {
        dispatch({
          type: types.EDIT_REPAIR_ADDITIONAL_WORK_FAIL,
        });
        reject(error);
      });
  });
};

export const deleteRepairAdditionalWork = (repairId, workId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aadditional_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
      body: JSON.stringify({
        id: workId,
      }),
    })
      .then(data => {
        dispatch({
          type: types.DELETE_REPAIR_ADDITIONAL_WORK_SUCCESS,
          payload: {
            workId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.DELETE_REPAIR_ADDITIONAL_WORK_FAIL,
        });
        reject(error);
      });
  });
};

export const setRepairAdditionalWorkReady = (repairId, workId, value) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aadditionalChangeReady_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        id: workId,
        ready: value,
      }),
    })
      .then(work => {
        dispatch({
          type: types.SET_REPAIR_ADDITIONAL_WORK_READY_SUCCESS,
          payload: {
            workId,
            value,
          },
        });
        resolve(work);
      })
      .catch(error => {
        dispatch({
          type: types.SET_REPAIR_ADDITIONAL_WORK_READY_FAIL,
        });
        reject(error);
      });
  });
};

export const setRepairAdditionalWorkPaid = (repairId, workId, value) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aadditionalChangePaid_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        id: workId,
        paid: value,
      }),
    })
      .then(work => {
        dispatch({
          type: types.SET_REPAIR_ADDITIONAL_WORK_PAID_SUCCESS,
          payload: {
            workId,
            value,
          },
        });
        resolve(work);
      })
      .catch(error => {
        dispatch({
          type: types.SET_REPAIR_ADDITIONAL_WORK_PAID_FAIL,
        });
        reject(error);
      });
  });
};
/** Repair Additional Works END */

/** Repair Roster */
export const getRepairRosterTeam = repairId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aroster_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPAIR_ROSTER_TEAM_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPAIR_ROSTER_TEAM_FAIL,
        });
        reject(error);
      });
  });
};

export const getRosterRepairmanList = repairId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_arosterRepairMen_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_ROSTER_REPAIRMAN_LIST_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_ROSTER_REPAIRMAN_LIST_FAIL,
        });
        reject(error);
      });
  });
};

export const addRepairmanToRoster = (repairId, repairmanId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aroster_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        id: repairmanId,
      }),
    })
      .then(repairman => {
        dispatch({
          type: types.ADD_REPAIRMAN_TO_ROSTER_SUCCESS,
          payload: {
            repairman,
          },
        });
        resolve(repairman);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_REPAIRMAN_TO_ROSTER_FAIL,
        });
        reject(error);
      });
  });
};

export const deleteRepairmanFromRoster = (repairId, repairmanId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aroster_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
      body: JSON.stringify({
        id: repairmanId,
      }),
    })
      .then(data => {
        dispatch({
          type: types.DELETE_REPAIRMAN_FROM_ROSTER_SUCCESS,
          payload: {
            repairmanId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.DELETE_REPAIRMAN_FROM_ROSTER_FAIL,
        });
        reject(error);
      });
  });
};

export const setRosterRepairmanActive = (repairId, repairmanId, active) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_arosterActivate_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        id: repairmanId,
        active,
      }),
    })
      .then(repairman => {
        dispatch({
          type: types.SET_ROSTER_REPAIRMAN_ACTIVE_SUCCESS,
          payload: {
            repairmanId,
            active,
          },
        });
        resolve(repairman);
      })
      .catch(error => {
        dispatch({
          type: types.SET_ROSTER_REPAIRMAN_ACTIVE_FAIL,
        });
        reject(error);
      });
  });
};
/** Repair Roster END */

/** Repair coop */
export const getRepairCoWorks = repairId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_acoop_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPAIR_CO_WORKS_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPAIR_CO_WORKS_FAIL,
        });
        reject(error);
      });
  });
};

export const addRepairCoWork = (repairId, maker, name, date) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_acoop_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        maker,
        name,
        date,
      }),
    })
      .then(coWork => {
        dispatch({
          type: types.ADD_REPAIR_CO_WORK_SUCCESS,
          payload: {
            coWork,
          },
        });
        resolve(coWork);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_REPAIR_CO_WORK_FAIL,
        });
        reject(error);
      });
  });
};

export const deleteRepairCoWork = (repairId, makerId, workId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_acoop_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
      body: JSON.stringify({
        id: workId,
      }),
    })
      .then(data => {
        dispatch({
          type: types.DELETE_REPAIR_CO_WORK_SUCCESS,
          payload: {
            makerId,
            workId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.DELETE_REPAIR_CO_WORK_FAIL,
        });
        reject(error);
      });
  });
};

export const editRepairCoWork = (repairId, workId, name, date) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_acoop_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        id: workId,
        name,
        date,
      }),
    })
      .then(coWorkItem => {
        dispatch({
          type: types.EDIT_REPAIR_CO_WORK_SUCCESS,
          payload: {
            coWorkItem,
            workId,
          },
        });
        resolve(coWorkItem);
      })
      .catch(error => {
        dispatch({
          type: types.EDIT_REPAIR_CO_WORK_FAIL,
        });
        reject(error);
      });
  });
};
/** Repair coop END */

/** Repair prolong */
export const getRepairProlongs = repairId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aprolongs_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPAIR_PROLONGS_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPAIR_PROLONGS_FAIL,
        });
        reject(error);
      });
  });
};

export const addRepairProlong = (repairId, schedule, act, date, reason, comment) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aprolongs_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        schedule,
        act,
        newdate: date,
        reason,
        comment,
      }),
    })
      .then(prolong => {
        dispatch({
          type: types.ADD_REPAIR_PROLONG_SUCCESS,
          payload: {
            prolong,
          },
        });
        resolve(prolong);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_REPAIR_PROLONG_FAIL,
        });
        reject(error);
      });
  });
};

export const setRepairProlongReaction = (repairId, prolongId, reaction, reason) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aprolongsReact_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        confirm: reaction,
        rejectReason: reason,
        prolongId,
      }),
    })
      .then(prolong => {
        dispatch({
          type: types.SET_REPAIR_PROLONG_REACTION_SUCCESS,
          payload: {
            prolong,
            prolongId,
          },
        });
        resolve(prolong);
      })
      .catch(error => {
        dispatch({
          type: types.SET_REPAIR_PROLONG_REACTION_FAIL,
        });
        reject(error);
      });
  });
};

export const repairProlongMoveToOther = (
  repairId,
  schedule,
  act,
  newDate,
  newDateForOther,
  reason,
  comment,
) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_aprolongsMoveToOther_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        schedule,
        act,
        newdate: newDate,
        newdateForOther: newDateForOther,
        reason,
        comment,
      }),
    })
      .then(prolong => {
        dispatch({
          type: types.REPAIR_PROLONG_MOVE_TO_OTHER_SUCCESS,
          payload: {
            prolong,
          },
        });
        resolve(prolong);
      })
      .catch(error => {
        dispatch({
          type: types.REPAIR_PROLONG_MOVE_TO_OTHER_FAIL,
        });
        reject(error);
      });
  });
};
/** Repair prolong END */

/** Repair docs */
export const getRepairDocs = repairId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_adocs_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPAIR_DOCS_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPAIR_DOCS_FAIL,
        });
        reject(error);
      });
  });
};

export const addRepairCustomDoc = (repairId, name) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_adocsCustom_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        name,
      }),
    })
      .then(customDoc => {
        dispatch({
          type: types.ADD_REPAIR_CUSTOM_DOC_SUCCESS,
          payload: {
            customDoc: {
              ...customDoc,
              files: [
                {
                  file: '',
                },
              ],
            },
          },
        });
        resolve(customDoc);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_REPAIR_CUSTOM_DOC_FAIL,
        });
        reject(error);
      });
  });
};

export const deleteRepairCustomDoc = (repairId, docId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_adocsCustom_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
      body: JSON.stringify({
        docId,
      }),
    })
      .then(data => {
        dispatch({
          type: types.DELETE_REPAIR_CUSTOM_DOC_SUCCESS,
          payload: {
            docId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.DELETE_REPAIR_CUSTOM_DOC_FAIL,
        });
        reject(error);
      });
  });
};

export const changeRepairDocNoNeed = (repairId, type, value) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_adocsChangeNoNeed_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        type,
        value,
      }),
    })
      .then(doc => {
        dispatch({
          type: types.CHANGE_REPAIR_DOC_NO_NEED_SUCCESS,
          payload: {
            type,
            value,
          },
        });
        resolve(doc);
      })
      .catch(error => {
        dispatch({
          type: types.CHANGE_REPAIR_DOC_NO_NEED_FAIL,
        });
        reject(error);
      });
  });
};

export const setRepairUnitWorkHours = (
  repairId,
  nar1Value,
  nar2Value,
  nar3Value,
  nar4Value,
  nar5Value,
) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_adocsWorkHours_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        nar1: nar1Value,
        nar2: nar2Value,
        nar3: nar3Value,
        nar4: nar4Value,
        nar5: nar5Value,
      }),
    })
      .then(data => {
        dispatch({
          type: types.SET_REPAIR_UNIT_WORK_HOURS_SUCCESS,
          payload: {
            nar1Value,
            nar2Value,
            nar3Value,
            nar4Value,
            nar5Value,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.SET_REPAIR_UNIT_WORK_HOURS_FAIL,
        });
        reject(error);
      });
  });
};

export const uploadRepairMultiDocument = (
  repairId,
  type,
  documentIndex,
  fileUrl,
  action = 'add',
  isCustomDocument = false,
) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_adocsMultiDocs_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        url: fileUrl,
        type,
        index: documentIndex,
        action,
        isCustomDocument,
      }),
    })
      .then(data => {
        dispatch({
          type: types.UPLOAD_REPAIR_MULTI_DOCUMENT_SUCCESS,
          payload: {
            type,
            isCustomDocument,
            files: data,
          },
        });
        resolve();
      })
      .catch(error => {
        dispatch({
          type: types.UPLOAD_REPAIR_MULTI_DOCUMENT_FAIL,
        });
        reject(error);
      });
  });
};

export const uploadRepairDocument = (repairId, type, fileUrl, action = 'add') => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_adocsDocs_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        url: fileUrl,
        type,
        action,
      }),
    })
      .then(data => {
        dispatch({
          type: types.UPLOAD_REPAIR_DOCUMENT_SUCCESS,
          payload: {
            data,
            type,
          },
        });
        resolve();
      })
      .catch(error => {
        dispatch({
          type: types.UPLOAD_REPAIR_DOCUMENT_FAIL,
        });
        reject(error);
      });
  });
};
/** Repair docs END */

/** Repair Mtr END */
export const getRepairMtr = repairId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_amtr_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPAIR_MTR_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPAIR_MTR_FAIL,
        });
        reject(error);
      });
  });
};

export const setRepairMtrIncluded = (repairId, mtrId, mode) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_amtr_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        id: mtrId,
        mode,
      }),
    })
      .then(data => {
        dispatch({
          type: types.SET_REPAIR_MTR_INCLUDED_SUCCESS,
          payload: {
            data,
            mtrId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.SET_REPAIR_MTR_INCLUDED_FAIL,
        });
        reject(error);
      });
  });
};

export const setRepairMtrReceived = (repairId, mtrId, mode) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_amtr_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        id: mtrId,
        mode,
      }),
    })
      .then(data => {
        dispatch({
          type: types.SET_REPAIR_MTR_RECEIVED_SUCCESS,
          payload: {
            data,
            mtrId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.SET_REPAIR_MTR_RECEIVED_FAIL,
        });
        reject(error);
      });
  });
};

export const setRepairMtrCharged = (repairId, mtrId, mode) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_amtr_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        id: mtrId,
        mode,
      }),
    })
      .then(data => {
        dispatch({
          type: types.SET_REPAIR_MTR_CHARGED_SUCCESS,
          payload: {
            data,
            mtrId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.SET_REPAIR_MTR_CHARGED_FAIL,
        });
        reject(error);
      });
  });
};
/** Repair Mtr END */

/** Repair Node */
export const getRepairNodes = repairId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_anode_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPAIR_NODES_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPAIR_NODES_FAIL,
        });
        reject(error);
      });
  });
};

export const addRepairNode = (repairId, name, rmmId, dateSend, act) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_anode_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        name,
        rmmId,
        dateSend,
        act,
      }),
    })
      .then(node => {
        dispatch({
          type: types.ADD_REPAIR_NODE_SUCCESS,
          payload: {
            node,
          },
        });
        resolve(node);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_REPAIR_NODE_FAIL,
        });
        reject(error);
      });
  });
};

export const updateActRepairNode = (act, nodeId, repairId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_anodeUpdateAct_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        nodeId,
        act,
      }),
    })
      .then(data => {
        dispatch({
          type: types.UPDATE_ACT_REPAIR_NODE_SUCCESS,
          payload: {
            nodeId,
            data,
          },
        });
        resolve(data);
      })
      .catch(reject);
  });
};

export const confirmDateRepairNode = (repairId, nodeId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_anodeConfirmDate_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        nodeId,
      }),
    })
      .then(data => {
        dispatch({
          type: types.CONFIRM_DATE_REPAIR_NODE_SUCCESS,
          payload: {
            nodeId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.CONFIRM_DATE_REPAIR_NODE_FAIL,
        });
        reject(error);
      });
  });
};

export const receiveRepairNode = (repairId, nodeId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}repair/_anodeReceive_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        nodeId,
      }),
    })
      .then(data => {
        dispatch({
          type: types.RECEIVE_REPAIR_NODE_SUCCESS,
          payload: {
            nodeId,
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.RECEIVE_REPAIR_NODE_FAIL,
        });
        reject(error);
      });
  });
};

export const resetRepairNodeUnreadCount = nodeId => ({
  type: types.RESET_REPAIR_NODE_UNREAD_COUNT_SUCCESS,
  payload: {
    nodeId,
  },
});
/** Repair Node END */

/** Диспетчерский отчет */
export const getDispatch = archive => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}${archive ? 'dispatch/_aarchive/' : 'dispatch/'}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_DISPATCH_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_DISPATCH_FAIL,
        });
        reject(error);
      });
  });
};

export const toggleCollapseDispatchTableRows = (id, collapsed) => ({
  type: types.TOGGLE_COLLAPSE_DISPATCH_TABLE_ROWS,
  payload: {
    id,
    collapsed,
  },
});
/** Диспетчерский отчет END */

/** Информация по меню внизу сайта */
export const getNotifications = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}_anotifications/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_NOTIFICATIONS_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_NOTIFICATIONS_FAIL,
        });
        reject(error);
      });
  });
};
/** Информация по меню внизу сайта END */

/** Задачи */
export const addTask = (name, text, dateEnd, target) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}_atask/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        name,
        text,
        dateEnd,
        target: target.value,
      }),
    })
      .then(task => {
        dispatch({
          type: types.ADD_TASK_SUCCESS,
          payload: {
            task,
            role: target.role,
          },
        });
        resolve(task);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_TASK_FAIL,
        });
        reject(error);
      });
  });
};

export const getMyTaskTargets = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}_ataskTargets/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(targets => {
        dispatch({
          type: types.GET_MY_TASK_TARGETS_SUCCESS,
          payload: {
            targets,
          },
        });
        resolve(targets);
      })
      .catch(error => {
        dispatch({
          type: types.GET_MY_TASK_TARGETS_FAIL,
        });
        reject(error);
      });
  });
};

export const editTask = (taskId, name, text, dateEnd, target) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}_atask_b${taskId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        id: taskId,
        name,
        text,
        dateEnd,
        target,
      }),
    })
      .then(task => {
        dispatch({
          type: types.EDIT_TASK_SUCCESS,
          payload: {
            task,
          },
        });
        resolve(task);
      })
      .catch(error => {
        dispatch({
          type: types.EDIT_TASK_FAIL,
        });
        reject(error);
      });
  });
};

export const setTaskDone = taskId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}_ataskDone_b${taskId}//`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        id: taskId,
        done: true,
      }),
    })
      .then(data => {
        dispatch({
          type: types.SET_TASK_DONE_SUCCESS,
          payload: {
            id: taskId,
            done: true,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.SET_TASK_DONE_FAIL,
        });
        reject(error);
      });
  });
};

export const setTaskDoneApprove = taskId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}_ataskDoneApprove_b${taskId}//`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        id: taskId,
        doneapproved: true,
      }),
    })
      .then(data => {
        dispatch({
          type: types.SET_TASK_DONE_APPROVE_SUCCESS,
          payload: {
            id: taskId,
            doneapproved: true,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.SET_TASK_DONE_APPROVE_FAIL,
        });
        reject(error);
      });
  });
};

export const setTaskDoneDeny = taskId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}_ataskDoneDeny_b${taskId}//`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
      body: JSON.stringify({
        id: taskId,
      }),
    })
      .then(data => {
        dispatch({
          type: types.SET_TASK_DONE_DENY_SUCCESS,
          payload: {
            id: taskId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.SET_TASK_DONE_DENY_FAIL,
        });
        reject(error);
      });
  });
};

export const getTaskComments = taskId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}_ataskComments_b${taskId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(comments => {
        dispatch({
          type: types.GET_TASK_COMMENTS_SUCCESS,
          payload: {
            comments,
          },
        });
        resolve(comments);
      })
      .catch(error => {
        dispatch({
          type: types.GET_TASK_COMMENTS_FAIL,
        });
        reject(error);
      });
  });
};

export const addTaskComment = (taskId, text) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}_ataskComments_b${taskId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        text,
      }),
    })
      .then(comment => {
        dispatch({
          type: types.ADD_TASK_COMMENT_SUCCESS,
          payload: {
            comment,
          },
        });
        resolve(comment);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_TASK_COMMENT_FAIL,
        });
        reject(error);
      });
  });
};

export const uploadTaskDocument = (
  taskId,
  taskType,
  documentIndex,
  fileUrl,
  action = 'add',
) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}_ataskMultiDocs/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        id: taskId,
        index: documentIndex,
        url: fileUrl,
        action,
      }),
    })
      .then(data => {
        dispatch({
          type: types.UPLOAD_TASK_DOCUMENT_SUCCESS,
          payload: {
            taskId,
            taskType,
            files: data,
          },
        });
        resolve();
      })
      .catch(reject);
  });
};

export const resetTaskUnreadCount = (taskId, taskType) => ({
  type: types.RESET_TASK_UNREAD_COUNT_SUCCESS,
  payload: {
    taskId,
    taskType,
  },
});
/** Задачи END */

/** Свод дополнительных работ */
export const getReportAdditionalWorks = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}reportadd/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPORT_ADDITIONAL_WORKS_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPORT_ADDITIONAL_WORKS_FAIL,
        });
        reject(error);
      });
  });
};
/** Свод дополнительных работ END */

/** Свод работ соисполнителей */
export const getReportCoWorks = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}reportcowork/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPORT_CO_WORKS_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPORT_CO_WORKS_FAIL,
        });
        reject(error);
      });
  });
};
/** Свод работ соисполнителей END */

/** Свод текущий отчет */
export const getReportProblems = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}report/_aproblem/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPORT_PROBLEMS_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPORT_PROBLEMS_FAIL,
        });
        reject(error);
      });
  });
};

export const getReportTasks = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}report/_atask/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPORT_TASKS_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPORT_TASKS_FAIL,
        });
        reject(error);
      });
  });
};

export const getReportRepairsMissDocs = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}report/_arepairDocument/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPORT_MISS_DOCS_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPORT_MISS_DOCS_FAIL,
        });
        reject(error);
      });
  });
};

export const getReportMissDocsRmm = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}report/_anodeDocument/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPORT_MISS_DOCS_RMM_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPORT_MISS_DOCS_RMM_FAIL,
        });
        reject(error);
      });
  });
};

export const getReportNodesNotReceived = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}report/_anodeNotReceived/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPORT_NODES_NOT_RECEIVED_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPORT_NODES_NOT_RECEIVED_FAIL,
        });
        reject(error);
      });
  });
};

export const getReportRepairsOverdue = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}report/_arepairOverdue/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPORT_REPAIRS_OVERDUE_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPORT_REPAIRS_OVERDUE_FAIL,
        });
        reject(error);
      });
  });
};

export const getReportNodesNoForecast = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}report/_anodeNoForecast/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPORT_NODES_NO_FORECAST_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPORT_NODES_NO_FORECAST_FAIL,
        });
        reject(error);
      });
  });
};

export const getReportNodesOverdue = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}report/_anodeOverdue/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPORT_NODES_OVERDUE_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPORT_NODES_OVERDUE_FAIL,
        });
        reject(error);
      });
  });
};

export const getReportNoNar = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}report/_arepairNoNar/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPORT_NO_NAR_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPORT_NO_NAR_FAIL,
        });
        reject(error);
      });
  });
};

export const getReportNoReportRepairs = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}report/_arepairNoReport/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_REPORT_NO_REPORT_REPAIRS_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_REPORT_NO_REPORT_REPAIRS_FAIL,
        });
        reject(error);
      });
  });
};
/** Свод текущий отчет END */

/** РММ */
export const getRmm = (rmmBase, isArchive, currentPage) => dispatch => {
  let url = `${process.env.REACT_APP_API_URL}${rmmBase}/_p${currentPage}`;

  url = isArchive ? `${url}_vararchive1/` : `${url}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_RMM_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_RMM_FAIL,
        });
        reject(error);
      });
  });
};

export const linkNodeToRepair = (nodeId, repairId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rmm/_alink_b${nodeId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        repairId,
      }),
    })
      .then(data => {
        dispatch({
          type: types.LINK_NODE_TO_REPAIR_SUCCESS,
          payload: {
            repair: data,
            nodeId,
          },
        });
        resolve(data);
      })
      .catch(reject);
  });
};

export const sendRmmNodeToArchive = nodeId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rmm/_aarchive_b${nodeId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
    })
      .then(data => {
        dispatch({
          type: types.SEND_RMM_NODE_TO_ARCHIVE_SUCCESS,
          payload: {
            id: nodeId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.SEND_RMM_NODE_TO_ARCHIVE_FAIL,
        });
        reject(error);
      });
  });
};

export const moveRmmNodeToOtherBase = nodeId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rmm/_amove_b${nodeId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
    })
      .then(data => {
        dispatch({
          type: types.MOVE_RMM_NODE_TO_OTHER_BASE_SUCCESS,
          payload: {
            id: nodeId,
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.MOVE_RMM_NODE_TO_OTHER_BASE_FAIL,
        });
        reject(error);
      });
  });
};

export const deleteRmmNode = nodeId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rmm/_b${nodeId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
    })
      .then(data => {
        dispatch({
          type: types.DELETE_RMM_NODE_SUCCESS,
          payload: {
            id: nodeId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.DELETE_RMM_NODE_FAIL,
        });
        reject(error);
      });
  });
};

export const changeRmmNodePriority = nodeId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rmm/_achangePriority_b${nodeId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
    })
      .then(data => {
        dispatch({
          type: types.SET_RMM_NODE_PRIORITY_SUCCESS,
          payload: {
            id: nodeId,
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.SET_RMM_NODE_PRIORITY_FAIL,
        });
        reject(error);
      });
  });
};

export const changeRmmDocNoNeed = (nodeId, type) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rmm/_achangeNoNeed_b${nodeId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        type,
      }),
    })
      .then(data => {
        dispatch({
          type: types.CHANGE_RMM_DOC_NO_NEED_SUCCESS,
          payload: {
            nodeId,
            data,
          },
        });
        resolve(data);
      })
      .catch(reject);
  });
};

export const uploadRMMDocument = (nodeId, type, fileUrl, action = 'add') => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rmm/_adoc_b${nodeId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        type,
        url: fileUrl,
        action,
      }),
    })
      .then(data => {
        dispatch({
          type: types.UPLOAD_RMM_DOCUMENT_SUCCESS,
          payload: {
            nodeId,
            type,
            data,
          },
        });
        resolve(data);
      })
      .catch(reject);
  });
};

export const setRmmNodeReceive = (nodeId, dateReceipt, dateForecast) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rmm/_areceive_b${nodeId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        dateReceipt,
        dateForecast,
      }),
    })
      .then(data => {
        dispatch({
          type: types.SET_RMM_NODE_RECEIVE_SUCCESS,
          payload: {
            id: nodeId,
            dateReceipt,
            dateForecast,
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.SET_RMM_NODE_RECEIVE_FAIL,
        });
        reject(error);
      });
  });
};

export const changeRmmNodeDateForecast = (nodeId, dateForecast) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rmm/_achangeForecastDate_b${nodeId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        dateForecast,
      }),
    })
      .then(data => {
        dispatch({
          type: types.CHANGE_RMM_NODE_DATE_FORECAST_SUCCESS,
          payload: {
            id: nodeId,
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.CHANGE_RMM_NODE_DATE_FORECAST_FAIL,
        });
        reject(error);
      });
  });
};

export const addRmmNode = (name, dateReceipt, dateForecast, rmmId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rmm/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        name,
        dateReceipt,
        dateForecast,
        rmmId,
      }),
    })
      .then(node => {
        dispatch({
          type: types.ADD_RMM_NODE_SUCCESS,
          payload: {
            node,
          },
        });
        resolve(node);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_RMM_NODE_FAIL,
        });
        reject(error);
      });
  });
};

export const addRmmNodePercent = (nodeId, percent) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rmm/_apercent_b${nodeId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        percent,
      }),
    })
      .then(percentNode => {
        dispatch({
          type: types.ADD_RMM_NODE_PERCENT_SUCCESS,
          payload: {
            id: nodeId,
            percentNode,
            percent,
          },
        });
        resolve(percentNode);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_RMM_NODE_PERCENT_FAIL,
        });
        reject(error);
      });
  });
};

export const getRmmNodeComments = nodeId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rmm/_acomment_b${nodeId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(comments => {
        dispatch({
          type: types.GET_RMM_NODE_COMMENTS_SUCCESS,
          payload: {
            id: nodeId,
            comments,
          },
        });
        resolve(comments);
      })
      .catch(error => {
        dispatch({
          type: types.GET_RMM_NODE_COMMENTS_FAIL,
        });
        reject(error);
      });
  });
};

export const addRmmNodeComment = (nodeId, text) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rmm/_acomment_b${nodeId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        text,
      }),
    })
      .then(comment => {
        dispatch({
          type: types.ADD_RMM_NODE_COMMENT_SUCCESS,
          payload: {
            comment,
            id: nodeId,
          },
        });
        resolve(comment);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_RMM_NODE_COMMENT_FAIL,
        });
        reject(error);
      });
  });
};

export const sendRmmNodeToKs = (nodeId, date) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rmm/_areturn_b${nodeId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        date,
      }),
    })
      .then(data => {
        dispatch({
          type: types.SEND_RMM_NODE_TO_KS_SUCCESS,
          payload: {
            data,
            id: nodeId,
            newPercent: data.newPercent,
            percentUpdatedFor: data.percentUpdatedFor,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.SEND_RMM_NODE_TO_KS_FAIL,
        });
        reject(error);
      });
  });
};

export const resetRmmNodeUnreadCount = nodeId => ({
  type: types.RESET_RMM_NODE_UNREAD_COUNT_SUCCESS,
  payload: {
    nodeId,
  },
});
/** РММ END */

/** Администрирование - Заказчики */
export const getAdminOrderMan = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}admin/_aorderMan/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_ADMIN_ORDER_MAN_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_ADMIN_ORDER_MAN_FAIL,
        });
        reject(error);
      });
  });
};

export const addAdminOrderMan = name => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}admin/_aorderMan/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        name,
      }),
    })
      .then(orderMan => {
        dispatch({
          type: types.ADD_ADMIN_ORDER_MAN_SUCCESS,
          payload: {
            orderMan,
          },
        });
        resolve(orderMan);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_ADMIN_ORDER_MAN_FAIL,
        });
        reject(error);
      });
  });
};

export const editAdminOrderMan = (orderManId, name) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}admin/_aorderMan_b${orderManId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        name,
      }),
    })
      .then(data => {
        dispatch({
          type: types.EDIT_ADMIN_ORDER_MAN_SUCCESS,
          payload: {
            data,
            name,
            id: orderManId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.EDIT_ADMIN_ORDER_MAN_FAIL,
        });
        reject(error);
      });
  });
};

export const deleteAdminOrderMan = orderManId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}admin/_aorderMan_b${orderManId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
    })
      .then(data => {
        dispatch({
          type: types.DELETE_ADMIN_ORDER_MAN_SUCCESS,
          payload: {
            id: orderManId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.DELETE_ADMIN_ORDER_MAN_FAIL,
        });
        reject(error);
      });
  });
};
/** Администрирование - Заказчики END */

/** Администрирование - Периоды */
export const getAdminPeriods = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}admin/_aperiod/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_ADMIN_PERIODS_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_ADMIN_PERIODS_FAIL,
        });
        reject(error);
      });
  });
};

export const addAdminPeriod = year => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}admin/_aperiod/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        year,
      }),
    })
      .then(period => {
        dispatch({
          type: types.ADD_ADMIN_PERIOD_SUCCESS,
          payload: {
            period,
          },
        });
        resolve(period);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_ADMIN_PERIOD_FAIL,
        });
        reject(error);
      });
  });
};

export const getAdminPeriod = periodId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}admin/_aperiod_b${periodId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_ADMIN_PERIOD_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_ADMIN_PERIOD_FAIL,
        });
        reject(error);
      });
  });
};

export const addAdminOrderManToPeriod = (orderManId, periodId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}admin/_aorderManToPeriod/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        ordermanId: orderManId,
        periodId,
      }),
    })
      .then(data => {
        dispatch({
          type: types.ADD_ADMIN_ORDER_MAN_TO_PERIOD_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_ADMIN_ORDER_MAN_TO_PERIOD_FAIL,
        });
        reject(error);
      });
  });
};
/** Администрирование - Периоды END */

/** Информация по шапке сайта */
export const getHeaderData = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}_aheader/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_HEADER_DATA_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_HEADER_DATA_FAIL,
        });
        reject(error);
      });
  });
};

export const emulateUser = user => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}_aemulateUser/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        user,
      }),
    })
      .then(data => {
        dispatch({
          type: types.EMULATE_USER_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.EMULATE_USER_FAIL,
        });
        reject(error);
      });
  });
};

export const finishEmulateUser = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}_acancelEmulateUser/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
    })
      .then(data => {
        dispatch({
          type: types.FINISH_EMULATE_USER_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.FINISH_EMULATE_USER_FAIL,
        });
        reject(error);
      });
  });
};

export const changePeriod = period => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}_achangePeriod/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        period,
      }),
    })
      .then(data => {
        dispatch({
          type: types.CHANGE_PERIOD_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.CHANGE_PERIOD_FAIL,
        });
        reject(error);
      });
  });
};
/** Информация по шапке сайта END */

/** Администрирование - КС */
export const addKsToOrderMan = (ordermanId, name) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}admin/_aks/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        ordermanId,
        name,
      }),
    })
      .then(ks => {
        dispatch({
          type: types.ADD_KS_TO_ORDER_MAN_SUCCESS,
          payload: {
            ks,
            orderManId: ordermanId,
          },
        });
        resolve(ks);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_KS_TO_ORDER_MAN_FAIL,
        });
        reject(error);
      });
  });
};

export const editKsAdmin = (ksName, ksId, orderManId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}admin/_aks_b${ksId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        name: ksName,
      }),
    })
      .then(data => {
        dispatch({
          type: types.EDIT_KS_ADMIN_SUCCESS,
          payload: {
            ksName,
            ksId,
            orderManId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.EDIT_KS_ADMIN_FAIL,
        });
        reject(error);
      });
  });
};

export const deleteKsAdmin = (ksId, orderManId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}admin/_aks_b${ksId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
    })
      .then(data => {
        dispatch({
          type: types.DELETE_KS_ADMIN_SUCCESS,
          payload: {
            ksId,
            orderManId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.DELETE_KS_ADMIN_FAIL,
        });
        reject(error);
      });
  });
};

export const moveKsAdmin = (ksId, moveType, orderManId, ksIndex) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}admin/_aksMove_b${ksId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        moveType,
      }),
    })
      .then(data => {
        dispatch({
          type: types.MOVE_KS_ADMIN_SUCCESS,
          payload: {
            ksIndex,
            ksId,
            orderManId,
            moveType,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.MOVE_KS_ADMIN_FAIL,
        });
        reject(error);
      });
  });
};
/** Администрирование - КС END */

/** Занятость работников */
export const getEmployment = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}employment/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_EMPLOYMENT_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_EMPLOYMENT_FAIL,
        });
        reject(error);
      });
  });
};

export const getEmploymentUserReport = (userId, type) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}employment/_areport_b${userId}_type${type}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_EMPLOYMENT_USER_REPORT_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_EMPLOYMENT_USER_REPORT_FAIL,
        });
        reject(error);
      });
  });
};

export const clearEmploymentUserReport = () => ({
  type: types.CLEAR_EMPLOYMENT_USER_REPORT_SUCCESS,
});

export const clearEmploymentDepartmentReport = () => ({
  type: types.CLEAR_EMPLOYMENT_DEPARTMENT_REPORT_SUCCESS,
});

export const getEmploymentDepartmentReport = (departmentId, type) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}employment/_areportDep_b${departmentId}_type${type}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_EMPLOYMENT_DEPARTMENT_REPORT_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_EMPLOYMENT_DEPARTMENT_REPORT_FAIL,
        });
        reject(error);
      });
  });
};

export const setEmploymentUserContacts = (userId, departmentId, email, phone) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}employment/_acontacts_b${userId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        email,
        phone,
      }),
    })
      .then(data => {
        dispatch({
          type: types.SET_EMPLOYMENT_USER_CONTACTS_SUCCESS,
          payload: {
            email,
            phone,
            userId,
            departmentId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.SET_EMPLOYMENT_USER_CONTACTS_FAIL,
        });
        reject(error);
      });
  });
};

export const getEmploymentComments = userId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}employment/_acomment_b${userId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(comments => {
        dispatch({
          type: types.GET_EMPLOYMENT_COMMENTS_SUCCESS,
          payload: {
            comments,
          },
        });
        resolve(comments);
      })
      .catch(error => {
        dispatch({
          type: types.GET_EMPLOYMENT_COMMENTS_FAIL,
        });
        reject(error);
      });
  });
};

export const addEmploymentComment = (userId, departmentId, text) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}employment/_acomment_b${userId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        text,
      }),
    })
      .then(comment => {
        dispatch({
          type: types.ADD_EMPLOYMENT_COMMENT_SUCCESS,
          payload: {
            comment,
            userId,
            departmentId,
            text,
          },
        });
        resolve(comment);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_EMPLOYMENT_COMMENT_FAIL,
        });
        reject(error);
      });
  });
};

export const setEmploymentOutOfWorkUser = (userId, departmentId, type, commentText) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}employment/_aout_b${userId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        type,
        comment: commentText,
      }),
    })
      .then(commentData => {
        dispatch({
          type: types.SET_EMPLOYMENT_OUT_OF_WORK_USER_SUCCESS,
          payload: {
            userId,
            departmentId,
            commentData,
            commentText,
            type,
          },
        });
        resolve(commentData);
      })
      .catch(error => {
        dispatch({
          type: types.SET_EMPLOYMENT_OUT_OF_WORK_USER_FAIL,
        });
        reject(error);
      });
  });
};

export const setEmploymentInToWorkUser = (userId, departmentId, commentText) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}employment/_ain_b${userId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        comment: commentText,
      }),
    })
      .then(commentData => {
        dispatch({
          type: types.SET_EMPLOYMENT_IN_TO_WORK_USER_SUCCESS,
          payload: {
            userId,
            departmentId,
            commentData,
            commentText,
          },
        });
        resolve(commentData);
      })
      .catch(error => {
        dispatch({
          type: types.SET_EMPLOYMENT_IN_TO_WORK_USER_FAIL,
        });
        reject(error);
      });
  });
};

export const uploadEmployeeDocument = (employeeId, departmentId, type, fileUrl) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}employment/_adocs_b${employeeId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        url: fileUrl,
        type,
      }),
    })
      .then(data => {
        dispatch({
          type: types.UPLOAD_EMPLOYEE_DOCUMENT_SUCCESS,
          payload: {
            employeeId,
            departmentId,
            data,
            type,
          },
        });
        resolve();
      })
      .catch(reject);
  });
};
/** Занятость работников END */

/** Администрирование - Ремонты */
export const getAdminRepairDictionary = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}admin/_adictionary/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(dictionary => {
        dispatch({
          type: types.GET_ADMIN_REPAIR_DICTIONARY_SUCCESS,
          payload: {
            dictionary,
          },
        });
        resolve(dictionary);
      })
      .catch(error => {
        dispatch({
          type: types.GET_ADMIN_REPAIR_DICTIONARY_FAIL,
        });
        reject(error);
      });
  });
};

export const addAdminRepair = (
  gpa,
  privod,
  blower,
  invNumber,
  stationNumber,
  type,
  dateStart,
  dateEnd,
  ksId,
  orderManId,
  month,
  master,
  master2,
  nu,
  isOutOfPlan,
  noPrintProblems,
  mark,
  markHand,
) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}admin/_arepair/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        gpa,
        privod,
        blower,
        invnumber: invNumber,
        stationnumber: stationNumber,
        type,
        datestart: dateStart,
        dateend: dateEnd,
        ksId,
        orderManId,
        month,
        master,
        master2,
        nu,
        isOutOfPlan,
        noPrintProblems,
        mark,
        markHand,
      }),
    })
      .then(repair => {
        dispatch({
          type: types.ADD_ADMIN_REPAIR_SUCCESS,
          payload: {
            repair,
            ksId,
            orderManId,
            month: `month${month}`,
          },
        });
        resolve(repair);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_ADMIN_REPAIR_FAIL,
        });
        reject(error);
      });
  });
};

export const getAdminRepair = repairId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}admin/_arepair_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_ADMIN_REPAIR_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_ADMIN_REPAIR_FAIL,
        });
        reject(error);
      });
  });
};

export const editAdminRepair = (
  gpa,
  privod,
  blower,
  invNumber,
  stationNumber,
  type,
  dateStart,
  dateEnd,
  repairId,
  ksId,
  orderManId,
  month,
  master,
  master2,
  nu,
  isOutOfPlan,
  noPrintProblems,
  mark,
  markHand,
) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}admin/_arepair_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        gpa,
        privod,
        blower,
        invnumber: invNumber,
        stationnumber: stationNumber,
        type,
        datestart: dateStart,
        dateend: dateEnd,
        ksId,
        orderManId,
        month,
        master,
        master2,
        nu,
        isOutOfPlan,
        noPrintProblems,
        mark,
        markHand,
      }),
    })
      .then(repair => {
        dispatch({
          type: types.EDIT_ADMIN_REPAIR_SUCCESS,
          payload: {
            repair,
            repairId,
            ksId,
            orderManId,
            month: `month${month}`,
          },
        });
        resolve(repair);
      })
      .catch(error => {
        dispatch({
          type: types.EDIT_ADMIN_REPAIR_FAIL,
        });
        reject(error);
      });
  });
};

export const deleteAdminRepair = (orderManId, ksId, month, repairId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}admin/_arepair_b${repairId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
    })
      .then(data => {
        dispatch({
          type: types.DELETE_ADMIN_REPAIR_SUCCESS,
          payload: {
            orderManId,
            ksId,
            month: `month${month}`,
            repairId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.DELETE_ADMIN_REPAIR_FAIL,
        });
        reject(error);
      });
  });
};
/** Администрирование - Ремонты END */

/** Администрирование - Юзеры */
export const getAdminUsers = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}adminusers/_auser/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_ADMIN_USERS_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_ADMIN_USERS_FAIL,
        });
        reject(error);
      });
  });
};

export const getAdminUser = userId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}adminusers/_auser_b${userId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_ADMIN_USER_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_ADMIN_USER_FAIL,
        });
        reject(error);
      });
  });
};

export const deleteAdminUser = userId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}adminusers/_auser_b${userId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
    })
      .then(data => {
        dispatch({
          type: types.DELETE_ADMIN_USER_SUCCESS,
          payload: {
            userId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.DELETE_ADMIN_USER_FAIL,
        });
        reject(error);
      });
  });
};

export const addAdminUser = (
  name,
  number,
  role,
  stage,
  department,
  op,
  login,
  password,
) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}adminusers/_auser/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        name,
        number,
        role,
        stage,
        department,
        op,
        login,
        password,
      }),
    })
      .then(user => {
        dispatch({
          type: types.ADD_ADMIN_USER_SUCCESS,
          payload: {
            user,
          },
        });
        resolve(user);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_ADMIN_USER_FAIL,
        });
        reject(error);
      });
  });
};

export const editAdminUser = (
  userId,
  name,
  number,
  role,
  stage,
  department,
  op,
  login,
  password,
) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}adminusers/_auser_b${userId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        name,
        number,
        role,
        stage,
        department,
        op,
        login,
        password,
      }),
    })
      .then(user => {
        dispatch({
          type: types.EDIT_ADMIN_USER_SUCCESS,
          payload: {
            user,
            userId,
          },
        });
        resolve(user);
      })
      .catch(error => {
        dispatch({
          type: types.EDIT_ADMIN_USER_FAIL,
        });
        reject(error);
      });
  });
};

export const getAdminUsersRoles = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}adminusers/_arole/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(roles => {
        dispatch({
          type: types.GET_ADMIN_USERS_ROLES_SUCCESS,
          payload: {
            roles,
          },
        });
        resolve(roles);
      })
      .catch(error => {
        dispatch({
          type: types.GET_ADMIN_USERS_ROLES_FAIL,
        });
        reject(error);
      });
  });
};

export const toggleAdminUserActive = (userId, value) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}adminusers/_auserActivate_b${userId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
    })
      .then(data => {
        dispatch({
          type: types.TOGGLE_ADMIN_USER_ACTIVE_SUCCESS,
          payload: {
            userId,
            value,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.TOGGLE_ADMIN_USER_ACTIVE_FAIL,
        });
        reject(error);
      });
  });
};
/** Администрирование - Юзеры END */

/** Занятость работников РММ */
export const getRmmEmploymentWorkers = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rmmemployment/_aworker/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_RMM_EMPLOYMENT_WORKERS_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_RMM_EMPLOYMENT_WORKERS_FAIL,
        });
        reject(error);
      });
  });
};

export const getRmmEmploymentUsers = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rmmemployment/_auser/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_RMM_EMPLOYMENT_USERS_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_RMM_EMPLOYMENT_USERS_FAIL,
        });
        reject(error);
      });
  });
};

export const addRmmEmploymentWorker = workerId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rmmemployment/_aworker/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        user: workerId,
      }),
    })
      .then(data => {
        dispatch({
          type: types.ADD_RMM_EMPLOYMENT_WORKER_SUCCESS,
          payload: {
            workerId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_RMM_EMPLOYMENT_WORKER_FAIL,
        });
        reject(error);
      });
  });
};

export const deleteRmmEmploymentWorker = workerId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rmmemployment/_aworker_b${workerId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
    })
      .then(data => {
        dispatch({
          type: types.DELETE_RMM_EMPLOYMENT_WORKER_SUCCESS,
          payload: {
            workerId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.DELETE_RMM_EMPLOYMENT_WORKER_FAIL,
        });
        reject(error);
      });
  });
};

export const getRmmEmploymentWorkerWorks = workerId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rmmemployment/_aworker_b${workerId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_RMM_EMPLOYMENT_WORKER_WORKS_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_RMM_EMPLOYMENT_WORKER_WORKS_FAIL,
        });
        reject(error);
      });
  });
};

export const getRmmEmploymentWorkNodes = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rmmemployment/_anode/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_RMM_EMPLOYMENT_WORK_NODES_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_RMM_EMPLOYMENT_WORK_NODES_FAIL,
        });
        reject(error);
      });
  });
};

export const addRmmEmploymentWork = (worker, work, node) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rmmemployment/_awork/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        worker,
        work,
        node,
      }),
    })
      .then(data => {
        dispatch({
          type: types.ADD_RMM_EMPLOYMENT_WORK_SUCCESS,
          payload: {
            worker,
            work,
            node,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_RMM_EMPLOYMENT_WORK_FAIL,
        });
        reject(error);
      });
  });
};

export const rmmEmploymentProlongLastWork = (workerId, tomorrow) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rmmemployment/_aworkerProlongLastWork_b${workerId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        tomorrow,
      }),
    })
      .then(data => {
        dispatch({
          type: types.RMM_EMPLOYMENT_PROLONG_LAST_WORK_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.RMM_EMPLOYMENT_PROLONG_LAST_WORK_FAIL,
        });
        reject(error);
      });
  });
};
/** Занятость работников РММ END */

/** МТР */
export const getMtrLinks = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}mtr/_alink/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_MTR_LINKS_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_MTR_LINKS_FAIL,
        });
        reject(error);
      });
  });
};

export const getMtrList = (page, filterOptions) => dispatch => {
  const url = generateMtrUrl(page, filterOptions);
  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_MTR_LIST_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.GET_MTR_LIST_FAIL,
        });
        reject(error);
      });
  });
};

export const linkMtr = (objectId, repairId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}mtr/_alink_b${objectId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        repairId,
      }),
    })
      .then(data => {
        dispatch({
          type: types.LINK_MTR_SUCCESS,
          payload: {
            data,
            objectId,
            repairId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.LINK_MTR_FAIL,
        });
        reject(error);
      });
  });
};

export const setMtrFilter = (filterOptions, options = {}) => ({
  type: types.SET_MTR_FILTER_SUCCESS,
  payload: {
    filterOptions,
    options,
  },
});
/** МТР END */

/** RULES */
export const getRulesFiles = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rules/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then(data => {
        dispatch({
          type: types.GET_RULES_FILES_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(reject);
  });
};

export const addRulesFile = (fileName, fileUrl) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rules/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        fileName,
        fileUrl,
      }),
    })
      .then(data => {
        dispatch({
          type: types.ADD_RULES_FILE_SUCCESS,
          payload: {
            file: data,
          },
        });
        resolve();
      })
      .catch(reject);
  });
};

export const deleteRulesFile = file => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}rules/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
      body: JSON.stringify({
        fileId: file.id,
      }),
    })
      .then(() => {
        dispatch({
          type: types.DELETE_RULES_FILE_SUCCESS,
          payload: {
            fileId: file.id,
            role: file.role,
          },
        });
        resolve();
      })
      .catch(reject);
  });
};

/** /RULES */
