import React from 'react';
import PropTypes from 'prop-types';

// Icons
import { FaPaperclip } from 'react-icons/fa';

// Components
import InputForm from 'components/InputForm/InputForm';

// Styles
import './InputGroup.scss';

const InputGroup = ({ value, onClick }) => {
  return (
    <div className="input-group">
      <button type="button" className="input-group__btn btn btn-primary" onClick={onClick}>
        <FaPaperclip />
      </button>

      <InputForm type="text" readonly value={value} />
    </div>
  );
};

InputGroup.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

InputGroup.defaultProps = {
  value: null,
};

export default InputGroup;
