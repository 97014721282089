import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import TableRepairProblems from 'components/TableRepairProblems/TableRepairProblems';
import Modal from 'components/Modal/Modal';
import AddProblemModal from 'components/AddProblemModal/AddProblemModal';
import Loader from 'components/Loader/Loader';
import Warning from 'components/Warning/Warning';

// Actions
import { getRepairProblems } from 'actions';

const RepairProblems = ({
  canEdit,
  open,
  finished,
  userRole,
  repairId,
  dispatch,
  repairProblems,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAddProblemActive, setAddProblemActive] = useState(false);

  /** Запрашиваем информацию по ремонту (Текущие проблемы) */
  useEffect(() => {
    dispatch(getRepairProblems(repairId))
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch, repairId]);

  const activateAddProblemModal = () => {
    setAddProblemActive(true);
  };

  const deactivateAddProblemModal = () => {
    setAddProblemActive(false);
  };

  return (
    <React.Fragment>
      <div className="repair-content__header">
        <h2 className="repair-content__title">Текущие проблемы</h2>
        {canEdit && (
          <div className="repair-content__tools">
            {open && (
              <button type="button" className="btn btn-primary" onClick={activateAddProblemModal}>
                Добавить проблему
              </button>
            )}

            {isAddProblemActive && (
              <Modal title="Добавление проблемы" closeHandler={deactivateAddProblemModal}>
                <AddProblemModal closeHandler={deactivateAddProblemModal} repairId={repairId} />
              </Modal>
            )}
          </div>
        )}
      </div>

      {isLoading && <Loader />}

      {!isLoading &&
        repairProblems &&
        Array.isArray(repairProblems) &&
        repairProblems.length > 0 && (
          <TableRepairProblems
            canEdit={canEdit}
            open={open}
            finished={finished}
            problems={repairProblems}
            userRole={userRole}
            repairId={repairId}
          />
        )}

      {!isLoading &&
        repairProblems &&
        Array.isArray(repairProblems) &&
        repairProblems.length === 0 && <Warning msg="Нет текущих проблем" />}
    </React.Fragment>
  );
};

RepairProblems.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  finished: PropTypes.bool.isRequired,
  userRole: PropTypes.number.isRequired,
  repairId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  repairProblems: PropTypes.arrayOf(PropTypes.shape({})),
};

RepairProblems.defaultProps = {
  repairProblems: null,
};

const mapStateToProps = state => ({
  dispatch: state.mainReducer.dispatch,
  repairProblems: state.mainReducer.repairProblems,
});

export default connect(mapStateToProps)(RepairProblems);
