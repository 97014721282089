import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalError from 'components/ModalError/ModalError';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import SubmitButton from 'components/SubmitButton/SubmitButton';

// Actions
import { deleteRepairWork, getRepairWorkProgress } from 'actions';

const RemoveWorkConfirmModal = ({ dispatch, closeHandler, removeWorkId, repairId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseError, setResponseError] = useState(null);

  const removeWork = () => {
    /** Показываем прелоадер на кнопку */
    setIsLoading(true);

    /** Удаляем работу из базы данных */
    dispatch(deleteRepairWork(repairId, removeWorkId))
      .then(() => {
        /** Запрашиваем обновленный список работ */
        return dispatch(getRepairWorkProgress(repairId));
      })
      .then(() => {
        /** Скрываем прелоадер на кнопку */
        setIsLoading(false);

        /** Закрываем модалку */
        closeHandler();
      })
      .catch(error => {
        /** Если есть ошибки, выводим */
        if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        /** Скрываем прелоадер на кнопку */
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader className="modal-header_has-sub-title">
        <h4 className="modal-header__title">Вы уверены,</h4>
        <h5 className="modal-header__sub-title">что хотите безвозвратно удалить работу?</h5>
      </ModalHeader>

      {responseError && <ModalError>{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Отмена
        </button>

        <SubmitButton isLoading={isLoading} isLoadingText="Удаляю..." clickHandler={removeWork}>
          Удалить
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

RemoveWorkConfirmModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  closeHandler: PropTypes.func.isRequired,
  removeWorkId: PropTypes.string.isRequired,
  repairId: PropTypes.number.isRequired,
};

export default connect()(RemoveWorkConfirmModal);
