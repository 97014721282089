import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import InputForm from 'components/InputForm/InputForm';
import SelectForm from 'components/SelectForm/SelectForm';
import TextArea from 'components/TextArea/TextArea';
import InputDate from 'components/InputDate/InputDate';
import LocationSelect from 'components/LocationSelect/LocationSelect';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { updateDespatch } from 'actions/despatch';
import { addLocation, loadLocations } from 'actions/common';

// Helpers
import { formatDateYYYYmmDD } from 'helpers/formatDateYYYYmmDD';

// Utils
import * as propTypes from 'utils/propTypes';
import { debounce, isNumber } from 'utils/_';

const priorityValues = [
  { value: 1, label: 'Низкий приоритет' },
  { value: 2, label: 'Средний приоритет' },
  { value: 3, label: 'Высокий приоритет' },
];

const EditDespatchFormModal = ({
  dispatch,
  users,
  closeHandler,
  parentId,
  editDespatchItem,
  locations,
  customLocations,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);
  const [dateStart, setDateStart] = useState(new Date(editDespatchItem.dateStart));
  const [dateEnd, setDateEnd] = useState(new Date(editDespatchItem.dateEnd));
  const [startLocation, setStartLocation] = useState({ value: editDespatchItem.startLocation ? editDespatchItem.startLocation.id : null, label: editDespatchItem.startLocation ? editDespatchItem.startLocation.city : null });
  const [destLocation, setDestLocation] = useState({ value: editDespatchItem.destLocation ? editDespatchItem.destLocation.id : null, label: editDespatchItem.destLocation ? editDespatchItem.destLocation.city : null });
  const [size, setSize] = useState(editDespatchItem.size);
  const [weight, setWeight] = useState(editDespatchItem.weight);
  const [nomenclature, setNomenclature] = useState(editDespatchItem.nomenclature);
  const [urgency, setUrgency] = useState(priorityValues[ isNumber(editDespatchItem.urgency) ? editDespatchItem.urgency - 1 : 0 ]);
  const [other, setOther] = useState(editDespatchItem.other);
  const [despatchRecipient, setDespatchRecipient] = useState(
    users.map(user => ({ value: user.id, label: `${user.name_1} (${user.rolename})` }))[0],
  );
  const [locationData, setLocationData] = useState(locations && locations.map(loc => ({ value: loc.id, label: loc.city })));

  useEffect(() => {
    customLocations && 
    customLocations.length && 
    setLocationData(customLocations.map(loc => ({ value: loc.id, label: loc.city })).concat(
      locations && 
      locations.length 
        ? locations.map(loc => ({ value: loc.id, label: loc.city }))
        : []
      ));
  }, [customLocations, locations]);

  const loadLocationsDebounced = useCallback(debounce((query) => {
    dispatch(loadLocations(query))
      .then(() => {

        /** Скрываем прелоадер на кнопку */
        // setIsLoading(false);

        /** Закрываем модалку */
        // closeHandler();
      })
      .catch(error => {
      })
      .finally(() => {

      });
  }, 300), []);

  const handleLocationChange = useCallback((inputValue, actionMeta) => {
    if (actionMeta.action === 'input-change' || actionMeta.action === 'clear') {
      if (inputValue === '' || actionMeta.action === 'clear') {
        setLocationData(locations.map(loc => ({ value: loc.id, label: loc.city })));
      } else {
        loadLocationsDebounced(inputValue);

        setStartLocation(inputValue);
      }

    } else if (actionMeta.action === 'create-option') {
      /** Записываем новую произвольную локацию в базу данных */
      dispatch(addLocation(parentId, { city: inputValue.label }, true))
        .then(() => {
          /** Скрываем прелоадер на кнопку */
          // setIsLoading(false);

        })
        .catch(error => {
        })
        .finally(() => {

        });

      setStartLocation(inputValue);
    } else if (actionMeta.action === 'select-option') {
      setStartLocation(inputValue);
    }

  }, [dispatch, loadLocationsDebounced, locations, parentId]);

  const handleDestLocationChange = useCallback((inputValue, actionMeta) => {
    if (actionMeta.action === 'input-change' || actionMeta.action === 'clear') {
      if (inputValue === '' || actionMeta.action === 'clear') {
        setLocationData(locations.map(loc => ({ value: loc.id, label: loc.city })));
      } else {
        loadLocationsDebounced(inputValue);

        setDestLocation(inputValue);
      }

    } else if (actionMeta.action === 'create-option') {
      /** Записываем новую произвольную локацию в базу данных */
      dispatch(addLocation(parentId, { city: inputValue.label }, true))
        .then(() => {
          /** Скрываем прелоадер на кнопку */
          // setIsLoading(false);

        })
        .catch(error => {
        })
        .finally(() => {

        });

      setDestLocation(inputValue);
    } else if (actionMeta.action === 'select-option') {
      setDestLocation(inputValue);
    }

  }, [dispatch, loadLocationsDebounced, locations, parentId]);

  const submitUpdateDespatch = () => {
    /** Показываем прелоадер на кнопку */
    setIsLoading(true);

    let params = {
      dateStart: formatDateYYYYmmDD(dateStart),
      dateEnd: formatDateYYYYmmDD(dateEnd),
      startLocation: startLocation.value,
      destLocation: destLocation.value,
      size,
      weight,
      nomenclature,
      urgency: urgency.value,
      recipient: despatchRecipient.value,
      other,
    };

    /** Записываем отредактированную работу в базу данных */
    dispatch(updateDespatch(editDespatchItem.id, params))
      .then(() => {
        /** Скрываем прелоадер на кнопку */
        setIsLoading(false);

        /** Закрываем модалку */
        closeHandler();
      })
      .catch(error => {
        /** Если есть ошибки, выводим */
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        /** Скрываем прелоадер на кнопку */
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Редактирование отправления" />

      <ModalBody>
        <FormGroup title="Дата начала" error={fieldErrors.dateStart}>
          <InputDate selectedDate={dateStart} onChange={date => setDateStart(date)} />
        </FormGroup>

        <FormGroup title="Дата завершения" error={fieldErrors.date}>
          <InputDate selectedDate={dateEnd} onChange={date => setDateEnd(date)} />
        </FormGroup>

        <FormGroup title="Место отправления" error={fieldErrors.startLocation}>
          <LocationSelect
            options={locationData}
            selectedValue={startLocation}
            onChange={handleLocationChange}
            onInputChange={handleLocationChange}
          />
        </FormGroup>

        <FormGroup title="Место назначения" error={fieldErrors.destLocation}>
          <LocationSelect
            options={locationData}
            selectedValue={destLocation}
            onChange={handleDestLocationChange}
            onInputChange={handleDestLocationChange}
          />
        </FormGroup>

        <FormGroup title="Габариты (м)" error={fieldErrors.size}>
          <InputForm
            type="text"
            value={size}
            placeholder="д-ш-в"
            onChange={({ target }) => setSize(target.value)}
          />
        </FormGroup>

        <FormGroup title="Вес (кг)" error={fieldErrors.weight}>
          <InputForm
            type="text"
            value={weight}
            placeholder="10"
            onChange={({ target }) => setWeight(target.value)}
          />
        </FormGroup>

        <FormGroup title="Номенкулатурный перечень" error={fieldErrors.nomenclature}>
          <InputForm
            type="text"
            value={nomenclature}
            // placeholder="№1"
            onChange={({ target }) => setNomenclature(target.value)}
          />
        </FormGroup>

        <FormGroup title="Срочность" error={fieldErrors.urgency}>
          <SelectForm
            options={priorityValues}
            selectedValue={urgency}
            onChange={selectedOption => setUrgency(selectedOption)}
          />
        </FormGroup>

        <FormGroup title="Получатель" error={fieldErrors.user}>
          <SelectForm
            options={users.map(user => ({
              value: user.id,
              label: `${user.name_1} (${user.rolename})`,
            }))}
            selectedValue={despatchRecipient}
            onChange={selectedOption => setDespatchRecipient(selectedOption)}
          />
        </FormGroup>

        <FormGroup title="Дополнительная информация" error={fieldErrors.other}>
          <TextArea value={other} onChange={({ target }) => setOther(target.value)} />
        </FormGroup>
      </ModalBody>

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isValid={!dateStart}
          isLoading={isLoading}
          isLoadingText="Сохраняю..."
          clickHandler={submitUpdateDespatch}
        >
          Сохранить
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

EditDespatchFormModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  editDespatchItem: propTypes.despatchInfo.isRequired,
  closeHandler: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  customLocations: PropTypes.array.isRequired,
};

export default connect()(EditDespatchFormModal);
