import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

// Components
import Loader from 'components/Loader/Loader';

const AccessRoute = ({
  component: Component,
  isPrivate,
  isUserGot,
  isUserAuth,
  locationDetector,
  // dispatch,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={({ location, match }) => {
        if (!isUserGot) {
          return <Loader className="loader_page" />;
        }

        if ((isUserAuth && isPrivate) || (!isUserAuth && !isPrivate)) {
          const componentProps = {};

          if (locationDetector) {
            componentProps[locationDetector.propName] =
              location.pathname.indexOf(locationDetector.pattern) > -1;
          }

          return <Component {...componentProps} location={location} match={match} />;
        }

        if (isPrivate && !isUserAuth) {
          return <Redirect to="/" />;
        }

        if (!isPrivate && isUserAuth) {
          return <Redirect to="/dashboard" />;
        }

        return null;
      }}
    />
  );
};

AccessRoute.propTypes = {
  // eslint-disable-next-line
  component: PropTypes.any.isRequired,
  isUserGot: PropTypes.bool.isRequired,
  isUserAuth: PropTypes.bool.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  locationDetector: PropTypes.shape({
    pattern: PropTypes.string,
    propName: PropTypes.string,
  }),
  // dispatch: PropTypes.func.isRequired,
};

AccessRoute.defaultProps = {
  locationDetector: null,
};

const mapStateToProps = state => ({
  isUserAuth: state.mainReducer.isUserAuth,
  isUserGot: state.mainReducer.isUserGot,
});

export default connect(mapStateToProps)(AccessRoute);
