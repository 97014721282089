import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Styles
import './MissDocsRmmListReport.scss';

const MissDocsRmmListReport = ({ missDocsRmm }) => {
  return (
    <ul className="miss-docs-rmm-list-report">
      {/* eslint-disable react/no-array-index-key */}
      {Array.isArray(missDocsRmm) &&
        missDocsRmm.map(item => (
          <li key={item.id} className="miss-docs-rmm-list-report__item">
            <div className="miss-docs-rmm-list-report__item-info">
              {item.name}{' '}
              {item.object && (
                <>
                  (
                  <Link
                    to={`/repair/${item.object.id}/docs`}
                    className="miss-docs-rmm-list-report__item-link"
                  >
                    {item.object.ksname} {item.object.gpa} №{item.object.stationnumber}
                  </Link>
                  )
                </>
              )}{' '}
              {Array.isArray(item.documents) &&
                item.documents.map((doc, docIndex) => (
                  <React.Fragment key={docIndex}>
                    <span className="label label_default">{doc.name}</span>{' '}
                  </React.Fragment>
                ))}
            </div>
          </li>
        ))}
      {/* eslint-enable react/no-array-index-key */}
    </ul>
  );
};

MissDocsRmmListReport.propTypes = {
  missDocsRmm: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      object: PropTypes.shape({
        id: PropTypes.number,
        ksname: PropTypes.string,
        gpa: PropTypes.string,
        stationnumber: PropTypes.string,
      }),
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        }),
      ),
    }),
  ),
};

MissDocsRmmListReport.defaultProps = {
  missDocsRmm: null,
};

export default MissDocsRmmListReport;
