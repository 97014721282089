import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import TableRepairRoster from 'components/TableRepairRoster/TableRepairRoster';
import Modal from 'components/Modal/Modal';
import AddRepairManModal from 'components/AddRepairManModal/AddRepairManModal';
import Loader from 'components/Loader/Loader';

// Actions
import { getRepairRosterTeam } from 'actions';

const RepairRoster = ({ canEdit, repairId, dispatch, repairRosterTeam, finished }) => {
  const [isAddRepairManActive, setAddRepairManActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const activateAddRepairManModal = () => {
    setAddRepairManActive(true);
  };
  const deactivateAddRepairManModal = () => {
    setAddRepairManActive(false);
  };

  /** Запрашиваем информацию по ремонту (Состав бригады) */
  useEffect(() => {
    dispatch(getRepairRosterTeam(repairId))
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch, repairId]);

  return (
    <React.Fragment>
      <div className="repair-content__header">
        <h2 className="repair-content__title">Состав бригады</h2>
        {canEdit && !finished && (
          <div className="repair-content__tools">
            <button type="button" className="btn btn-primary" onClick={activateAddRepairManModal}>
              Добавить работника
            </button>

            {isAddRepairManActive && (
              <Modal title="Добавление работника" closeHandler={deactivateAddRepairManModal}>
                <AddRepairManModal repairId={repairId} closeHandler={deactivateAddRepairManModal} />
              </Modal>
            )}
          </div>
        )}
      </div>

      {isLoading && <Loader />}

      {!isLoading &&
        repairRosterTeam &&
        Array.isArray(repairRosterTeam) &&
        repairRosterTeam.length > 0 && (
          <TableRepairRoster repairId={repairId} roster={repairRosterTeam} canEdit={canEdit} />
        )}
    </React.Fragment>
  );
};

RepairRoster.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  finished: PropTypes.bool.isRequired,
  repairId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  repairRosterTeam: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = state => ({
  repairRosterTeam: state.mainReducer.repairRosterTeam,
});

export default connect(mapStateToProps)(RepairRoster);
