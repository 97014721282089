import React from 'react';
import { connect } from 'react-redux';


const Filemanager = ({ url }) => {

  const fl = () => {
    url = `${process.env.REACT_APP_BACK_ROOT}Filemanager/indexFilemanager.html?callback=fileManagerCallback`;

    return url;
  }


  return (
    <React.Fragment>

        <iframe src={fl()} style={{height:"calc(100vh - 64px - 80px)", width:"100%"}}   title="ifilemanager"/>

    </React.Fragment>
  );
};

export default connect()(Filemanager);
