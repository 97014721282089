import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Styles
import './NoReceiveListReport.scss';

const NoReceiveListReport = ({ noReceive }) => {
  return (
    <ul className="no-receive-list-report">
      {Array.isArray(noReceive) &&
        noReceive.map(item => (
          <li key={item.id} className="no-receive-list-report__item">
            <div className="no-receive-list-report__item-info">
              <Link
                to={`/repair/${item.repairid}/node`}
                className="no-receive-list-report__item-link"
              >
                {item.name}
              </Link>{' '}
              {item.object && (
                <Link to={`/repair/${item.repairid}/node`}>
                  {item.object.ksname} {item.object.gpa} №{item.object.stationnumber}
                </Link>
              )}
            </div>
            <div className="no-receive-list-report__item-tools">
              <span className="label label_primary">{item.master_1}</span>
            </div>
          </li>
        ))}
    </ul>
  );
};

NoReceiveListReport.propTypes = {
  noReceive: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      repairid: PropTypes.string,
      name: PropTypes.string,
      object: PropTypes.shape({
        ksname: PropTypes.string,
        gpa: PropTypes.string,
        stationnumber: PropTypes.string,
      }),
      master_1: PropTypes.string,
    }),
  ),
};

NoReceiveListReport.defaultProps = {
  noReceive: null,
};

export default NoReceiveListReport;
