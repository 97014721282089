import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

// Icons
import { FaTrashAlt, FaEyeSlash, FaEye } from 'react-icons/fa';

// Components
import Modal from 'components/Modal/Modal';
import RemoveRepairManConfirmModal from 'components/RemoveRepairManConfirmModal/RemoveRepairManConfirmModal';

// Actions
import { setRosterRepairmanActive } from 'actions';

// Styles
import './TableRepairRoster.scss';

const TableRepairRoster = ({ dispatch, roster, canEdit, repairId }) => {
  const [isRemoveRepairManConfirmActive, setRemoveRepairManConfirmActive] = useState(false);
  const [removeRepairmanId, setRemoveRepairmanId] = useState(null);

  const activateRemoveRepairManConfirmModal = repairmanId => {
    setRemoveRepairmanId(repairmanId);
    setRemoveRepairManConfirmActive(true);
  };
  const deactivateRemoveRepairManConfirmModal = () => {
    setRemoveRepairManConfirmActive(false);
  };

  /** Активировать/Деактивировать ремонтника в бригаде */
  const handleRepairmanActive = (id, repairmanId, active) => {
    dispatch(setRosterRepairmanActive(id, repairmanId, active))
      .then(() => {})
      .catch(() => {});
  };

  return (
    <table className="table table-bordered table-striped">
      <colgroup>
        {canEdit && (
          <React.Fragment>
            <col style={{ width: '45%' }} />
            <col style={{ width: '45%' }} />
            <col style={{ width: '10%' }} />
          </React.Fragment>
        )}

        {!canEdit && (
          <React.Fragment>
            <col style={{ width: '50%' }} />
            <col style={{ width: '50%' }} />
          </React.Fragment>
        )}
      </colgroup>

      <thead>
        <tr>
          <th>ФИО</th>
          <th>Должность</th>
          {canEdit && <th> </th>}
        </tr>
      </thead>

      <tbody>
        {roster.map(item => (
          <tr key={item.id}>
            <td
              className={cx({
                'table-repair-roster__disactive': !item.active,
              })}
            >
              {item.name}
            </td>
            <td
              className={cx({
                'table-repair-roster__disactive': !item.active,
              })}
            >
              {item.role}
            </td>

            {canEdit && (
              <td>
                {item.canDelete && (
                  <React.Fragment>
                    <button
                      type="button"
                      className="btn btn-danger btn-xs"
                      title="Удалить"
                      onClick={() => activateRemoveRepairManConfirmModal(item.id)}
                    >
                      <FaTrashAlt className="icon" />
                    </button>{' '}
                  </React.Fragment>
                )}
                <button
                  type="button"
                  className={cx('btn btn-xs', {
                    'btn-warning': item.active,
                    'btn-success': !item.active,
                  })}
                  title={item.active ? 'Деактивировать' : 'Активировать'}
                  onClick={() => handleRepairmanActive(repairId, item.id, !item.active)}
                >
                  {item.active && <FaEyeSlash className="icon" />}
                  {!item.active && <FaEye className="icon" />}
                </button>
              </td>
            )}
          </tr>
        ))}

        {isRemoveRepairManConfirmActive && (
          <Modal
            title="Удаление работника с объекта"
            closeHandler={deactivateRemoveRepairManConfirmModal}
          >
            <RemoveRepairManConfirmModal
              repairId={repairId}
              removeRepairmanId={removeRepairmanId}
              closeHandler={deactivateRemoveRepairManConfirmModal}
            />
          </Modal>
        )}
      </tbody>
    </table>
  );
};

TableRepairRoster.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  repairId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  roster: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      active: PropTypes.bool,
      name: PropTypes.string,
      role: PropTypes.string,
      canDelete: PropTypes.bool,
    }),
  ).isRequired,
};

export default connect()(TableRepairRoster);
