import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import TextArea from 'components/TextArea/TextArea';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { setEmploymentInToWorkUser } from 'actions';

const InToWorkUserModal = ({ closeHandler, dispatch, userId, departmentId }) => {
  const [comment, setComment] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const submitInToWork = () => {
    setIsLoading(true);

    dispatch(setEmploymentInToWorkUser(userId, departmentId, comment))
      .then(() => {
        setIsLoading(false);

        closeHandler();
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Действия с работником" />

      <ModalBody>
        <FormGroup title="Добавьте примечание:" error={fieldErrors.comment}>
          <TextArea value={comment} onChange={({ target }) => setComment(target.value)} />
        </FormGroup>
      </ModalBody>

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isLoading={isLoading}
          isValid={comment === ''}
          isLoadingText="Отправка..."
          clickHandler={submitInToWork}
        >
          Отправить
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

InToWorkUserModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  departmentId: PropTypes.number.isRequired,
};

export default connect()(InToWorkUserModal);
