import React from 'react';
import PropTypes from 'prop-types';

// Utils
import * as propTypes from 'utils/propTypes';

// Styles
import './DriversTable.scss';

const DriversTable = ({ drivers }) => {
  return (
    <table className="drivers-table table table-bordered">
      <thead>
        <tr className="info">
          <th> </th>
          <th className="drivers-table__month-th">
            <span className="label label_default">Имя</span>
          </th>
          <th className="drivers-table__month-th">
            <span className="label label_default">Статус</span>
          </th>
          <th className="drivers-table__month-th">
            <span className="label label_default">Машина</span>
          </th>
        </tr>
      </thead>

      <tbody>
        {drivers &&
          drivers.map(driver => (
            <React.Fragment key={driver.id}>
              <tr>
                <td> </td>
                <td className="drivers-table__driver">{driver.lastName} {driver.firstName} {driver.middleName}</td>
                <td className="drivers-table__driver">{driver.status}</td>
                {driver.assignedCar ? (
                  <td className="drivers-table__driver">
                    {driver.assignedCar.name} - {driver.assignedCar.carNumber}
                  </td>
                ) : (
                  <td className="drivers-table__driver">Не назначена</td>
                )}
              </tr>
            </React.Fragment>
          ))}
      </tbody>
    </table>
  );
};

DriversTable.propTypes = {
  drivers: PropTypes.arrayOf(propTypes.driverData).isRequired,
};

export default DriversTable;
