import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Icons
import { FaRegArrowAltCircleDown } from 'react-icons/fa';

// Components
import TableDispatch from 'components/TableDispatch/TableDispatch';
import Loader from 'components/Loader/Loader';

// Actions
import { getDispatch } from 'actions';

// Styles
import './Dispatch.scss';

const Dispatch = ({ match, dispatch, dispatchInfo }) => {
  const [isLoading, setIsLoading] = useState(true);

  /** Запрашиваем информацию по диспетчерскому отчету */
  useEffect(() => {
    dispatch(getDispatch(match.params.archive))
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch, match.params.archive]);

  return (
    <React.Fragment>
      {isLoading && <Loader className="loader_page" />}

      {!isLoading && dispatchInfo && (
        <div className="dispatch-page">
          <div className="dispatch-page__container container">
            <h1 className="dispatch-page__title">
              Диспечтерский отчет на {dispatchInfo.periodname} год
            </h1>

            <div className="dispatch-page__menu">
              <Link
                to={match.params.archive ? '/dispatch' : '/dispatch/archive'}
                className="btn btn-large btn-primary"
                onClick={() => setIsLoading(true)}
              >
                {match.params.archive ? 'Текущие объекты' : 'Архив'}
              </Link>{' '}
              <a
                href={`${process.env.REACT_APP_API_URL}${
                  match.params.archive ? 'dispatch/_aarchive_file1/' : 'dispatch/_file1/'
                }`}
                target="_blank"
                rel="noreferrer noopener"
                download
                className="btn btn-large btn-warning"
              >
                Сохранить в .xls <FaRegArrowAltCircleDown className="icon" />
              </a>
            </div>

            <div className="dispatch-page__content">
              <TableDispatch orderManToPeriod={dispatchInfo.ordermantoperiod} />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

Dispatch.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      archive: PropTypes.string,
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  dispatchInfo: PropTypes.shape({
    periodname: PropTypes.string,
    ordermantoperiod: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

const mapStateToProps = state => ({
  dispatchInfo: state.mainReducer.dispatchInfo,
});

export default connect(mapStateToProps)(Dispatch);
