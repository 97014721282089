import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import Loader from 'components/Loader/Loader';
import ModalError from 'components/ModalError/ModalError';

// Actions
import {
  getRmmEmploymentWorkerWorks,
  rmmEmploymentProlongLastWork,
  getRmmEmploymentWorkers,
} from 'actions';

const EmployeeWorkModal = ({ closeHandler, worker, openModal, dispatch, worksData }) => {
  const [isModalLoading, setIsModalLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [responseError, setResponseError] = useState(null);

  useEffect(() => {
    dispatch(getRmmEmploymentWorkerWorks(worker.id))
      .then(() => setIsModalLoading(false))
      .catch(() => setIsModalLoading(false));
  }, [dispatch, worker.id]);

  const submitProlongLastWork = (tomorrow = false) => {
    setIsSubmitLoading(true);

    dispatch(rmmEmploymentProlongLastWork(worker.id, tomorrow))
      .then(() => dispatch(getRmmEmploymentWorkers()))
      .then(() => dispatch(getRmmEmploymentWorkerWorks(worker.id)))
      .then(() => {
        setIsSubmitLoading(false);
      })
      .catch(error => {
        if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsSubmitLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader>
        <h4 className="modal-header__title">
          Работы по <abbr title={worker.name}>{worker.name_1}</abbr>
        </h4>
      </ModalHeader>

      {isModalLoading && <Loader className="loader_modal" />}

      {!isModalLoading && worksData && worksData.works && (
        <ModalBody>
          <table className="table table-bordered table-stripped">
            <thead>
              <tr>
                <th>Даты</th>
                <th>Работы</th>
                <th>Узел</th>
                <th> </th>
              </tr>
            </thead>

            <tbody>
              {/* eslint-disable react/no-array-index-key */}
              {Array.isArray(worksData.works) &&
                worksData.works.map((work, index) => (
                  <tr key={index}>
                    <td>
                      {!work.realdate_1 && work.datestart_1 && (
                        <div>
                          {work.datestart_1} - {work.datetime_1}
                        </div>
                      )}

                      {!work.realdate_1 && !work.datestart_1 && <div>{work.datetime_1}</div>}

                      {work.realdate_1 && <div>{work.realdate_1}</div>}
                    </td>

                    <td>
                      {!work.realdate_1 && <div>{work.text}</div>}
                      {work.realdate_1 && <div>&mdash;</div>}
                    </td>
                    <td>{work.node && work.node.name}</td>

                    <td>
                      {Boolean(
                        !worksData.todayWork && work.node && Object.keys(work.node).length,
                      ) && (
                        <button
                          type="button"
                          className="btn btn-xs btn-primary"
                          disabled={isSubmitLoading}
                          onClick={() => {
                            submitProlongLastWork();
                          }}
                        >
                          Продлить на текущий день
                        </button>
                      )}

                      {Boolean(
                        !worksData.tomorrowWork &&
                          worksData.todayWork &&
                          work.node &&
                          Object.keys(work.node).length,
                      ) && (
                        <button
                          type="button"
                          className="btn btn-xs btn-primary"
                          disabled={isSubmitLoading}
                          onClick={() => {
                            submitProlongLastWork(true);
                          }}
                        >
                          Продлить на завтра
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              {/* eslint-enable react/no-array-index-key */}
            </tbody>
          </table>
        </ModalBody>
      )}

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        {!worksData.todayWork && (
          <button type="button" className="btn btn-warning" onClick={openModal}>
            Новая работа
          </button>
        )}

        {!worksData.tomorrowWork && worksData.todayWork && (
          <button type="button" className="btn btn-warning" onClick={openModal}>
            Новая работа на зватра
          </button>
        )}

        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>
      </ModalFooter>
    </React.Fragment>
  );
};

EmployeeWorkModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  worker: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    name_1: PropTypes.string,
  }).isRequired,
  openModal: PropTypes.func.isRequired,
  worksData: PropTypes.shape({
    todayWork: PropTypes.string,
    tomorrowWork: PropTypes.string,
    works: PropTypes.arrayOf(
      PropTypes.shape({
        realdate_1: PropTypes.string,
        datestart_1: PropTypes.string,
        datetime_1: PropTypes.string,
        text: PropTypes.string,
      }),
    ),
  }).isRequired,
};

const mapStateToProps = state => ({
  worksData: state.mainReducer.rmmEmploymentWorkerWorks,
});

export default connect(mapStateToProps)(EmployeeWorkModal);
