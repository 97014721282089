import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import WorkTableAdmin from 'components/WorkTableAdmin/WorkTableAdmin';
import Modal from 'components/Modal/Modal';
import AddOrderManModal from 'components/AddOrderManModal/AddOrderManModal';
import Loader from 'components/Loader/Loader';
import Warning from 'components/Warning/Warning';

// Actions
import { getAdminPeriod, getAdminPeriods } from 'actions';

// Styles
import './AdminPeriod.scss';

const AdminPeriod = ({ match, dispatch, periodData, periods }) => {
  const [isAddOrderManActive, setAddOrderManActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const period = periods.find(item => item.id === +match.params.periodId);

  const activateAddOrderManModal = () => {
    setAddOrderManActive(true);
  };

  const deactivateAddOrderManModal = () => {
    setAddOrderManActive(false);
  };

  /** Получаем периоды и данные по конкретному периоду */
  useEffect(() => {
    Promise.all([dispatch(getAdminPeriods()), dispatch(getAdminPeriod(match.params.periodId))])
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch, match.params.periodId]);

  return (
    <>
      {isLoading && <Loader className="loader_page" />}

      {!isLoading &&
        period &&
        periodData &&
        periodData.orderMenOfThisPeriod &&
        periodData.orderMen && (
          <div className="admin-period-page">
            <div className="admin-period-page__container container">
              {period && period.name && (
                <h2 className="admin-period-page__title">
                  Регламент ремонтов на {period.name} год
                </h2>
              )}

              {Array.isArray(periodData.orderMen) && periodData.orderMen.length > 0 && (
                <div className="admin-period-page__menu">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={activateAddOrderManModal}
                  >
                    Добавить заказчика
                  </button>
                </div>
              )}

              {isAddOrderManActive && (
                <Modal
                  title="Добавление заказчика к отчетному периоду"
                  closeHandler={deactivateAddOrderManModal}
                >
                  <AddOrderManModal
                    orderMen={periodData.orderMen}
                    periodId={match.params.periodId}
                    closeHandler={deactivateAddOrderManModal}
                  />
                </Modal>
              )}

              {Array.isArray(periodData.orderMenOfThisPeriod) &&
                periodData.orderMenOfThisPeriod.length > 0 && (
                  <WorkTableAdmin
                    orderMenOfThisPeriod={periodData.orderMenOfThisPeriod}
                    url={match.url}
                  />
                )}

              {Array.isArray(periodData.orderMenOfThisPeriod) &&
                periodData.orderMenOfThisPeriod.length === 0 && (
                  <Warning msg="Пока не добавлено заказчиков" />
                )}
            </div>
          </div>
        )}
    </>
  );
};

AdminPeriod.propTypes = {
  dispatch: PropTypes.func.isRequired,
  periodData: PropTypes.shape({
    orderMen: PropTypes.arrayOf(PropTypes.shape({})),
    orderMenOfThisPeriod: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      periodId: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
  periods: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = state => ({
  periodData: state.mainReducer.adminPeriodData,
  periods: state.mainReducer.adminPeriods,
});

export default connect(mapStateToProps)(AdminPeriod);
