import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Icons
import { FaTrashAlt } from 'react-icons/fa';

// Components
import Modal from 'components/Modal/Modal';
import RemoveRmmWorkerConfirmModal from 'components/RemoveRmmWorkerConfirmModal/RemoveRmmWorkerConfirmModal';
import EmployeeWorkModal from 'components/EmployeeWorkModal/EmployeeWorkModal';
import AddWorkRmmModal from 'components/AddWorkRmmModal/AddWorkRmmModal';

// Styles
import './TableRmmEmployment.scss';

const TableRmmEmployment = ({ canEdit, workers }) => {
  const [isRemoveRmmWorkerConfirmActive, setRemoveRmmWorkerConfirmActive] = useState(false);
  const [isEmployeeWorkActive, setEmployeeWorkActive] = useState(false);
  const [isAddWorkRmmActive, setAddWorkRmmActive] = useState(false);

  const [currentWorker, setCurrentWorker] = useState(null);
  const [removeWorkerId, setRemoveWorkerId] = useState(null);

  const activateRemoveRmmWorkerConfirmModal = workerId => {
    setRemoveWorkerId(workerId);
    setRemoveRmmWorkerConfirmActive(true);
  };

  const deactivateRemoveRmmWorkerConfirmModal = () => {
    setRemoveRmmWorkerConfirmActive(false);
  };

  const activateEmployeeWorkModal = worker => {
    setCurrentWorker(worker);
    setEmployeeWorkActive(true);
  };

  const deactivateEmployeeWorkModal = () => {
    setEmployeeWorkActive(false);
  };

  const activateAddWorkRmmModal = () => {
    setAddWorkRmmActive(true);
  };

  const deactivateAddWorkRmmModal = () => {
    setAddWorkRmmActive(false);
  };

  if (!workers.length) {
    return null;
  }

  return (
    <table className="table table-bordered table-rmm-employment">
      <thead>
        <tr>
          <th> </th>
          <th>Работник</th>

          {/* eslint-disable react/no-array-index-key */}
          {Array.isArray(workers[0].work) && (
            <>
              {workers[0].work.map((work, index) => (
                <th key={index}>
                  {work.realdate_1 && (
                    <div>
                      {work.realdate_1} {work.day}
                    </div>
                  )}
                </th>
              ))}
            </>
          )}

          {/* eslint-enable react/no-array-index-key */}
        </tr>
      </thead>

      <tbody>
        {workers.map(worker => (
          <tr key={worker.id}>
            <td>
              {canEdit && (
                <button
                  type="button"
                  className="btn btn-danger btn-xs"
                  onClick={() => activateRemoveRmmWorkerConfirmModal(worker.id)}
                >
                  <FaTrashAlt className="icon" />
                </button>
              )}
            </td>
            <td>
              <button
                type="button"
                className="table-rmm-employment__worker"
                onClick={() => activateEmployeeWorkModal(worker)}
              >
                <abbr title={worker.name}>{worker.name_1}</abbr> ({worker.role_1})
              </button>
            </td>

            {/* eslint-disable react/no-array-index-key */}
            {Array.isArray(worker.work) &&
              worker.work.map((work, index) => (
                <td key={index}>
                  {!work.realdate_1 ? (
                    <abbr className="label label_info" title={`${work.nodename} (${work.text})`}>
                      {work.nodename_sh}
                    </abbr>
                  ) : (
                    <div>&mdash;</div>
                  )}
                </td>
              ))}
            {/* eslint-enable react/no-array-index-key */}
          </tr>
        ))}

        {isRemoveRmmWorkerConfirmActive && (
          <Modal
            title="Убрать работника из ведомости"
            closeHandler={deactivateRemoveRmmWorkerConfirmModal}
          >
            <RemoveRmmWorkerConfirmModal
              workerId={removeWorkerId}
              closeHandler={deactivateRemoveRmmWorkerConfirmModal}
            />
          </Modal>
        )}

        {isEmployeeWorkActive && (
          <Modal
            title={`Работы по работнику ${currentWorker.name}`}
            closeHandler={deactivateEmployeeWorkModal}
          >
            <EmployeeWorkModal
              openModal={activateAddWorkRmmModal}
              closeHandler={deactivateEmployeeWorkModal}
              worker={currentWorker}
            />
          </Modal>
        )}

        {isAddWorkRmmActive && (
          <Modal title="Добавить работу" closeHandler={deactivateAddWorkRmmModal}>
            <AddWorkRmmModal closeHandler={deactivateAddWorkRmmModal} workerId={currentWorker.id} />
          </Modal>
        )}
      </tbody>
    </table>
  );
};

TableRmmEmployment.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  workers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      name_1: PropTypes.string,
      role_1: PropTypes.string,
      work: PropTypes.arrayOf(
        PropTypes.shape({
          realdate_1: PropTypes.string,
          day: PropTypes.string,
          nodename: PropTypes.string,
          nodename_sh: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
};

export default TableRmmEmployment;
