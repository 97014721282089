import React from 'react';
import PropTypes from 'prop-types';

// Icons
import { FaLongArrowAltRight } from 'react-icons/fa';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';

const TaskBigInfoModal = ({ closeHandler, task }) => (
  <React.Fragment>
    <ModalHeader className="modal-header_has-sub-title">
      <h4 className="modal-header__title">
        Задача №{task.id} «{task.name}»
      </h4>
      <h5 className="modal-header__sub-title">
        <span className="label label_primary">
          {task.authorname_1} ({task.authorrole})
        </span>{' '}
        <FaLongArrowAltRight className="icon" />{' '}
        <span className="label label_danger">
          {task.targetname_1} ({task.targetrole})
        </span>
      </h5>
    </ModalHeader>

    <ModalBody>
      <div className="modal__section">
        <div className="modal__section-title">Текст задачи:</div>
        <div className="modal__section-text">{task.text}</div>
      </div>
    </ModalBody>

    <ModalFooter closeHandler={closeHandler} />
  </React.Fragment>
);

TaskBigInfoModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  task: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    authorname_1: PropTypes.string,
    authorrole: PropTypes.string,
    text: PropTypes.string,
    targetname_1: PropTypes.string,
    targetrole: PropTypes.string,
  }).isRequired,
};

export default TaskBigInfoModal;
