export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAIL = 'LOG_IN_FAIL';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_FAIL = 'LOG_OUT_FAIL';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const DISPOSE_USER = 'DISPOSE_USER';
export const GET_REPAIRS_LIST_SUCCESS = 'GET_REPAIRS_LIST_SUCCESS';
export const GET_REPAIRS_LIST_FAIL = 'GET_REPAIRS_LIST_FAIL';
export const GET_REPAIR_INFO_SUCCESS = 'GET_REPAIR_INFO_SUCCESS';
export const GET_REPAIR_INFO_FAIL = 'GET_REPAIR_INFO_FAIL';
export const GET_REPAIR_WORK_PROGRESS_SUCCESS = 'GET_REPAIR_WORK_PROGRESS_SUCCESS';
export const GET_REPAIR_WORK_PROGRESS_FAIL = 'GET_REPAIR_WORK_PROGRESS_FAIL';
export const GET_REPAIR_PROBLEMS_SUCCESS = 'GET_REPAIR_PROBLEMS_SUCCESS';
export const GET_REPAIR_PROBLEMS_FAIL = 'GET_REPAIR_PROBLEMS_FAIL';
export const GET_REPAIR_PROBLEM_TARGETS_SUCCESS = 'GET_REPAIR_PROBLEM_TARGETS_SUCCESS';
export const GET_REPAIR_PROBLEM_TARGETS_FAIL = 'GET_REPAIR_PROBLEM_TARGETS_FAIL';
export const ADD_REPAIR_WORK_SUCCESS = 'ADD_REPAIR_WORK_SUCCESS';
export const ADD_REPAIR_WORK_FAIL = 'ADD_REPAIR_WORK_FAIL';
export const DELETE_REPAIR_WORK_SUCCESS = 'DELETE_REPAIR_WORK_SUCCESS';
export const DELETE_REPAIR_WORK_FAIL = 'DELETE_REPAIR_WORK_FAIL';
export const EDIT_REPAIR_WORK_SUCCESS = 'EDIT_REPAIR_WORK_SUCCESS';
export const EDIT_REPAIR_WORK_FAIL = 'EDIT_REPAIR_WORK_FAIL';
export const SET_REPAIR_WORK_WEEK_SUCCESS = 'SET_REPAIR_WORK_WEEK_SUCCESS';
export const SET_REPAIR_WORK_WEEK_FAIL = 'SET_REPAIR_WORK_WEEK_FAIL';
export const SET_OPEN_REPAIR_SUCCESS = 'SET_OPEN_REPAIR_SUCCESS';
export const SET_OPEN_REPAIR_FAIL = 'SET_OPEN_REPAIR_FAIL';
export const SET_FINISH_REPAIR_SUCCESS = 'SET_FINISH_REPAIR_SUCCESS';
export const SET_FINISH_REPAIR_FAIL = 'SET_FINISH_REPAIR_FAIL';
export const SET_CLOSE_REPAIR_SUCCESS = 'SET_CLOSE_REPAIR_SUCCESS';
export const SET_CLOSE_REPAIR_FAIL = 'SET_CLOSE_REPAIR_FAIL';
export const DELETE_REPAIR_PROBLEM_SUCCESS = 'DELETE_REPAIR_PROBLEM_SUCCESS';
export const DELETE_REPAIR_PROBLEM_FAIL = 'DELETE_REPAIR_PROBLEM_FAIL';
export const ADD_REPAIR_PROBLEM_SUCCESS = 'ADD_REPAIR_PROBLEM_SUCCESS';
export const ADD_REPAIR_PROBLEM_FAIL = 'ADD_REPAIR_PROBLEM_FAIL';
export const SET_REPAIR_PROBLEM_SOLVED_SUCCESS = 'SET_REPAIR_PROBLEM_SOLVED_SUCCESS';
export const SET_REPAIR_PROBLEM_SOLVED_FAIL = 'SET_REPAIR_PROBLEM_SOLVED_FAIL';
export const SET_REPAIR_RETURN_PROBLEM_SUCCESS = 'SET_REPAIR_RETURN_PROBLEM_SUCCESS';
export const SET_REPAIR_RETURN_PROBLEM_FAIL = 'SET_REPAIR_RETURN_PROBLEM_FAIL';
export const SET_REPAIR_TAKEOFF_PROBLEM_SUCCESS = 'SET_REPAIR_TAKEOFF_PROBLEM_SUCCESS';
export const SET_REPAIR_TAKEOFF_PROBLEM_FAIL = 'SET_REPAIR_TAKEOFF_PROBLEM_FAIL';
export const UPLOAD_PROBLEM_DOCUMENT_SUCCESS = 'UPLOAD_PROBLEM_DOCUMENT_SUCCESS';
export const UPLOAD_PROBLEM_DOCUMENT_FAIL = 'UPLOAD_PROBLEM_DOCUMENT_FAIL';
export const SET_NO_DISPATCH_SUCCESS = 'SET_NO_DISPATCH_SUCCESS';
export const SET_NO_DISPATCH_FAIL = 'SET_NO_DISPATCH_FAIL';
export const SET_REPAIR_COMMENT_SUCCESS = 'SET_REPAIR_COMMENT_SUCCESS';
export const SET_REPAIR_COMMENT_FAIL = 'SET_REPAIR_COMMENT_FAIL';

/** Repair Additional Works */
export const GET_REPAIR_ADDITIONAL_WORKS_SUCCESS = 'GET_REPAIR_ADDITIONAL_WORKS_SUCCESS';
export const GET_REPAIR_ADDITIONAL_WORKS_FAIL = 'GET_REPAIR_ADDITIONAL_WORKS_FAIL';
export const ADD_REPAIR_ADDITIONAL_WORK_SUCCESS = 'ADD_REPAIR_ADDITIONAL_WORK_SUCCESS';
export const ADD_REPAIR_ADDITIONAL_WORK_FAIL = 'ADD_REPAIR_ADDITIONAL_WORK_FAIL';
export const EDIT_REPAIR_ADDITIONAL_WORK_SUCCESS = 'EDIT_REPAIR_ADDITIONAL_WORK_SUCCESS';
export const EDIT_REPAIR_ADDITIONAL_WORK_FAIL = 'EDIT_REPAIR_ADDITIONAL_WORK_FAIL';
export const DELETE_REPAIR_ADDITIONAL_WORK_SUCCESS = 'DELETE_REPAIR_ADDITIONAL_WORK_SUCCESS';
export const DELETE_REPAIR_ADDITIONAL_WORK_FAIL = 'DELETE_REPAIR_ADDITIONAL_WORK_FAIL';
export const SET_REPAIR_ADDITIONAL_WORK_READY_SUCCESS = 'SET_REPAIR_ADDITIONAL_WORK_READY_SUCCESS';
export const SET_REPAIR_ADDITIONAL_WORK_READY_FAIL = 'SET_REPAIR_ADDITIONAL_WORK_READY_FAIL';
export const SET_REPAIR_ADDITIONAL_WORK_PAID_SUCCESS = 'SET_REPAIR_ADDITIONAL_WORK_PAID_SUCCESS';
export const SET_REPAIR_ADDITIONAL_WORK_PAID_FAIL = 'SET_REPAIR_ADDITIONAL_WORK_PAID_FAIL';
/** Repair Additional Works END */

/** Repair Roster */
export const GET_REPAIR_ROSTER_TEAM_SUCCESS = 'GET_REPAIR_ROSTER_TEAM_SUCCESS';
export const GET_REPAIR_ROSTER_TEAM_FAIL = 'GET_REPAIR_ROSTER_TEAM_FAIL';
export const GET_ROSTER_REPAIRMAN_LIST_SUCCESS = 'GET_ROSTER_REPAIRMAN_LIST_SUCCESS';
export const GET_ROSTER_REPAIRMAN_LIST_FAIL = 'GET_ROSTER_REPAIRMAN_LIST_FAIL';
export const ADD_REPAIRMAN_TO_ROSTER_SUCCESS = 'ADD_REPAIRMAN_TO_ROSTER_SUCCESS';
export const ADD_REPAIRMAN_TO_ROSTER_FAIL = 'ADD_REPAIRMAN_TO_ROSTER_FAIL';
export const DELETE_REPAIRMAN_FROM_ROSTER_SUCCESS = 'DELETE_REPAIRMAN_FROM_ROSTER_SUCCESS';
export const DELETE_REPAIRMAN_FROM_ROSTER_FAIL = 'DELETE_REPAIRMAN_FROM_ROSTER_FAIL';
export const SET_ROSTER_REPAIRMAN_ACTIVE_SUCCESS = 'SET_ROSTER_REPAIRMAN_ACTIVE_SUCCESS';
export const SET_ROSTER_REPAIRMAN_ACTIVE_FAIL = 'SET_ROSTER_REPAIRMAN_ACTIVE_FAIL';
/** Repair Roster END */

/** Repair coop */
export const GET_REPAIR_CO_WORKS_SUCCESS = 'GET_REPAIR_CO_WORKS_SUCCESS';
export const GET_REPAIR_CO_WORKS_FAIL = 'GET_REPAIR_CO_WORKS_FAIL';
export const ADD_REPAIR_CO_WORK_SUCCESS = 'ADD_REPAIR_CO_WORK_SUCCESS';
export const ADD_REPAIR_CO_WORK_FAIL = 'ADD_REPAIR_CO_WORK_FAIL';
export const DELETE_REPAIR_CO_WORK_SUCCESS = 'DELETE_REPAIR_CO_WORK_SUCCESS';
export const DELETE_REPAIR_CO_WORK_FAIL = 'DELETE_REPAIR_CO_WORK_FAIL';
export const EDIT_REPAIR_CO_WORK_SUCCESS = 'EDIT_REPAIR_CO_WORK_SUCCESS';
export const EDIT_REPAIR_CO_WORK_FAIL = 'EDIT_REPAIR_CO_WORK_FAIL';
/** Repair coop END */

/** Repair prolong */
export const GET_REPAIR_PROLONGS_SUCCESS = 'GET_REPAIR_PROLONGS_SUCCESS';
export const GET_REPAIR_PROLONGS_FAIL = 'GET_REPAIR_PROLONGS_FAIL';
export const ADD_REPAIR_PROLONG_SUCCESS = 'ADD_REPAIR_PROLONG_SUCCESS';
export const ADD_REPAIR_PROLONG_FAIL = 'ADD_REPAIR_PROLONG_FAIL';
export const SET_REPAIR_PROLONG_REACTION_SUCCESS = 'SET_REPAIR_PROLONG_REACTION_SUCCESS';
export const SET_REPAIR_PROLONG_REACTION_FAIL = 'SET_REPAIR_PROLONG_REACTION_FAIL';
export const REPAIR_PROLONG_MOVE_TO_OTHER_SUCCESS = 'REPAIR_PROLONG_MOVE_TO_OTHER_SUCCESS';
export const REPAIR_PROLONG_MOVE_TO_OTHER_FAIL = 'REPAIR_PROLONG_MOVE_TO_OTHER_FAIL';
/** Repair prolong END */

/** Repair docs */
export const GET_REPAIR_DOCS_SUCCESS = 'GET_REPAIR_DOCS_SUCCESS';
export const GET_REPAIR_DOCS_FAIL = 'GET_REPAIR_DOCS_FAIL';
export const ADD_REPAIR_CUSTOM_DOC_SUCCESS = 'ADD_REPAIR_CUSTOM_DOC_SUCCESS';
export const ADD_REPAIR_CUSTOM_DOC_FAIL = 'ADD_REPAIR_CUSTOM_DOC_FAIL';
export const DELETE_REPAIR_CUSTOM_DOC_SUCCESS = 'DELETE_REPAIR_CUSTOM_DOC_SUCCESS';
export const DELETE_REPAIR_CUSTOM_DOC_FAIL = 'DELETE_REPAIR_CUSTOM_DOC_FAIL';
export const CHANGE_REPAIR_DOC_NO_NEED_SUCCESS = 'CHANGE_REPAIR_DOC_NO_NEED_SUCCESS';
export const CHANGE_REPAIR_DOC_NO_NEED_FAIL = 'CHANGE_REPAIR_DOC_NO_NEED_FAIL';
export const SET_REPAIR_UNIT_WORK_HOURS_SUCCESS = 'SET_REPAIR_UNIT_WORK_HOURS_SUCCESS';
export const SET_REPAIR_UNIT_WORK_HOURS_FAIL = 'SET_REPAIR_UNIT_WORK_HOURS_FAIL';
export const UPLOAD_REPAIR_MULTI_DOCUMENT_SUCCESS = 'UPLOAD_REPAIR_MULTI_DOCUMENT_SUCCESS';
export const UPLOAD_REPAIR_MULTI_DOCUMENT_FAIL = 'UPLOAD_REPAIR_MULTI_DOCUMENT_FAIL';
export const UPLOAD_REPAIR_DOCUMENT_SUCCESS = 'UPLOAD_REPAIR_DOCUMENT_SUCCESS';
export const UPLOAD_REPAIR_DOCUMENT_FAIL = 'UPLOAD_REPAIR_DOCUMENT_FAIL';
/** Repair docs END */

/** Repair Mtr */
export const GET_REPAIR_MTR_SUCCESS = 'GET_REPAIR_MTR_SUCCESS';
export const GET_REPAIR_MTR_FAIL = 'GET_REPAIR_MTR_FAIL';
export const SET_REPAIR_MTR_INCLUDED_SUCCESS = 'SET_REPAIR_MTR_INCLUDED_SUCCESS';
export const SET_REPAIR_MTR_INCLUDED_FAIL = 'SET_REPAIR_MTR_INCLUDED_FAIL';
export const SET_REPAIR_MTR_RECEIVED_SUCCESS = 'SET_REPAIR_MTR_RECEIVED_SUCCESS';
export const SET_REPAIR_MTR_RECEIVED_FAIL = 'SET_REPAIR_MTR_RECEIVED_FAIL';
export const SET_REPAIR_MTR_CHARGED_SUCCESS = 'SET_REPAIR_MTR_CHARGED_SUCCESS';
export const SET_REPAIR_MTR_CHARGED_FAIL = 'SET_REPAIR_MTR_CHARGED_FAIL';
/** Repair Mtr END */

/** Repair Node */
export const GET_REPAIR_NODES_SUCCESS = 'GET_REPAIR_NODES_SUCCESS';
export const GET_REPAIR_NODES_FAIL = 'GET_REPAIR_NODES_FAIL';
export const ADD_REPAIR_NODE_SUCCESS = 'ADD_REPAIR_NODE_SUCCESS';
export const ADD_REPAIR_NODE_FAIL = 'ADD_REPAIR_NODE_FAIL';
export const CONFIRM_DATE_REPAIR_NODE_SUCCESS = 'CONFIRM_DATE_REPAIR_NODE_SUCCESS';
export const CONFIRM_DATE_REPAIR_NODE_FAIL = 'CONFIRM_DATE_REPAIR_NODE_FAIL';
export const RECEIVE_REPAIR_NODE_SUCCESS = 'RECEIVE_REPAIR_NODE_SUCCESS';
export const RECEIVE_REPAIR_NODE_FAIL = 'RECEIVE_REPAIR_NODE_FAIL';
export const UPDATE_ACT_REPAIR_NODE_SUCCESS = 'UPDATE_ACT_REPAIR_NODE_SUCCESS';
export const RESET_REPAIR_NODE_UNREAD_COUNT_SUCCESS = 'RESET_REPAIR_NODE_UNREAD_COUNT_SUCCESS';
/** Repair Node END */

/** Диспетчерский отчет */
export const GET_DISPATCH_SUCCESS = 'GET_DISPATCH_SUCCESS';
export const GET_DISPATCH_FAIL = 'GET_DISPATCH_FAIL';
export const TOGGLE_COLLAPSE_DISPATCH_TABLE_ROWS = 'TOGGLE_COLLAPSE_DISPATCH_TABLE_ROWS';
/** Диспетчерский отчет END */

/** Информация по меню внизу сайта */
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';
/** Информация по меню внизу сайта END */

/** Задачи */
export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS';
export const ADD_TASK_FAIL = 'ADD_TASK_FAIL';
export const GET_MY_TASK_TARGETS_SUCCESS = 'GET_MY_TASK_TARGETS_SUCCESS';
export const GET_MY_TASK_TARGETS_FAIL = 'GET_MY_TASK_TARGETS_FAIL';
export const EDIT_TASK_SUCCESS = 'EDIT_TASK_SUCCESS';
export const EDIT_TASK_FAIL = 'EDIT_TASK_FAIL';
export const SET_TASK_DONE_SUCCESS = 'SET_TASK_DONE_SUCCESS';
export const SET_TASK_DONE_FAIL = 'SET_TASK_DONE_FAIL';
export const SET_TASK_DONE_APPROVE_SUCCESS = 'SET_TASK_DONE_APPROVE_SUCCESS';
export const SET_TASK_DONE_APPROVE_FAIL = 'SET_TASK_DONE_APPROVE_FAIL';
export const SET_TASK_DONE_DENY_SUCCESS = 'SET_TASK_DONE_DENY_SUCCESS';
export const SET_TASK_DONE_DENY_FAIL = 'SET_TASK_DONE_DENY_FAIL';
export const GET_TASK_COMMENTS_SUCCESS = 'GET_TASK_COMMENTS_SUCCESS';
export const GET_TASK_COMMENTS_FAIL = 'GET_TASK_COMMENTS_FAIL';
export const ADD_TASK_COMMENT_SUCCESS = 'ADD_TASK_COMMENT_SUCCESS';
export const ADD_TASK_COMMENT_FAIL = 'ADD_TASK_COMMENT_FAIL';
export const UPLOAD_TASK_DOCUMENT_SUCCESS = 'UPLOAD_TASK_DOCUMENT_SUCCESS';
export const RESET_TASK_UNREAD_COUNT_SUCCESS = 'RESET_TASK_UNREAD_COUNT_SUCCESS';
/** Задачи END */

/** Свод дополнительных работ */
export const GET_REPORT_ADDITIONAL_WORKS_SUCCESS = 'GET_REPORT_ADDITIONAL_WORKS_SUCCESS';
export const GET_REPORT_ADDITIONAL_WORKS_FAIL = 'GET_REPORT_ADDITIONAL_WORKS_FAIL';
/** Свод дополнительных работ END */

/** Свод работ соисполнителей */
export const GET_REPORT_CO_WORKS_SUCCESS = 'GET_REPORT_CO_WORKS_SUCCESS';
export const GET_REPORT_CO_WORKS_FAIL = 'GET_REPORT_CO_WORKS_FAIL';
/** Свод работ соисполнителей END */

/** Свод текущий отчет */
export const GET_REPORT_PROBLEMS_SUCCESS = 'GET_REPORT_PROBLEMS_SUCCESS';
export const GET_REPORT_PROBLEMS_FAIL = 'GET_REPORT_PROBLEMS_FAIL';
export const GET_REPORT_TASKS_SUCCESS = 'GET_REPORT_TASKS_SUCCESS';
export const GET_REPORT_TASKS_FAIL = 'GET_REPORT_TASKS_FAIL';
export const GET_REPORT_MISS_DOCS_SUCCESS = 'GET_REPORT_MISS_DOCS_SUCCESS';
export const GET_REPORT_MISS_DOCS_FAIL = 'GET_REPORT_MISS_DOCS_FAIL';
export const GET_REPORT_MISS_DOCS_RMM_SUCCESS = 'GET_REPORT_MISS_DOCS_RMM_SUCCESS';
export const GET_REPORT_MISS_DOCS_RMM_FAIL = 'GET_REPORT_MISS_DOCS_RMM_FAIL';
export const GET_REPORT_NODES_NOT_RECEIVED_SUCCESS = 'GET_REPORT_NODES_NOT_RECEIVED_SUCCESS';
export const GET_REPORT_NODES_NOT_RECEIVED_FAIL = 'GET_REPORT_NODES_NOT_RECEIVED_FAIL';
export const GET_REPORT_REPAIRS_OVERDUE_SUCCESS = 'GET_REPORT_REPAIRS_OVERDUE_SUCCESS';
export const GET_REPORT_REPAIRS_OVERDUE_FAIL = 'GET_REPORT_REPAIRS_OVERDUE_FAIL';
export const GET_REPORT_NODES_NO_FORECAST_SUCCESS = 'GET_REPORT_NODES_NO_FORECAST_SUCCESS';
export const GET_REPORT_NODES_NO_FORECAST_FAIL = 'GET_REPORT_NODES_NO_FORECAST_FAIL';
export const GET_REPORT_NODES_OVERDUE_SUCCESS = 'GET_REPORT_NODES_OVERDUE_SUCCESS';
export const GET_REPORT_NODES_OVERDUE_FAIL = 'GET_REPORT_NODES_OVERDUE_FAIL';
export const GET_REPORT_NO_NAR_SUCCESS = 'GET_REPORT_NO_NAR_SUCCESS';
export const GET_REPORT_NO_NAR_FAIL = 'GET_REPORT_NO_NAR_FAIL';
export const GET_REPORT_NO_REPORT_REPAIRS_SUCCESS = 'GET_REPORT_NO_REPORT_REPAIRS_SUCCESS';
export const GET_REPORT_NO_REPORT_REPAIRS_FAIL = 'GET_REPORT_NO_REPORT_REPAIRS_FAIL';
/** Свод текущий отчет END */

/** РММ */
export const GET_RMM_SUCCESS = 'GET_RMM_SUCCESS';
export const GET_RMM_FAIL = 'GET_RMM_FAIL';
export const SEND_RMM_NODE_TO_ARCHIVE_SUCCESS = 'SEND_RMM_NODE_TO_ARCHIVE_SUCCESS';
export const SEND_RMM_NODE_TO_ARCHIVE_FAIL = 'SEND_RMM_NODE_TO_ARCHIVE_FAIL';
export const MOVE_RMM_NODE_TO_OTHER_BASE_SUCCESS = 'MOVE_RMM_NODE_TO_OTHER_BASE_SUCCESS';
export const MOVE_RMM_NODE_TO_OTHER_BASE_FAIL = 'MOVE_RMM_NODE_TO_OTHER_BASE_FAIL';
export const DELETE_RMM_NODE_SUCCESS = 'DELETE_RMM_NODE_SUCCESS';
export const DELETE_RMM_NODE_FAIL = 'DELETE_RMM_NODE_FAIL';
export const SET_RMM_NODE_PRIORITY_SUCCESS = 'SET_RMM_NODE_PRIORITY_SUCCESS';
export const SET_RMM_NODE_PRIORITY_FAIL = 'SET_RMM_NODE_PRIORITY_FAIL';
export const CHANGE_RMM_DOC_NO_NEED_SUCCESS = 'CHANGE_RMM_DOC_NO_NEED_SUCCESS';
// export const CHANGE_RMM_DOC_NO_NEED_FAIL = 'CHANGE_RMM_DOC_NO_NEED_FAIL';
export const SET_RMM_NODE_RECEIVE_SUCCESS = 'SET_RMM_NODE_RECEIVE_SUCCESS';
export const SET_RMM_NODE_RECEIVE_FAIL = 'SET_RMM_NODE_RECEIVE_FAIL';
export const CHANGE_RMM_NODE_DATE_FORECAST_SUCCESS = 'CHANGE_RMM_NODE_DATE_FORECAST_SUCCESS';
export const CHANGE_RMM_NODE_DATE_FORECAST_FAIL = 'CHANGE_RMM_NODE_DATE_FORECAST_FAIL';
export const ADD_RMM_NODE_SUCCESS = 'ADD_RMM_NODE_SUCCESS';
export const ADD_RMM_NODE_FAIL = 'ADD_RMM_NODE_FAIL';
export const ADD_RMM_NODE_PERCENT_SUCCESS = 'ADD_RMM_NODE_PERCENT_SUCCESS';
export const ADD_RMM_NODE_PERCENT_FAIL = 'ADD_RMM_NODE_PERCENT_FAIL';
export const GET_RMM_NODE_COMMENTS_SUCCESS = 'GET_RMM_NODE_COMMENTS_SUCCESS';
export const GET_RMM_NODE_COMMENTS_FAIL = 'GET_RMM_NODE_COMMENTS_FAIL';
export const ADD_RMM_NODE_COMMENT_SUCCESS = 'ADD_RMM_NODE_COMMENT_SUCCESS';
export const ADD_RMM_NODE_COMMENT_FAIL = 'ADD_RMM_NODE_COMMENT_FAIL';
export const SEND_RMM_NODE_TO_KS_SUCCESS = 'SEND_RMM_NODE_TO_KS_SUCCESS';
export const SEND_RMM_NODE_TO_KS_FAIL = 'SEND_RMM_NODE_TO_KS_FAIL';
export const UPLOAD_RMM_DOCUMENT_SUCCESS = 'UPLOAD_RMM_DOCUMENT_SUCCESS';
export const RESET_RMM_NODE_UNREAD_COUNT_SUCCESS = 'RESET_RMM_NODE_UNREAD_COUNT_SUCCESS';
export const LINK_NODE_TO_REPAIR_SUCCESS = 'LINK_NODE_TO_REPAIR_SUCCESS';
/** РММ END */

/** Администрирование - Заказчики */
export const GET_ADMIN_ORDER_MAN_SUCCESS = 'GET_ADMIN_ORDER_MAN_SUCCESS';
export const GET_ADMIN_ORDER_MAN_FAIL = 'GET_ADMIN_ORDER_MAN_FAIL';
export const ADD_ADMIN_ORDER_MAN_SUCCESS = 'ADD_ADMIN_ORDER_MAN_SUCCESS';
export const ADD_ADMIN_ORDER_MAN_FAIL = 'ADD_ADMIN_ORDER_MAN_FAIL';
export const EDIT_ADMIN_ORDER_MAN_SUCCESS = 'EDIT_ADMIN_ORDER_MAN_SUCCESS';
export const EDIT_ADMIN_ORDER_MAN_FAIL = 'EDIT_ADMIN_ORDER_MAN_FAIL';
export const DELETE_ADMIN_ORDER_MAN_SUCCESS = 'DELETE_ADMIN_ORDER_MAN_SUCCESS';
export const DELETE_ADMIN_ORDER_MAN_FAIL = 'DELETE_ADMIN_ORDER_MAN_FAIL';
/** Администрирование - Заказчики END */

/** Администрирование - Периоды */
export const GET_ADMIN_PERIODS_SUCCESS = 'GET_ADMIN_PERIODS_SUCCESS';
export const GET_ADMIN_PERIODS_FAIL = 'GET_ADMIN_PERIODS_FAIL';
export const ADD_ADMIN_PERIOD_SUCCESS = 'ADD_ADMIN_PERIOD_SUCCESS';
export const ADD_ADMIN_PERIOD_FAIL = 'ADD_ADMIN_PERIOD_FAIL';
export const GET_ADMIN_PERIOD_SUCCESS = 'GET_ADMIN_PERIOD_SUCCESS';
export const GET_ADMIN_PERIOD_FAIL = 'GET_ADMIN_PERIOD_FAIL';
export const ADD_ADMIN_ORDER_MAN_TO_PERIOD_SUCCESS = 'ADD_ADMIN_ORDER_MAN_TO_PERIOD_SUCCESS';
export const ADD_ADMIN_ORDER_MAN_TO_PERIOD_FAIL = 'ADD_ADMIN_ORDER_MAN_TO_PERIOD_FAIL';
/** Администрирование - Периоды END */

/** Информация по шапке сайта */
export const GET_HEADER_DATA_SUCCESS = 'GET_HEADER_DATA_SUCCESS';
export const GET_HEADER_DATA_FAIL = 'GET_HEADER_DATA_FAIL';
export const EMULATE_USER_SUCCESS = 'EMULATE_USER_SUCCESS';
export const EMULATE_USER_FAIL = 'EMULATE_USER_FAIL';
export const FINISH_EMULATE_USER_SUCCESS = 'FINISH_EMULATE_USER_SUCCESS';
export const FINISH_EMULATE_USER_FAIL = 'FINISH_EMULATE_USER_FAIL';
export const CHANGE_PERIOD_SUCCESS = 'CHANGE_PERIOD_SUCCESS';
export const CHANGE_PERIOD_FAIL = 'CHANGE_PERIOD_FAIL';
/** Информация по шапке сайта END */

/** Администрирование - КС */
export const ADD_KS_TO_ORDER_MAN_SUCCESS = 'ADD_KS_TO_ORDER_MAN_SUCCESS';
export const ADD_KS_TO_ORDER_MAN_FAIL = 'ADD_KS_TO_ORDER_MAN_FAIL';
export const EDIT_KS_ADMIN_SUCCESS = 'EDIT_KS_ADMIN_SUCCESS';
export const EDIT_KS_ADMIN_FAIL = 'EDIT_KS_ADMIN_FAIL';
export const DELETE_KS_ADMIN_SUCCESS = 'DELETE_KS_ADMIN_SUCCESS';
export const DELETE_KS_ADMIN_FAIL = 'DELETE_KS_ADMIN_FAIL';
export const MOVE_KS_ADMIN_SUCCESS = 'MOVE_KS_ADMIN_SUCCESS';
export const MOVE_KS_ADMIN_FAIL = 'MOVE_KS_ADMIN_FAIL';
/** Администрирование - КС END */

/** Занятость работников */
export const GET_EMPLOYMENT_SUCCESS = 'GET_EMPLOYMENT_SUCCESS';
export const GET_EMPLOYMENT_FAIL = 'GET_EMPLOYMENT_FAIL';
export const GET_EMPLOYMENT_USER_REPORT_SUCCESS = 'GET_EMPLOYMENT_USER_REPORT_SUCCESS';
export const GET_EMPLOYMENT_USER_REPORT_FAIL = 'GET_EMPLOYMENT_USER_REPORT_FAIL';
export const CLEAR_EMPLOYMENT_USER_REPORT_SUCCESS = 'CLEAR_EMPLOYMENT_USER_REPORT_SUCCESS';
export const CLEAR_EMPLOYMENT_DEPARTMENT_REPORT_SUCCESS = 'CLEAR_EMPLOYMENT_USER_REPORT_SUCCESS';
export const GET_EMPLOYMENT_DEPARTMENT_REPORT_SUCCESS = 'GET_EMPLOYMENT_DEPARTMENT_REPORT_SUCCESS';
export const GET_EMPLOYMENT_DEPARTMENT_REPORT_FAIL = 'GET_EMPLOYMENT_DEPARTMENT_REPORT_FAIL';
export const SET_EMPLOYMENT_USER_CONTACTS_SUCCESS = 'SET_EMPLOYMENT_USER_CONTACTS_SUCCESS';
export const SET_EMPLOYMENT_USER_CONTACTS_FAIL = 'SET_EMPLOYMENT_USER_CONTACTS_FAIL';
export const GET_EMPLOYMENT_COMMENTS_SUCCESS = 'GET_EMPLOYMENT_COMMENTS_SUCCESS';
export const GET_EMPLOYMENT_COMMENTS_FAIL = 'GET_EMPLOYMENT_COMMENTS_FAIL';
export const ADD_EMPLOYMENT_COMMENT_SUCCESS = 'ADD_EMPLOYMENT_COMMENT_SUCCESS';
export const ADD_EMPLOYMENT_COMMENT_FAIL = 'ADD_EMPLOYMENT_COMMENT_FAIL';
export const SET_EMPLOYMENT_OUT_OF_WORK_USER_SUCCESS = 'SET_EMPLOYMENT_OUT_OF_WORK_USER_SUCCESS';
export const SET_EMPLOYMENT_OUT_OF_WORK_USER_FAIL = 'SET_EMPLOYMENT_OUT_OF_WORK_USER_FAIL';
export const SET_EMPLOYMENT_IN_TO_WORK_USER_SUCCESS = 'SET_EMPLOYMENT_IN_TO_WORK_USER_SUCCESS';
export const SET_EMPLOYMENT_IN_TO_WORK_USER_FAIL = 'SET_EMPLOYMENT_IN_TO_WORK_USER_FAIL';
export const UPLOAD_EMPLOYEE_DOCUMENT_SUCCESS = 'UPLOAD_EMPLOYEE_DOCUMENT_SUCCESS';
/** Занятость работников END */

/** Администрирование - Ремонты */
export const GET_ADMIN_REPAIR_DICTIONARY_SUCCESS = 'GET_ADMIN_REPAIR_DICTIONARY_SUCCESS';
export const GET_ADMIN_REPAIR_DICTIONARY_FAIL = 'GET_ADMIN_REPAIR_DICTIONARY_FAIL';
export const ADD_ADMIN_REPAIR_SUCCESS = 'ADD_ADMIN_REPAIR_SUCCESS';
export const ADD_ADMIN_REPAIR_FAIL = 'ADD_ADMIN_REPAIR_FAIL';
export const GET_ADMIN_REPAIR_SUCCESS = 'GET_ADMIN_REPAIR_SUCCESS';
export const GET_ADMIN_REPAIR_FAIL = 'GET_ADMIN_REPAIR_FAIL';
export const EDIT_ADMIN_REPAIR_SUCCESS = 'EDIT_ADMIN_REPAIR_SUCCESS';
export const EDIT_ADMIN_REPAIR_FAIL = 'EDIT_ADMIN_REPAIR_FAIL';
export const DELETE_ADMIN_REPAIR_SUCCESS = 'DELETE_ADMIN_REPAIR_SUCCESS';
export const DELETE_ADMIN_REPAIR_FAIL = 'DELETE_ADMIN_REPAIR_FAIL';
/** Администрирование - Ремонты END */

/** Администрирование - Юзеры */
export const GET_ADMIN_USERS_SUCCESS = 'GET_ADMIN_USERS_SUCCESS';
export const GET_ADMIN_USERS_FAIL = 'GET_ADMIN_USERS_FAIL';
export const GET_ADMIN_USER_SUCCESS = 'GET_ADMIN_USER_SUCCESS';
export const GET_ADMIN_USER_FAIL = 'GET_ADMIN_USER_FAIL';
export const GET_ADMIN_USERS_ROLES_SUCCESS = 'GET_ADMIN_USERS_ROLES_SUCCESS';
export const GET_ADMIN_USERS_ROLES_FAIL = 'GET_ADMIN_USERS_ROLES_FAIL';
export const DELETE_ADMIN_USER_SUCCESS = 'DELETE_ADMIN_USER_SUCCESS';
export const DELETE_ADMIN_USER_FAIL = 'DELETE_ADMIN_USER_FAIL';
export const ADD_ADMIN_USER_SUCCESS = 'ADD_ADMIN_USER_SUCCESS';
export const ADD_ADMIN_USER_FAIL = 'ADD_ADMIN_USER_FAIL';
export const EDIT_ADMIN_USER_SUCCESS = 'EDIT_ADMIN_USER_SUCCESS';
export const EDIT_ADMIN_USER_FAIL = 'EDIT_ADMIN_USER_FAIL';
export const TOGGLE_ADMIN_USER_ACTIVE_SUCCESS = 'TOGGLE_ADMIN_USER_ACTIVE_SUCCESS';
export const TOGGLE_ADMIN_USER_ACTIVE_FAIL = 'TOGGLE_ADMIN_USER_ACTIVE_FAIL';
/** Администрирование - Юзеры END */

/** Занятость работников РММ */
export const GET_RMM_EMPLOYMENT_WORKERS_SUCCESS = 'GET_RMM_EMPLOYMENT_WORKERS_SUCCESS';
export const GET_RMM_EMPLOYMENT_WORKERS_FAIL = 'GET_RMM_EMPLOYMENT_WORKERS_FAIL';
export const GET_RMM_EMPLOYMENT_USERS_SUCCESS = 'GET_RMM_EMPLOYMENT_USERS_SUCCESS';
export const GET_RMM_EMPLOYMENT_USERS_FAIL = 'GET_RMM_EMPLOYMENT_USERS_FAIL';
export const ADD_RMM_EMPLOYMENT_WORKER_SUCCESS = 'ADD_RMM_EMPLOYMENT_WORKER_SUCCESS';
export const ADD_RMM_EMPLOYMENT_WORKER_FAIL = 'ADD_RMM_EMPLOYMENT_WORKER_FAIL';
export const DELETE_RMM_EMPLOYMENT_WORKER_SUCCESS = 'DELETE_RMM_EMPLOYMENT_WORKER_SUCCESS';
export const DELETE_RMM_EMPLOYMENT_WORKER_FAIL = 'DELETE_RMM_EMPLOYMENT_WORKER_FAIL';
export const GET_RMM_EMPLOYMENT_WORKER_WORKS_SUCCESS = 'GET_RMM_EMPLOYMENT_WORKER_WORKS_SUCCESS';
export const GET_RMM_EMPLOYMENT_WORKER_WORKS_FAIL = 'GET_RMM_EMPLOYMENT_WORKER_WORKS_FAIL';
export const GET_RMM_EMPLOYMENT_WORK_NODES_SUCCESS = 'GET_RMM_EMPLOYMENT_WORK_NODES_SUCCESS';
export const GET_RMM_EMPLOYMENT_WORK_NODES_FAIL = 'GET_RMM_EMPLOYMENT_WORK_NODES_FAIL';
export const ADD_RMM_EMPLOYMENT_WORK_SUCCESS = 'ADD_RMM_EMPLOYMENT_WORK_SUCCESS';
export const ADD_RMM_EMPLOYMENT_WORK_FAIL = 'ADD_RMM_EMPLOYMENT_WORK_FAIL';
export const RMM_EMPLOYMENT_PROLONG_LAST_WORK_SUCCESS = 'RMM_EMPLOYMENT_PROLONG_LAST_WORK_SUCCESS';
export const RMM_EMPLOYMENT_PROLONG_LAST_WORK_FAIL = 'RMM_EMPLOYMENT_PROLONG_LAST_WORK_FAIL';
/** Занятость работников РММ END */

/** МТР */
export const GET_MTR_LINKS_SUCCESS = 'GET_MTR_LINKS_SUCCESS';
export const GET_MTR_LINKS_FAIL = 'GET_MTR_LINKS_FAIL';
export const GET_MTR_LIST_SUCCESS = 'GET_MTR_LIST_SUCCESS';
export const GET_MTR_LIST_FAIL = 'GET_MTR_LIST_FAIL';
export const LINK_MTR_SUCCESS = 'LINK_MTR_SUCCESS';
export const LINK_MTR_FAIL = 'LINK_MTR_FAIL';
export const SET_MTR_FILTER_SUCCESS = 'SET_MTR_FILTER_SUCCESS';
export const RESET_MTR_FILTER_SUCCESS = 'RESET_MTR_FILTER_SUCCESS';
/** МТР END */

/** RULES */
export const GET_RULES_FILES_SUCCESS = 'GET_RULES_FILES_SUCCESS';
export const ADD_RULES_FILE_SUCCESS = 'ADD_RULES_FILE_SUCCESS';
export const DELETE_RULES_FILE_SUCCESS = 'DELETE_RULES_FILE_SUCCESS';
/** /RULES */

/** COMMON */
export const ADD_LOCATION_REQUEST = 'addLocationRequest';
export const ADD_LOCATION_SUCCESS = 'addLocationSuccess';
export const ADD_LOCATION_FAIL = 'addLocationFail';

export const LOAD_LOCATIONS_REQUEST = 'loadLocationsRequest';
export const LOAD_LOCATIONS_SUCCESS = 'loadLocationsSuccess';
export const LOAD_LOCATIONS_FAIL = 'loadLocationsFail';

export const ADD_TRANSPORT_REQUEST = 'addTransportRequest';
export const ADD_TRANSPORT_SUCCESS = 'addTransportSuccess';
export const ADD_TRANSPORT_FAIL = 'addTransportFail';

export const LOAD_TRANSPORT_REQUEST = 'loadTransportRequest';
export const LOAD_TRANSPORT_SUCCESS = 'loadTransportSuccess';
export const LOAD_TRANSPORT_FAIL = 'loadTransportFail';

export const LOAD_DRIVERS_REQUEST = 'loadDriversRequest';
export const LOAD_DRIVERS_SUCCESS = 'loadDriversSuccess';
export const LOAD_DRIVERS_FAIL = 'loadDriversFail';

export const ADD_DRIVER_REQUEST = 'addDriverRequest';
export const ADD_DRIVER_SUCCESS = 'addDriverSuccess';
export const ADD_DRIVER_FAIL = 'addDriverFail';
/** /COMMON */

/** DESPATCH */
export const DESPATCHES_REQUEST = 'despatchesRequest';
export const DESPATCHES_SUCCESS = 'despatchesSuccess';
export const DESPATCHES_FAIL = 'despatchesFail';

export const DESPATCH_INFO_REQUEST = 'despatchInfoRequest';
export const DESPATCH_INFO_SUCCESS = 'despatchInfoSuccess';
export const DESPATCH_INFO_FAIL = 'despatchInfoFail';

export const ADD_DESPATCH_REQUEST = 'addDespatchRequest';
export const ADD_DESPATCH_SUCCESS = 'addDespatchSuccess';
export const ADD_DESPATCH_FAIL = 'addDespatchFail';

export const EDIT_DESPATCH_REQUEST = 'editDespatchRequest';
export const EDIT_DESPATCH_SUCCESS = 'editDespatchSuccess';
export const EDIT_DESPATCH_FAIL = 'editDespatchFail';

export const RETURN_DESPATCH_REQUEST = 'returnDespatchRequest';
export const RETURN_DESPATCH_SUCCESS = 'returnDespatchSuccess';
export const RETURN_DESPATCH_FAIL = 'returnDespatchFail';

export const SET_DESPATCH_PROBLEM_SOLVED_REQUEST = 'setDespatchProblemSolvedRequest';
export const SET_DESPATCH_PROBLEM_SOLVED_SUCCESS = 'setDespatchProblemSolvedSuccess';
export const SET_DESPATCH_PROBLEM_SOLVED_FAIL = 'setDespatchProblemSolvedFail';

export const SET_DESPATCH_TU_RETURNS_PROBLEM_REQUEST = 'setDespatchTuReturnProblemRequest';
export const SET_DESPATCH_TU_RETURNS_PROBLEM_SUCCESS = 'setDespatchTuReturnProblemSuccess';
export const SET_DESPATCH_TU_RETURNS_PROBLEM_FAIL = 'setDespatchTuReturnProblemFail';

export const SET_DESPATCH_PDO_APPROVED_REQUEST = 'setDespatchPdoApprovedRequest';
export const SET_DESPATCH_PDO_APPROVED_SUCCESS = 'setDespatchPdoApprovedSuccess';
export const SET_DESPATCH_PDO_APPROVED_FAIL = 'setDespatchPdoApprovedFail';

export const CLEAR_DESPATCH_PDO_APPROVED_REQUEST = 'clearDespatchPdoApprovedRequest';
export const CLEAR_DESPATCH_PDO_APPROVED_SUCCESS = 'clearDespatchPdoApprovedSuccess';
export const CLEAR_DESPATCH_PDO_APPROVED_FAIL = 'clearDespatchPdoApprovedFail';

export const SET_DESPATCH_OMTS_APPROVED_REQUEST = 'setDespatchOmtsApprovedRequest';
export const SET_DESPATCH_OMTS_APPROVED_SUCCESS = 'setDespatchOmtsApprovedSuccess';
export const SET_DESPATCH_OMTS_APPROVED_FAIL = 'setDespatchOmtsApprovedFail';

export const CLEAR_DESPATCH_OMTS_APPROVED_REQUEST = 'clearDespatchOmtsApprovedRequest';
export const CLEAR_DESPATCH_OMTS_APPROVED_SUCCESS = 'clearDespatchOmtsApprovedSuccess';
export const CLEAR_DESPATCH_OMTS_APPROVED_FAIL = 'clearDespatchOmtsApprovedFail';

export const SET_DESPATCH_RESPONSIBLE_SHIPPED_REQUEST = 'setDespatchResponsibleShippedRequest';
export const SET_DESPATCH_RESPONSIBLE_SHIPPED_SUCCESS = 'setDespatchResponsibleShippedSuccess';
export const SET_DESPATCH_RESPONSIBLE_SHIPPED_FAIL = 'setDespatchResponsibleShippedFail';

export const CLEAR_DESPATCH_RESPONSIBLE_SHIPPED_REQUEST = 'clearDespatchResponsibleShippedRequest';
export const CLEAR_DESPATCH_RESPONSIBLE_SHIPPED_SUCCESS = 'clearDespatchResponsibleShippedSuccess';
export const CLEAR_DESPATCH_RESPONSIBLE_SHIPPED_FAIL = 'clearDespatchResponsibleShippedFail';

export const SET_DESPATCH_RECIPIENT_COMPLETED_REQUEST = 'setDespatchRecipientCompletedRequest';
export const SET_DESPATCH_RECIPIENT_COMPLETED_SUCCESS = 'setDespatchRecipientCompletedSuccess';
export const SET_DESPATCH_RECIPIENT_COMPLETED_FAIL = 'setDespatchRecipientCompletedFail';

export const CLEAR_DESPATCH_RECIPIENT_COMPLETED_REQUEST = 'clearDespatchRecipientCompletedRequest';
export const CLEAR_DESPATCH_RECIPIENT_COMPLETED_SUCCESS = 'clearDespatchRecipientCompletedSuccess';
export const CLEAR_DESPATCH_RECIPIENT_COMPLETED_FAIL = 'clearDespatchRecipientCompletedFail';
export const CLEAR_DESPATCH_ASSIGN_DRIVER_SUCCESS = 'clearDespatchAssignDriverSuccess';
export const CLEAR_DESPATCH_ASSIGN_DRIVER_FAIL = 'clearDespatchAssignDriverSuccessFail';
export const CLEAR_DESPATCH_ASSIGN_TRANSPORT_SUCCESS = 'clearDespatchAssignTransportSuccess';
export const CLEAR_DESPATCH_ASSIGN_TRANSPORT_FAIL = 'clearDespatchAssignTransportFail';

export const DESPATCH_COMMENTS_REQUEST = 'despatchCommentsRequest';
export const DESPATCH_COMMENTS_SUCCESS = 'despatchCommentsSuccess';
export const DESPATCH_COMMENTS_FAIL = 'despatchCommentsFail';

export const DESPATCH_PROBLEM_COMMENTS_REQUEST = 'despatchProblemCommentsRequest';
export const DESPATCH_PROBLEM_COMMENTS_SUCCESS = 'despatchProblemCommentsSuccess';
export const DESPATCH_PROBLEM_COMMENTS_FAIL = 'despatchProblemCommentsFail';

export const ADD_DESPATCH_COMMENTS_REQUEST = 'addDespatchCommentsRequest';
export const ADD_DESPATCH_COMMENTS_SUCCESS = 'addDespatchCommentsSuccess';
export const ADD_DESPATCH_COMMENTS_FAIL = 'addDespatchCommentsFail';

export const DESPATCH_ASSIGN_TRANSPORT_REQUEST = 'assignTransportRequest';
export const DESPATCH_ASSIGN_TRANSPORT_SUCCESS = 'assignTransportSuccess';
export const DESPATCH_ASSIGN_TRANSPORT_FAIL = 'assignTransportFail';

export const DESPATCH_ASSIGN_DRIVER_REQUEST = 'assignDriverRequest';
export const DESPATCH_ASSIGN_DRIVER_SUCCESS = 'assignDriverSuccess';
export const DESPATCH_ASSIGN_DRIVER_FAIL = 'assignDriverFail';

export const SEND_DESPATCH_SUCCESS = 'sendDespatchSuccess';
export const SEND_DESPATCH_FAIL = 'sendDespatchFail';

export const SEND_DESPATCH_TO_ARCHIVE_SUCCESS = 'sendDespatchToArchiveSuccess';
export const SEND_DESPATCH_TO_ARCHIVE_FAIL = 'sendDespatchToArchiveFail';
/** /DESPATCH */
