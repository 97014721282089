import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './ModalFooter.scss';

const ModalFooter = ({ closeHandler, children }) => {
  return (
    <React.Fragment>
      {children ? (
        <footer className="modal-footer">{children}</footer>
      ) : (
        <footer className="modal-footer">
          <button type="button" className="btn btn-default" onClick={closeHandler}>
            Закрыть
          </button>
        </footer>
      )}
    </React.Fragment>
  );
};

ModalFooter.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  children: PropTypes.node,
};

ModalFooter.defaultProps = {
  children: null,
};

export default ModalFooter;
