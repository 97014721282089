import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Styles
import './NoForecastListReport.scss';

const NoForecastListReport = ({ noForecast }) => {
  return (
    <ul className="no-forecast-list-report">
      {Array.isArray(noForecast) &&
        noForecast.map(item => (
          <li key={item.id} className="no-forecast-list-report__item">
            <div className="no-forecast-list-report__item-info">
              <Link
                to={`/repair/${item.repairid}/node`}
                className="no-forecast-list-report__item-link"
              >
                {item.name}
              </Link>{' '}
              {item.object && (
                <>
                  (
                  <Link
                    to={`/repair/${item.object.id}/node`}
                    className="no-forecast-list-report__item-link"
                  >
                    {item.object.ksname} {item.object.gpa} №{item.object.stationnumber}
                  </Link>
                  )
                </>
              )}
            </div>
            <div className="no-forecast-list-report__item-tools">
              <span className="label label_primary">{item.master_1}</span>
            </div>
          </li>
        ))}
    </ul>
  );
};

NoForecastListReport.propTypes = {
  noForecast: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      repairid: PropTypes.number,
      name: PropTypes.string,
      object: PropTypes.shape({
        id: PropTypes.number,
        ksname: PropTypes.string,
        gpa: PropTypes.string,
        stationnumber: PropTypes.string,
      }),
      master_1: PropTypes.string,
    }),
  ),
};

NoForecastListReport.defaultProps = {
  noForecast: null,
};

export default NoForecastListReport;
