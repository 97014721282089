import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';

// Styles
import './RepairNavigation.scss';

const RepairNavigation = ({ url, className }) => {
  return (
    <nav className={cx('repair-nav', { [className]: className })}>
      <NavLink
        exact
        to={url}
        className="repair-nav__link"
        activeClassName="repair-nav__link_active"
      >
        Ход работ
      </NavLink>
      <NavLink
        exact
        to={`${url}/node`}
        className="repair-nav__link"
        activeClassName="repair-nav__link_active"
      >
        Узловой ремонт
      </NavLink>
      <NavLink
        exact
        to={`${url}/problems`}
        className="repair-nav__link"
        activeClassName="repair-nav__link_active"
      >
        Текущие проблемы
      </NavLink>
      <NavLink
        exact
        to={`${url}/mtr`}
        className="repair-nav__link"
        activeClassName="repair-nav__link_active"
      >
        Поставка МТР
      </NavLink>
      <NavLink
        exact
        to={`${url}/roster`}
        className="repair-nav__link"
        activeClassName="repair-nav__link_active"
      >
        Состав бригады
      </NavLink>
      <NavLink
        exact
        to={`${url}/prolong`}
        className="repair-nav__link"
        activeClassName="repair-nav__link_active"
      >
        Продление сроков ремонта
      </NavLink>
      <NavLink
        exact
        to={`${url}/docs`}
        className="repair-nav__link"
        activeClassName="repair-nav__link_active"
      >
        Документы
      </NavLink>
      <NavLink
        exact
        to={`${url}/add`}
        className="repair-nav__link"
        activeClassName="repair-nav__link_active"
      >
        Дополнительные работы
      </NavLink>
      <NavLink
        exact
        to={`${url}/coop`}
        className="repair-nav__link"
        activeClassName="repair-nav__link_active"
      >
        Соисполнители
      </NavLink>
    </nav>
  );
};

RepairNavigation.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
};

RepairNavigation.defaultProps = {
  className: null,
};

export default RepairNavigation;
