import React from 'react';
import PropTypes from 'prop-types';
import AriaModal from 'react-aria-modal';

// Icons
import { FaTimes } from 'react-icons/fa';

// Styles
import './Modal.scss';

const Modal = ({ title, children, closeHandler }) => {
  return (
    <AriaModal
      titleText={title}
      onExit={closeHandler}
      underlayStyle={{
        paddingTop: '40px',
      }}
      focusDialog
    >
      <div className="modal">
        <button
          type="button"
          onClick={closeHandler}
          className="modal__close-button"
          aria-label="Закрыть попап"
        >
          <FaTimes className="icon" />
        </button>
        {children}
      </div>
    </AriaModal>
  );
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  closeHandler: PropTypes.func.isRequired,
};

Modal.defaultProps = {};

export default Modal;
