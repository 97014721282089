import React from 'react';
import PropTypes from 'prop-types';

// Icons
import { FaPaperclip } from 'react-icons/fa';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';

// Misc
import { browseServer } from 'helpers/file-manager';

const DocsEmployeeModal = ({
  closeHandler,
  canEdit,
  employee: {
    id: employeeId,
    protocolot = '',
    protocolpb = '',
    protocoladd = '',
    protocolgaz = '',
    protocolpog = '',
    protocoldop1 = '',
    protocoldop2 = '',
    protocoldop3 = '',
  } = {},
  uploadEmployeeDocument,
  departmentId,
}) => {
  const selectFileForDocument = type => {
    browseServer({
      onSuccess: fileUrl => {
        uploadEmployeeDocument(employeeId, departmentId, type, fileUrl).catch(() => {});
      },
    });
  };

  const sections = [
    {
      title: 'Протокол по ОТ',
      value: protocolot,
      type: 'protocolot',
    },
    {
      title: 'Протокол по ПБ',
      value: protocolpb,
      type: 'protocolpb',
    },
    {
      title: 'Протокол по вспом. профессиям',
      value: protocoladd,
      type: 'protocoladd',
    },
    {
      title: 'Протокол по газ. безопасности',
      value: protocolgaz,
      type: 'protocolgaz',
    },
    {
      title: 'Протокол по пожарной безопасности',
      value: protocolpog,
      type: 'protocolpog',
    },
    {
      title: 'Протокол на доп. обучение',
      value: protocoldop1,
      type: 'protocoldop1',
    },
    {
      title: 'Протокол на доп. обучение 2',
      value: protocoldop2,
      type: 'protocoldop2',
    },
    {
      title: 'Протокол на доп. обучение 3',
      value: protocoldop3,
      type: 'protocoldop3',
    },
  ];

  return (
    <React.Fragment>
      <ModalHeader title="Сопроводительные документы" />

      <ModalBody>
        {sections.map(({ title, value, type }) => (
          <FormGroup title={title}>
            <div className="upload-docs">
              {canEdit && (
                <button
                  type="button"
                  className="btn btn-xs btn-primary"
                  onClick={() => {
                    selectFileForDocument(type);
                  }}
                >
                  <FaPaperclip className="icon" />
                </button>
              )}

              {value !== '' && (
                <React.Fragment>
                  {' '}
                  <a
                    href={value}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="label label_info"
                  >
                    {`...${value.slice(-8)}`}
                  </a>
                </React.Fragment>
              )}
            </div>
          </FormGroup>
        ))}
      </ModalBody>

      <ModalFooter closeHandler={closeHandler} />
    </React.Fragment>
  );
};

DocsEmployeeModal.propTypes = {
  employee: PropTypes.shape({
    id: PropTypes.number,
    protocolot: PropTypes.string,
    protocolpb: PropTypes.string,
    protocoladd: PropTypes.string,
    protocolgaz: PropTypes.string,
    protocolpog: PropTypes.string,
    protocoldop1: PropTypes.string,
    protocoldop2: PropTypes.string,
    protocoldop3: PropTypes.string,
  }).isRequired,
  closeHandler: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  uploadEmployeeDocument: PropTypes.func.isRequired,
  departmentId: PropTypes.number.isRequired,
};

export default DocsEmployeeModal;
