import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import TableRepairMtr from 'components/TableRepairMtr/TableRepairMtr';
import Loader from 'components/Loader/Loader';
import Warning from 'components/Warning/Warning';

// Actions
import { getRepairMtr } from 'actions';

const RepairMtr = ({ repairId, mtrList, dispatch }) => {
  const [isLoading, setIsLoading] = useState(true);

  /** Запрашиваем информацию по ремонту (МТР) */
  useEffect(() => {
    dispatch(getRepairMtr(repairId))
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch, repairId]);

  return (
    <React.Fragment>
      <div className="repair-content__header">
        <h2 className="repair-content__title">МТР</h2>
      </div>

      {isLoading && <Loader />}

      {!isLoading && mtrList && Array.isArray(mtrList) && mtrList.length > 0 && (
        <TableRepairMtr mtrList={mtrList} repairId={repairId} />
      )}

      {!isLoading && mtrList && Array.isArray(mtrList) && mtrList.length === 0 && (
        <Warning msg="Пока не добавлено деталей" />
      )}
    </React.Fragment>
  );
};

RepairMtr.propTypes = {
  repairId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  mtrList: PropTypes.arrayOf(PropTypes.shape({})),
};

RepairMtr.defaultProps = {
  mtrList: null,
};

const mapStateToProps = state => ({
  mtrList: state.mainReducer.repairMtr.mtr,
});

export default connect(mapStateToProps)(RepairMtr);
