import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import ModalError from 'components/ModalError/ModalError';
import FormGroup from 'components/FormGroup/FormGroup';
import TextArea from 'components/TextArea/TextArea';
import TaskComment from 'components/TaskComment/TaskComment';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import Loader from 'components/Loader/Loader';

// Actions
import { addTaskComment, getTaskComments } from 'actions';

const AddTaskCommentModal = ({ closeHandler, comments, dispatch, taskId }) => {
  const [isModalLoading, setIsModalLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const [comment, setComment] = useState('');

  useEffect(() => {
    dispatch(getTaskComments(taskId))
      .then(() => setIsModalLoading(false))
      .catch(() => setIsModalLoading(false));
  }, [dispatch, taskId]);

  const submitTaskComment = () => {
    setIsSubmitLoading(true);

    dispatch(addTaskComment(taskId, comment))
      .then(() => {
        setIsSubmitLoading(false);

        closeHandler();
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsSubmitLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Примечания" />

      {isModalLoading && <Loader className="loader_modal" />}

      {!isModalLoading && comments && (
        <ModalBody>
          {Array.isArray(comments) &&
            comments.map(commentItem => <TaskComment key={commentItem.id} comment={commentItem} />)}

          <FormGroup title="Добавить примечание" error={fieldErrors.comment}>
            <TextArea value={comment} onChange={({ target }) => setComment(target.value)} />
          </FormGroup>
        </ModalBody>
      )}

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isValid={comment === ''}
          isLoading={isSubmitLoading}
          isLoadingText="Отправка..."
          clickHandler={submitTaskComment}
        >
          Отправить
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

AddTaskCommentModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  taskId: PropTypes.number.isRequired,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ).isRequired,
};

const mapStateToProps = state => ({
  comments: state.mainReducer.taskComments,
});

export default connect(mapStateToProps)(AddTaskCommentModal);
