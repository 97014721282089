import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import TableRmmEmployment from 'components/TableRmmEmployment/TableRmmEmployment';
import Modal from 'components/Modal/Modal';
import AddUserModal from 'components/AddUserModal/AddUserModal';
import Loader from 'components/Loader/Loader';

// Actions
import { getRmmEmploymentWorkers } from 'actions';

const RmmEmployment = ({ rmmEmploymentData, dispatch }) => {
  const [isAddUserActive, setAddUserActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const activateAddUserModal = () => {
    setAddUserActive(true);
  };

  const deactivateAddUserModal = () => {
    setAddUserActive(false);
  };

  useEffect(() => {
    dispatch(getRmmEmploymentWorkers())
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch]);

  return (
    <>
      {isLoading && <Loader className="loader_page" />}

      {!isLoading && rmmEmploymentData && rmmEmploymentData.workers && (
        <div className="rmm">
          <div className="rmm__container container">
            <h1 className="rmm__title">Занятость работников РММ</h1>

            <div className="rmm__menu">
              <button type="button" className="btn btn-primary" onClick={activateAddUserModal}>
                Добавить работника
              </button>{' '}
              <a
                href={`${process.env.REACT_APP_API_URL}rmmemployment/_adayReport/`}
                className="btn btn-primary"
              >
                Скачать отчет за сегодня
              </a>
            </div>

            {isAddUserActive && (
              <Modal title="Добавить работника" closeHandler={deactivateAddUserModal}>
                <AddUserModal closeHandler={deactivateAddUserModal} />
              </Modal>
            )}

            {Array.isArray(rmmEmploymentData.workers) && rmmEmploymentData.workers.length > 0 && (
              <div className="rmm__content">
                <TableRmmEmployment
                  canEdit={rmmEmploymentData.canEdit}
                  workers={rmmEmploymentData.workers}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

RmmEmployment.propTypes = {
  dispatch: PropTypes.func.isRequired,
  rmmEmploymentData: PropTypes.shape({
    canEdit: PropTypes.bool,
    workers: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

const mapStateToProps = state => ({
  rmmEmploymentData: state.mainReducer.rmmEmploymentData,
});

export default connect(mapStateToProps)(RmmEmployment);
