import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './FormGroup.scss';

const FormGroup = ({ title, children, error, style }) => {
  return (
    <div className="form-group" style={style}>
      {title && <label className="form-group__label">{title}</label>}

      {children}

      {error && <p className="form-group__error">{error}</p>}
    </div>
  );
};

FormGroup.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  error: PropTypes.string,
  style: PropTypes.shape({}),
};

FormGroup.defaultProps = {
  title: null,
  error: null,
  style: null,
};

export default FormGroup;
