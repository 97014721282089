import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Components
import TableMtr from 'components/TableMtr/TableMtr';
import Loader from 'components/Loader/Loader';

// Actions
import { getMtrLinks } from 'actions';

// Styles
import './Mtr.scss';

const Mtr = ({ dispatch, objects, repairs }) => {
  const [canContinue, setCanContinue] = useState(false);
  const [repairOptions, setRepairOptions] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(getMtrLinks())
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch]);

  /** Формирую новый массив из ремонтов для селекта в таблице TableMtr */
  useEffect(() => {
    if (Array.isArray(repairs)) {
      const options = repairs.map(({ id, ksname, gpa, stationnumber, type_s }) => ({
        label: `${ksname} ${gpa} №${stationnumber} (${type_s})`,
        value: id,
      }));
      setRepairOptions(options);
    }
  }, [repairs]);

  return (
    <>
      {isLoading && <Loader className="loader_page" />}

      {!isLoading && objects && repairs && repairOptions && (
        <div className="mtr-page">
          <div className="mtr-page__container container">
            <h1 className="mtr-page__title">МТР</h1>

            <div className="mtr-page__upload-file">
              <div className="mtr-page__input-wrap">
                <input type="file" onChange={() => setCanContinue(true)} />
              </div>
              <div className="mtr-page__btn-wrap">
                {canContinue ? (
                  <button type="button" className="btn btn-success">
                    Продолжить загрузку
                  </button>
                ) : (
                  <button type="button" className="btn btn-primary">
                    Загрузить МТР
                  </button>
                )}
              </div>
            </div>

            <div className="mtr-page__btn-wrap">
              <Link to="/mtr/list/0" className="btn btn-success">
                Ведомость МТР
              </Link>
            </div>

            <div className="mtr-page__content">
              <TableMtr objects={objects} repairOptions={repairOptions} />
            </div>

            <div className="mtr-page__footer">
              <a href={`${'mtrUrl'}_aupload/`} className="btn btn-primary">
                Подтвердить загрузку
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Mtr.propTypes = {
  dispatch: PropTypes.func.isRequired,
  objects: PropTypes.arrayOf(PropTypes.shape({})),
  repairs: PropTypes.arrayOf(PropTypes.shape({})),
};

Mtr.defaultProps = {
  objects: null,
  repairs: null,
};

const mapStateToProps = state => ({
  objects: state.mainReducer.mtrLinks.objects,
  repairs: state.mainReducer.mtrLinks.repairs,
});

export default connect(mapStateToProps)(Mtr);
