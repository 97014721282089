import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Icons
import { FaPaperclip, FaTrashAlt } from 'react-icons/fa';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { setTaskDoneApprove, setTaskDoneDeny, uploadTaskDocument } from 'actions';

// Misc
import { browseServer } from 'helpers/file-manager';

const TaskInfoModal = ({ closeHandler, myTask, dispatch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseError, setResponseError] = useState(null);

  const selectFile = index => {
    browseServer({
      onSuccess: fileUrl => {
        dispatch(uploadTaskDocument(myTask.id, 'my', index, fileUrl)).catch(() => {});
      },
    });
  };

  const deleteFile = index => {
    dispatch(uploadTaskDocument(myTask.id, 'my', index, 'fake', 'delete')).catch(() => {});
  };

  const handleTaskDoneApprove = () => {
    setIsLoading(true);

    dispatch(setTaskDoneApprove(myTask.id))
      .then(() => {
        setIsLoading(false);

        closeHandler();
      })
      .catch(error => {
        if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsLoading(false);
      });
  };

  const handleTaskDoneDeny = () => {
    setIsLoading(true);

    dispatch(setTaskDoneDeny(myTask.id))
      .then(() => {
        setIsLoading(false);

        closeHandler();
      })
      .catch(error => {
        if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader className="modal-header_has-sub-title">
        <h4 className="modal-header__title">
          Задача №{myTask.id} «{myTask.name}»
        </h4>
        <h5 className="modal-header__sub-title">
          Назначена кому: <span className="label label_primary">{myTask.targetname_1}</span>{' '}
          <span className="label label_info">{myTask.targetrole}</span>
        </h5>
      </ModalHeader>

      <ModalBody>
        <div className="modal__section">
          <div className="modal__section-title">Текст задачи:</div>
          <div className="modal__section-text">{myTask.text}</div>
        </div>

        <div className="modal__section">
          {/* UPLOAD DOCS */}

          {/* eslint-disable react/no-array-index-key */}
          {myTask.files &&
            Array.isArray(myTask.files) &&
            myTask.files.map((item, index) => (
              <div className="upload-docs" key={index}>
                <button
                  type="button"
                  className="btn btn-xs btn-primary"
                  onClick={() => {
                    selectFile(index);
                  }}
                >
                  <FaPaperclip className="icon" />
                </button>{' '}
                {item.file && (
                  <React.Fragment>
                    <a
                      href={item.file}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="label label_info"
                    >
                      {item.shortfile}
                    </a>{' '}
                  </React.Fragment>
                )}
                {item.file && (
                  <button
                    type="button"
                    className="btn btn-danger btn-xs"
                    onClick={() => {
                      deleteFile(index);
                    }}
                  >
                    <FaTrashAlt className="icon" />
                  </button>
                )}
              </div>
            ))}
          {/* eslint-enable react/no-array-index-key */}

          {/* UPLOAD DOCS END */}
        </div>

        {myTask.done && (
          <div className="modal__task-status">Задача выполнена {myTask.datetimefact_3}</div>
        )}
      </ModalBody>

      {responseError && <ModalError>{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        {myTask.done && !myTask.doneapproved && (
          <SubmitButton
            isLoading={isLoading}
            isLoadingText="Отправляю..."
            clickHandler={handleTaskDoneApprove}
          >
            Подтвердить выполнение
          </SubmitButton>
        )}

        {myTask.done && !myTask.doneapproved && (
          <SubmitButton
            isLoading={isLoading}
            isLoadingText="Отправляю..."
            clickHandler={handleTaskDoneDeny}
            className="btn-danger"
          >
            Отклонить выполнение
          </SubmitButton>
        )}

        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>
      </ModalFooter>
    </React.Fragment>
  );
};

TaskInfoModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  myTask: PropTypes.shape({
    id: PropTypes.number,
    doneapproved: PropTypes.bool,
    date_1: PropTypes.string,
    dateend_1: PropTypes.string,
    datetimefact_3: PropTypes.string,
    name: PropTypes.string,
    text: PropTypes.string,
    done: PropTypes.bool,
    targetname_1: PropTypes.string,
    targetrole: PropTypes.string,
    files: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        file: PropTypes.string,
        shortfile: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default connect()(TaskInfoModal);
