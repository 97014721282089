import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import TextArea from 'components/TextArea/TextArea';
import TaskComment from 'components/TaskComment/TaskComment';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import Loader from 'components/Loader/Loader';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { addRmmNodeComment, getRmmNodeComments } from 'actions';

const CommentsNodeModal = ({ closeHandler, comments, canEdit, nodeId, dispatch }) => {
  const [commentNodeText, setCommentNodeText] = useState('');

  const [isModalLoading, setIsModalLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  useEffect(() => {
    dispatch(getRmmNodeComments(nodeId))
      .then(() => setIsModalLoading(false))
      .catch(() => setIsModalLoading(false));
  }, [dispatch, nodeId]);

  const submitNodeComment = () => {
    setIsSubmitLoading(true);

    dispatch(addRmmNodeComment(nodeId, commentNodeText))
      .then(() => {
        setIsSubmitLoading(false);

        closeHandler();
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsSubmitLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Примечания" />

      {isModalLoading && <Loader className="loader_modal" />}

      {!isModalLoading && comments && (
        <ModalBody>
          {Array.isArray(comments) &&
            comments.map(comment => <TaskComment key={comment.id} comment={comment} />)}

          {canEdit && (
            <FormGroup title="Добавить примечание" error={fieldErrors.comment}>
              <TextArea
                value={commentNodeText}
                onChange={({ target }) => setCommentNodeText(target.value)}
              />
            </FormGroup>
          )}
        </ModalBody>
      )}

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        {canEdit && (
          <SubmitButton
            isLoading={isSubmitLoading}
            isValid={commentNodeText === ''}
            isLoadingText="Отправка..."
            clickHandler={submitNodeComment}
          >
            Отправить
          </SubmitButton>
        )}
      </ModalFooter>
    </React.Fragment>
  );
};

CommentsNodeModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  nodeId: PropTypes.number.isRequired,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ).isRequired,
};

const mapStateToProps = state => ({
  comments: state.mainReducer.rmmNodeComments,
});

export default connect(mapStateToProps)(CommentsNodeModal);
