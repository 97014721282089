import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

// Styles
import { selectStyles } from 'scss/select';

const SelectForm = ({ options, selectedValue, onChange, isDisabled }) => {
  return (
    <Select
      value={selectedValue}
      styles={selectStyles}
      classNamePrefix="indicators-select"
      options={options}
      onChange={onChange}
      menuPlacement="auto"
      isDisabled={isDisabled}
    />
  );
};

SelectForm.propTypes = {
  selectedValue: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

SelectForm.defaultProps = {
  selectedValue: null,
  isDisabled: false,
};

export default SelectForm;
