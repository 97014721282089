import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Icons
import { FaPlus } from 'react-icons/fa';

// Components
import Modal from '../../components/Modal/Modal';
import DriversTable from 'components/DriversTable/DriversTable';
import AddDriverFormModal from 'components/AddDriverFormModal/AddDriverFormModal';
import Loader from 'components/Loader/Loader';

// Actions
import { loadDrivers } from 'actions/common';

// Utils
import * as propTypes from 'utils/propTypes';

// Styles
import './Drivers.scss';

const Drivers = ({ dispatch, user, drivers, canEdit }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAddDriverActive, setAddDriverActive] = useState(false);

  useEffect(() => {
    dispatch(loadDrivers())
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch]);

  const activateAddDriverFormModal = () => {
    setAddDriverActive(true);
  };

  const deactivateAddDriverFormModal = () => {
    setAddDriverActive(false);
  };

  return (
    <React.Fragment>
      {false && isLoading && <Loader className="loader_page" />}

      <div className="drivers-page">
        <div className="drivers-page__container container">
          <h1 className="drivers-page__title">Водители</h1>
          {(true || canEdit) && (
            <div className="drivers-page__tools">
              <button
                type="button"
                className="btn btn-primary"
                onClick={activateAddDriverFormModal}
              >
                <FaPlus className="icon" /> Добавить водителя
              </button>
            </div>
          )}

          <DriversTable drivers={drivers} />
        </div>
      </div>
      {isAddDriverActive && (canEdit || true) && (
        <Modal title="Добавление водителя" closeHandler={deactivateAddDriverFormModal}>
          <AddDriverFormModal user={user} closeHandler={deactivateAddDriverFormModal} />
        </Modal>
      )}
    </React.Fragment>
  );
};

Drivers.propTypes = {
  dispatch: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
  user: PropTypes.shape({
    userRole: PropTypes.number,
    id: PropTypes.number,
  }).isRequired,
  drivers: PropTypes.arrayOf(propTypes.driverData),
};

const mapStateToProps = state => ({
  drivers: state.commonReducer.drivers,
  canEdit: state.commonReducer.canEdit,
  user: state.mainReducer.user,
});

export default connect(mapStateToProps)(Drivers);
