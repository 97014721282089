import { combineReducers } from 'redux';

import mainReducer from 'reducer';
import commonReducer from 'reducer/common';
import despatchReducer from 'reducer/despatch';

export default combineReducers({
  mainReducer,
  commonReducer,
  despatchReducer,
});
