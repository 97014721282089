import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Icons
import { FaPaperclip } from 'react-icons/fa';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import InputForm from 'components/InputForm/InputForm';
import InputDate from 'components/InputDate/InputDate';
import SelectForm from 'components/SelectForm/SelectForm';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { addRepairNode } from 'actions';

// Helpers
import { formatDateYYYYmmDD } from 'helpers/formatDateYYYYmmDD';
import { browseServer } from 'helpers/file-manager';

const AddNodeModal = ({ closeHandler, repairId, dispatch, rmmList }) => {
  const [nodeName, setNodeName] = useState('');
  const [nodeDateSend, setNodeDateSend] = useState(null);

  const rmmBases = rmmList.map(item => ({
    value: item.parent,
    label: item.page_name,
  }));
  const [rmmBase, setRmmBase] = useState(rmmBases[0]);

  const [act, setAct] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const submitRepairNode = () => {
    setIsLoading(true);

    dispatch(
      addRepairNode(repairId, nodeName, rmmBase.value, formatDateYYYYmmDD(nodeDateSend), act),
    )
      .then(() => {
        setIsLoading(false);

        closeHandler();
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsLoading(false);
      });
  };

  const selectFile = () => {
    browseServer({
      onSuccess: fileUrl => {
        setAct(fileUrl);
      },
    });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Добавление узла" />

      <ModalBody>
        <FormGroup title="Наименование узла" error={fieldErrors.name}>
          <InputForm
            type="text"
            value={nodeName}
            onChange={({ target }) => setNodeName(target.value)}
          />
        </FormGroup>

        <FormGroup title="Дата отправки из КС" error={fieldErrors.dateSend}>
          <InputDate selectedDate={nodeDateSend} onChange={date => setNodeDateSend(date)} />
        </FormGroup>

        <FormGroup title="Ремонтная мастерская" error={fieldErrors.rmmId}>
          <SelectForm
            selectedValue={rmmBase}
            options={rmmBases}
            onChange={selectedOption => setRmmBase(selectedOption)}
          />
        </FormGroup>

        <FormGroup title="Акт передачи в ремонт">
          {/* UPLOAD DOCS */}
          <div className="upload-docs">
            <button type="button" className="btn btn-xs btn-primary" onClick={selectFile}>
              <FaPaperclip className="icon" />
            </button>{' '}
            {act && <span className="label label_info">{`...${act.substr(-8)}`}</span>}
          </div>
          {/* UPLOAD DOCS END */}
        </FormGroup>
      </ModalBody>

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isValid={nodeName === '' || !rmmBase || !nodeDateSend || !act}
          isLoading={isLoading}
          isLoadingText="Добавляю..."
          clickHandler={submitRepairNode}
        >
          Добавить
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

AddNodeModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  repairId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  rmmList: PropTypes.arrayOf(
    PropTypes.shape({
      parent: PropTypes.number,
      page_name: PropTypes.string,
    }),
  ).isRequired,
};

export default connect()(AddNodeModal);
