import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Icons
import { FaTimes } from 'react-icons/fa';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';

// Styles
import './despatch-filters.scss';
import './despatch-filters-list.scss';

const DespatchFiltersModal = ({
  closeHandler,
  statuses,
  userRole,
  isFilterActive,
  despatchFilter,
  handleSelectFilterOptions,
  handleResetFilter,
}) => (
  <React.Fragment>
    <ModalHeader>
      <h4 className="modal-header__title">Подобрать по параметрам</h4>

      {isFilterActive && (
        <button type="button" className="btn btn-danger" onClick={handleResetFilter}>
          Сбросить фильтрацию <FaTimes className="icon" />
        </button>
      )}
    </ModalHeader>

    <ModalBody>
      <div className="despatch-filters">

        {/** СТАТУС ОТПРАВЛЕНИЯ */}
        {statuses && Array.isArray(statuses) && statuses.length > 0 && (
          <div className="despatch-filters__group">
            <div className="despatch-filters__group-name">Статус отправления</div>
            <div className="despatch-filters__group-list-wrap">
              <div className="despatch-filters-list">
                {statuses.map(status => (
                  <button
                    key={status.name}
                    type="button"
                    className={cx('despatch-filters-list__item', {
                      'despatch-filters-list__item_active': despatchFilter.status.includes(status.name),
                    })}
                    onClick={() => handleSelectFilterOptions('status', status.name)}
                  >
                    {status.label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
        {/** СТАТУС ОТПРАВЛЕНИЯ END */}

      </div>
    </ModalBody>

    <ModalFooter closeHandler={closeHandler} />
  </React.Fragment>
);

DespatchFiltersModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  despatchFilter: PropTypes.shape({
    status: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  isFilterActive: PropTypes.bool.isRequired,
  userRole: PropTypes.number.isRequired,
  handleSelectFilterOptions: PropTypes.func.isRequired,
  handleResetFilter: PropTypes.func.isRequired,
};

export default DespatchFiltersModal;
