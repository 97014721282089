import * as types from 'actions/action-types';

const initialState = {
  user: null,
  /**
   * Этот флаг получили ли ответ от сервера по поводу текущео пользователя
   * используется только для того, чтобы показать Loader
   */
  isUserGot: false,

  /**
   * Этот флаг используется чтобы не позволять ходить на приватные роуты
   * апп автоматом редиректит с приватных роутов при на / при false
   * и с антиприватных на /lk при true
   */
  isUserAuth: false,

  /** Данные по странице dashboard */
  repairsData: {},

  /** Данные по странице ремонта (информация в шапке) */
  repairInfo: {},

  /** Данные по странице ремонта (Ход работ) */
  repairWorkProgress: {},

  /** Данные по странице ремонта (Текущие проблемы) */
  repairProblems: [],

  /** Список пользователей (таргетов) для проблемы */
  repairProblemTargets: [],

  /** Данные по странице ремонта (Дополнительные работы) */
  repairAdditionalWorks: [],

  /** Данные по странице ремонта (Состав бригады) */
  repairRosterTeam: [],

  /** Список ремонтников для добавления в состав бригады */
  rosterRepairmanList: [],

  /** Данные по странице ремонта (Соисполнители) */
  repairCoWorks: [],

  /** Данные по странице ремонта (Продления ремонта) */
  repairProlongs: [],
  repairProlongReasons: [],

  /** Данные по странице ремонта (Документы) */
  repairDocs: {},

  /** Данные по странице ремонта (МТР) */
  repairMtr: {},

  /** Данные по странице ремонта (Узловой ремонт) */
  repairNodes: [],
  repairRmmList: [],

  /** Диспетчерский отчет */
  dispatchInfo: {},

  /** Информация по меню внизу сайта */
  notifications: {
    prolong: [],
    tasks: [],
    myTasks: [],
    missDocs: [],
    missDocsRmm: [],
    problems: [],
    overdue: [],
    noReports: [],
    noNar: [],
    noForecast: [],
    noReceive: [],
    overdueNodes: [],
  },

  /** Список пользователей (таргетов) для задачи */
  myTaskTargets: [],

  /** Список примечаний к задаче */
  taskComments: [],

  /** Свод дополнительных работ */
  reportAdditionalWorks: {},

  /** Свод работ соисполнителей */
  reportCoWorks: {},

  /** Свод текущий отчет */
  reportAll: {
    problems: [],
    tasks: [],
    missDocs: [],
    missDocsRmm: [],
    notReceived: [],
    overdue: [],
    noForecast: [],
    overdueNodes: [],
    noNar: [],
    noReports: [],
  },

  /** РММ */
  rmmData: {},
  rmmNodeComments: [],

  /** Администрирование */
  adminOrderMan: [],
  adminPeriods: [],
  adminPeriodData: {},

  /** Информация по шапке сайта */
  headerData: {},

  /** Занятость работников */
  employmentData: {},
  employmentUserReport: {},
  employmentDepartmentReport: {},
  employmentComments: [],

  /** Администрирование - Ремонты */
  adminRepairDictionary: {},
  // adminRepairData: {},

  /** Администрирование - Юзеры */
  adminUsers: [],
  adminUsersRoles: [],

  /** Занятость работников РММ */
  rmmEmploymentData: {},
  rmmEmploymentWorkerWorks: {},

  /** МТР */
  mtrLinks: {},
  mtrList: {},
  mtrFilter: null,
  mtrFilterTemp: null,

  /** RULES */
  rules: [],

  /** DESPATCH */
  despatchProblems: [],
};

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOG_IN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        isUserAuth: true,
        isUserGot: true,
      };

    case types.LOG_IN_FAIL:
      return {
        ...state,
        isUserAuth: false,
        isUserGot: true,
      };

    case types.LOG_OUT_SUCCESS:
      return {
        ...state,
        user: null,
        isUserAuth: false,
        isUserGot: true,
      };

    case types.DISPOSE_USER:
      return {
        ...state,
        user: null,
        isUserAuth: false,
        isUserGot: true,
      };

    case types.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        isUserAuth: true,
        isUserGot: true,
      };

    case types.GET_USER_FAIL:
      return {
        ...state,
        isUserAuth: false,
        isUserGot: true,
      };

    case types.GET_REPAIRS_LIST_SUCCESS:
      return {
        ...state,
        repairsData: action.payload,
      };

    case types.GET_REPAIR_INFO_SUCCESS:
      return {
        ...state,
        repairInfo: action.payload,
      };

    case types.GET_REPAIR_WORK_PROGRESS_SUCCESS:
      return {
        ...state,
        repairWorkProgress: action.payload,
      };

    case types.GET_REPAIR_PROBLEMS_SUCCESS:
      return {
        ...state,
        repairProblems: action.payload,
      };

    case types.ADD_REPAIR_PROBLEM_SUCCESS:
      return {
        ...state,
        repairProblems: [action.payload.problem, ...state.repairProblems],
      };

    case types.GET_REPAIR_PROBLEM_TARGETS_SUCCESS:
      return {
        ...state,
        repairProblemTargets: action.payload,
      };

    case types.SET_REPAIR_PROBLEM_SOLVED_SUCCESS:
      return {
        ...state,
        repairProblems: state.repairProblems.map(problem => {
          if (problem.id === action.payload.problemId) {
            return {
              ...problem,
              solved: true,
            };
          }

          return problem;
        }),
      };

    case types.SET_REPAIR_RETURN_PROBLEM_SUCCESS:
      return {
        ...state,
        repairProblems: [
          action.payload.problem,
          ...state.repairProblems.map(problem => {
            if (problem.id === action.payload.problemId) {
              return {
                ...problem,
                solved: true,
              };
            }

            return problem;
          }),
        ],
      };

    case types.SET_REPAIR_TAKEOFF_PROBLEM_SUCCESS:
      return {
        ...state,
        repairProblems: state.repairProblems.map(problem => {
          if (problem.id === action.payload.problemId) {
            return {
              ...problem,
              solved: true,
            };
          }

          return problem;
        }),
      };

    case types.SET_DESPATCH_PROBLEM_SOLVED_SUCCESS:
      return {
        ...state,
        despatchProblems: state.despatchProblems.map(problem => {
          if (problem.id === action.payload.problemId) {
            return {
              ...problem,
              solved: true,
            };
          }

          return problem;
        }),
      };

    case types.UPLOAD_PROBLEM_DOCUMENT_SUCCESS:
      return {
        ...state,
        repairProblems: state.repairProblems.map(problem => {
          if (problem.id === action.payload.problemId) {
            return {
              ...problem,
              files: action.payload.files,
            };
          }
          return problem;
        }),
      };

    case types.DELETE_REPAIR_PROBLEM_SUCCESS:
      return {
        ...state,
        repairProblems: state.repairProblems.filter(
          problem => problem.id !== action.payload.problemId,
        ),
      };

    case types.GET_REPAIR_ADDITIONAL_WORKS_SUCCESS:
      return {
        ...state,
        repairAdditionalWorks: action.payload.data.additionalWorks,
      };

    case types.ADD_REPAIR_ADDITIONAL_WORK_SUCCESS:
      return {
        ...state,
        repairAdditionalWorks: [action.payload.additionalWork, ...state.repairAdditionalWorks],
      };

    case types.EDIT_REPAIR_ADDITIONAL_WORK_SUCCESS:
      return {
        ...state,
        repairAdditionalWorks: state.repairAdditionalWorks.map(work => {
          if (work.id === action.payload.workId) {
            return action.payload.additionalWork;
          }

          return work;
        }),
      };

    case types.DELETE_REPAIR_ADDITIONAL_WORK_SUCCESS:
      return {
        ...state,
        repairAdditionalWorks: state.repairAdditionalWorks.filter(
          work => work.id !== action.payload.workId,
        ),
      };

    case types.SET_REPAIR_ADDITIONAL_WORK_READY_SUCCESS:
      return {
        ...state,
        repairAdditionalWorks: state.repairAdditionalWorks.map(work => {
          if (work.id === action.payload.workId) {
            return {
              ...work,
              ready: action.payload.value,
            };
          }

          return work;
        }),
      };

    case types.SET_REPAIR_ADDITIONAL_WORK_PAID_SUCCESS:
      return {
        ...state,
        repairAdditionalWorks: state.repairAdditionalWorks.map(work => {
          if (work.id === action.payload.workId) {
            return {
              ...work,
              paid: action.payload.value,
            };
          }

          return work;
        }),
      };

    case types.GET_REPAIR_ROSTER_TEAM_SUCCESS:
      return {
        ...state,
        repairRosterTeam: action.payload.data.repairEmployees,
      };

    case types.GET_ROSTER_REPAIRMAN_LIST_SUCCESS:
      return {
        ...state,
        rosterRepairmanList: action.payload.data,
      };

    case types.ADD_REPAIRMAN_TO_ROSTER_SUCCESS:
      return {
        ...state,
        repairRosterTeam: [action.payload.repairman, ...state.repairRosterTeam],
      };

    case types.DELETE_REPAIRMAN_FROM_ROSTER_SUCCESS:
      return {
        ...state,
        repairRosterTeam: state.repairRosterTeam.filter(
          repairman => repairman.id !== action.payload.repairmanId,
        ),
      };

    case types.SET_ROSTER_REPAIRMAN_ACTIVE_SUCCESS:
      return {
        ...state,
        repairRosterTeam: state.repairRosterTeam.map(repairman => {
          if (repairman.id === action.payload.repairmanId) {
            return {
              ...repairman,
              active: action.payload.active,
            };
          }

          return repairman;
        }),
      };

    case types.SET_FINISH_REPAIR_SUCCESS:
      return {
        ...state,
        repairInfo: {
          ...state.repairInfo,
          finished: action.payload.finish,
        },
      };

    case types.SET_CLOSE_REPAIR_SUCCESS:
      return {
        ...state,
        repairInfo: {
          ...state.repairInfo,
          closed: action.payload.closed,
        },
      };

    case types.SET_NO_DISPATCH_SUCCESS:
      return {
        ...state,
        repairInfo: {
          ...state.repairInfo,
          nodispatch: action.payload.noDispatch,
        },
      };

    case types.SET_REPAIR_COMMENT_SUCCESS:
      return {
        ...state,
        repairInfo: {
          ...state.repairInfo,
          comment: action.payload.comment,
          show_comment: action.payload.showComment,
        },
      };

    case types.GET_REPAIR_CO_WORKS_SUCCESS:
      return {
        ...state,
        repairCoWorks: action.payload.data.coWorks,
      };

    case types.ADD_REPAIR_CO_WORK_SUCCESS:
      if (state.repairCoWorks.find(maker => maker.maker === action.payload.coWork.maker)) {
        return {
          ...state,
          repairCoWorks: state.repairCoWorks.map(maker => {
            if (maker.maker === action.payload.coWork.maker) {
              return {
                ...maker,
                works: [...maker.works, action.payload.coWork],
              };
            }

            return maker;
          }),
        };
      }
      return {
        ...state,
        repairCoWorks: [
          ...state.repairCoWorks,
          {
            ...action.payload.coWork,
            works: [
              {
                ...action.payload.coWork,
              },
            ],
          },
        ],
      };

    case types.DELETE_REPAIR_CO_WORK_SUCCESS:
      if (
        state.repairCoWorks.find(
          maker => maker.id === action.payload.makerId && maker.works.length === 1,
        )
      ) {
        return {
          ...state,
          repairCoWorks: state.repairCoWorks.filter(maker => maker.id !== action.payload.makerId),
        };
      }
      return {
        ...state,
        repairCoWorks: state.repairCoWorks.map(maker => {
          if (maker.id === action.payload.makerId) {
            return {
              ...maker,
              works: maker.works.filter(work => work.id !== action.payload.workId),
            };
          }

          return maker;
        }),
      };

    case types.EDIT_REPAIR_CO_WORK_SUCCESS:
      if (!state.repairCoWorks.find(maker => maker.maker === action.payload.coWorkItem.maker)) {
        return state;
      }

      return {
        ...state,
        repairCoWorks: state.repairCoWorks.map(maker => {
          if (maker.maker === action.payload.coWorkItem.maker) {
            return {
              ...maker,
              works: maker.works.map(work => {
                if (work.id === action.payload.workId) {
                  return {
                    ...action.payload.coWorkItem,
                    id: action.payload.workId,
                  };
                }

                return work;
              }),
            };
          }

          return maker;
        }),
      };

    case types.GET_REPAIR_PROLONGS_SUCCESS:
      return {
        ...state,
        repairProlongs: action.payload.data.prolongs,
        repairProlongReasons: action.payload.data.reasons,
      };

    case types.ADD_REPAIR_PROLONG_SUCCESS:
      return {
        ...state,
        repairProlongs: [action.payload.prolong, ...state.repairProlongs],
      };

    case types.SET_REPAIR_PROLONG_REACTION_SUCCESS:
      return {
        ...state,
        repairProlongs: state.repairProlongs.map(prolong => {
          if (prolong.id === action.payload.prolongId) {
            return action.payload.prolong;
          }

          return prolong;
        }),
      };

    case types.REPAIR_PROLONG_MOVE_TO_OTHER_SUCCESS:
      return {
        ...state,
        repairProlongs: [action.payload.prolong, ...state.repairProlongs],
      };

    case types.GET_REPAIR_DOCS_SUCCESS:
      return {
        ...state,
        repairDocs: action.payload.data,
      };

    case types.ADD_REPAIR_CUSTOM_DOC_SUCCESS:
      return {
        ...state,
        repairDocs: {
          ...state.repairDocs,
          customDocuments: [...state.repairDocs.customDocuments, action.payload.customDoc],
        },
      };

    case types.DELETE_REPAIR_CUSTOM_DOC_SUCCESS:
      return {
        ...state,
        repairDocs: {
          ...state.repairDocs,
          customDocuments: state.repairDocs.customDocuments.filter(
            doc => doc.id !== action.payload.docId,
          ),
        },
      };

    case types.CHANGE_REPAIR_DOC_NO_NEED_SUCCESS:
      return {
        ...state,
        repairDocs: {
          ...state.repairDocs,
          [action.payload.type]: {
            ...state.repairDocs[action.payload.type],
            noneed: action.payload.value,
          },
        },
      };

    case types.SET_REPAIR_UNIT_WORK_HOURS_SUCCESS:
      return {
        ...state,
        repairDocs: {
          ...state.repairDocs,
          nar1: {
            ...state.repairDocs.nar1,
            value: action.payload.nar1Value,
          },
          nar2: {
            ...state.repairDocs.nar2,
            value: action.payload.nar2Value,
          },
          nar3: {
            ...state.repairDocs.nar3,
            value: action.payload.nar3Value,
          },
          nar4: {
            ...state.repairDocs.nar4,
            value: action.payload.nar4Value,
          },
          nar5: {
            ...state.repairDocs.nar5,
            value: action.payload.nar5Value,
          },
        },
      };

    case types.UPLOAD_REPAIR_DOCUMENT_SUCCESS:
      return {
        ...state,
        repairDocs: {
          ...state.repairDocs,
          [action.payload.type]: {
            ...state.repairDocs[action.payload.type],
            ...action.payload.data,
          },
        },
      };

    case types.UPLOAD_REPAIR_MULTI_DOCUMENT_SUCCESS: {
      if (action.payload.isCustomDocument) {
        return {
          ...state,
          repairDocs: {
            ...state.repairDocs,
            customDocuments: state.repairDocs.customDocuments.map(doc => {
              if (doc.spname === action.payload.type) {
                return {
                  ...doc,
                  files: action.payload.files,
                };
              }
              return doc;
            }),
          },
        };
      }
      return {
        ...state,
        repairDocs: {
          ...state.repairDocs,
          [action.payload.type]: {
            ...state.repairDocs[action.payload.type],
            files: action.payload.files,
          },
        },
      };
    }

    case types.GET_REPAIR_MTR_SUCCESS:
      return {
        ...state,
        repairMtr: action.payload.data,
      };

    case types.SET_REPAIR_MTR_INCLUDED_SUCCESS:
      return {
        ...state,
        repairMtr: {
          ...state.repairMtr,
          mtr: state.repairMtr.mtr.map(mtr => {
            if (mtr.id === action.payload.mtrId) {
              return {
                ...mtr,
                included: action.payload.data.included,
              };
            }

            return mtr;
          }),
        },
      };

    case types.SET_REPAIR_MTR_RECEIVED_SUCCESS:
      return {
        ...state,
        repairMtr: {
          ...state.repairMtr,
          mtr: state.repairMtr.mtr.map(mtr => {
            if (mtr.id === action.payload.mtrId) {
              return {
                ...mtr,
                received: action.payload.data.received,
              };
            }

            return mtr;
          }),
        },
      };

    case types.SET_REPAIR_MTR_CHARGED_SUCCESS:
      return {
        ...state,
        repairMtr: {
          ...state.repairMtr,
          mtr: state.repairMtr.mtr.map(mtr => {
            if (mtr.id === action.payload.mtrId) {
              return {
                ...mtr,
                charged: action.payload.data.charged,
              };
            }

            return mtr;
          }),
        },
      };

    case types.GET_REPAIR_NODES_SUCCESS:
      return {
        ...state,
        repairNodes: action.payload.data.nodes,
        repairRmmList: action.payload.data.rmmList,
      };

    case types.ADD_REPAIR_NODE_SUCCESS:
      return {
        ...state,
        repairNodes: [...state.repairNodes, action.payload.node],
      };

    case types.UPDATE_ACT_REPAIR_NODE_SUCCESS:
      return {
        ...state,
        repairNodes: state.repairNodes.map(node => {
          if (node.id === action.payload.nodeId) {
            return {
              ...node,
              ...action.payload.data,
            };
          }
          return node;
        }),
      };

    case types.CONFIRM_DATE_REPAIR_NODE_SUCCESS:
      return {
        ...state,
        repairNodes: state.repairNodes.map(node => {
          if (node.id === action.payload.nodeId) {
            return {
              ...node,
              dateforecast_confirm: 1,
            };
          }

          return node;
        }),
      };

    case types.RESET_REPAIR_NODE_UNREAD_COUNT_SUCCESS:
      return {
        ...state,
        repairNodes: state.repairNodes.map(node => {
          if (node.id === action.payload.nodeId) {
            return {
              ...node,
              unreadCount: 0,
            };
          }

          return node;
        }),
      };

    case types.GET_DISPATCH_SUCCESS:
      return {
        ...state,
        dispatchInfo: action.payload.data,
      };

    case types.TOGGLE_COLLAPSE_DISPATCH_TABLE_ROWS:
      return {
        ...state,
        dispatchInfo: {
          ...state.dispatchInfo,
          ordermantoperiod: state.dispatchInfo.ordermantoperiod.map(orderMan => ({
            ...orderMan,
            ks: orderMan.ks.map(ksItem => ({
              ...ksItem,
              repair: ksItem.repair.map(repairItem => {
                if (repairItem.id === action.payload.id) {
                  return {
                    ...repairItem,
                    collapsed: !action.payload.collapsed,
                  };
                }

                return repairItem;
              }),
            })),
          })),
        },
      };

    case types.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload.data,
      };

    case types.GET_MY_TASK_TARGETS_SUCCESS:
      return {
        ...state,
        myTaskTargets: action.payload.targets,
      };

    case types.ADD_TASK_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          myTasks: [
            ...state.notifications.myTasks,
            {
              ...action.payload.task,
              targetrole: action.payload.role,
              unreadCount: 0,
            },
          ],
        },
      };

    case types.EDIT_TASK_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          myTasks: state.notifications.myTasks.map(task => {
            if (task.id === action.payload.task.id) {
              return {
                ...task,
                ...action.payload.task,
              };
            }

            return task;
          }),
        },
      };

    case types.SET_TASK_DONE_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          tasks: state.notifications.tasks.map(task => {
            if (task.id === action.payload.id) {
              return {
                ...task,
                done: action.payload.done,
              };
            }

            return task;
          }),
        },
      };

    case types.SET_TASK_DONE_APPROVE_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          myTasks: state.notifications.myTasks
            .map(task => {
              if (task.id === action.payload.id) {
                return {
                  ...task,
                  doneapproved: action.payload.doneapproved,
                };
              }

              return task;
            })
            .filter(task => task.id !== action.payload.id),
        },
      };

    case types.SET_TASK_DONE_DENY_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          myTasks: state.notifications.myTasks.filter(task => task.id !== action.payload.id),
        },
      };

    case types.GET_TASK_COMMENTS_SUCCESS:
      return {
        ...state,
        taskComments: action.payload.comments,
      };

    case types.ADD_TASK_COMMENT_SUCCESS:
      return {
        ...state,
        taskComments: [...state.taskComments, action.payload.comment],
      };

    case types.UPLOAD_TASK_DOCUMENT_SUCCESS: {
      let field;
      if (action.payload.taskType === 'my') {
        field = 'myTasks';
      } else {
        field = 'tasks';
      }

      if (!state.notifications[field]) {
        return state;
      }

      const tasks = state.notifications[field].map(task => {
        if (task.id === action.payload.taskId) {
          return {
            ...task,
            files: action.payload.files,
          };
        }
        return task;
      });

      return {
        ...state,
        notifications: {
          ...state.notifications,
          [field]: tasks,
        },
      };
    }

    case types.RESET_TASK_UNREAD_COUNT_SUCCESS: {
      let field;
      if (action.payload.taskType === 'my') {
        field = 'myTasks';
      } else {
        field = 'tasks';
      }

      if (!state.notifications[field]) {
        return state;
      }

      const tasks = state.notifications[field].map(task => {
        if (task.id === action.payload.taskId) {
          return {
            ...task,
            unreadCount: 0,
          };
        }
        return task;
      });

      return {
        ...state,
        notifications: {
          ...state.notifications,
          [field]: tasks,
        },
      };
    }

    case types.GET_REPORT_ADDITIONAL_WORKS_SUCCESS:
      return {
        ...state,
        reportAdditionalWorks: action.payload.data,
      };

    case types.GET_REPORT_CO_WORKS_SUCCESS:
      return {
        ...state,
        reportCoWorks: action.payload.data,
      };

    case types.GET_REPORT_PROBLEMS_SUCCESS:
      return {
        ...state,
        reportAll: {
          ...state.reportAll,
          problems: [...action.payload.data],
        },
      };

    case types.GET_REPORT_TASKS_SUCCESS:
      return {
        ...state,
        reportAll: {
          ...state.reportAll,
          tasks: [...action.payload.data],
        },
      };

    case types.GET_REPORT_MISS_DOCS_SUCCESS:
      return {
        ...state,
        reportAll: {
          ...state.reportAll,
          missDocs: [...action.payload.data],
        },
      };

    case types.GET_REPORT_MISS_DOCS_RMM_SUCCESS:
      return {
        ...state,
        reportAll: {
          ...state.reportAll,
          missDocsRmm: [...action.payload.data],
        },
      };

    case types.GET_REPORT_NODES_NOT_RECEIVED_SUCCESS:
      return {
        ...state,
        reportAll: {
          ...state.reportAll,
          notReceived: [...action.payload.data],
        },
      };

    case types.GET_REPORT_REPAIRS_OVERDUE_SUCCESS:
      return {
        ...state,
        reportAll: {
          ...state.reportAll,
          overdue: [...action.payload.data],
        },
      };

    case types.GET_REPORT_NODES_NO_FORECAST_SUCCESS:
      return {
        ...state,
        reportAll: {
          ...state.reportAll,
          noForecast: [...action.payload.data],
        },
      };

    case types.GET_REPORT_NODES_OVERDUE_SUCCESS:
      return {
        ...state,
        reportAll: {
          ...state.reportAll,
          overdueNodes: [...action.payload.data],
        },
      };

    case types.GET_REPORT_NO_NAR_SUCCESS:
      return {
        ...state,
        reportAll: {
          ...state.reportAll,
          noNar: [...action.payload.data],
        },
      };

    case types.GET_REPORT_NO_REPORT_REPAIRS_SUCCESS:
      return {
        ...state,
        reportAll: {
          ...state.reportAll,
          noReports: [...action.payload.data],
        },
      };

    case types.GET_RMM_SUCCESS:
      return {
        ...state,
        rmmData: {
          ...action.payload.data,
        },
      };

    case types.LINK_NODE_TO_REPAIR_SUCCESS:
      return {
        ...state,
        rmmData: {
          ...state.rmmData,
          nodes: state.rmmData.nodes.map(node => {
            if (node.id === action.payload.nodeId) {
              return {
                ...node,
                repair: action.payload.repair,
              };
            }
            return node;
          }),
        },
      };

    case types.SEND_RMM_NODE_TO_ARCHIVE_SUCCESS:
      return {
        ...state,
        rmmData: {
          ...state.rmmData,
          nodes: state.rmmData.nodes.filter(node => node.id !== action.payload.id),
        },
      };

    case types.MOVE_RMM_NODE_TO_OTHER_BASE_SUCCESS:
      return {
        ...state,
        rmmData: {
          ...state.rmmData,
          nodes: state.rmmData.nodes.filter(node => node.id !== action.payload.id),
        },
      };

    case types.DELETE_RMM_NODE_SUCCESS:
      return {
        ...state,
        rmmData: {
          ...state.rmmData,
          nodes: state.rmmData.nodes.filter(node => node.id !== action.payload.id),
        },
      };

    case types.SET_RMM_NODE_PRIORITY_SUCCESS:
      return {
        ...state,
        rmmData: {
          ...state.rmmData,
          nodes: state.rmmData.nodes.map(node => {
            if (node.id === action.payload.id) {
              return {
                ...node,
                priority: action.payload.data.priority,
                priorityClass: action.payload.data.priorityClass,
              };
            }

            return node;
          }),
        },
      };

    case types.CHANGE_RMM_DOC_NO_NEED_SUCCESS: {
      const nextState = {
        ...state,
      };
      if (state.rmmData && state.rmmData.nodes) {
        nextState.rmmData = {
          ...state.rmmData,
          nodes: state.rmmData.nodes.map(node => {
            if (node.id === action.payload.nodeId) {
              return {
                ...node,
                ...action.payload.data,
              };
            }

            return node;
          }),
        };
      }

      if (state.repairNodes) {
        nextState.repairNodes = state.repairNodes.map(node => {
          if (node.id === action.payload.nodeId) {
            return {
              ...node,
              ...action.payload.data,
            };
          }

          return node;
        });
      }

      return nextState;
    }

    case types.UPLOAD_RMM_DOCUMENT_SUCCESS: {
      const nextState = {
        ...state,
      };
      if (state.rmmData && state.rmmData.nodes) {
        nextState.rmmData = {
          ...state.rmmData,
          nodes: state.rmmData.nodes.map(node => {
            if (node.id === action.payload.nodeId) {
              return {
                ...node,
                ...action.payload.data,
              };
            }

            return node;
          }),
        };
      }

      if (state.repairNodes) {
        nextState.repairNodes = state.repairNodes.map(node => {
          if (node.id === action.payload.nodeId) {
            return {
              ...node,
              ...action.payload.data,
            };
          }

          return node;
        });
      }

      return nextState;
    }

    case types.SET_RMM_NODE_RECEIVE_SUCCESS:
      return {
        ...state,
        rmmData: {
          ...state.rmmData,
          nodes: state.rmmData.nodes.map(node => {
            if (node.id === action.payload.id) {
              return {
                ...node,
                datereceipt: action.payload.dateReceipt,
                dateforecast: action.payload.dateForecast,
                datereceipt_1: action.payload.data.datereceipt_1,
                dateforecast_1: action.payload.data.dateforecast_1,
              };
            }

            return node;
          }),
        },
      };

    case types.CHANGE_RMM_NODE_DATE_FORECAST_SUCCESS:
      return {
        ...state,
        rmmData: {
          ...state.rmmData,
          nodes: state.rmmData.nodes.map(node => {
            if (node.id === action.payload.id) {
              return {
                ...node,
                dateforecast: action.payload.data.dateforecast,
                dateforecast_1: action.payload.data.dateforecast_1,
              };
            }

            return node;
          }),
        },
      };

    case types.ADD_RMM_NODE_SUCCESS:
      return {
        ...state,
        rmmData: {
          ...state.rmmData,
          nodes: [action.payload.node, ...state.rmmData.nodes],
        },
      };

    case types.RECEIVE_REPAIR_NODE_SUCCESS:
      return {
        ...state,
        repairNodes: state.repairNodes.map(node => {
          if (node.id === action.payload.nodeId) {
            return {
              ...node,
              ...action.payload.data,
            };
          }

          return node;
        }),
      };

    case types.ADD_RMM_NODE_PERCENT_SUCCESS:
      return {
        ...state,
        rmmData: {
          ...state.rmmData,
          nodes: state.rmmData.nodes.map(node => {
            if (node.id === action.payload.id) {
              return {
                ...node,
                percent: action.payload.percent,
                status: action.payload.percentNode.status,
                percentnode: [...node.percentnode, action.payload.percentNode],
              };
            }

            return node;
          }),
        },
      };

    case types.GET_RMM_NODE_COMMENTS_SUCCESS:
      return {
        ...state,
        rmmNodeComments: action.payload.comments,
      };

    case types.ADD_RMM_NODE_COMMENT_SUCCESS:
      return {
        ...state,
        rmmNodeComments: [...state.rmmNodeComments, action.payload.comment],
      };

    case types.SEND_RMM_NODE_TO_KS_SUCCESS:
      return {
        ...state,
        rmmData: {
          ...state.rmmData,
          nodes: state.rmmData.nodes.map(node => {
            if (node.id === action.payload.id) {
              return {
                ...node,
                datesendreturn: action.payload.data.datesendreturn,
                datesendreturn_1: action.payload.data.datesendreturn_1,
                percent: 100,
                status: 'success',
                percentnode: [
                  ...node.percentnode.map(percentItem => ({
                    ...percentItem,
                    percent: action.payload.percentUpdatedFor.includes(percentItem.id)
                      ? 100
                      : percentItem.percent,
                    status: action.payload.percentUpdatedFor.includes(percentItem.id)
                      ? 'success'
                      : percentItem.status,
                  })),
                  ...(action.payload.newPercent ? [action.payload.newPercent] : []),
                ],
              };
            }

            return node;
          }),
        },
      };

    case types.RESET_RMM_NODE_UNREAD_COUNT_SUCCESS:
      return {
        ...state,
        rmmData: {
          ...state.rmmData,
          nodes: state.rmmData.nodes.map(node => {
            if (node.id === action.payload.nodeId) {
              return {
                ...node,
                unreadCount: 0,
              };
            }

            return node;
          }),
        },
      };

    case types.GET_ADMIN_ORDER_MAN_SUCCESS:
      return {
        ...state,
        adminOrderMan: [...action.payload.data],
      };

    case types.ADD_ADMIN_ORDER_MAN_SUCCESS:
      return {
        ...state,
        adminOrderMan: [action.payload.orderMan, ...state.adminOrderMan],
      };

    case types.EDIT_ADMIN_ORDER_MAN_SUCCESS:
      return {
        ...state,
        adminOrderMan: state.adminOrderMan.map(orderMan => {
          if (orderMan.id === action.payload.id) {
            return {
              ...orderMan,
              name: action.payload.name,
            };
          }

          return orderMan;
        }),
      };

    case types.DELETE_ADMIN_ORDER_MAN_SUCCESS:
      return {
        ...state,
        adminOrderMan: state.adminOrderMan.filter(orderMan => orderMan.id !== action.payload.id),
      };

    case types.GET_ADMIN_PERIODS_SUCCESS:
      return {
        ...state,
        adminPeriods: [...action.payload.data],
      };

    case types.ADD_ADMIN_PERIOD_SUCCESS:
      return {
        ...state,
        adminPeriods: [action.payload.period, ...state.adminPeriods],
      };

    case types.GET_ADMIN_PERIOD_SUCCESS:
      return {
        ...state,
        adminPeriodData: action.payload.data,
      };

    case types.ADD_ADMIN_ORDER_MAN_TO_PERIOD_SUCCESS:
      return {
        ...state,
        adminPeriodData: {
          ...state.adminPeriodData,
          orderMenOfThisPeriod: [
            action.payload.data,
            ...state.adminPeriodData.orderMenOfThisPeriod,
          ],
        },
      };

    case types.GET_HEADER_DATA_SUCCESS:
      return {
        ...state,
        headerData: action.payload.data,
      };

    case types.ADD_KS_TO_ORDER_MAN_SUCCESS:
      return {
        ...state,
        adminPeriodData: {
          ...state.adminPeriodData,
          orderMenOfThisPeriod: state.adminPeriodData.orderMenOfThisPeriod.map(orderMan => {
            if (orderMan.id === action.payload.orderManId) {
              return {
                ...orderMan,
                ks: [...orderMan.ks, action.payload.ks],
              };
            }

            return orderMan;
          }),
        },
      };

    case types.EDIT_KS_ADMIN_SUCCESS:
      return {
        ...state,
        adminPeriodData: {
          ...state.adminPeriodData,
          orderMenOfThisPeriod: state.adminPeriodData.orderMenOfThisPeriod.map(orderMan => {
            if (orderMan.id === action.payload.orderManId) {
              return {
                ...orderMan,
                ks: orderMan.ks.map(ks => {
                  if (ks.id === action.payload.ksId) {
                    return {
                      ...ks,
                      name: action.payload.ksName,
                    };
                  }

                  return ks;
                }),
              };
            }

            return orderMan;
          }),
        },
      };

    case types.DELETE_KS_ADMIN_SUCCESS:
      return {
        ...state,
        adminPeriodData: {
          ...state.adminPeriodData,
          orderMenOfThisPeriod: state.adminPeriodData.orderMenOfThisPeriod.map(orderMan => {
            if (orderMan.id === action.payload.orderManId) {
              return {
                ...orderMan,
                ks: orderMan.ks.filter(ks => ks.id !== action.payload.ksId),
              };
            }

            return orderMan;
          }),
        },
      };

    case types.GET_EMPLOYMENT_SUCCESS:
      return {
        ...state,
        employmentData: action.payload.data,
      };

    case types.GET_EMPLOYMENT_USER_REPORT_SUCCESS:
      return {
        ...state,
        employmentUserReport: action.payload.data,
      };

    case types.CLEAR_EMPLOYMENT_USER_REPORT_SUCCESS:
      return {
        ...state,
        employmentUserReport: {},
      };

    case types.CLEAR_EMPLOYMENT_DEPARTMENT_REPORT_SUCCESS:
      return {
        ...state,
        employmentDepartmentReport: {},
      };

    case types.GET_EMPLOYMENT_DEPARTMENT_REPORT_SUCCESS:
      return {
        ...state,
        employmentDepartmentReport: action.payload.data,
      };

    case types.SET_EMPLOYMENT_USER_CONTACTS_SUCCESS:
      return {
        ...state,
        employmentData: {
          ...state.employmentData,
          deps: state.employmentData.deps.map(dep => {
            if (dep.id === action.payload.departmentId) {
              return {
                ...dep,
                staff: dep.staff.map(user => {
                  if (user.id === action.payload.userId) {
                    return {
                      ...user,
                      email: action.payload.email,
                      phone: action.payload.phone,
                    };
                  }

                  return user;
                }),
              };
            }

            return dep;
          }),
        },
      };

    case types.GET_EMPLOYMENT_COMMENTS_SUCCESS:
      return {
        ...state,
        employmentComments: action.payload.comments,
      };

    case types.ADD_EMPLOYMENT_COMMENT_SUCCESS:
      return {
        ...state,
        employmentData: {
          ...state.employmentData,
          deps: state.employmentData.deps.map(dep => {
            if (dep.id === action.payload.departmentId) {
              return {
                ...dep,
                staff: dep.staff.map(user => {
                  if (user.id === action.payload.userId) {
                    return {
                      ...user,
                      comment: action.payload.text,
                    };
                  }

                  return user;
                }),
              };
            }

            return dep;
          }),
        },
        employmentComments: [action.payload.comment, ...state.employmentComments],
      };

    case types.SET_EMPLOYMENT_OUT_OF_WORK_USER_SUCCESS:
      return {
        ...state,
        employmentData: {
          ...state.employmentData,
          deps: state.employmentData.deps.map(dep => {
            if (dep.id === action.payload.departmentId) {
              return {
                ...dep,
                staff: dep.staff.map(user => {
                  if (user.id === action.payload.userId) {
                    return {
                      ...user,
                      comment: action.payload.commentText,
                      outofwork: action.payload.type,
                    };
                  }

                  return user;
                }),
              };
            }

            return dep;
          }),
        },
        employmentComments: [action.payload.commentData, ...state.employmentComments],
      };

    case types.SET_EMPLOYMENT_IN_TO_WORK_USER_SUCCESS:
      return {
        ...state,
        employmentData: {
          ...state.employmentData,
          deps: state.employmentData.deps.map(dep => {
            if (dep.id === action.payload.departmentId) {
              return {
                ...dep,
                staff: dep.staff.map(user => {
                  if (user.id === action.payload.userId) {
                    return {
                      ...user,
                      comment: action.payload.commentText,
                      outofwork: '',
                    };
                  }

                  return user;
                }),
              };
            }

            return dep;
          }),
        },
        employmentComments: [action.payload.commentData, ...state.employmentComments],
      };

    case types.UPLOAD_EMPLOYEE_DOCUMENT_SUCCESS:
      return {
        ...state,
        employmentData: {
          ...state.employmentData,
          deps: state.employmentData.deps.map(dep => {
            if (dep.id === action.payload.departmentId) {
              return {
                ...dep,
                staff: dep.staff.map(user => {
                  if (user.id === action.payload.employeeId) {
                    return {
                      ...user,
                      [action.payload.type]: action.payload.data,
                    };
                  }

                  return user;
                }),
              };
            }

            return dep;
          }),
        },
      };

    case types.GET_ADMIN_REPAIR_DICTIONARY_SUCCESS:
      return {
        ...state,
        adminRepairDictionary: action.payload.dictionary,
      };

    case types.ADD_ADMIN_REPAIR_SUCCESS:
      return {
        ...state,
        adminPeriodData: {
          ...state.adminPeriodData,
          orderMenOfThisPeriod: state.adminPeriodData.orderMenOfThisPeriod.map(orderMan => {
            if (orderMan.id === action.payload.orderManId) {
              return {
                ...orderMan,
                ks: orderMan.ks.map(ksItem => {
                  if (ksItem.id === action.payload.ksId) {
                    return {
                      ...ksItem,
                      [action.payload.month]: [
                        ...(ksItem[action.payload.month] ? ksItem[action.payload.month] : []),
                        action.payload.repair,
                      ],
                    };
                  }

                  return ksItem;
                }),
              };
            }

            return orderMan;
          }),
        },
      };

    // case types.GET_ADMIN_REPAIR_SUCCESS:
    //   return {
    //     ...state,
    //     adminRepairData: action.payload.data,
    //   };

    case types.GET_ADMIN_USERS_SUCCESS:
      return {
        ...state,
        adminUsers: action.payload.data.user,
      };

    case types.DELETE_ADMIN_USER_SUCCESS:
      return {
        ...state,
        adminUsers: state.adminUsers.filter(user => user.id !== action.payload.userId),
      };

    case types.ADD_ADMIN_USER_SUCCESS:
      return {
        ...state,
        adminUsers: [...state.adminUsers, action.payload.user],
      };

    case types.EDIT_ADMIN_USER_SUCCESS:
      return {
        ...state,
        adminUsers: state.adminUsers.map(user => {
          if (user.id === action.payload.userId) {
            return action.payload.user;
          }

          return user;
        }),
      };

    case types.GET_ADMIN_USERS_ROLES_SUCCESS:
      return {
        ...state,
        adminUsersRoles: action.payload.roles,
      };

    case types.TOGGLE_ADMIN_USER_ACTIVE_SUCCESS:
      return {
        ...state,
        adminUsers: state.adminUsers.map(user => {
          if (user.id === action.payload.userId) {
            return {
              ...user,
              active: action.payload.value,
            };
          }

          return user;
        }),
      };

    case types.EDIT_ADMIN_REPAIR_SUCCESS:
      return {
        ...state,
        adminPeriodData: {
          ...state.adminPeriodData,
          orderMenOfThisPeriod:
            state.adminPeriodData.orderMenOfThisPeriod &&
            Array.isArray(state.adminPeriodData.orderMenOfThisPeriod)
              ? state.adminPeriodData.orderMenOfThisPeriod.map(orderMan => {
                  if (orderMan.id === action.payload.orderManId) {
                    return {
                      ...orderMan,
                      ks: orderMan.ks.map(ksItem => {
                        if (ksItem.id === action.payload.ksId) {
                          return {
                            ...ksItem,
                            [action.payload.month]: ksItem[[action.payload.month]].map(repair => {
                              if (repair.id === action.payload.repairId) {
                                return action.payload.repair;
                              }

                              return repair;
                            }),
                          };
                        }

                        return ksItem;
                      }),
                    };
                  }

                  return orderMan;
                })
              : [],
        },
      };

    case types.DELETE_ADMIN_REPAIR_SUCCESS:
      return {
        ...state,
        adminPeriodData: {
          ...state.adminPeriodData,
          orderMenOfThisPeriod:
            state.adminPeriodData.orderMenOfThisPeriod &&
            Array.isArray(state.adminPeriodData.orderMenOfThisPeriod)
              ? state.adminPeriodData.orderMenOfThisPeriod.map(orderMan => {
                  if (orderMan.id === action.payload.orderManId) {
                    return {
                      ...orderMan,
                      ks: orderMan.ks.map(ksItem => {
                        if (ksItem.id === action.payload.ksId) {
                          return {
                            ...ksItem,
                            [action.payload.month]: ksItem[[action.payload.month]].filter(
                              repair => repair.id !== action.payload.repairId,
                            ),
                          };
                        }

                        return ksItem;
                      }),
                    };
                  }

                  return orderMan;
                })
              : [],
        },
      };

    case types.GET_RMM_EMPLOYMENT_WORKERS_SUCCESS:
      return {
        ...state,
        rmmEmploymentData: action.payload.data,
      };

    case types.GET_RMM_EMPLOYMENT_WORKER_WORKS_SUCCESS:
      return {
        ...state,
        rmmEmploymentWorkerWorks: action.payload.data,
      };

    case types.GET_MTR_LINKS_SUCCESS:
      return {
        ...state,
        mtrLinks: action.payload.data,
      };

    case types.GET_MTR_LIST_SUCCESS:
      if (action.payload.data.gpas && action.payload.data.objects && action.payload.data.delivery) {
        return {
          ...state,
          mtrList: {
            ...action.payload.data,
          },
        };
      }

      return {
        ...state,
        mtrList: {
          ...state.mtrList,
          item: action.payload.data.item,
          pagesCount: action.payload.data.pagesCount,
        },
      };

    case types.LINK_MTR_SUCCESS:
      return {
        ...state,
        mtrLinks: {
          ...state.mtrLinks,
          objects: state.mtrLinks.objects.map(obj => {
            if (obj.id === action.payload.objectId) {
              return {
                ...obj,
                object: action.payload.repairId,
              };
            }

            if (obj.object === action.payload.repairId) {
              return {
                ...obj,
                object: 0,
              };
            }

            return obj;
          }),
        },
      };

    case types.SET_MTR_FILTER_SUCCESS:
      return {
        ...state,
        ...(!action.payload.options.temp
          ? {
              mtrFilter: action.payload.filterOptions,
              mtrFilterTemp: null,
            }
          : {
              mtrFilter: null,
              mtrFilterTemp: action.payload.filterOptions,
            }),
      };

    case types.RESET_MTR_FILTER_SUCCESS:
      return {
        ...state,
        mtrFilter: null,
        mtrFilterTemp: null,
      };

    case types.GET_RULES_FILES_SUCCESS:
      return {
        ...state,
        rules: action.payload.data,
      };

    case types.ADD_RULES_FILE_SUCCESS:
      return {
        ...state,
        rules: [
          ...(state.rules.find(item => item.role === action.payload.file.role)
            ? state.rules.map(item => {
                if (item.role === action.payload.file.role) {
                  return {
                    ...item,
                    files: [...item.files, action.payload.file],
                  };
                }
                return item;
              })
            : [
                ...state.rules,
                {
                  id: action.payload.file.id,
                  rolename: action.payload.file.rolename,
                  files: [action.payload.file],
                },
              ]),
        ],
      };

    case types.DELETE_RULES_FILE_SUCCESS:
      return {
        ...state,
        rules: state.rules.map(item => {
          if (item.role === action.payload.role) {
            return {
              ...item,
              files: item.files.filter(file => file.id !== action.payload.fileId),
            };
          }
          return item;
        }),
      };

    default:
      return state;
  }
};

export default mainReducer;
