import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Icons
import { FaPlusSquare } from 'react-icons/fa';

// Components
import FormGroup from 'components/FormGroup/FormGroup';
import TextArea from 'components/TextArea/TextArea';
import Loader from 'components/Loader/Loader';

// Actions
import {
  loadDespatchComments,
  loadDespatchProblemComments,
  addDespatchComment,
} from 'actions/despatch';

const DespatchComments = ({ despatchId, user, canEdit, dispatch, comments, problemComments }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);
  const [comment, setComment] = useState('');

  /** Запрашиваем информацию по отправлению */
  useEffect(() => {
    setIsLoading(true);

    dispatch(loadDespatchComments(despatchId))
      // .then(() => loadDespatchProblemComments(despatchId))
      // .catch(() => setIsLoading(false));
      .finally(() => setIsLoading(false));

    dispatch(loadDespatchProblemComments(despatchId))
      // .then(() => (despatchId))
      // .catch(() => setIsLoading(false));
      .finally(() => setIsLoading(false));
  }, [dispatch, despatchId]);

  const handleAddCommentClick = () => {
    dispatch(addDespatchComment(despatchId, 0, comment))
      .then(() => setIsLoading(false))
      .catch((error) => {
        /** Если есть ошибки, выводим */
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        /** Скрываем прелоадер на кнопку */
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="despatch-comments">
        <div className="despatch-comments__header">
          <h2 className="despatch-details__title">Комментарии</h2>
        </div>

        <FormGroup error={fieldErrors.comment}>>
          <TextArea value={comment} onChange={({ target }) => setComment(target.value)} />
        </FormGroup>

        {responseError && <div className="modal-error_border">{responseError}</div>}

        <div className="despatch-comments__cta">
          <button type="button" className="btn btn-secondary" onClick={handleAddCommentClick}>
            <FaPlusSquare className="icon" /> Добавить комментарий
          </button>
        </div>

        <hr style={{ marginTop: 24, marginBottom: 24 }}/>

        {Array.isArray(comments) && comments.length && <h2 className="despatch-details__title">Комментарии для отправления</h2>}

        <ul>
          {Array.isArray(comments) && comments.map(v => (
            <li>
              {v.createdBy}({v.dateTime}): {v.comment}
            </li>
          ))}
        </ul>

        {Array.isArray(problemComments) && problemComments.length && <h2 className="despatch-details__title">Комментарии по проблемам</h2>}

        <ul>
          {Array.isArray(problemComments) && problemComments.map(v => (
            <li>
              {v.createdBy_name}({v.date}): {v.comment}
            </li>
          ))}
        </ul>

        {false && isLoading && <Loader />}
      </div>

    </React.Fragment>
  );
};

DespatchComments.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  despatchId: PropTypes.number.isRequired,
  // repairId: PropTypes.number.isRequired,
  // nodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // rmmList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({
    userRole: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = state => ({
  nodes: state.mainReducer.DespatchCommentss,
  rmmList: state.mainReducer.repairRmmList,
  user: state.mainReducer.user,
  comments: state.despatchReducer.comments,
  problemComments: state.despatchReducer.problemComments,
});

export default connect(mapStateToProps)(DespatchComments);
