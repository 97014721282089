import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import SelectForm from 'components/SelectForm/SelectForm';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { assignDriver } from 'actions/despatch';

// Utils
import * as propTypes from 'utils/propTypes';

const AssignDriverModal = ({ 
  closeHandler, 
  dispatch, 
  transportId, 
  driverId, 
  drivers, 
  userId, 
  despatchId,
}) => {
  const [selectedDriver, setSelectedDriver] = useState(
    drivers && drivers.length
      ? drivers
          .map(v => ({ ...v, value: v.id, label: `${v.lastName} ${v.firstName} ${v.middleName}` }))
          .find(v => v.id == driverId) // eslint-disable-line eqeqeq
      : {},
  );
  const [driversData] = useState(
    drivers.map(driver => ({ ...driver, value: driver.id, label: `${driver.lastName} ${driver.firstName} ${driver.middleName}` })),
  );
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null); 
  const [isLoading, setIsLoading] = useState(false);

  const submitAssignDriver = () => {
    setIsLoading(true);

    dispatch(assignDriver(transportId, selectedDriver.id, userId, despatchId))
      .then(() => {
        setIsLoading(false);

        closeHandler();
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Подкрепить водителя" />

      <ModalBody>
        <FormGroup title="Водитель" error={fieldErrors.driverid}>
          <SelectForm
            options={driversData}
            selectedValue={selectedDriver}
            onChange={selectedOption => setSelectedDriver(selectedOption)}
          />
        </FormGroup>
      </ModalBody>

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isLoading={isLoading}
          isLoadingText="Отправляю..."
          clickHandler={submitAssignDriver}
        >
          Отправить
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

AssignDriverModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  transportId: PropTypes.number.isRequired,
  driverId: PropTypes.number,
  userId: PropTypes.number,
  drivers: PropTypes.arrayOf(propTypes.driverData).isRequired,
  despatchId: PropTypes.number,
};

const mapStateToProps = state => ({
  drivers: state.commonReducer.drivers,
});

export default connect(mapStateToProps)(AssignDriverModal);
