import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import TextArea from 'components/TextArea/TextArea';
import InputDate from 'components/InputDate/InputDate';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { addRepairAdditionalWork } from 'actions';

// Helpers
import { formatDateYYYYmmDD } from 'helpers/formatDateYYYYmmDD';

const AddAdditionalWorkModal = ({ closeHandler, dispatch, repairId }) => {
  const [workName, setWorkName] = useState('');
  const [workDate, setWorkDate] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const submitRepairAdditionalWork = () => {
    /** Показываем прелоадер на кнопку */
    setIsLoading(true);

    /** Записываем доп.работу в базу данных */
    dispatch(addRepairAdditionalWork(repairId, workName, formatDateYYYYmmDD(workDate)))
      .then(() => {
        /** Скрываем прелоадер на кнопку */
        setIsLoading(false);

        /** Закрываем модалку */
        closeHandler();
      })
      .catch(error => {
        /** Если есть ошибки, выводим */
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        /** Скрываем прелоадер на кнопку */
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Добавление работы" />

      <ModalBody>
        <FormGroup title="Работы" error={fieldErrors.works}>
          <TextArea value={workName} onChange={({ target }) => setWorkName(target.value)} />
        </FormGroup>

        <FormGroup title="Дата" error={fieldErrors.date}>
          <InputDate selectedDate={workDate} onChange={date => setWorkDate(date)} />
        </FormGroup>
      </ModalBody>

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isLoading={isLoading}
          isLoadingText="Добавляю..."
          isValid={workName === '' || !workDate}
          clickHandler={submitRepairAdditionalWork}
        >
          Добавить
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

AddAdditionalWorkModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  repairId: PropTypes.number.isRequired,
};

export default connect()(AddAdditionalWorkModal);
