import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import InputDate from 'components/InputDate/InputDate';
import SelectForm from 'components/SelectForm/SelectForm';
import TextArea from 'components/TextArea/TextArea';
import InputGroup from 'components/InputGroup/InputGroup';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { addRepairProlong } from 'actions';

// Helpers
import { formatDateYYYYmmDD } from 'helpers/formatDateYYYYmmDD';
import { browseServer } from 'helpers/file-manager';

const AddProlongModal = ({ closeHandler, reasons, openModal, repairId, dispatch }) => {
  const [newDate, setNewDate] = useState(null);
  const [comment, setComment] = useState('');
  const [schedule, setSchedule] = useState(null);
  const [act, setAct] = useState(null);
  const [reason, setReason] = useState(
    reasons.map(item => ({ value: item.id, label: item.name }))[0],
  );
  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const selectSchedule = () => {
    browseServer({
      onSuccess: fileUrl => {
        setSchedule(fileUrl);
      },
    });
  };

  const selectAct = () => {
    browseServer({
      onSuccess: fileUrl => {
        setAct(fileUrl);
      },
    });
  };

  const submitProlong = () => {
    /** Показываем прелоадер на кнопку */
    setIsLoading(true);

    /** Записываем доп.работу в базу данных */
    dispatch(
      addRepairProlong(repairId, schedule, act, formatDateYYYYmmDD(newDate), reason.value, comment),
    )
      .then(() => {
        /** Скрываем прелоадер на кнопку */
        setIsLoading(false);

        /** Закрываем модалку */
        closeHandler();
      })
      .catch(error => {
        /** Если есть ошибки, выводим */
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        /** Скрываем прелоадер на кнопку */
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Добавление запроса на продление сроков ремонта" />

      <ModalBody>
        <FormGroup title="Новая дата окончания ремонта" error={fieldErrors.newdate}>
          <InputDate selectedDate={newDate} onChange={date => setNewDate(date)} />
        </FormGroup>

        <FormGroup title="Комментарий" error={fieldErrors.comment}>
          <TextArea value={comment} onChange={({ target }) => setComment(target.value)} />
        </FormGroup>

        <FormGroup title="Причина" error={fieldErrors.reason}>
          <SelectForm
            options={reasons.map(item => ({ value: item.id, label: item.name }))}
            selectedValue={reason}
            onChange={selectedOption => setReason(selectedOption)}
          />
        </FormGroup>

        <FormGroup title="График ремонта" error={fieldErrors.schedule}>
          <InputGroup onClick={selectSchedule} value={schedule} />
        </FormGroup>

        <FormGroup title="Акт расследования" error={fieldErrors.act}>
          <InputGroup onClick={selectAct} value={act} />
        </FormGroup>
      </ModalBody>

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button
          type="button"
          className="btn btn-danger modal-footer__btn-to-left"
          onClick={() => {
            closeHandler();
            openModal();
          }}
        >
          Перевод в прочий
        </button>

        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isValid={act === '' || schedule === '' || comment === '' || !newDate || !reason}
          isLoading={isLoading}
          isLoadingText="Добавляю..."
          clickHandler={submitProlong}
        >
          Добавить
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

AddProlongModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  repairId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  reasons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
};

export default connect()(AddProlongModal);
