import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Icons
import { FaEdit } from 'react-icons/fa';

// Components
import FormGroup from 'components/FormGroup/FormGroup';
import TextArea from 'components/TextArea/TextArea';
import SubmitButton from 'components/SubmitButton/SubmitButton';

const WorkWeek = ({ week, canEdit, fieldErrors, submit }) => {
  const [workWeekName, setWorkWeekName] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const saveWeekWork = () => {
    setIsLoading(true);
    submit(week.week, workWeekName).then(() => {
      setIsLoading(false);
      setIsEditing(false);
    });
  };

  return (
    <tr className="table-repair-works__week-tr info">
      <td>
        Работы на неделю <span className="label label_primary">{week.dates}</span>
      </td>

      {!week.name && (
        <td colSpan="2">
          <div className="table-repair-works__form">
            <FormGroup error={fieldErrors}>
              <TextArea
                cols={16}
                rows={4}
                value={workWeekName}
                onChange={({ target }) => setWorkWeekName(target.value)}
              />
            </FormGroup>
            <div className="table-repair-works__form-footer">
              <SubmitButton
                isValid={workWeekName === ''}
                isLoading={isLoading}
                isLoadingText="Добавляю..."
                clickHandler={saveWeekWork}
              >
                Добавить
              </SubmitButton>
            </div>
          </div>
        </td>
      )}

      {week.name && (
        <td colSpan="2">
          {isEditing ? (
            <div className="table-repair-works__hidden">
              <div className="table-repair-works__form">
                <FormGroup error={fieldErrors}>
                  <TextArea
                    cols={16}
                    rows={4}
                    value={workWeekName}
                    onChange={({ target }) => setWorkWeekName(target.value)}
                  />
                </FormGroup>
                <div className="table-repair-works__form-footer">
                  <SubmitButton
                    isValid={workWeekName === ''}
                    isLoading={isLoading}
                    isLoadingText="Сохраняю..."
                    clickHandler={saveWeekWork}
                  >
                    Сохранить
                  </SubmitButton>
                </div>
              </div>
            </div>
          ) : (
            <div className="table-repair-works__visible">{week.name}</div>
          )}
        </td>
      )}

      <td>
        {week.name && canEdit && week.canEdit && (
          <button
            type="button"
            className="btn btn-primary btn-xs"
            title="Редактировать"
            style={{ display: !isEditing ? 'block' : 'none' }}
            onClick={() => {
              setWorkWeekName(week.name);
              setIsEditing(true);
            }}
          >
            <FaEdit className="icon" />
          </button>
        )}
      </td>
    </tr>
  );
};

WorkWeek.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  week: PropTypes.shape({
    canEdit: PropTypes.bool,
    dates: PropTypes.string,
    name: PropTypes.string,
    week: PropTypes.number,
  }).isRequired,
  fieldErrors: PropTypes.arrayOf(PropTypes.shape({})),
};

WorkWeek.defaultProps = {
  fieldErrors: null,
};

export default WorkWeek;
