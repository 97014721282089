import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

// Store
import store from 'store/store';

// Components
import AccessRoute from 'pages/AccessComponent';
import Authorization from 'pages/Authorization/Authorization';
import Main from 'pages/Main/Main';
import Repair from 'pages/Repair/Repair';
import Employment from 'pages/Employment/Employment';
import Rmm from 'pages/Rmm/Rmm';
import RmmEmployment from 'pages/RmmEmployment/RmmEmployment';
import Dispatch from 'pages/Dispatch/Dispatch';
import Despatch from 'pages/Despatch/Despatch';
import Transport from 'pages/Transport/Transport';
import Drivers from 'pages/Drivers/Drivers';
import Filemanager from 'pages/FileManager/Filemanager';
import ReportAddWork from 'pages/ReportAddWork/ReportAddWork';
import ReportCoWork from 'pages/ReportCoWork/ReportCoWork';
import ReportAll from 'pages/ReportAll/ReportAll';
import Rules from 'pages/Rules/Rules';
import Mtr from 'pages/Mtr/Mtr';
import MtrList from 'pages/MtrList/MtrList';
import Admin from 'pages/Admin/Admin';
import AdminPeriod from 'pages/AdminPeriod/AdminPeriod';
import RepairAdmin from 'pages/RepairAdmin/RepairAdmin';
import AdminUsers from 'pages/AdminUsers/AdminUsers';
import AdminUser from 'pages/AdminUser/AdminUser';
import Header from 'components/Header/Header';
import BottomNavigationWrapper from 'components/BottomNavigationWrapper/BottomNavigationWrapper';

// Actions
import { getUser } from 'actions';

// Styles
import './index.scss';
import './App.scss';
import Tscontrol from "./pages/Tscontrol/Tscontrol";

store.dispatch(getUser()).catch(() => {});

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <div className="app">
        <div className="app__content">
          <AccessRoute isPrivate component={Header} />

          <Switch>
            <AccessRoute path="/" exact isPrivate={false} component={Authorization} />
            <AccessRoute path="/dashboard" isPrivate component={Main} />

            <AccessRoute path="/repair/:id" isPrivate component={Repair} />
            <AccessRoute path="/employment" isPrivate component={Employment} />

            {/* RMM - NRB */}
            <AccessRoute path="/rmm/employment" isPrivate component={RmmEmployment} />
            <AccessRoute
              path="/:rmmBase(rmm|nrb)/:page(\d+)/:isArchive(archive)?"
              exact
              isPrivate
              component={Rmm}
            />
            {/* RMM - NRB END */}

            <AccessRoute path="/dispatch/:archive?" isPrivate component={Dispatch} />
            <AccessRoute path="/report-add" isPrivate component={ReportAddWork} />
            <AccessRoute path="/report-co-work" isPrivate component={ReportCoWork} />
            <AccessRoute path="/report" isPrivate component={ReportAll} />
            <AccessRoute path="/rules" isPrivate component={Rules} />
            <AccessRoute path="/despatch/:id/details" exact isPrivate component={Despatch} />
            <AccessRoute path="/despatch/:page(\d+)?/:isArchive(archive)?" isPrivate component={Despatch} />

            <AccessRoute path="/transport-site" isPrivate component={Transport} />
            <AccessRoute path="/drivers/:id?" isPrivate component={Drivers} />
            <AccessRoute path="/tscontrol/:id?" isPrivate component={Tscontrol} />
            <AccessRoute path="/filemanager/" isPrivate component={Filemanager} />

            <AccessRoute path="/mtr/list/:page(\d+)" isPrivate component={MtrList} />
            <AccessRoute path="/mtr" isPrivate component={Mtr} />
            <AccessRoute
              path="/admin/period/:periodId/orderMan/:orderManId/ks/:ksId/month/:month/repair/:repairId"
              isPrivate
              component={RepairAdmin}
            />
            <AccessRoute path="/admin/period/:periodId" isPrivate component={AdminPeriod} />
            <AccessRoute path="/admin" isPrivate component={Admin} />

            <AccessRoute path="/admin-users/user/:userId" isPrivate component={AdminUser} />
            <AccessRoute path="/admin-users" isPrivate component={AdminUsers} />
          </Switch>

          <AccessRoute isPrivate component={BottomNavigationWrapper} />
        </div>
      </div>
    </Router>
  </Provider>,
  document.getElementById('root'),
);
