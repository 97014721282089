import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';

// Actions
import { toggleCollapseDispatchTableRows } from 'actions/index';

// Styles
import './TableDispatch.scss';

const TableDispatch = ({ dispatch, orderManToPeriod }) => {
  return (
    <table className="table table-bordered table-dispatch">
      <thead>
        <tr className="info">
          <th rowSpan="2">Работы</th>
          <th rowSpan="2">+/-</th>
          <th rowSpan="2">Текущие проблемы</th>
          <th colSpan="4">Узловой ремонт</th>
          <th rowSpan="2">Руководитель работ</th>
          <th rowSpan="2">Исполнители работ</th>
          <th colSpan="2">Сроки ремонта</th>
        </tr>
        <tr className="info">
          <th>Узел</th>
          <th>Дата поступления в РММ</th>
          <th>Дата отправки из РММ</th>
          <th>Дата отгрузки на КС</th>

          <th>Начало ремонта</th>
          <th>Окончание ремонта</th>
        </tr>
      </thead>

      <colgroup>
        <col style={{ width: '300px' }} />
        <col style={{ width: '41px' }} />
        <col style={{ width: '150px' }} />
        <col style={{ width: '112px' }} />
        <col style={{ width: '167px' }} />
        <col style={{ width: '153px' }} />
        <col style={{ width: '137px' }} />
        <col style={{ width: '247px' }} />
        <col style={{ width: '255px' }} />
        <col style={{ width: '116px' }} />
        <col style={{ width: '139px' }} />
      </colgroup>

      <tbody>
        {orderManToPeriod &&
          Array.isArray(orderManToPeriod) &&
          orderManToPeriod.length > 0 &&
          orderManToPeriod.map(orderMan => (
            <React.Fragment key={orderMan.id}>
              <tr className="table-dispatch__orderman-tr">
                <td colSpan="13" className="table-dispatch__orderman-td">
                  {orderMan.name}
                </td>
              </tr>

              {orderMan.ks &&
                Array.isArray(orderMan.ks) &&
                orderMan.ks.length > 0 &&
                orderMan.ks.map(ks => (
                  <React.Fragment key={ks.id}>
                    <tr className="table-dispatch__ks-tr">
                      <td colSpan="13" className="table-dispatch__ks-td">
                        <span className="label label_default">{ks.name}</span>
                      </td>
                    </tr>

                    {ks.repair &&
                      Array.isArray(ks.repair) &&
                      ks.repair.map(repair => (
                        <React.Fragment key={repair.id}>
                          <tr className="table-dispatch__repair-name-tr">
                            <td colSpan="13" className="table-dispatch__repair-name-td">
                              {repair.type_s} {repair.gpa} Ст. № {repair.stationnumber}
                            </td>
                          </tr>

                          {/* eslint-disable react/no-array-index-key */}
                          {repair.objects &&
                            Array.isArray(repair.objects) &&
                            repair.objects.map((obj, index) => (
                              <React.Fragment key={index}>
                                <tr
                                  className={cx({
                                    'table-dispatch__object-tr': repair.collapsed,
                                    'table-dispatch__object-tr_hidden':
                                      repair.collapsed && index + 1 > repair.countVisRows,
                                  })}
                                >
                                  <td className="table-dispatch__work-item">
                                    {obj.work && !obj.work.realdate_1 && (
                                      <React.Fragment>
                                        {obj.work.date_1} {obj.work.name}
                                      </React.Fragment>
                                    )}

                                    {obj.work && obj.work.realdate_1 && (
                                      <React.Fragment>
                                        {obj.work.realdate_1}{' '}
                                        <span className="label label_danger">
                                          работ не добавлено
                                        </span>
                                      </React.Fragment>
                                    )}
                                  </td>

                                  {index === 0 && (
                                    <td
                                      rowSpan={
                                        repair.collapsed ? repair.countVisRows : repair.count
                                      }
                                      className="table-dispatch__accordance"
                                    >
                                      {repair.accordance}
                                    </td>
                                  )}

                                  <td className="table-dispatch__problem-item">
                                    {obj.problems && obj.problems.solved && (
                                      <div className="table-dispatch__problem-item-solved">
                                        {obj.problems.date_1} {obj.problems.name}
                                      </div>
                                    )}

                                    {obj.problems && !obj.problems.solved && (
                                      <React.Fragment>
                                        {obj.problems.date_1} {obj.problems.name}
                                      </React.Fragment>
                                    )}
                                  </td>

                                  <td className="table-dispatch__node-name">
                                    {obj.node && obj.node.name}
                                  </td>
                                  <td className="table-dispatch__node-date1">
                                    {obj.node && obj.node.datereceipt_1}
                                  </td>
                                  <td className="table-dispatch__node-date2">
                                    {obj.node && obj.node.datesendreturn_1}
                                  </td>
                                  <td className="table-dispatch__node-date3">
                                    {obj.node && obj.node.datereceiptreturn_1}
                                  </td>

                                  {index === 0 && (
                                    <td
                                      rowSpan={
                                        repair.collapsed ? repair.countVisRows : repair.count
                                      }
                                      className={cx('table-dispatch__master', {
                                        dispatch__master_finished: repair.finished,
                                      })}
                                    >
                                      {repair.newmaster && (
                                        <React.Fragment>
                                          {repair.newmaster}
                                          <br />
                                          <s>{repair.master}</s>
                                        </React.Fragment>
                                      )}

                                      {!repair.newmaster && <div>{repair.master}</div>}

                                      {repair.master2 && (
                                        <React.Fragment>
                                          <br />
                                          <br />
                                          {repair.newmaster2 && (
                                            <React.Fragment>
                                              {repair.newmaster2}
                                              <br />
                                              <s>{repair.master2}</s>
                                            </React.Fragment>
                                          )}
                                          {!repair.newmaster2 && <div>{repair.master2}</div>}
                                          (второй мастер)
                                        </React.Fragment>
                                      )}
                                    </td>
                                  )}

                                  <td
                                    className={cx('table-dispatch__roster-item', {
                                      'table-dispatch__roster-item_finished': repair.finished,
                                    })}
                                  >
                                    <React.Fragment>
                                      {obj.roster && obj.roster.name} (
                                      {obj.roster && obj.roster.role})
                                    </React.Fragment>
                                  </td>

                                  {index === 0 && (
                                    <td
                                      rowSpan={
                                        repair.collapsed ? repair.countVisRows : repair.count
                                      }
                                      className="table-dispatch__datestart"
                                    >
                                      {repair.factdatestart_1}
                                    </td>
                                  )}

                                  {index === 0 && (
                                    <td
                                      rowSpan={
                                        repair.collapsed ? repair.countVisRows : repair.count
                                      }
                                      className="table-dispatch__dateend"
                                    >
                                      {repair.factdateend_1}
                                      {repair.prolongdate && (
                                        <React.Fragment>
                                          <br />
                                          Продлен до:
                                          <br />
                                          {repair.prolongdate_1}
                                        </React.Fragment>
                                      )}
                                    </td>
                                  )}
                                </tr>
                              </React.Fragment>
                            ))}
                          {/* eslint-enable react/no-array-index-key */}

                          {repair.collapsed && (
                            <tr>
                              <td colSpan="13">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() =>
                                    dispatch(
                                      toggleCollapseDispatchTableRows(repair.id, repair.collapsed),
                                    )
                                  }
                                >
                                  Показать более ранние работы
                                </button>
                              </td>
                            </tr>
                          )}

                          {!repair.collapsed &&
                            (repair.objects &&
                              Array.isArray(repair.objects) &&
                              repair.objects.length > repair.countVisRows) && (
                              <tr>
                                <td colSpan="13">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() =>
                                      dispatch(
                                        toggleCollapseDispatchTableRows(
                                          repair.id,
                                          repair.collapsed,
                                        ),
                                      )
                                    }
                                  >
                                    Свернуть
                                  </button>
                                </td>
                              </tr>
                            )}
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                ))}
            </React.Fragment>
          ))}
      </tbody>
    </table>
  );
};

TableDispatch.propTypes = {
  dispatch: PropTypes.func.isRequired,
  orderManToPeriod: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
      ks: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.number,
          repair: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              gpa: PropTypes.string,
              stationnumber: PropTypes.string,
              type_s: PropTypes.string,
              collapsed: PropTypes.bool,
              countVisRows: PropTypes.number,
              count: PropTypes.number,
              accordance: PropTypes.number,
              finished: PropTypes.bool,
              newmaster: PropTypes.string,
              master: PropTypes.string,
              master2: PropTypes.string,
              newmaster2: PropTypes.string,
              factdatestart_1: PropTypes.string,
              factdateend_1: PropTypes.string,
              prolongdate: PropTypes.string,
              prolongdate_1: PropTypes.string,
              objects: PropTypes.arrayOf(
                PropTypes.shape({
                  work: PropTypes.shape({
                    realdate_1: PropTypes.string,
                    date_1: PropTypes.string,
                    name: PropTypes.string,
                  }),
                  problems: PropTypes.shape({
                    solved: PropTypes.bool,
                    date_1: PropTypes.string,
                    name: PropTypes.string,
                  }),
                  node: PropTypes.shape({
                    name: PropTypes.string,
                    datereceipt_1: PropTypes.string,
                    datesendreturn_1: PropTypes.string,
                    datereceiptreturn_1: PropTypes.string,
                  }),
                  roster: PropTypes.shape({
                    name: PropTypes.string,
                    role: PropTypes.string,
                  }),
                }),
              ),
            }),
          ),
        }),
      ),
    }),
  ),
};

TableDispatch.defaultProps = {
  orderManToPeriod: null,
};

export default connect()(TableDispatch);
