import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import InputDate from 'components/InputDate/InputDate';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { changeRmmNodeDateForecast } from 'actions';

// Helpers
import { formatDateYYYYmmDD } from 'helpers/formatDateYYYYmmDD';

const ChangeForecastDateModal = ({ closeHandler, node, dispatch }) => {
  const [dateForecast, setDateForecast] = useState(new Date(Date.parse(node.dateforecast)));

  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const submitNodeDateForecast = () => {
    setIsLoading(true);

    dispatch(changeRmmNodeDateForecast(node.id, formatDateYYYYmmDD(dateForecast)))
      .then(() => {
        setIsLoading(false);

        closeHandler();
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Изменить дату" />

      <ModalBody>
        <FormGroup title="Ожидаемая дата отправки из ремонта" error={fieldErrors.dateforecast}>
          <InputDate selectedDate={dateForecast} onChange={date => setDateForecast(date)} />
        </FormGroup>
      </ModalBody>

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isLoading={isLoading}
          isLoadingText="Сохранение..."
          isValid={!dateForecast}
          clickHandler={submitNodeDateForecast}
        >
          Изменить
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

ChangeForecastDateModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number,
    dateforecast: PropTypes.string,
    dateforecast_1: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(ChangeForecastDateModal);
