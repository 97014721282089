import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import TableReportCoWork from 'components/TableReportCoWork/TableReportCoWork';
import Loader from 'components/Loader/Loader';

// Actions
import { getReportCoWorks } from 'actions';

const ReportCoWork = ({ dispatch, report }) => {
  const [isLoading, setIsLoading] = useState(true);

  /** Запрашиваем информацию по своду работ соисполниетелй */
  useEffect(() => {
    dispatch(getReportCoWorks())
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch]);

  return (
    <React.Fragment>
      {isLoading && <Loader className="loader_page" />}

      {!isLoading && report && (
        <div className="report-page">
          <div className="report-page__container container">
            <h1 className="report-page__title">
              Свод работ соисполнителей на {report.periodname} год
            </h1>

            <div className="report-page__content">
              <TableReportCoWork ordermantoperiod={report.ordermantoperiod} />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

ReportCoWork.propTypes = {
  dispatch: PropTypes.func.isRequired,
  report: PropTypes.shape({
    periodname: PropTypes.string,
    ordermantoperiod: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

ReportCoWork.defaultProps = {
  report: null,
};

const mapStateToProps = state => ({
  report: state.mainReducer.reportCoWorks,
});

export default connect(mapStateToProps)(ReportCoWork);
