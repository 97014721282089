import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

const ConfirmModal = ({ closeHandler, confirmAction, title, question, actionLabel }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseError, setResponseError] = useState(false);

  const submit = () => {
    setIsLoading(true);

    confirmAction()
      .then(() => {
        setIsLoading(false);

        closeHandler();
      })
      .catch(error => {
        if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader className="modal-header_has-sub-title">
        <h4 className="modal-header__title">{title}</h4>
        <h5 className="modal-header__sub-title">{question}</h5>
      </ModalHeader>

      {responseError && <ModalError>{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Отмена
        </button>

        <SubmitButton isLoading={isLoading} isLoadingText="Отправка..." clickHandler={submit}>
          {actionLabel}
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

ConfirmModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  confirmAction: PropTypes.func.isRequired,
  actionLabel: PropTypes.string.isRequired,
};

export default connect()(ConfirmModal);
