import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import InputDate from 'components/InputDate/InputDate';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { setOpenRepair, getRepairInfo } from 'actions';

// Helpers
import { formatDateYYYYmmDD } from 'helpers/formatDateYYYYmmDD';

const OpenRepairModal = ({ closeHandler, dispatch, repairId }) => {
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const submitOpenRepair = () => {
    /** Показываем прелоадер на кнопку */
    setIsLoading(true);

    /** Записываем открытие ремонта в базу данных */
    dispatch(setOpenRepair(repairId, formatDateYYYYmmDD(dateStart), formatDateYYYYmmDD(dateEnd)))
      .then(() => {
        /** Запрашиваем обновленную информацию по ремонту */
        return dispatch(getRepairInfo(repairId));
      })
      .then(() => {
        /** Скрываем прелоадер на кнопку */
        setIsLoading(false);

        /** Закрываем модалку */
        closeHandler();
      })
      .catch(error => {
        /** Если есть ошибки, выводим */
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        /** Скрываем прелоадер на кнопку */
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Открыть ремонт" />

      <ModalBody>
        <FormGroup title="Фактическая дата начала" error={fieldErrors.datestart}>
          <InputDate selectedDate={dateStart} onChange={date => setDateStart(date)} />
        </FormGroup>

        <FormGroup title="Фактическая дата окончания" error={fieldErrors.dateend}>
          <InputDate selectedDate={dateEnd} onChange={date => setDateEnd(date)} />
        </FormGroup>
      </ModalBody>

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isValid={!dateStart || !dateEnd}
          isLoading={isLoading}
          isLoadingText="Открытие..."
          clickHandler={submitOpenRepair}
        >
          Открыть
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

OpenRepairModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  repairId: PropTypes.number.isRequired,
};

export default connect()(OpenRepairModal);
