import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// Icons
import { FaCircleNotch } from 'react-icons/fa';

// Styles
import './Loader.scss';

const Loader = ({ className, color }) => {
  return (
    <div
      className={cx('loader', {
        [className]: className,
        [`loader_${color}`]: color,
      })}
    >
      <div className="loader__icon-wrap">
        <FaCircleNotch className="icon" color={color} />
        <div className="loader__text">загрузка</div>
      </div>
    </div>
  );
};

Loader.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

Loader.defaultProps = {
  className: null,
  color: null,
};

export default Loader;
