import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const GpaObjectAdmin = ({ repair, url, orderManId, ksId, month }) => {
  return (
    <Link
      to={`${url}/orderMan/${orderManId}/ks/${ksId}/month/${month}/repair/${repair.id}`}
      className="gpa-object"
    >
      <p className="gpa-object__name">{repair.gpa}</p>
      <p className="gpa-object__number">
        Ст. № {repair.stationnumber} {repair.mark && `(${repair.mark})`}
      </p>
      <p className="gpa-object__dates">
        {repair.datestart_1} - {repair.dateend_1}
      </p>
      <p className="gpa-object__type">
        <span className={`label label_${repair.labelclass}`}>{repair.type_s}</span>{' '}
        {repair.outofplan && <span className="label label_black">Вп</span>}
      </p>
    </Link>
  );
};

GpaObjectAdmin.propTypes = {
  url: PropTypes.string.isRequired,
  orderManId: PropTypes.number.isRequired,
  ksId: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  repair: PropTypes.shape({
    id: PropTypes.number,
    gpa: PropTypes.string,
    stationnumber: PropTypes.string,
    mark: PropTypes.string,
    datestart_1: PropTypes.string,
    dateend_1: PropTypes.string,
    labelclass: PropTypes.string,
    type_s: PropTypes.string,
    outofplan: PropTypes.bool,
  }).isRequired,
};

export default GpaObjectAdmin;
