import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Icons
import {
  FaUpload,
  FaPaperclip,
  FaDownload,
  FaExchangeAlt,
  FaCheck,
  FaCommentAlt,
  FaServer,
} from 'react-icons/fa';

// Components
import ProgressBar from 'components/ProgressBar/ProgressBar';
import Modal from 'components/Modal/Modal';
import ConfirmDateModal from 'components/ConfirmDateModal/ConfirmDateModal';
import CommentsNodeModal from 'components/CommentsNodeModal/CommentsNodeModal';
import ReceiptNodeModal from 'components/ReceiptNodeModal/ReceiptNodeModal';
import DocsNodeModal from 'components/DocsNodeModal/DocsNodeModal';
import PercentNodeModal from 'components/PercentNodeModal/PercentNodeModal';

// Actions
import { resetRepairNodeUnreadCount, updateActRepairNode } from 'actions';

// Misc
import { browseServer } from 'helpers/file-manager';
import { ModalTypes } from 'components/TableRmmRow/TableRmmRow';

// Styles
import './TableRepairNode.scss';

const TableRepairNode = ({ nodes, canEdit, dispatch, repairId, userRole }) => {
  const [activeModal, setActiveModal] = useState(null);
  const [currentNodeId, setCurrentNodeId] = useState(null);

  const selectFileForAct = nodeId => {
    browseServer({
      onSuccess: fileUrl => {
        dispatch(updateActRepairNode(fileUrl, nodeId, repairId)).catch(() => {});
      },
    });
  };

  const activateModal = (nodeId, modalType) => {
    setCurrentNodeId(nodeId);
    setActiveModal(modalType);
  };

  const deactivateModal = () => {
    setActiveModal(null);
    setCurrentNodeId(null);
  };

  return (
    <table className="table-repair-node table table-bordered table-striped">
      <thead>
        <tr>
          <th>Узел</th>
          <th>
            <abbr title="Дата отправки из КС">
              <FaUpload className="icon" /> из КС
            </abbr>
          </th>
          <th>
            <abbr title="Акт передачи в ремонт">
              <FaPaperclip className="icon" /> Акт
            </abbr>
          </th>
          <th>
            <abbr title="Дата поступления в РММ">
              <FaDownload className="icon" /> в РММ
            </abbr>
          </th>
          <th>
            <abbr title="Ожидаемая дата отправки из ремонта">
              <FaExchangeAlt className="icon" /> Прогноз
            </abbr>
          </th>
          <th>
            <abbr title="График ремонта">
              <FaPaperclip className="icon" /> График
            </abbr>
          </th>
          <th>
            <abbr title="Процент готовности">
              <FaServer className="icon" /> Процент
            </abbr>
          </th>
          <th>
            <abbr title="Дата отправки из РММ">
              <FaUpload className="icon" /> из РММ
            </abbr>
          </th>
          <th>
            <abbr title="Сопроводительные документы">
              <FaPaperclip className="icon" /> Док.
            </abbr>
          </th>
          <th>
            <abbr title="Дата отгрузки на КС">
              <FaCheck className="icon" /> в КС
            </abbr>
          </th>
          <th>
            <abbr title="Примечания">
              <FaCommentAlt className="icon" /> Прим.
            </abbr>
          </th>
        </tr>
      </thead>
      <tbody>
        {nodes.map(node => (
          <tr key={node.id}>
            <td className="table-repair-node__td">{node.name}</td>

            <td className="table-repair-node__td">
              <span className="label label_default">{node.datesend_1}</span>
            </td>

            <td className="table-repair-node__td">
              <div className="upload-docs">
                {Boolean(node.shortact && node.act) && (
                  <React.Fragment>
                    <a
                      href={node.act}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="label label_info"
                    >
                      {node.shortact}
                    </a>{' '}
                    <button
                      type="button"
                      className="btn btn-xs btn-primary"
                      onClick={() => {
                        selectFileForAct(node.id);
                      }}
                    >
                      <FaPaperclip className="icon" />
                    </button>
                  </React.Fragment>
                )}
              </div>
            </td>

            <td className="table-repair-node__td">
              {node.datereceipt_1 && (
                <span className="label label_success">{node.datereceipt_1}</span>
              )}
            </td>

            <td className="table-repair-node__td">
              {node.datereceipt_1 && (
                <>
                  {!node.dateforecast_confirm && canEdit && node.dateforecast_1 && (
                    <button
                      type="button"
                      className="label label_warning"
                      onClick={() => activateModal(node.id, ModalTypes.ConfirmReceive)}
                    >
                      {node.dateforecast_1}
                    </button>
                  )}

                  {node.dateforecast_confirm && node.dateforecast_1 && (
                    <span className="label label_success">{node.dateforecast_1} </span>
                  )}
                </>
              )}
            </td>

            <td className="table-repair-node__td">
              {node.datereceipt_1 && (
                <>
                  {!node.schedule && (
                    <span className="label label_danger">График не прикреплен</span>
                  )}

                  {Boolean(node.schedule && node.shortschedule) && (
                    <a
                      href={node.schedule}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="label label_info"
                    >
                      {node.shortschedule}
                    </a>
                  )}
                </>
              )}
            </td>

            <td className="table-repair-node__td">
              <ProgressBar
                status={node.status}
                percent={node.percent}
                date={node.datereceipt_1}
                handleOpenModal={() => activateModal(node.id, ModalTypes.Percent)}
              />
            </td>

            <td className="table-repair-node__td">
              <span className="label label_success">{node.datesendreturn_1}</span>
            </td>

            <td className="table-repair-node__td">
              {node.datesendreturn_1 && (
                <button
                  type="button"
                  className="table-repair-node__docs-btn"
                  onClick={() => activateModal(node.id, ModalTypes.Docs)}
                >
                  <FaServer className="icon" />
                </button>
              )}
            </td>

            <td className="table-repair-node__td">
              {node.datereceiptreturn_1 && (
                <React.Fragment>
                  <span className="label label_success">{node.datereceiptreturn_1}</span>
                  <br />

                  {node.actreceipt && (
                    <React.Fragment>
                      <a
                        href={node.actreceipt}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="label label_info"
                      >
                        Акт приемки
                      </a>
                      <br />
                    </React.Fragment>
                  )}

                  {node.naklad && (
                    <React.Fragment>
                      <a
                        href={node.naklad}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="label label_info"
                      >
                        Накладная
                      </a>
                      <br />
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}

              {Boolean(node.datesendreturn_1 && !node.datereceiptreturn_1) && (
                <button
                  type="button"
                  className="btn-success btn btn-sm"
                  onClick={() => activateModal(node.id, ModalTypes.ReceiveFromRmm)}
                >
                  Принять
                </button>
              )}
            </td>

            <td className="table-repair-node__td">
              {node.unreadCount === 0 && (
                <button
                  type="button"
                  className="btn btn-xs btn-success btn-comments"
                  onClick={() => activateModal(node.id, ModalTypes.Comments)}
                >
                  Прим
                </button>
              )}

              {node.unreadCount > 0 && (
                <button
                  type="button"
                  className="btn btn-xs btn-danger btn-comments"
                  onClick={() => {
                    activateModal(node.id, ModalTypes.Comments);
                    dispatch(resetRepairNodeUnreadCount(node.id));
                  }}
                >
                  Прим <span className="badge">{node.unreadCount}</span>
                </button>
              )}
            </td>
          </tr>
        ))}

        {activeModal === ModalTypes.ConfirmReceive && currentNodeId && canEdit && (
          <Modal title="Подтверждение даты" closeHandler={deactivateModal}>
            <ConfirmDateModal
              repairId={repairId}
              currentNodeId={currentNodeId}
              closeHandler={deactivateModal}
            />
          </Modal>
        )}

        {activeModal === ModalTypes.Comments && currentNodeId && (
          <Modal title="Примечания" closeHandler={deactivateModal}>
            <CommentsNodeModal closeHandler={deactivateModal} canEdit nodeId={currentNodeId} />
          </Modal>
        )}

        {activeModal === ModalTypes.ReceiveFromRmm && currentNodeId && (
          <Modal title="Принять узел" closeHandler={deactivateModal}>
            <ReceiptNodeModal
              repairId={repairId}
              currentNodeId={currentNodeId}
              closeHandler={deactivateModal}
            />
          </Modal>
        )}

        {activeModal === ModalTypes.Docs && currentNodeId && (
          <Modal title="Сопроводительные документы" closeHandler={deactivateModal}>
            <DocsNodeModal
              closeHandler={deactivateModal}
              node={nodes.find(n => n.id === currentNodeId)}
              userRole={userRole}
              canEdit={false}
            />
          </Modal>
        )}

        {activeModal === ModalTypes.Percent && currentNodeId && (
          <Modal title="Статус ремонта" closeHandler={deactivateModal}>
            <PercentNodeModal
              closeHandler={deactivateModal}
              node={nodes.find(n => n.id === currentNodeId)}
              canEdit={false}
            />
          </Modal>
        )}
      </tbody>
    </table>
  );
};

TableRepairNode.propTypes = {
  dispatch: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  userRole: PropTypes.number.isRequired,
  repairId: PropTypes.number.isRequired,
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      datesend_1: PropTypes.string,
      act: PropTypes.string,
      shortact: PropTypes.string,
      datereceipt_1: PropTypes.string,
      dateforecast_confirm: PropTypes.bool,
      dateforecast_1: PropTypes.string,
      schedule: PropTypes.string,
      shortschedule: PropTypes.string,
      status: PropTypes.string,
      percent: PropTypes.number,
      datesendreturn_1: PropTypes.string,
      datereceiptreturn_1: PropTypes.string,
      actreceipt: PropTypes.string,
      naklad: PropTypes.string,
      unreadCount: PropTypes.number,
    }),
  ).isRequired,
};

export default connect()(TableRepairNode);
