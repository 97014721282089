import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

// Components
import BottomNavigation from 'components/BottomNavigation/BottomNavigation';
import Loader from 'components/Loader/Loader';

// Actions
import { getNotifications } from 'actions';

// Styles
import './BottomNavigationWrapper.scss';

const BottomNavigationWrapper = ({ user, dispatch, notifications }) => {
  const [isLoading, setIsLoading] = useState(true);

  /** Получаем инфу по меню внизу сайта */
  useEffect(() => {
    dispatch(getNotifications())
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch]);

  return (
    <div
      className={cx('bottom-navigation-wrapper', {
        'bottom-navigation-wrapper_loaded': !isLoading,
      })}
    >
      {isLoading && <Loader className="loader_mini" color="white" />}
      {!isLoading && (
        <BottomNavigation userRole={user.userRole} canAddTask notifications={notifications} />
      )}
    </div>
  );
};

BottomNavigationWrapper.propTypes = {
  user: PropTypes.shape({
    userRole: PropTypes.number,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  notifications: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  user: state.mainReducer.user,
  notifications: state.mainReducer.notifications,
});

export default connect(mapStateToProps)(BottomNavigationWrapper);
