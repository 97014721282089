import React from 'react';
import PropTypes from 'prop-types';

// Components
import FormGroup from 'components/FormGroup/FormGroup';
import InputForm from 'components/InputForm/InputForm';

const UsersSearch = ({ value, changeHandler, placeholder }) => {
  return (
    <div>
      <FormGroup>
        <InputForm type="text" value={value} onChange={changeHandler} placeholder={placeholder} />
      </FormGroup>
    </div>
  );
};

UsersSearch.propTypes = {
  value: PropTypes.string.isRequired,
  changeHandler: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default UsersSearch;
