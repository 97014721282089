import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import TableRmm from 'components/TableRmm/TableRmm';
import Modal from 'components/Modal/Modal';
import Pagination from 'components/Pagination/Pagination';
import AddNodeSelfModal from 'components/AddNodeSelfModal/AddNodeSelfModal';
import Loader from 'components/Loader/Loader';

// Actions
import { getRmm, uploadRMMDocument } from 'actions';

// Misc
import { browseServer } from 'helpers/file-manager';

// Styles
import './Rmm.scss';

const Rmm = ({ rmmData, dispatch, user, page, isArchive, rmmBase }) => {
  const [isAddNodeSelfActive, setAddNodeSelfActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  /** Запрашиваем информацию по РММ */
  useEffect(() => {
    setIsLoading(true);

    dispatch(getRmm(rmmBase, isArchive, page))
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch, rmmBase, isArchive, page]);

  const activateAddNodeSelfModal = () => {
    setAddNodeSelfActive(true);
  };

  const deactivateAddNodeSelfModal = () => {
    setAddNodeSelfActive(false);
  };

  const selectFileForDocument = (type, nodeId) => {
    browseServer({
      onSuccess: fileUrl => {
        dispatch(uploadRMMDocument(nodeId, type, fileUrl)).catch(() => {});
      },
    });
  };

  return (
    <React.Fragment>
      {isLoading && <Loader className="loader_page" />}

      {!isLoading && rmmData && (
        <div className="rmm">
          <div className="rmm__container container">
            {rmmBase === 'rmm' && <h1 className="rmm__title">СРБ</h1>}

            {rmmBase === 'nrb' && <h1 className="rmm__title">НРБ</h1>}

            <div className="rmm__menu">
              <Link
                to={isArchive ? `/${rmmBase}/0` : `/${rmmBase}/0/archive`}
                className="btn btn-large btn-primary"
              >
                {isArchive ? 'Текущие ремонты' : 'Архив'}
              </Link>{' '}
              {rmmData.canGoToEmployment && (
                <React.Fragment>
                  <Link to="/rmm/employment" className="btn btn-large btn-primary">
                    Занятость работников РММ
                  </Link>{' '}
                  <a
                    href={`${process.env.REACT_APP_API_URL}excelreports/_armm/`}
                    download
                    className="btn btn-large btn-primary"
                  >
                    Скачать сетевой график
                  </a>{' '}
                </React.Fragment>
              )}
            </div>

            <div className="rmm__btn-wrap">
              <button type="button" className="btn btn-primary" onClick={activateAddNodeSelfModal}>
                Добавить узел
              </button>
            </div>

            {isAddNodeSelfActive && (
              <Modal title="Добавление узла" closeHandler={deactivateAddNodeSelfModal}>
                <AddNodeSelfModal rmmId={rmmData.rmmId} closeHandler={deactivateAddNodeSelfModal} />
              </Modal>
            )}

            {rmmData.nodes && Array.isArray(rmmData.nodes) && rmmData.nodes.length > 0 ? (
              <div className="rmm__content">
                <TableRmm
                  nodes={rmmData.nodes}
                  canSetPriority={rmmData.canSetPriority}
                  canEdit={rmmData.canEdit}
                  userRole={user.userRole}
                  selectFileForDocument={selectFileForDocument}
                />

                <Pagination
                  pagesCount={rmmData.pagesCount}
                  currentPage={page}
                  asLinks
                  linkTemplate={`/${rmmBase}/%%PAGE%%${isArchive ? '/archive' : ''}`}
                />
              </div>
            ) : (
              <div className="rmm__notify">Нет узлов в ремонте</div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

Rmm.propTypes = {
  page: PropTypes.number,
  rmmBase: PropTypes.string,
  isArchive: PropTypes.bool,
  user: PropTypes.shape({
    userRole: PropTypes.number,
  }).isRequired,
  rmmData: PropTypes.shape({
    canGoToEmployment: PropTypes.bool,
    canSetPriority: PropTypes.bool,
    canEdit: PropTypes.bool,
    rmmId: PropTypes.number,
    pagesCount: PropTypes.number,
    nodes: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

Rmm.defaultProps = {
  page: 0,
  rmmBase: 'default',
  isArchive: false,
};

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  return {
    page: match && match.params ? parseInt(match.params.page, 10) : 0,
    isArchive: match && match.params ? Boolean(match.params.isArchive) : false,
    rmmBase: match && match.params ? match.params.rmmBase : false,
    rmmData: state.mainReducer.rmmData,
    user: state.mainReducer.user,
  };
};

export default connect(mapStateToProps)(Rmm);
