import { replaceSlashWithBackSlash } from 'helpers/replace-slash-to-back-slash';

/* eslint-disable import/prefer-default-export */
export const generateMtrUrl = (page, filterOptions) => {
  let url = `${process.env.REACT_APP_API_URL}mtr/_alist_p${page}?`;

  if (filterOptions) {
    url =
      filterOptions.gpa && filterOptions.gpa.value
        ? `${url}gpa=${replaceSlashWithBackSlash(filterOptions.gpa.label)}&`
        : `${url}`;

    url =
      filterOptions.object && filterOptions.object.value
        ? `${url}object=${filterOptions.object.value}&`
        : `${url}`;

    url = filterOptions.uns
      ? `${url}uns=${replaceSlashWithBackSlash(filterOptions.uns)}&`
      : `${url}`;

    url =
      filterOptions.nomenclature && filterOptions.nomenclature.value
        ? `${url}nomenklatura=${filterOptions.nomenclature.value}&`
        : `${url}`;

    url =
      filterOptions.delivery && filterOptions.delivery.value
        ? `${url}delivery=${replaceSlashWithBackSlash(filterOptions.delivery.label)}&`
        : `${url}`;

    url =
      filterOptions.specExist && filterOptions.specExist.value
        ? `${url}specexist=${replaceSlashWithBackSlash(filterOptions.specExist.value)}&`
        : `${url}`;

    url = filterOptions.received ? `${url}received=1&` : `${url}`;

    url = filterOptions.included ? `${url}included=1&` : `${url}`;

    url = filterOptions.charged ? `${url}charged=1&` : `${url}`;
  }

  return url;
};
