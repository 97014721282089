import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Modal from 'components/Modal/Modal';
import TaskInInfoModal from 'components/TaskInInfoModal/TaskInInfoModal';
import AddTaskCommentModal from 'components/AddTaskCommentModal/AddTaskCommentModal';

// Actions
import { resetTaskUnreadCount } from 'actions';

// Styles
import './TasksList.scss';

const ModalTypes = {
  TaskInInfo: 0,
  Comments: 1,
};

const TasksList = ({ tasks, dispatch, canAddTask }) => {
  const [activeModal, setActiveModal] = useState(null);

  const [currentTaskId, setCurrentTaskId] = useState(null);
  const [currentTask, setCurrentTask] = useState(null);

  const activateModal = (taskId, modalType) => {
    setCurrentTaskId(taskId);
    setActiveModal(modalType);
  };

  const deactivateModal = () => {
    setCurrentTaskId(null);
    setActiveModal(null);
  };

  useEffect(() => {
    if (currentTaskId) {
      setCurrentTask(tasks.find(item => item.id === currentTaskId));
    } else {
      setCurrentTask(null);
    }
  }, [currentTaskId, tasks]);

  return (
    <>
      <ul className="tasks-list">
        {Array.isArray(tasks) &&
          tasks.map(task => (
            <li key={task.id} className="tasks-list__item">
              <div className="tasks-list__item-info">
                {canAddTask && task.date_1 && (
                  <>
                    <span className="label label_primary">{task.date_1}</span> -{' '}
                  </>
                )}

                {canAddTask && task.dateend_1 && (
                  <>
                    <span className="label label_danger">{task.dateend_1}</span> -{' '}
                  </>
                )}

                {!canAddTask && task.date_1 && (
                  <>
                    <span className="label label_danger">{task.date_1}</span> -{' '}
                  </>
                )}

                <button
                  type="button"
                  className="tasks-list__item-link"
                  onClick={() => {
                    activateModal(task.id, ModalTypes.TaskInInfo);
                  }}
                >
                  {task.name} {task.done && '(Выполнена, ждем подтверждения)'}
                </button>
              </div>

              <div className="tasks-list__item-tools">
                {canAddTask && (
                  <>
                    {task.unreadCount === 0 && (
                      <button
                        type="button"
                        className="tasks-list__item-btn btn btn-xs btn-success btn-comments"
                        onClick={() => activateModal(task.id, ModalTypes.Comments)}
                      >
                        Прим
                      </button>
                    )}

                    {task.unreadCount > 0 && (
                      <button
                        type="button"
                        className="tasks-list__item-btn btn btn-xs btn-danger btn-comments"
                        onClick={() => {
                          activateModal(task.id, ModalTypes.Comments);
                          dispatch(resetTaskUnreadCount(task.id, 'toMe'));
                        }}
                      >
                        Прим <span className="badge">{task.unreadCount}</span>
                      </button>
                    )}
                  </>
                )}

                <span className="tasks-list__item-user label label_danger">
                  {task.authorname_1} ({task.authorrole})
                </span>
              </div>
            </li>
          ))}
      </ul>

      {activeModal === ModalTypes.TaskInInfo && currentTask && (
        <Modal
          title={`Задача №${currentTaskId} «${currentTask.name}»`}
          closeHandler={deactivateModal}
        >
          <TaskInInfoModal task={currentTask} closeHandler={deactivateModal} />
        </Modal>
      )}

      {activeModal === ModalTypes.Comments && currentTask && (
        <Modal title="Примечания" closeHandler={deactivateModal}>
          <AddTaskCommentModal taskId={currentTaskId} closeHandler={deactivateModal} />
        </Modal>
      )}
    </>
  );
};

TasksList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  canAddTask: PropTypes.bool.isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      date_1: PropTypes.string,
      dateend_1: PropTypes.string,
      name: PropTypes.string,
      done: PropTypes.bool,
      unreadCount: PropTypes.number,
      authorname_1: PropTypes.string,
      authorrole: PropTypes.string,
    }),
  ),
};

TasksList.defaultProps = {
  tasks: null,
};

export default connect()(TasksList);
