import React from 'react';
import PropTypes from 'prop-types';

// Components
import Checkbox from 'components/Checkbox/Checkbox';

// Icons
import { FaPaperclip, FaTrashAlt } from 'react-icons/fa';

export const docsType = PropTypes.shape({
  authorname: PropTypes.string,
  datetime_1: PropTypes.string,
  file: PropTypes.string,
  shortfile: PropTypes.string,
  noneed: PropTypes.bool,
});

const DocSingleRow = ({
  canEdit,
  isAllowedToEditNoNeed,
  doc,
  docName,
  docType,
  selectFileForDocument,
  changeRepairDocNoNeed,
  deleteFileForDocument,
}) => {
  return (
    <tr>
      <td>{docName}</td>
      <td>{doc.authorname && <span className="label label_default">{doc.authorname}</span>}</td>
      <td>{doc.datetime_1 && <span className="label label_default">{doc.datetime_1}</span>}</td>
      <td>
        <div className="upload-docs">
          {canEdit && (
            <React.Fragment>
              <button
                type="button"
                className="btn btn-xs btn-primary"
                onClick={() => {
                  selectFileForDocument(docType);
                }}
              >
                <FaPaperclip className="icon" />
              </button>{' '}
            </React.Fragment>
          )}

          {Boolean(doc.shortfile && doc.file) && (
            <React.Fragment>
              <a
                href={doc.file}
                target="_blank"
                rel="noopener noreferrer"
                className="label label_info"
              >
                {doc.shortfile}
              </a>{' '}
              {canEdit && (
                <button
                  type="button"
                  className="btn btn-danger btn-xs"
                  onClick={() => {
                    deleteFileForDocument(docType);
                  }}
                >
                  <FaTrashAlt className="icon" />
                </button>
              )}
            </React.Fragment>
          )}
        </div>
      </td>

      {isAllowedToEditNoNeed && (
        <td>
          <Checkbox
            checked={doc.noneed}
            onClick={() => {
              changeRepairDocNoNeed(docType, !doc.noneed);
            }}
            ariaLabel={docName}
          />
        </td>
      )}
    </tr>
  );
};

DocSingleRow.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  isAllowedToEditNoNeed: PropTypes.bool.isRequired,
  doc: docsType.isRequired,
  docName: PropTypes.string.isRequired,
  docType: PropTypes.oneOf([
    'lopata',
    'schedule',
    'actin',
    'vedbefore',
    'vedwork',
    'vedreplace',
    'actout',
    'vedafter',
    'formular',
    'ks6',
    'actquality',
  ]).isRequired,
  selectFileForDocument: PropTypes.func.isRequired,
  changeRepairDocNoNeed: PropTypes.func.isRequired,
  deleteFileForDocument: PropTypes.func.isRequired,
};

export default DocSingleRow;
