import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import TableRepairAddWorks from 'components/TableRepairAddWorks/TableRepairAddWorks';
import Modal from 'components/Modal/Modal';
import AddAdditionalWorkModal from 'components/AddAdditionalWorkModal/AddAdditionalWorkModal';
import Loader from 'components/Loader/Loader';
import Warning from 'components/Warning/Warning';

// Actions
import { getRepairAddWorks } from 'actions';

const RepairAdd = ({ canEdit, dispatch, repairAdditionalWorks, repairId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAddAdditionalWorkActive, setAddAdditionalWorkActive] = useState(false);

  const activateAddAdditionalWorkModal = () => {
    setAddAdditionalWorkActive(true);
  };

  const deactivateAddAdditionalWorkModal = () => {
    setAddAdditionalWorkActive(false);
  };

  /** Запрашиваем информацию по ремонту (Текущие проблемы) */
  useEffect(() => {
    dispatch(getRepairAddWorks(repairId))
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch, repairId]);

  return (
    <React.Fragment>
      <div className="repair-content__header">
        <h2 className="repair-content__title">Дополнительные работы</h2>

        {canEdit && (
          <div className="repair-content__tools">
            <button
              type="button"
              className="btn btn-primary"
              onClick={activateAddAdditionalWorkModal}
            >
              Добавить работу
            </button>

            {isAddAdditionalWorkActive && (
              <Modal title="Добавление работы" closeHandler={deactivateAddAdditionalWorkModal}>
                <AddAdditionalWorkModal
                  closeHandler={deactivateAddAdditionalWorkModal}
                  repairId={repairId}
                />
              </Modal>
            )}
          </div>
        )}
      </div>

      {isLoading && <Loader />}

      {!isLoading &&
        repairAdditionalWorks &&
        Array.isArray(repairAdditionalWorks) &&
        repairAdditionalWorks.length > 0 && (
          <TableRepairAddWorks
            canEdit={canEdit}
            works={repairAdditionalWorks}
            repairId={repairId}
          />
        )}

      {!isLoading &&
        repairAdditionalWorks &&
        Array.isArray(repairAdditionalWorks) &&
        repairAdditionalWorks.length === 0 && (
          <Warning msg="Пока не добавлено дополнительных работ" />
        )}
    </React.Fragment>
  );
};

RepairAdd.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  repairId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  repairAdditionalWorks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = state => ({
  repairAdditionalWorks: state.mainReducer.repairAdditionalWorks,
});

export default connect(mapStateToProps)(RepairAdd);
