import { array, arrayOf, bool, number, string, shape, oneOfType, object } from 'prop-types'; // eslint-disable-line no-unused-vars

export const despatchInfo = shape({
  canEdit: bool,
  canSee: bool,
  id: oneOfType([number, string]),
  parent: oneOfType([number, string]),
  blockparent: oneOfType([number, string]),
  sort: oneOfType([number, string]),
  visible: oneOfType([number, string]),
  assignedCarId: string,
  assignedCarNumber: string,
  assignedDriver: string,
  assignedDriver_1: string,
  isAdditional: bool,
  isPdoApproved: bool,
  isOmtsApproved: bool,
  isCompleted: bool,
  isShipped: bool,
  dateCreation: string,
  dateCreation_1: string,
  dateCreation_2: string,
  dateReady: string,
  dateReady_1: string,
  dateReady_2: string,
  dateStart: string,
  dateStart_1: string,
  dateStart_2: string,
  dateEnd: string,
  dateEnd_1: string,
  dateEnd_2: string,
  dateFact: string,
  dateFact_1: string,
  dateFact_2: string,
  startLocation: shape({
    id: oneOfType([number, string]),
    ksid: string,
    object: string,
    city: string,
    country: string,
    isCustom: string,
    latitude: number,
    longitude: number,
    postCode: string,
    firstStreet: string,
    homeNumber: string,
    county: string,
  }),
  destLocation: shape({
    id: oneOfType([number, string]),
    ksid: string,
    object: string,
    city: string,
    country: string,
    isCustom: string,
    latitude: number,
    longitude: number,
    postCode: string,
    firstStreet: string,
    homeNumber: string,
    county: string,
  }),
  size: string,
  weight: string,
  nomenclature: string,
  nomenclatureUrl: string,
  shortNomenclature: string,
  urgency: string,
  status: string,
  other: string,
  createdBy: string,
  createdByFullName: string,
  responsible: string,
  responsibleFullName: string,
  parentId: string,
  recipient: string,
  recipientFullName: string,
  active: string,
  additionalDespatches: arrayOf(shape({})),
});

export const transportData = shape({
  carNumber: string,
  id: oneOfType([number, string]),
  liftingCapacity: oneOfType([number, string]),
  name: string,
  passengers: oneOfType([number, string]),
  status: string,
  visible: oneOfType([number, string]),
});

export const driverData = shape({
  id: number,
  name: string,
  status: string,
  assignedCar: shape({
    id: number,
    carNumber: string,
    name: string,
    passengers: string,
    liftingCapacity: string,
    status: string,
  }),
});

export default {};
