import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './ModalBody.scss';

const ModalBody = ({ children, title }) => {
  return (
    <div className="modal-body">
      {title && <h4 className="modal-body__title">{title}</h4>}
      {children}
    </div>
  );
};

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

ModalBody.defaultProps = {
  title: null,
};

export default ModalBody;
