import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import InputForm from 'components/InputForm/InputForm';
import InputDate from 'components/InputDate/InputDate';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { addRmmNode } from 'actions';

// Helpers
import { formatDateYYYYmmDD } from 'helpers/formatDateYYYYmmDD';

const AddNodeSelfModal = ({ closeHandler, dispatch, rmmId }) => {
  const [nodeName, setNodeName] = useState('');
  const [dateReceipt, setDateReceipt] = useState(null);
  const [dateForecast, setDateForecast] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const submitCustomNode = () => {
    setIsLoading(true);

    dispatch(
      addRmmNode(
        nodeName,
        formatDateYYYYmmDD(dateReceipt),
        formatDateYYYYmmDD(dateForecast),
        rmmId,
      ),
    )
      .then(() => {
        setIsLoading(false);

        closeHandler();
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Добавление узла" />

      <ModalBody>
        <FormGroup title="Узел" error={fieldErrors.name}>
          <InputForm
            type="text"
            value={nodeName}
            onChange={({ target }) => setNodeName(target.value)}
          />
        </FormGroup>

        <FormGroup title="Дата поступления в РММ" error={fieldErrors.dateReceipt}>
          <InputDate selectedDate={dateReceipt} onChange={date => setDateReceipt(date)} />
        </FormGroup>

        <FormGroup title="Ожидаемая дата готовности узла" error={fieldErrors.dateForecast}>
          <InputDate selectedDate={dateForecast} onChange={date => setDateForecast(date)} />
        </FormGroup>
      </ModalBody>

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isLoading={isLoading}
          isValid={nodeName === '' || !dateReceipt || !dateForecast}
          isLoadingText="Принятие..."
          clickHandler={submitCustomNode}
        >
          Принять
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

AddNodeSelfModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  rmmId: PropTypes.number.isRequired,
};

export default connect()(AddNodeSelfModal);
