import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Styles
import './MissDocsListReport.scss';

const MissDocsListReport = ({ missDocs }) => {
  return (
    <ul className="miss-docs-list-report">
      {/* eslint-disable react/no-array-index-key */}
      {Array.isArray(missDocs) &&
        missDocs.map((item, index) => (
          <li key={index} className="miss-docs-list-report__item">
            <div className="miss-docs-list-report__item-info">
              {item.object && (
                <Link
                  to={`/repair/${item.object.id}/docs`}
                  className="miss-docs-list-report__item-link"
                >
                  {item.object.ksname} {item.object.gpa} №{item.object.stationnumber}
                </Link>
              )}{' '}
              {Array.isArray(item.document) &&
                item.document.map((doc, docIndex) => (
                  <React.Fragment key={docIndex}>
                    <span className="label label_default">{doc.name}</span>{' '}
                  </React.Fragment>
                ))}
            </div>
            {item.object && (
              <div className="miss-docs-list-report__item-tools">
                <span className="label label_primary">{item.object.master_1}</span>
              </div>
            )}
          </li>
        ))}
      {/* eslint-enable react/no-array-index-key */}
    </ul>
  );
};

MissDocsListReport.propTypes = {
  missDocs: PropTypes.arrayOf(
    PropTypes.shape({
      object: PropTypes.shape({
        id: PropTypes.number,
        ksname: PropTypes.string,
        gpa: PropTypes.string,
        stationnumber: PropTypes.string,
        master_1: PropTypes.string,
      }),
      document: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        }),
      ),
    }),
  ),
};

MissDocsListReport.defaultProps = {
  missDocs: null,
};

export default MissDocsListReport;
