export const browseServer = options => {
  window.fileManagerOptions = options;
  openBrowser(
    `${process.env.REACT_APP_BACK_ROOT}Filemanager/index.html?callback=fileManagerCallback`,
    window.screen.width * 0.7,
    window.screen.height * 0.7,
  );
};

export const openBrowser = (url, width, height) => {
  const windowLeft = (window.screen.width - width) / 2;
  const windowTop = (window.screen.height - height) / 2;
  let windowOptions = 'toolbar=no,status=no,resizable=yes,dependent=yes';
  windowOptions += `,width=${width}`;
  windowOptions += `,height=${height}`;
  windowOptions += `,left=${windowLeft}`;
  windowOptions += `,top=${windowTop}`;
  window.open(url, 'browse window', windowOptions);
};

window.fileManagerCallback = url => {
  const { onSuccess } = window.fileManagerOptions;
  onSuccess(url);
};

export const getLastSegment = path => {
  const segments = path.split('/');
  let lastSegment = segments.pop();
  lastSegment = `...${lastSegment.substr(-8)}`;
  return lastSegment;
};
