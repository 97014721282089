import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Icons
import { FaLongArrowAltRight } from 'react-icons/fa';

// Styles
import './ProblemsListReport.scss';

const ProblemsListReport = ({ problems }) => {
  return (
    <ul className="problems-list-report">
      {Array.isArray(problems) &&
        problems.map(item => (
          <li key={item.id} className="problems-list-report__item">
            <div className="problems-list-report__item-info">
              <span className="label label_danger">{item.date_1}</span> -{' '}
              {item.object && (
                <>
                  <Link
                    to={`/repair/${item.object.id}/problems`}
                    className="problems-list-report__item-link"
                  >
                    {item.object.ksname} {item.object.gpa} №{item.object.stationnumber}
                  </Link>{' '}
                  - <span className="problems-list-report__item-text">{item.name}</span>
                </>
              )}
              {item.outofdate && ' (просрочено)'}
            </div>
            <div className="problems-list-report__item-tools">
              <span className="label label_primary">
                {item.authorname_1} ({item.authorrole})
              </span>{' '}
              <FaLongArrowAltRight className="icon" />{' '}
              {item.targetdepart === '' && (
                <span className="label label_danger">
                  {item.targetname_1} ({item.targetrole})
                </span>
              )}
              {item.targetdepart !== '' && (
                <span className="label label_danger">{item.targetname}</span>
              )}
            </div>
          </li>
        ))}
    </ul>
  );
};

ProblemsListReport.propTypes = {
  problems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      date_1: PropTypes.string,
      object: PropTypes.shape({
        id: PropTypes.number,
        ksname: PropTypes.string,
        gpa: PropTypes.string,
        stationnumber: PropTypes.string,
      }),
      name: PropTypes.string,
      outofdate: PropTypes.bool,
      authorname_1: PropTypes.string,
      authorrole: PropTypes.string,
      targetdepart: PropTypes.string,
      targetname_1: PropTypes.string,
      targetrole: PropTypes.string,
      targetname: PropTypes.string,
    }),
  ),
};

ProblemsListReport.defaultProps = {
  problems: null,
};

export default ProblemsListReport;
