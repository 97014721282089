import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Styles
import './OverdueNodesList.scss';

const OverdueNodesList = ({ overdueNodes, closePanel }) => {
  return (
    <ul className="overdue-nodes-list">
      {Array.isArray(overdueNodes) &&
        overdueNodes.map(node => (
          <li key={node.id} className="overdue-nodes-list__item">
            <div className="overdue-nodes-list__item-info">
              <span className="label label_danger">Просрочен ремонт узла</span> - {node.name}{' '}
              {node.object && (
                <>
                  (
                  <Link
                    to={`/repair/${node.object.id}`}
                    className="overdue-nodes-list__item-link"
                    onClick={closePanel}
                  >
                    {node.object.ksname} {node.object.gpa} №{node.object.stationnumber}
                  </Link>
                  )
                </>
              )}
            </div>
          </li>
        ))}
    </ul>
  );
};

OverdueNodesList.propTypes = {
  closePanel: PropTypes.func.isRequired,
  overdueNodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      object: PropTypes.shape({
        id: PropTypes.number,
        ksname: PropTypes.string,
        gpa: PropTypes.string,
        stationnumber: PropTypes.string,
      }),
    }),
  ),
};

OverdueNodesList.defaultProps = {
  overdueNodes: null,
};

export default OverdueNodesList;
