import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import SelectForm from 'components/SelectForm/SelectForm';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { addAdminOrderManToPeriod } from 'actions';

const AddOrderManModal = ({ closeHandler, orderMen, dispatch, periodId }) => {
  const [orderMan, setOrderMan] = useState(
    orderMen.map(item => ({ value: item.id, label: item.name }))[0],
  );

  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const submitOrderMan = () => {
    setIsLoading(true);

    dispatch(addAdminOrderManToPeriod(orderMan.value, periodId))
      .then(() => {
        setIsLoading(false);

        closeHandler();
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Добавление заказчика к отчетному периоду" />

      <ModalBody>
        <FormGroup title="Выберите заказчика" error={fieldErrors.ordermanId}>
          <SelectForm
            selectedValue={orderMan}
            options={orderMen.map(item => ({ value: item.id, label: item.name }))}
            onChange={selectedOption => setOrderMan(selectedOption)}
          />
        </FormGroup>
      </ModalBody>

      {(responseError || fieldErrors.periodId) && (
        <ModalError className="modal-error_border">
          {responseError || fieldErrors.periodId}
        </ModalError>
      )}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isLoading={isLoading}
          isValid={!orderMan}
          isLoadingText="Отправка..."
          clickHandler={submitOrderMan}
        >
          Создать
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

AddOrderManModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  periodId: PropTypes.string.isRequired,
  orderMen: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
};

export default connect()(AddOrderManModal);
