import React from 'react';
import PropTypes from 'prop-types';
// import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

// Styles
import { selectStyles } from 'scss/select';

const LocationSelect = ({ options, selectedValue, onChange, onInputChange, isDisabled }) => {
  return (
    <CreatableSelect
      isClearable
      // value={selectedValue}
      defaultValue={selectedValue}
      styles={selectStyles}
      classNamePrefix="indicators-select"
      options={options}
      onChange={onChange}
      onInputChange={onInputChange}
      menuPlacement="auto"
      isDisabled={isDisabled}
    />
  );
};

LocationSelect.propTypes = {
  selectedValue: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

LocationSelect.defaultProps = {
  selectedValue: null,
  isDisabled: false,
};

export default LocationSelect;
