import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Styles
import './ProblemsList.scss';

const ProblemsList = ({ problems, closePanel }) => {
  return (
    <ul className="problems-list">
      {Array.isArray(problems) &&
        problems.map(item => (
          <li key={item.id} className="problems-list__item">
            <div className="problems-list__item-info">
              <span className="label label_danger">{item.date_1}</span> -{' '}
              {item.object && (
                <>
                  <Link
                    to={`/repair/${item.object.id}/problems`}
                    className="problems-list__item-link"
                    onClick={closePanel}
                  >
                    {item.object.ksname} {item.object.gpa} №{item.object.stationnumber}
                  </Link>{' '}
                  - <span className="problems-list__item-text">{item.name}</span>
                </>
              )}
            </div>
          </li>
        ))}
    </ul>
  );
};

ProblemsList.propTypes = {
  closePanel: PropTypes.func.isRequired,
  problems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      date_1: PropTypes.string,
      object: PropTypes.shape({
        id: PropTypes.number,
        ksname: PropTypes.string,
        gpa: PropTypes.string,
        stationnumber: PropTypes.string,
      }),
      name: PropTypes.string,
    }),
  ),
};

ProblemsList.defaultProps = {
  problems: null,
};

export default ProblemsList;
