import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import DocSingleRow, { docsType } from 'components/DocSingleRow/DocSingleRow';
import DocMultiRow, { docTypeMultiFiles } from 'components/DocMultiRow/DocMultiRow';

// Actions
import { changeRepairDocNoNeed, uploadRepairMultiDocument, uploadRepairDocument } from 'actions';

// Misc
import { browseServer } from 'helpers/file-manager';

// Styles
import './TableUrgentDocs.scss';

const TableUrgentDocs = ({
  userRole,
  repairId,
  dispatch,
  canEdit,
  lopata,
  mtr,
  schedule,
  actin,
  vedbefore,
  vedwork,
  vedreplace,
  techacts,
  actout,
  vedafter,
  formular,
  ks6,
  actquality,
  por,
}) => {
  const selectFileForDocument = type => {
    browseServer({
      onSuccess: fileUrl => {
        dispatch(uploadRepairDocument(repairId, type, fileUrl)).catch(() => {});
      },
    });
  };

  const deleteFileForDocument = type => {
    dispatch(uploadRepairDocument(repairId, type, 'fake', 'delete')).catch(() => {});
  };

  const selectFileForMultiDocument = (type, index) => {
    browseServer({
      onSuccess: fileUrl => {
        dispatch(uploadRepairMultiDocument(repairId, type, index, fileUrl)).catch(() => {});
      },
    });
  };

  const deleteFileForMultiDocument = (type, index) => {
    dispatch(uploadRepairMultiDocument(repairId, type, index, 'fake', 'delete')).catch(() => {});
  };

  const changeRepairDocNoNeedCallback = (type, value) => {
    dispatch(changeRepairDocNoNeed(repairId, type, value)).catch(() => {});
  };

  const isAllowedToEditNoNeed = userRole === 10 || userRole === 14;

  return (
    <div className="panel panel_danger">
      <div className="panel__heading">Срочные документы</div>

      <table className="table table-striped table-bordered table-docs">
        <thead>
          <tr>
            <th>Документ</th>
            <th>Автор загрузки</th>
            <th>Дата загрузки</th>
            <th>Файл</th>
            {isAllowedToEditNoNeed && <th>Н/о</th>}
          </tr>
        </thead>

        <tbody>
          {lopata && (
            <DocSingleRow
              repairId={repairId}
              canEdit={canEdit}
              isAllowedToEditNoNeed={isAllowedToEditNoNeed}
              doc={lopata}
              docName="Отчет по наработке лопаточного аппарате (по форме)"
              docType="lopata"
              selectFileForDocument={selectFileForDocument}
              deleteFileForDocument={deleteFileForDocument}
              changeRepairDocNoNeed={changeRepairDocNoNeedCallback}
            />
          )}

          {mtr && (
            <DocMultiRow
              repairId={repairId}
              canEdit={canEdit}
              isAllowedToEditNoNeed={isAllowedToEditNoNeed}
              doc={mtr}
              docName="Заявка на МТР"
              docType="mtr"
              selectFileForDocument={selectFileForMultiDocument}
              deleteFileForDocument={deleteFileForMultiDocument}
              changeRepairDocNoNeed={changeRepairDocNoNeedCallback}
            />
          )}

          {schedule && (
            <DocSingleRow
              repairId={repairId}
              canEdit={canEdit}
              isAllowedToEditNoNeed={isAllowedToEditNoNeed}
              doc={schedule}
              docName="График ремонта"
              docType="schedule"
              selectFileForDocument={selectFileForDocument}
              deleteFileForDocument={deleteFileForDocument}
              changeRepairDocNoNeed={changeRepairDocNoNeedCallback}
            />
          )}

          {actin && (
            <DocSingleRow
              repairId={repairId}
              canEdit={canEdit}
              isAllowedToEditNoNeed={isAllowedToEditNoNeed}
              doc={actin}
              docName="Акт вывода в ремонт"
              docType="actin"
              selectFileForDocument={selectFileForDocument}
              deleteFileForDocument={deleteFileForDocument}
              changeRepairDocNoNeed={changeRepairDocNoNeedCallback}
            />
          )}

          {vedbefore && (
            <DocSingleRow
              repairId={repairId}
              canEdit={canEdit}
              isAllowedToEditNoNeed={isAllowedToEditNoNeed}
              doc={vedbefore}
              docName="Ведомость: технические показатели: до ремонта"
              docType="vedbefore"
              selectFileForDocument={selectFileForDocument}
              deleteFileForDocument={deleteFileForDocument}
              changeRepairDocNoNeed={changeRepairDocNoNeedCallback}
            />
          )}

          {vedwork && (
            <DocSingleRow
              repairId={repairId}
              canEdit={canEdit}
              isAllowedToEditNoNeed={isAllowedToEditNoNeed}
              doc={vedwork}
              docName="Ведомость наработки узлов"
              docType="vedwork"
              selectFileForDocument={selectFileForDocument}
              deleteFileForDocument={deleteFileForDocument}
              changeRepairDocNoNeed={changeRepairDocNoNeedCallback}
            />
          )}

          {vedreplace && (
            <DocSingleRow
              repairId={repairId}
              canEdit={canEdit}
              isAllowedToEditNoNeed={isAllowedToEditNoNeed}
              doc={vedreplace}
              docName="Ведомость замены деталей (Таблица №4)"
              docType="vedreplace"
              selectFileForDocument={selectFileForDocument}
              deleteFileForDocument={deleteFileForDocument}
              changeRepairDocNoNeed={changeRepairDocNoNeedCallback}
            />
          )}

          {techacts && (
            <DocMultiRow
              repairId={repairId}
              canEdit={canEdit}
              isAllowedToEditNoNeed={isAllowedToEditNoNeed}
              doc={techacts}
              docName="Технические акты (Готовность к ПСИ)"
              docType="techacts"
              selectFileForDocument={selectFileForMultiDocument}
              deleteFileForDocument={deleteFileForMultiDocument}
              changeRepairDocNoNeed={changeRepairDocNoNeedCallback}
            />
          )}

          {actout && (
            <DocSingleRow
              repairId={repairId}
              canEdit={canEdit}
              isAllowedToEditNoNeed={isAllowedToEditNoNeed}
              doc={actout}
              docName="Акт вывода из ремонта"
              docType="actout"
              selectFileForDocument={selectFileForDocument}
              deleteFileForDocument={deleteFileForDocument}
              changeRepairDocNoNeed={changeRepairDocNoNeedCallback}
            />
          )}

          {vedafter && (
            <DocSingleRow
              repairId={repairId}
              canEdit={canEdit}
              isAllowedToEditNoNeed={isAllowedToEditNoNeed}
              doc={vedafter}
              docName="Ведомость: технические показатели: после ремонта"
              docType="vedafter"
              selectFileForDocument={selectFileForDocument}
              deleteFileForDocument={deleteFileForDocument}
              changeRepairDocNoNeed={changeRepairDocNoNeedCallback}
            />
          )}

          {formular && (
            <DocSingleRow
              repairId={repairId}
              canEdit={canEdit}
              isAllowedToEditNoNeed={isAllowedToEditNoNeed}
              doc={formular}
              docName="Формуляр"
              docType="formular"
              selectFileForDocument={selectFileForDocument}
              deleteFileForDocument={deleteFileForDocument}
              changeRepairDocNoNeed={changeRepairDocNoNeedCallback}
            />
          )}

          {ks6 && (
            <DocSingleRow
              repairId={repairId}
              canEdit={canEdit}
              isAllowedToEditNoNeed={isAllowedToEditNoNeed}
              doc={ks6}
              docName="Журнал КС-6"
              docType="ks6"
              selectFileForDocument={selectFileForDocument}
              deleteFileForDocument={deleteFileForDocument}
              changeRepairDocNoNeed={changeRepairDocNoNeedCallback}
            />
          )}

          {actquality && (
            <DocSingleRow
              repairId={repairId}
              canEdit={canEdit}
              isAllowedToEditNoNeed={isAllowedToEditNoNeed}
              doc={actquality}
              docName="Акт оценки качества"
              docType="actquality"
              selectFileForDocument={selectFileForDocument}
              deleteFileForDocument={deleteFileForDocument}
              changeRepairDocNoNeed={changeRepairDocNoNeedCallback}
            />
          )}

          {por && (
            <DocMultiRow
              repairId={repairId}
              canEdit={canEdit}
              isAllowedToEditNoNeed={isAllowedToEditNoNeed}
              doc={por}
              docName="Приказ об организации ремонта"
              docType="por"
              selectFileForDocument={selectFileForMultiDocument}
              deleteFileForDocument={deleteFileForMultiDocument}
              changeRepairDocNoNeed={changeRepairDocNoNeedCallback}
            />
          )}
        </tbody>
      </table>
    </div>
  );
};

TableUrgentDocs.propTypes = {
  userRole: PropTypes.number.isRequired,
  repairId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  lopata: docsType.isRequired,
  mtr: docTypeMultiFiles.isRequired,
  schedule: docsType.isRequired,
  actin: docsType.isRequired,
  vedbefore: docsType.isRequired,
  vedwork: docsType.isRequired,
  vedreplace: docsType.isRequired,
  techacts: docTypeMultiFiles.isRequired,
  actout: docsType.isRequired,
  vedafter: docsType.isRequired,
  formular: docsType.isRequired,
  ks6: docsType.isRequired,
  actquality: docsType.isRequired,
  por: docTypeMultiFiles.isRequired,
};

export default connect()(TableUrgentDocs);
