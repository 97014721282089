import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const InputForm = ({ type, value, onChange, classname, readonly, placeholder }) => {
  return (
    <input
      type={type}
      readOnly={readonly}
      className={cx('form-control', {
        [classname]: classname,
      })}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};

InputForm.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  classname: PropTypes.string,
  readonly: PropTypes.bool,
  placeholder: PropTypes.string,
};

InputForm.defaultProps = {
  classname: null,
  readonly: false,
  placeholder: null,
};

export default InputForm;
