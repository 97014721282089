import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Icons
import { FaPlus, FaCheckCircle, FaBan, FaReply, } from 'react-icons/fa';

// Components
import Modal from 'components/Modal/Modal';
import Pagination from 'components/Pagination/Pagination';
import AddDespatchFormModal from 'components/AddDespatchFormModal/AddDespatchFormModal';
import EditDespatchFormModal from 'components/EditDespatchFormModal/EditDespatchFormModal';
import ReturnTsTaskModal from 'components/ReturnTsTaskModal/ReturnTsTaskModal';
import TableDespatch from 'components/TableDespatch/TableDespatch';
import TableDespatchLegend from 'components/TableDespatchLegend/TableDespatchLegend';
import DespatchTableFilter from 'components/DespatchTableFilter/DespatchTableFilter';
import DespatchDetails from 'components/DespatchDetails/DespatchDetails';
import DespatchComments from 'components/DespatchComments/DespatchComments';
import Loader from 'components/Loader/Loader';

// Actions
import {
  loadDespatches,
  setDespatchProblemSolved,
  setDespatchPdoAppoved,
  setDespatchOmtsAppoved,
  clearDespatchPdoAppoved,
  clearDespatchOmtsAppoved,
} from 'actions/despatch';
import {
  loadLocations,
} from 'actions/common';

// Utils
import * as propTypes from 'utils/propTypes';
import { USER_ROLES } from 'utils/constants';

// Styles
import './Despatch.scss';

const Despatch = ({
  match,
  // location,
  dispatch,
  isDespatchFetching,
  canEdit,
  users,
  page, 
  isArchive,
  locations,
  customLocations,
  despatches,
  despatchInfo,
  user,
}) => {
  const filterOptions = {
    status: [],
  };
  const despatchDtatuses = [{
    id: 0,
    name: 'CREATED',
    label: 'Формируется',
  }, {
    id: 1,
    name: 'AWAITING',
    label: 'Ожидает отправки',
  }, {
    id: 2,
    name: 'RETURNED',
    label: 'Возврат',
  }, {
    id: 3,
    name: 'SHIPPED',
    label: 'Отгружен',
  }, {
    id: 4,
    name: 'COMPLETED',
    label: 'Завершен',
  }, {
    id: 5,
    name: 'CANCELED',
    label: 'Отменен',
  }, {
    id: 6,
    name: 'IN_TRANSIT',
    label: 'Отправлено',
  }, {
    id: 7,
    name: 'CAR_ASSIGNED',
    label: 'Назначена машина',
  }, {
    id: 8,
    name: 'RETURNED_FOR_REV',
    label: 'На доработке',
  }];
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [despatchFilter, setDespatchFilter] = useState({
    ...filterOptions,
  });
  const [isAddDespatchActive, setAddDespatchActive] = useState(false);
  const [isEditDespatchActive, setIsEditDespatchActive] = useState(false);
  const [nodeId, setNodeId] = useState(+match.params.id); // Node id to pass on problem return
  const [parentId, setParentId] = useState();
  const [isReturnTaskActive, setReturnTaskActive] = useState(false);

  /** Запрашиваем информацию по отправлениям */
  useEffect(() => {
    // TODO: loaders
    dispatch(loadDespatches(isArchive ? {} : { 'status[completed]': false }, isArchive, page))
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));

    dispatch(loadLocations())
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch, isArchive, page]);

  useEffect(() => {
    setNodeId(despatchInfo.id);
  }, [despatchInfo]);

  const activateAddDespatchFormModal = () => {
    setAddDespatchActive(true);
  };

  const deactivateAddDespatchFormModal = () => {
    setAddDespatchActive(false);
  };

  const activateReturnTaskModal = () => {
    setReturnTaskActive(true);
  };

  const deactivateReturnTaskModal = () => {
    setReturnTaskActive(false);
  };

  const activateEditDespatchFormModal = () => {
    setIsEditDespatchActive(true);
  };

  const deactivateEditDespatchFormModal = () => {
    setIsEditDespatchActive(false);
  };

  const handleEditDespatchClick = bid => {
    // setNodeId(nodeId);

    activateEditDespatchFormModal();
  };

  const handleReturnProblem = bid => {
    setNodeId(bid);

    activateReturnTaskModal();
  };

  const handleProblemSolved = bid => {
    dispatch(setDespatchProblemSolved(bid, bid))
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  };

  const handleReturnProblemSuccess = bid => {
  //   dispatch(loadDespatchInfo(nodeId))
  //     .then(() => setIsLoading(false))
  //     .catch(() => setIsLoading(false));
  };

  const handleAddChildDespatch = bid => {
    setParentId(bid);

    activateAddDespatchFormModal();
  };

  const handleDespatchPdoApprovedClick = React.useCallback(() => {
    /** Переключаем статус согласований ПДО */
    if (despatchInfo.isPdoApproved) {
      dispatch(clearDespatchPdoAppoved(despatchInfo.id))
        .then(() => {})
        .catch(error => {
          // if (error && error.errorData && Object.keys(error.errorData).length) {
          //   setFieldErrors(error.errorData);
          // } else if (error && error.error) {
          //   setResponseError(error.error);
          // } else {
          //   setResponseError('Произошла неизвестная ошибка');
          // }
        })
        .finally(() => {});
    } else {
      dispatch(setDespatchPdoAppoved(despatchInfo.id))
        .then(() => {})
        .catch(() => {})
        .finally(() => {});
    }
  }, [dispatch, despatchInfo.id, despatchInfo.isPdoApproved]);

  const handleDespatchOmtsApprovedClick = React.useCallback(() => {
    /** Переключаем статус согласований ОМТС */
    if (despatchInfo.isOmtsApproved) {
      dispatch(clearDespatchOmtsAppoved(despatchInfo.id))
        .then(() => {})
        .catch(error => {
          // if (error && error.errorData && Object.keys(error.errorData).length) {
          //   setFieldErrors(error.errorData);
          // } else if (error && error.error) {
          //   setResponseError(error.error);
          // } else {
          //   setResponseError('Произошла неизвестная ошибка');
          // }
        })
        .finally(() => {});
    } else {
      dispatch(setDespatchOmtsAppoved(despatchInfo.id))
        .then(() => {})
        .catch(() => {})
        .finally(() => {});
    }
  }, [dispatch, despatchInfo.id, despatchInfo.isOmtsApproved]);


  const isTuProcessing = true;
  const isTuProblemReturned = despatchInfo && despatchInfo.status === 'RETURNED_FOR_REV';

  return (
    <React.Fragment>
      {false && isLoading && <Loader className="loader_page" />}

      {!isLoading && (
        <div className="despatch-page">
          <div className="despatch-page__container container">
            <h1 className="despatch-page__title">Транспортный участок</h1>

            <Route
              path="/despatch"
              exact
              render={() => (
                <React.Fragment>
                  <TableDespatchLegend />
                  <div className="despatch-page__header">
                    <h2 className="despatch-page__title">Отправления</h2>
                    {(true || canEdit) && (
                      <div className="despatch-page__tools">
                        <DespatchTableFilter
                          userRole={user.userRole}
                          statuses={despatchDtatuses}
                          despatchFilter={despatchFilter}
                          handleSelectFilterOptions={(type, value) => {
                            setIsFilterActive(true);

                            setDespatchFilter(prevState => ({
                              ...prevState,
                              [type]: prevState[type].includes(value)
                                ? prevState[type].filter(item => item !== value)
                                : [value, ...prevState[type]],
                            }));
                          }}
                          handleResetFilter={() => {
                            setIsFilterActive(false);

                            setDespatchFilter({
                              ...filterOptions,
                            });
                          }}
                        />
                        <Link
                          type="button"
                          to={`/despatch/0/archive`}
                          className="btn btn-primary"
                          onClick={() => setIsLoading(true)}
                        >
                          Архив
                        </Link>
                        &nbsp;
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={activateAddDespatchFormModal}
                        >
                          <FaPlus className="icon" /> Добавить отправление
                        </button>
                      </div>
                    )}
                  </div>
                </React.Fragment>
              )}
            />

            <div className="despatch-page__content">
              <div className="despatch-page__row">
                <div className="despatch-page__col">
                  <Switch>
                    <Route
                      path="/despatch/:id/details"
                      exact
                      render={() => (
                        <DespatchDetails
                          canEdit={canEdit}
                          despatchId={+match.params.id}
                          onAddChildDespatchClick={handleAddChildDespatch}
                          onEditDespatchClick={handleEditDespatchClick}
                        />
                      )}
                    />
                    <Route
                      path="/despatch(/:page/archive)?"
                      render={() => (
                        <div className="">
                          <TableDespatch
                            userRole={user.userRole}
                            despatches={despatches}
                            isArchive={isArchive}
                            despatchFilter={despatchFilter}
                            // onApproved={setPdoApproved}
                            onReturnProblem={handleReturnProblem}
                            onProblemSolved={handleProblemSolved}
                          />

                          <Pagination
                            pagesCount={despatches.totalPages}
                            currentPage={page}
                            asLinks
                            linkTemplate={`/despatch/%%PAGE%%${isArchive ? '/archive' : ''}`}
                          />
                        </div>
                      )}
                    />
                  </Switch>
                </div>
                <div className="" style={{ width: 64 }}></div>

                <div className="despatch-page__col">
                  <Route
                    path="/despatch/:id/details"
                    exact
                    render={() => <DespatchComments despatchId={+match.params.id} />}
                  />
                </div>
              </div>
              <Route
                path="/despatch/:id/details"
                exact
                render={() => (
                  <div className="despatch-content__tools">
                    {(user.userRole === USER_ROLES.ROLE_PDO ||
                      user.userRole === USER_ROLES.ROLE_BOSSES || 
                      user.userRole === USER_ROLES.ROLE_ADMIN) &&
                      despatchInfo && (
                      <button
                        type="button"
                        disabled={isDespatchFetching}
                        className={despatchInfo.isPdoApproved ? "btn btn-warning" : "btn btn-success"}
                        onClick={handleDespatchPdoApprovedClick}
                        // style={{ marginRight: 8 }}
                      >
                        {despatchInfo.isPdoApproved ? <FaBan className="icon" /> : <FaCheckCircle className="icon" />} {despatchInfo.isPdoApproved ? 'Снять отметку о согласовании в ПДО' : 'Согласовать ПДО'}
                      </button>
                    )}

                    {(user.userRole === USER_ROLES.ROLE_OMTS ||
                      user.userRole === USER_ROLES.ROLE_BOSSES || 
                      user.userRole === USER_ROLES.ROLE_ADMIN) && 
                      despatchInfo && (
                      <button
                        type="button"
                        disabled={isDespatchFetching}
                        className={despatchInfo.isOmtsApproved ? "btn btn-warning" : "btn btn-success"}
                        onClick={handleDespatchOmtsApprovedClick}
                        // style={{ marginRight: 8 }}
                      >
                        {despatchInfo.isOmtsApproved ? <FaBan className="icon" /> : <FaCheckCircle className="icon" />} {despatchInfo.isOmtsApproved ? 'Снять отметку о согласовании в ОМТС' : 'Согласовать ОМТС'}
                      </button>
                    )}

                    {isTuProcessing && !isTuProblemReturned && (
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleReturnProblem(nodeId)}
                      >
                        <FaReply className="icon" /> Вернуть на доработку
                      </button>
                    )}
                  </div>
                )}
              />
            </div>
          </div>

          {isAddDespatchActive && (canEdit || true) && (
            <Modal title="Добавление отправления" closeHandler={deactivateAddDespatchFormModal}>
              <AddDespatchFormModal
                users={users}
                parentId={parentId}
                locations={locations}
                customLocations={customLocations}
                closeHandler={deactivateAddDespatchFormModal}
              />
            </Modal>
          )}

          {isEditDespatchActive && despatchInfo && (
            <Modal title="Изменить задачу" closeHandler={deactivateEditDespatchFormModal}>
              <EditDespatchFormModal
                users={users}
                editDespatchItem={despatchInfo}
                locations={locations}
                customLocations={customLocations}
                closeHandler={deactivateEditDespatchFormModal}
              />
            </Modal>
          )}

          {isReturnTaskActive && (canEdit || true) && (
            <Modal title="Вернуть на доработку" closeHandler={deactivateReturnTaskModal}>
              <ReturnTsTaskModal
                nodeId={nodeId}
                closeHandler={deactivateReturnTaskModal}
                onReturnProblemSuccess={handleReturnProblemSuccess}
              />
            </Modal>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

Despatch.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
  isArchive: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  despatches: PropTypes.arrayOf(PropTypes.shape(propTypes.despatchInfo)),
  despatchInfo: propTypes.despatchInfo,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      rolename: PropTypes.string,
      name_1: PropTypes.string,
    }),
  ).isRequired,
  user: PropTypes.shape({
    userRole: PropTypes.number,
  }).isRequired,
};

Despatch.defaultProps = {
  despatches: [],
  page: 0,
  isArchive: false,
};

const mapStateToProps = (state, { match }) => ({
  dispatch: state.mainReducer.dispatch,
  page: match && match.params && match.params.page ? parseInt(match.params.page, 10) : 0,
  isArchive: match && match.params ? Boolean(match.params.isArchive) : false,
  isDespatchFetching: state.despatchReducer.isFetching,
  despatch: state.mainReducer.despatch,
  despatches: state.despatchReducer.content,
  despatchInfo: state.despatchReducer.despatchInfo,
  locations: state.commonReducer.locations,
  customLocations: state.commonReducer.customLocations,
  user: state.mainReducer.user,
  users: state.mainReducer.headerData.users,
});

export default connect(mapStateToProps)(Despatch);
