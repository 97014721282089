import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Styles
import './MissDocsList.scss';

const MissDocsList = ({ missDocs, closePanel, userRole }) => {
  return (
    <ul className="miss-docs-list">
      {/* eslint-disable react/no-array-index-key */}
      {(userRole === 4 || userRole === 5 || userRole === 12) &&
        Array.isArray(missDocs) &&
        missDocs.map((item, index) => (
          <li key={index} className="miss-docs-list__item">
            <div className="miss-docs-list__item-info">
              {item.object && (
                <Link
                  to={`/repair/${item.object.id}/docs`}
                  className="miss-docs-list__item-link"
                  onClick={closePanel}
                >
                  {item.object.ksname} {item.object.gpa} №{item.object.stationnumber}
                </Link>
              )}{' '}
              {Array.isArray(item.document) &&
                item.document.map((doc, docIndex) => (
                  <React.Fragment key={docIndex}>
                    <span className="label label_default">{doc.name}</span>{' '}
                  </React.Fragment>
                ))}
            </div>
          </li>
        ))}
      {/* eslint-enable react/no-array-index-key */}

      {userRole === 13 &&
        Array.isArray(missDocs) &&
        missDocs.map(item => (
          <li key={item.id} className="miss-docs-list__item">
            <div className="miss-docs-list__item-info">
              {item.name}{' '}
              {item.object && (
                <>
                  (
                  <Link
                    to={`/repair/${item.object.id}/docs`}
                    className="miss-docs-list__item-link"
                    onClick={closePanel}
                  >
                    {item.object.ksname} {item.object.gpa} №{item.object.stationnumber}
                  </Link>
                  )
                </>
              )}{' '}
              {/* eslint-disable react/no-array-index-key */}
              {Array.isArray(item.documents) &&
                item.documents.map((doc, docIndex) => (
                  <React.Fragment key={docIndex}>
                    <span className="label label_default">{doc.name}</span>{' '}
                  </React.Fragment>
                ))}
              {/* eslint-enable react/no-array-index-key */}
            </div>
          </li>
        ))}

      {/* eslint-disable react/no-array-index-key */}
      {userRole === 6 &&
        Array.isArray(missDocs) &&
        missDocs.map((item, index) => (
          <li key={index} className="miss-docs-list__item">
            <div className="miss-docs-list__item-info">
              {item.name}{' '}
              {item.object && (
                <>
                  (
                  <Link
                    to={`/repair/${item.object.id}/docs`}
                    className="miss-docs-list__item-link"
                    onClick={closePanel}
                  >
                    {item.object.ksname} {item.object.gpa} №{item.object.stationnumber}
                  </Link>
                  )
                </>
              )}{' '}
              {Array.isArray(item.document) &&
                item.document.map((doc, docIndex) => (
                  <React.Fragment key={docIndex}>
                    <span className="label label_default">{doc.name}</span>{' '}
                  </React.Fragment>
                ))}
            </div>
          </li>
        ))}
      {/* eslint-enable react/no-array-index-key */}
    </ul>
  );
};

MissDocsList.propTypes = {
  userRole: PropTypes.number.isRequired,
  closePanel: PropTypes.func.isRequired,
  missDocs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      object: PropTypes.shape({
        id: PropTypes.number,
        ksname: PropTypes.string,
        gpa: PropTypes.string,
        stationnumber: PropTypes.string,
      }),
      document: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        }),
      ),
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        }),
      ),
    }),
  ),
};

MissDocsList.defaultProps = {
  missDocs: null,
};

export default MissDocsList;
