export const USER_ROLES = {
    ROLE_GUEST: 1,
    ROLE_MECHANIST: 2,
    ROLE_WELDER: 3,
    ROLE_MECHANIST_KIP: 11,

    ROLE_MASTER: 4,
    ROLE_NU: 5,
    ROLE_PDO: 6,
    ROLE_OMTS: 7,
    ROLE_TO: 8,
    ROLE_OT: 9,
    ROLE_BOSSES: 10,
    ROLE_HIGH_MASTER: 12,
    ROLE_RMM: 13,
    ROLE_ADMIN: 14,
    ROLE_TU: 30,
};

export const DESPATCH_STATUSES = {
    CREATED: 'Формируется',
    AWAITING: 'Ожидает отправки',
    RETURNED: 'Возврат',
    SHIPPED: 'Отгружен',
    COMPLETED: 'Завершен',
    CANCELED: 'Отменен',
    IN_TRANSIT: 'Отправлено',
    CAR_ASSIGNED: 'Назначена машина',
    RETURNED_FOR_REV: 'На доработке',
    ARCHIVE: 'В архиве',
};

export const TRANSPORT_STATUSES = {
    VACANT: 'Свободен',
    IN_PROCESSING: 'В обработке',
};

export const DESPATCH_MESSAGES = {
    SEND: 'Отправить груз',
    ACCEPT: 'Принять груз',
    RETURNED: 'Заявка на доработке',
    SHIPPED: 'Отгрузить машину',
    CREATED: 'Создана заявка',
};

export const DESPATCH_STATUSES_SHORT_HASH = {
    CREATED: 'active',
    AWAITING: 'awaiting',
    RETURNED: 'return',
    SHIPPED: 'open',
    COMPLETED: 'finished',
    CANCELED: 'unavailable',
    CAR_ASSIGNED: 'car_assigned',
    IN_TRANSIT: 'in_transit',
    RETURNED_FOR_REV: 'revision',
};

export const DESPATCH_COMBINED_STATUSES_SHORT_HASH = {
    CREATED: 'active',
    AWAITING: 'open', // 'awaiting',
    RETURNED: 'return',
    SHIPPED: 'open',
    COMPLETED: 'finished',
    CANCELED: 'unavailable',
    CAR_ASSIGNED: 'open', //'car_assigned',
    IN_TRANSIT: 'in_transit',
    RETURNED_FOR_REV: 'revision',
}
