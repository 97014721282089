import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Styles
import './NoForecastList.scss';

const NoForecastList = ({ noForecast, closePanel }) => {
  return (
    <ul className="no-forecast-list">
      {Array.isArray(noForecast) &&
        noForecast.map(item => (
          <li key={item.id} className="no-forecast-list__item">
            <div className="no-forecast-list__item-info">
              <span className="label label_danger">Необходимо согласовать дату отправки узла</span>{' '}
              -{' '}
              <Link
                to={`/repair/${item.repairid}/node`}
                className="no-forecast-list__item-link"
                onClick={closePanel}
              >
                {item.name}
              </Link>
            </div>
          </li>
        ))}
    </ul>
  );
};

NoForecastList.propTypes = {
  closePanel: PropTypes.func.isRequired,
  noForecast: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      repairid: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
};

NoForecastList.defaultProps = {
  noForecast: null,
};

export default NoForecastList;
