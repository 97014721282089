import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './TableReportAddWork.scss';

const TableReportAddWork = ({ ordermantoperiod }) => {
  return (
    <table className="table table-bordered table-report">
      <thead>
        <tr>
          <th rowSpan="2" colSpan="3">
            Работы
          </th>
          <th rowSpan="2">Руководитель работ</th>
          <th colSpan="2">Сроки ремонта</th>
        </tr>
        <tr>
          <th>Начало ремонта</th>
          <th>Окончание ремонта</th>
        </tr>
      </thead>

      <tbody>
        {ordermantoperiod &&
          Array.isArray(ordermantoperiod) &&
          ordermantoperiod.length > 0 &&
          ordermantoperiod.map(orderman => (
            <React.Fragment key={orderman.id}>
              <tr className="table-report__orderman-tr">
                <td colSpan="6" className="table-report__orderman-td">
                  {orderman.name}
                </td>
              </tr>

              {orderman.ks &&
                Array.isArray(orderman.ks) &&
                orderman.ks.length > 0 &&
                orderman.ks.map(ks => (
                  <React.Fragment key={ks.id}>
                    <tr className="table-report__ks-tr">
                      <td colSpan="6" className="table-report__ks-td">
                        <span className="label label_default">{ks.name}</span>
                      </td>
                    </tr>

                    {ks.repairs &&
                      Array.isArray(ks.repairs) &&
                      ks.repairs.length > 0 &&
                      ks.repairs.map(repair => (
                        <React.Fragment key={repair.id}>
                          <tr className="table-report__repair-name-tr">
                            <td colSpan="6" className="table-report__repair-name-td">
                              {repair.type_s} {repair.gpa} Ст. № {repair.stationnumber}
                            </td>
                          </tr>

                          {repair.addWorks &&
                            Array.isArray(repair.addWorks) &&
                            repair.addWorks.length > 0 &&
                            repair.addWorks.map((work, index) => (
                              <tr key={work.id}>
                                <td className="table-report__work-item">
                                  {work.name} {work.date_1}
                                </td>

                                <td>
                                  {work.ready && (
                                    <span className="label label_success">Выполнена</span>
                                  )}

                                  {!work.ready && (
                                    <span className="label label_danger">Не выполнена</span>
                                  )}
                                </td>

                                <td>
                                  {work.paid && (
                                    <span className="label label_success">Оплачена</span>
                                  )}

                                  {!work.paid && (
                                    <span className="label label_danger">Не оплачена</span>
                                  )}
                                </td>

                                {index === 0 && (
                                  <td rowSpan={repair.count} className="table-report__master">
                                    {repair.master}
                                  </td>
                                )}

                                {index === 0 && (
                                  <td rowSpan={repair.count} className="table-report__datestart">
                                    {repair.datestart_1}
                                  </td>
                                )}

                                {index === 0 && (
                                  <td rowSpan={repair.count} className="table-report__dateend">
                                    {repair.dateend_1}
                                  </td>
                                )}
                              </tr>
                            ))}
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                ))}
            </React.Fragment>
          ))}
      </tbody>
    </table>
  );
};

TableReportAddWork.propTypes = {
  ordermantoperiod: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      ks: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          repairs: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              type_s: PropTypes.string,
              gpa: PropTypes.string,
              stationnumber: PropTypes.string,
              count: PropTypes.number,
              master: PropTypes.string,
              datestart_1: PropTypes.string,
              dateend_1: PropTypes.string,
              addWorks: PropTypes.arrayOf(
                PropTypes.shape({
                  id: PropTypes.number,
                  name: PropTypes.string,
                  date_1: PropTypes.string,
                  ready: PropTypes.bool,
                  paid: PropTypes.bool,
                }),
              ),
            }),
          ),
        }),
      ),
    }),
  ),
};

TableReportAddWork.defaultProps = {
  ordermantoperiod: null,
};

export default TableReportAddWork;
