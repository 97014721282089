import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Styles
import './NoReportsListReport.scss';

const NoReportsListReport = ({ noReports }) => {
  return (
    <ul className="no-reports-list-report">
      {/* eslint-disable react/no-array-index-key */}
      {Array.isArray(noReports) &&
        noReports.map((item, index) => (
          <li key={index} className="no-reports-list-report__item">
            <div className="no-reports-list-report__item-info">
              {item.object && (
                <Link
                  to={`/repair/${item.object.id}`}
                  className="no-reports-list-report__item-link"
                >
                  {item.object.ksname} {item.object.gpa} №{item.object.stationnumber}
                </Link>
              )}{' '}
              {item.dates &&
                Array.isArray(item.dates) &&
                item.dates.map((date, dateIndex) => (
                  <React.Fragment key={dateIndex}>
                    <span className="label label_default">{date.realdate_1}</span>{' '}
                  </React.Fragment>
                ))}
              {item.object && <span className="label label_primary">{item.object.master_1}</span>}
            </div>
          </li>
        ))}
      {/* eslint-enable react/no-array-index-key */}
    </ul>
  );
};

NoReportsListReport.propTypes = {
  noReports: PropTypes.arrayOf(
    PropTypes.shape({
      object: PropTypes.shape({
        id: PropTypes.number,
        ksname: PropTypes.string,
        gpa: PropTypes.string,
        stationnumber: PropTypes.string,
        master_1: PropTypes.string,
      }),
      dates: PropTypes.arrayOf(
        PropTypes.shape({
          realdate_1: PropTypes.string,
        }),
      ),
    }),
  ),
};

NoReportsListReport.defaultProps = {
  noReports: null,
};

export default NoReportsListReport;
