import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Icons
import { FaEdit } from 'react-icons/fa';

// Components
import Modal from 'components/Modal/Modal';
import AddTaskCommentModal from 'components/AddTaskCommentModal/AddTaskCommentModal';
import TaskInfoModal from 'components/TaskInfoModal/TaskInfoModal';
import EditTaskModal from 'components/EditTaskModal/EditTaskModal';

// Actions
import { resetTaskUnreadCount } from 'actions';

// Styles
import './MyTasksList.scss';

const ModalTypes = {
  TaskInfo: 0,
  EditTask: 1,
  Comments: 2,
};

const MyTasksList = ({ myTasks, dispatch }) => {
  const [activeModal, setActiveModal] = useState(null);

  const [currentMyTaskId, setCurrentMyTaskId] = useState(null);
  const [currentMyTask, setCurrentMyTask] = useState(null);

  const activateModal = (taskId, modalType) => {
    setCurrentMyTaskId(taskId);
    setActiveModal(modalType);
  };

  const deactivateModal = () => {
    setActiveModal(null);
    setCurrentMyTaskId(null);
  };

  useEffect(() => {
    if (currentMyTaskId) {
      setCurrentMyTask(myTasks.find(item => item.id === currentMyTaskId));
    } else {
      setCurrentMyTask(null);
    }
  }, [currentMyTaskId, myTasks]);

  return (
    <>
      <ul className="my-tasks-list">
        {Array.isArray(myTasks) &&
          myTasks.map(task => (
            <li key={task.id} className="my-tasks-list__item">
              <div className="my-tasks-list__item-info">
                {!task.doneapproved && (
                  <button
                    type="button"
                    className="btn btn-xs btn-primary"
                    onClick={() => {
                      activateModal(task.id, ModalTypes.EditTask);
                    }}
                  >
                    <FaEdit className="icon" />
                  </button>
                )}{' '}
                <span className="label label_danger">{task.date_1}</span> -{' '}
                <span className="label label_danger">{task.dateend_1}</span> -{' '}
                <button
                  type="button"
                  className="my-tasks-list__item-link"
                  onClick={() => {
                    activateModal(task.id, ModalTypes.TaskInfo);
                  }}
                >
                  {task.name} {task.done && '(Выполнена, необходимо подтвердить)'}
                </button>
              </div>
              <div className="my-tasks-list__item-tools">
                {task.unreadCount === 0 && (
                  <button
                    type="button"
                    className="my-tasks-list__item-btn btn btn-xs btn-success btn-comments"
                    onClick={() => activateModal(task.id, ModalTypes.Comments)}
                  >
                    Прим
                  </button>
                )}

                {task.unreadCount > 0 && (
                  <button
                    type="button"
                    className="my-tasks-list__item-btn btn btn-xs btn-danger btn-comments"
                    onClick={() => {
                      activateModal(task.id, ModalTypes.Comments);
                      dispatch(resetTaskUnreadCount(task.id, 'my'));
                    }}
                  >
                    Прим <span className="badge">{task.unreadCount}</span>
                  </button>
                )}
                <span className="my-tasks-list__item-user label label_primary">
                  {task.targetname_1} ({task.targetrole})
                </span>
              </div>
            </li>
          ))}
      </ul>

      {activeModal === ModalTypes.TaskInfo && currentMyTask && (
        <Modal
          title={`Задача №${currentMyTaskId} «${currentMyTask.name}»`}
          closeHandler={deactivateModal}
        >
          <TaskInfoModal myTask={currentMyTask} closeHandler={deactivateModal} />
        </Modal>
      )}

      {activeModal === ModalTypes.Comments && currentMyTask && (
        <Modal title="Примечания" closeHandler={deactivateModal}>
          <AddTaskCommentModal taskId={currentMyTaskId} closeHandler={deactivateModal} />
        </Modal>
      )}

      {activeModal === ModalTypes.EditTask && currentMyTask && (
        <Modal title="Изменить задачу" closeHandler={deactivateModal}>
          <EditTaskModal myTask={currentMyTask} closeHandler={deactivateModal} />
        </Modal>
      )}
    </>
  );
};

MyTasksList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  myTasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      doneapproved: PropTypes.bool,
      date_1: PropTypes.string,
      dateend_1: PropTypes.string,
      name: PropTypes.string,
      done: PropTypes.bool,
      unreadCount: PropTypes.number,
      targetname_1: PropTypes.string,
      targetrole: PropTypes.string,
    }),
  ),
};

MyTasksList.defaultProps = {
  myTasks: null,
};

export default connect()(MyTasksList);
