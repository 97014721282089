import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Styles
import './ModalError.scss';

const ModalError = ({ children, className }) => (
  <div
    className={cx('modal-error', {
      [className]: className,
    })}
  >
    <p className="modal-error__msg">{children}</p>
  </div>
);

ModalError.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ModalError.defaultProps = {
  // children: null,
  className: null,
};

export default ModalError;
