import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Icons
import { FaDownload } from 'react-icons/fa';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import Loader from 'components/Loader/Loader';

// Actions
import { getEmploymentUserReport, clearEmploymentUserReport } from 'actions';

const ReportResponseUserModal = ({ closeHandler, reportType, userId, dispatch, report }) => {
  const [isModalLoading, setIsModalLoading] = useState(true);

  useEffect(() => {
    dispatch(getEmploymentUserReport(userId, reportType))
      .then(() => setIsModalLoading(false))
      .catch(() => setIsModalLoading(false));
  }, [dispatch, userId, reportType]);

  return (
    <React.Fragment>
      <ModalHeader title={report && report.name ? report.name : 'Формируется отчет'} />

      {isModalLoading && <Loader className="loader_modal" />}

      {!isModalLoading && report && (
        <ModalBody>
          {report.eventsLong && Array.isArray(report.eventsLong) && report.eventsLong.length > 0 && (
            <table className="table table-stripped table-bordered">
              <thead>
                <tr>
                  <th>Объект</th>
                  <th>Событие</th>
                  <th>Дата</th>
                  <th>Примечание</th>
                </tr>
              </thead>

              <tbody>
                {/* eslint-disable react/no-array-index-key */}
                {report.eventsLong.map((log, index) => (
                  <tr key={index}>
                    <td>
                      {log.repair && (
                        <>
                          {log.repair.ksname} {log.repair.gpa} Ст. № {log.repair.stationnumber}{' '}
                          <span className={`label label_${log.repair.labelclass}`}>
                            {log.repair.type_s}
                          </span>
                        </>
                      )}
                    </td>

                    <td>{log.name}</td>

                    <td>
                      {log.date_start && log.date_end && (
                        <React.Fragment>
                          <b style={{ whiteSpace: 'nowrap' }}>{log.date_start}</b> <br /> &mdash;{' '}
                          <br /> <b style={{ whiteSpace: 'nowrap' }}>{log.date_end}</b>
                        </React.Fragment>
                      )}

                      {log.date_start && !log.date_end && (
                        <React.Fragment>
                          <b style={{ whiteSpace: 'nowrap' }}>{log.date_start}</b> <br /> &mdash;{' '}
                          <br /> <b style={{ whiteSpace: 'nowrap' }}>текущий момент</b>
                        </React.Fragment>
                      )}
                    </td>

                    <td>
                      {log.text}
                      {log.task && (
                        <React.Fragment>
                          <span className="label label_primary">{log.task.author_name}</span>
                          <span className="label label_danger">{log.task.role}</span>
                        </React.Fragment>
                      )}

                      {log.problem && (
                        <React.Fragment>
                          <span className="label label_primary">{log.problem.author_name}</span>
                          <span className="label label_danger">{log.problem.role}</span>
                        </React.Fragment>
                      )}
                    </td>
                  </tr>
                ))}
                {/* eslint-enable react/no-array-index-key */}
              </tbody>
            </table>
          )}

          {report.eventsLong &&
            Array.isArray(report.eventsLong) &&
            report.eventsLong.length === 0 && (
              <div className="modal-body__noty">Отчет за выбранный период отсутствует</div>
            )}
        </ModalBody>
      )}

      <ModalFooter closeHandler={closeHandler}>
        {!isModalLoading &&
          report &&
          report.eventsLong &&
          Array.isArray(report.eventsLong) &&
          report.eventsLong.length > 0 && (
            <a
              href={`${process.env.REACT_APP_API_URL}employment/_areportDownload_b${userId}_type${reportType}/`}
              download
              className="btn btn-primary"
            >
              <FaDownload className="icon" /> Скачать
            </a>
          )}

        <button
          type="button"
          className="btn btn-default"
          onClick={() => {
            dispatch(clearEmploymentUserReport());
            closeHandler();
          }}
        >
          Закрыть
        </button>
      </ModalFooter>
    </React.Fragment>
  );
};

ReportResponseUserModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  reportType: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  report: PropTypes.shape({
    name: PropTypes.string,
    eventsLong: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        date_start: PropTypes.string,
        date_end: PropTypes.string,
        datetime_1: PropTypes.string,
        text: PropTypes.string,
        repair: PropTypes.shape({
          gpa: PropTypes.string,
          ksname: PropTypes.string,
          stationnumber: PropTypes.string,
          labelclass: PropTypes.string,
          type_s: PropTypes.string,
        }),
        task: PropTypes.shape({
          author_name: PropTypes.string,
          userRole: PropTypes.string,
        }),
        problem: PropTypes.shape({
          author_name: PropTypes.string,
          userRole: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
};

const mapStateToProps = state => ({
  report: state.mainReducer.employmentUserReport,
});

export default connect(mapStateToProps)(ReportResponseUserModal);
