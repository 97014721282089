import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';

// Icons
import { FaTruck, FaPaperclip, FaUser, FaShare, FaCommentAlt } from 'react-icons/fa';

// Components
import Checkbox from 'components/Checkbox/Checkbox';

// Utils
import { USER_ROLES, DESPATCH_STATUSES, DESPATCH_STATUSES_SHORT_HASH } from 'utils/constants';

import './TableTsControl.scss';

const TableTsControlRow = ({
  canEdit,
  userRole,
  info, 
  isAdditional, 
  onDespatchShipped, 
  onDespatchShippedCleared, 
  onDespatchCompleted, 
  onDespatchCompletedCleared,
  onReturnDespatchClick,
  onSendDespatchToArchive,
}) => {
  const handleShippedClick = useCallback(() => {
    info.isShipped ? onDespatchShippedCleared(info.id) : onDespatchShipped(info.id);
  }, [info, onDespatchShipped, onDespatchShippedCleared]);

  const handleCompletedClick = useCallback(() => {
    info.isCompleted ? onDespatchCompletedCleared(info.id) : onDespatchCompleted(info.id);
  }, [info, onDespatchCompleted, onDespatchCompletedCleared]);

  const handleReturnDespatchClick = useCallback(() => {
    onReturnDespatchClick && onReturnDespatchClick(info.id);
  }, [info, onReturnDespatchClick]);

  const handleSendToArchiveClick = useCallback(() => {
    onSendDespatchToArchive && onSendDespatchToArchive(info.id);
  }, [info, onSendDespatchToArchive]);

  return (
    <React.Fragment>
      {!info.isAdditional && ( 
        <tr className="table-tscontrol-tr">
          <td colSpan="16" className="table-tscontrol-td">
            <FaTruck className="icon" /> <span>{info.assignedCarName} ({info.assignedCarNumber}) - {info.assignedDriver_1 ? info.assignedDriver_1 : 'Не назначен' }</span>
          </td>
        </tr>
      )}
      <tr 
        className={cx(`table-tscontrol-tr table-tscontrol-tr__${DESPATCH_STATUSES_SHORT_HASH[info.status]}`, { "table-tscontrol-tr__plus": isAdditional })}
      >
        <td className="table-tscontrol-despatch-item">
          <Link to={`/despatch/${info.id}/details`}>
            {info.dateCreation} <div className="label label_default">{isAdditional ? 'Дозаявка' : 'Заявка'} {info.id}</div>
          </Link>
        </td>
        <td className="table-tscontrol__despatch-startlocation">
          {info.startLocation && info.startLocation.city}
        </td>
        <td className="table-tscontrol__despatch-destlocation">
          {info.destLocation && info.destLocation.city}
        </td>
        <td className="table-tscontrol__despatch-dates">
          {info.dateStart_1} - {info.dateEnd_1}
        </td>
        <td className="table-tscontrol__despatch-datefact">{info.dateFact_1}</td>
        <td className="table-tscontrol__despatch-size">{info.size}</td>
        <td className="table-tscontrol__despatch-weight">{info.weight}</td>
        <td className="table-tscontrol__despatch-nomenclature">
          {info.nomenclature}
          <div className="upload-docs">
            {info.nomenclatureUrl && (
              <a
                href={info.nomenclatureUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="label label_info"
              >
                <FaPaperclip className="icon" /> {info.shortNomenclature}
              </a>
            )}
          </div>
        </td>
        <td className="table-tscontrol__despatch-responsible">{info.responsibleFullName}</td>
        <td className="table-tscontrol__despatch-recipient">{info.recipientFullName}</td>
        <td className="table-tscontrol__despatch-urgency">{info.urgency}</td>
        <td className="table-tscontrol__despatch-status">{DESPATCH_STATUSES[info.status]}</td>
        <td className="table-tscontrol__despatch-responsible">
          <Checkbox
            onClick={handleShippedClick}
            checked={info.isShipped}
            // checked={pdoApproved}
            disabled={!info.canEdit}
            ariaLabel="Поставить отметку, что заявка отгружена"
          />
        </td>
        <td className="table-tscontrol__despatch-recipient">
          <Checkbox
            onClick={handleCompletedClick}
            // checked={omtsApproved}
            checked={info.isCompleted}
            disabled={!info.canEdit}
            ariaLabel="Поставить отметку, что заявка принята получателем"
            // style={{ marginRight: 4 }}
          />
        </td>
        <td className="table-tscontrol__despatch-return">
          {!info.isCompleted && (
            <button
              disabled={!info.canEdit}
              type="button"
              className="btn btn-xs btn-danger"
              title="Вернуть груз"
              onClick={handleReturnDespatchClick}
            >
              <FaShare className="icon" />
            </button>
          )}
        </td>
        <td className="table-tscontrol__last-td">
          {/*info.datesendreturn_1 && */canEdit && info.active && (userRole === USER_ROLES.ROLE_ADMIN || userRole !== USER_ROLES.ROLE_MASTER) && (
            <button
              disabled={info.status === 'ARCHIVE'}
              type="button"
              className="btn btn-info btn-xs"
              onClick={handleSendToArchiveClick}
              // onClick={() => activateModal(info.id, ModalTypes.ToArchive)}
            >
              В архив
            </button>
          )}
        </td>
      </tr>
    </React.Fragment>
  );
}

const TableTsControl = ({
  canEdit,
  userRole,
  despatches, 
  onDespatchShipped, 
  onDespatchShippedCleared, 
  onDespatchCompleted, 
  onDespatchCompletedCleared,
  onReturnDespatchClick,
  onSendDespatchToArchive,
}) => {
  return (
    <table className="table table-bordered table-tscontrol">
      <thead>
        <tr className="info">
          <th className="table-tscontrol-th" rowSpan="2">
            Заявки
          </th>
          <th className="table-tscontrol-th" rowSpan="2">
            Место отправления
          </th>
          <th className="table-tscontrol-th" rowSpan="2">
            Место назначения
          </th>
          <th className="table-tscontrol-th" rowSpan="2">
            Срок проведения перевозки
          </th>
          <th className="table-tscontrol-th" rowSpan="2">
            Фактический срок перевозки
          </th>
          <th className="table-tscontrol-th" rowSpan="2">
            Габариты (м)
          </th>
          <th className="table-tscontrol-th" rowSpan="2">
            Вес (кг)
          </th>
          <th className="table-tscontrol-th" rowSpan="2">
            Номенклатурный перечень
          </th>
          <th className="table-tscontrol-th" rowSpan="2">
            <abbr title="Ответственный за отправку">
              <FaUser className="icon" /> Ответственный за отправку
            </abbr>
          </th>
          <th className="table-tscontrol-th" rowSpan="2">
            <abbr title="Получатель груза">
              <FaUser className="icon" /> Получатель
            </abbr>
          </th>
          <th className="table-tscontrol-th" rowSpan="2">
            Срочность
          </th>
          <th className="table-tscontrol-th" rowSpan="2">
            Статус
          </th>
          <th className="table-tscontrol-th" colSpan="2">
            Подтвеждения
          </th>
          <th className="table-tscontrol-th" rowSpan="2">
            Вернуть груз
          </th>
          <th className="table-tscontrol-th" rowSpan="2">
            <abbr title="Примечания">
              <FaCommentAlt className="icon" /> Прим.
            </abbr>
          </th>
        </tr>
        <tr className="info">
          <th>Отправитель</th>
          <th>Получатель</th>
        </tr>
      </thead>
      <tbody>
        {despatches &&
          Array.isArray(despatches) &&
          despatches.length > 0 &&
          despatches.map(
            (despatch, i) =>
              (
                <React.Fragment>
                  <TableTsControlRow
                    canEdit={despatch.canEdit}
                    userRole={userRole}
                    info={despatch}
                    onDespatchShipped={onDespatchShipped}
                    onDespatchShippedCleared={onDespatchShippedCleared}
                    onDespatchCompleted={onDespatchCompleted}
                    onDespatchCompletedCleared={onDespatchCompletedCleared}
                    onReturnDespatchClick={onReturnDespatchClick}
                    onSendDespatchToArchive={onSendDespatchToArchive}
                  />
                  {despatch.additionalDespatches &&
                    Array.isArray(despatch.additionalDespatches) &&
                    despatch.additionalDespatches.length > 0 &&
                    despatch.additionalDespatches.map(
                      (despatchAdditional, i) =>
                        despatch.assignedCarId && (
                          <TableTsControlRow
                            isAdditional
                            canEdit={despatchAdditional.canEdit}
                            userRole={userRole}
                            info={despatchAdditional}
                            onDespatchShipped={onDespatchShipped}
                            onDespatchShippedCleared={onDespatchShippedCleared}
                            onDespatchCompleted={onDespatchCompleted}
                            onDespatchCompletedCleared={onDespatchCompletedCleared}
                            onReturnDespatchClick={onReturnDespatchClick}
                            onSendDespatchToArchive={onSendDespatchToArchive}
                          />
                        )
                      )
                  }
                </React.Fragment>
              )
        )}
      </tbody>
    </table>
  );
};

TableTsControl.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  userRole: PropTypes.number.isRequired,
  despatches: PropTypes.func.isRequired,
  onDespatchShipped: PropTypes.func.isRequired,
  onDespatchShippedCleared: PropTypes.func.isRequired,
  onDespatchCompleted: PropTypes.func.isRequired,
  onDespatchCompletedCleared: PropTypes.func.isRequired,
  onReturnDespatchClick: PropTypes.func.isRequired,
  onSendDespatchToArchive: PropTypes.func.isRequired,
};

export default connect()(TableTsControl);
