import React from 'react';
import './WorkTableLegend.scss';

const WorkTableLegend = () => (
  <div className="work-table-legend">
    <div className="work-table-legend__item">
      <div className="work-table-legend__item-color work-table-legend__item-color_active">
        Объект
      </div>
    </div>
    <div className="work-table-legend__item">
      <div className="work-table-legend__item-color work-table-legend__item-color_open">
        Открытый объект
      </div>
    </div>
    <div className="work-table-legend__item">
      <div className="work-table-legend__item-color work-table-legend__item-color_finished">
        Закончен
      </div>
    </div>
    <div className="work-table-legend__item">
      <div className="work-table-legend__item-color work-table-legend__item-color_avar">
        Аварийный ремонт
      </div>
    </div>
    <div className="work-table-legend__item">
      <div className="work-table-legend__item-color work-table-legend__item-color_garant">
        Гарантийный ремонт
      </div>
    </div>
  </div>
);

export default WorkTableLegend;
