import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Icons
import { FaPaperclip, FaServer, FaTrashAlt } from 'react-icons/fa';

// Components
import ProgressBar from 'components/ProgressBar/ProgressBar';
import SelectForm from 'components/SelectForm/SelectForm';

// Actions
import { resetRmmNodeUnreadCount, linkNodeToRepair } from 'actions';

export const ModalTypes = {
  Percent: 0,
  Receive: 1,
  ChangeForecast: 2,
  ReturnToKs: 3,
  ToArchive: 4,
  RemoveNode: 5,
  MoveNode: 6,
  Docs: 7,
  Comments: 8,
  ChangePriority: 9,
  ConfirmReceive: 10,
  ReceiveFromRmm: 11,
};

const TableRmmRow = ({
  canEdit,
  userRole,
  canSetPriority,
  node,
  repairsForLinking,
  selectFileForDocument,
  dispatch,
  activateModal,
}) => {
  const [repair, setRepair] = useState(null);

  useEffect(() => {
    if (
      Array.isArray(repairsForLinking) &&
      repairsForLinking[0] &&
      !(node.repair && node.repair.ksname)
    ) {
      setRepair(repairsForLinking[0]);
    }
  }, [repairsForLinking, node]);

  const selectRepair = repairItem => {
    setRepair(repairItem);
    if (repairItem && repairItem.value !== -1) {
      dispatch(linkNodeToRepair(node.id, repairItem.value))
        .then(() => {
          setRepair(null);
        })
        .catch(() => {});
    }
  };

  return (
    <tr key={node.id}>
      <td className={`table-rmm__priority-td table-rmm__priority-td_${node.priorityClass}`}>
        {Boolean(node.repair && node.repair.ksname) && (
          <span>
            {node.repair.ksname} {node.repair.gpa} №{node.repair.stationnumber}{' '}
            <span className={`label label_${node.repair.labelclass}`}>{node.repair.type}</span>
          </span>
        )}

        {!(node.repair && node.repair.ksname) && (
          <SelectForm
            selectedValue={repair}
            options={repairsForLinking}
            onChange={selectedOption => selectRepair(selectedOption)}
          />
        )}
      </td>

      <td>{node.name}</td>

      <td>
        <span className="label label_default">{node.datesend_1}</span>
      </td>

      <td>
        {!node.act && <span className="label label_danger">Акт не прикреплен</span>}

        {node.act && (
          <a href={node.act} target="_blank" rel="noopener noreferrer" className="label label_info">
            {node.shortact}
          </a>
        )}
      </td>

      <td>
        {!node.datereceipt_1 && canEdit && (
          <button
            type="button"
            className="btn btn-success btn-sm"
            onClick={() => activateModal(node.id, ModalTypes.Receive)}
          >
            Принять
          </button>
        )}

        {node.datereceipt_1 && <span className="label label_primary">{node.datereceipt_1}</span>}
      </td>

      <td>
        {node.datereceipt_1 && (
          <>
            {node.dateforecast_confirm ? (
              <span className="label label_success">{node.dateforecast_1}</span>
            ) : (
              <>
                {canEdit ? (
                  <button
                    type="button"
                    className="label label_danger"
                    onClick={() => activateModal(node.id, ModalTypes.ChangeForecast)}
                  >
                    {node.dateforecast_1}
                  </button>
                ) : (
                  <span className="label label_danger">{node.dateforecast_1}</span>
                )}
              </>
            )}
          </>
        )}
      </td>

      {/* SCHEDULE */}
      <td>
        {node.datereceipt_1 && (
          <div className="upload-docs">
            {!node.datesendreturn_1 && canEdit && (
              <React.Fragment>
                <button
                  type="button"
                  className="btn btn-xs btn-primary"
                  onClick={() => {
                    selectFileForDocument('schedule', node.id);
                  }}
                >
                  <FaPaperclip className="icon" />
                </button>{' '}
              </React.Fragment>
            )}

            {node.schedule && (
              <a
                href={node.schedule}
                target="_blank"
                rel="noopener noreferrer"
                className="label label_info"
              >
                {node.shortschedule}
              </a>
            )}
          </div>
        )}
      </td>
      {/* /SCHEDULE */}

      <td>
        <ProgressBar
          percent={node.percent}
          date={node.datereceipt_1}
          status={node.status}
          handleOpenModal={() => activateModal(node.id, ModalTypes.Percent)}
        />
      </td>

      <td>
        {Boolean(node.dateforecast_confirm && node.datesendreturn_1 === '' && canEdit) && (
          <button
            type="button"
            className="btn btn-sm btn-warning"
            onClick={() => activateModal(node.id, ModalTypes.ReturnToKs)}
          >
            Отправить
          </button>
        )}

        {Boolean(node.dateforecast_confirm && node.datesendreturn_1 !== '') && (
          <span className="label label_success">{node.datesendreturn_1}</span>
        )}
      </td>

      <td>
        <button
          type="button"
          className="table-rmm__docs-btn"
          onClick={() => activateModal(node.id, ModalTypes.Docs)}
        >
          <FaServer className="icon" />
        </button>
      </td>

      <td>
        <span className="label label_success">{node.datereceiptreturn_1}</span>{' '}
        {node.actreceipt && (
          <React.Fragment>
            <a
              href={node.actreceipt}
              target="_blank"
              rel="noopener noreferrer"
              className="label label_info"
            >
              Акт приемки
            </a>{' '}
            <br />
          </React.Fragment>
        )}
        {node.naklad && (
          <React.Fragment>
            <a
              href={node.naklad}
              target="_blank"
              rel="noopener noreferrer"
              className="label label_info"
            >
              Накладная
            </a>
            <br />
          </React.Fragment>
        )}
      </td>

      <td className="table-rmm__last-td">
        {node.unreadCount === 0 && (
          <React.Fragment>
            <button
              type="button"
              className="btn btn-xs btn-success btn-comments"
              onClick={() => activateModal(node.id, ModalTypes.Comments)}
            >
              Прим
            </button>{' '}
          </React.Fragment>
        )}

        {node.unreadCount > 0 && (
          <React.Fragment>
            <button
              type="button"
              className="btn btn-xs btn-danger btn-comments"
              onClick={() => {
                activateModal(node.id, ModalTypes.Comments);
                dispatch(resetRmmNodeUnreadCount(node.id));
              }}
            >
              Прим <span className="badge">{node.unreadCount}</span>
            </button>{' '}
          </React.Fragment>
        )}

        {node.datesendreturn_1 && canEdit && !node.archive && userRole !== 13 && (
          <React.Fragment>
            <button
              type="button"
              className="btn btn-info btn-xs"
              onClick={() => activateModal(node.id, ModalTypes.ToArchive)}
            >
              В архив
            </button>{' '}
          </React.Fragment>
        )}

        {canEdit && (
          <React.Fragment>
            <button
              type="button"
              className="btn btn-danger btn-xs"
              onClick={() => activateModal(node.id, ModalTypes.RemoveNode)}
            >
              <FaTrashAlt className="icon" />
            </button>{' '}
          </React.Fragment>
        )}

        {canSetPriority && (
          <React.Fragment>
            <button
              type="button"
              className={`btn btn-${node.priorityClass} btn-xs`}
              onClick={() => activateModal(node.id, ModalTypes.ChangePriority)}
            >
              Приоритет
            </button>{' '}
          </React.Fragment>
        )}

        {(userRole === 14 || userRole === 13 || userRole === 6) && (
          <React.Fragment>
            <br />
            <button
              type="button"
              className="btn btn-primary btn-xs"
              onClick={() => activateModal(node.id, ModalTypes.MoveNode)}
            >
              Переместить узел
            </button>
          </React.Fragment>
        )}
      </td>
    </tr>
  );
};

TableRmmRow.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  userRole: PropTypes.number.isRequired,
  canSetPriority: PropTypes.bool.isRequired,
  activateModal: PropTypes.func.isRequired,
  node: PropTypes.shape({
    repair: PropTypes.shape({
      ksname: PropTypes.string,
      gpa: PropTypes.string,
      stationnumber: PropTypes.string,
      labelclass: PropTypes.string,
      type: PropTypes.string,
    }),
    id: PropTypes.number,
    priorityClass: PropTypes.string,
    name: PropTypes.string,
    datesend_1: PropTypes.string,
    act: PropTypes.string,
    shortact: PropTypes.string,
    datereceipt_1: PropTypes.string,
    dateforecast_confirm: PropTypes.bool,
    dateforecast_1: PropTypes.string,
    datesendreturn_1: PropTypes.string,
    datereceiptreturn_1: PropTypes.string,
    schedule: PropTypes.string,
    shortschedule: PropTypes.string,
    status: PropTypes.string,
    percent: PropTypes.number,
    actreceipt: PropTypes.string,
    naklad: PropTypes.string,
    unreadCount: PropTypes.number,
    archive: PropTypes.bool,
    priority: PropTypes.number,
  }).isRequired,
  selectFileForDocument: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  repairsForLinking: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
};

export default connect()(TableRmmRow);
