import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Switch, Route, NavLink } from 'react-router-dom';
import { FaPlusSquare } from 'react-icons/fa';
import { connect } from 'react-redux';

// Components
import Modal from 'components/Modal/Modal';

import AddTransportFormModal from 'components/AddTransportFormModal/AddTransportFormModal';
import TableAssignTransport from 'components/TableAssignTransport/TableAssignTransport';
import AssignTransportModal from 'components/AssignTransportModal/AssignTransportModal';
import AssignDriverModal from 'components/AssignDriverModal/AssignDriverModal';
import TransportSendModal from 'components/TransportSendModal/TransportSendModal';
import TableTransport from 'components/TableTransport/TableTransport';
import Loader from 'components/Loader/Loader';
import Warning from 'components/Warning/Warning';

// Actions
import {
  loadTransport,
  loadDrivers,
} from 'actions/common';
import {
  loadDespatches,
  setDespatchPdoAppoved,
  setDespatchOmtsAppoved,
  clearDespatchPdoAppoved,
  clearDespatchOmtsAppoved,
  sendDespatch,
  clearTransportAssigned,
  clearDriverAssigned
} from 'actions/despatch';

// Utils
import * as propTypes from 'utils/propTypes';
import { groupBy } from 'utils/_';

// Styles
import './Transport.scss';

const TransportNavigation = ({ url, className }) => {
  return (
    <nav className={cx('transport-nav', { [className]: className })}>
      <NavLink
        exact
        to={url}
        className="transport-nav__link"
        activeClassName="transport-nav__link_active"
      >
        Отправления
      </NavLink>
      <NavLink
        exact
        to={`${url}/transport`}
        className="transport-nav__link"
        activeClassName="transport-nav__link_active"
      >
        Доступный транспорт
      </NavLink>
    </nav>
  );
};

TransportNavigation.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
};

TransportNavigation.defaultProps = {
  className: null,
};

const Transport = ({
  match,
  location,
  transport,
  dispatch,
  users,
  despatches,
  despatchesTransportStatuses,
  canEdit,
  user,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAddTransportActive, setAddTransportActive] = useState(false);
  const [isAssignTransportActive, setAssignTransportActive] = useState(false);
  const [isAssignDriverActive, setAssignDriverActive] = useState(false);
  const [isTransportSendActive, setTransportSendActive] = useState(false);
  const [comment, setComment] = useState(true);
  const [transportData, setTransportData] = useState([]);
  const [nodeId, setNodeId] = useState(null);
  const [transportId, setTransportId] = useState(null);
  const [driverId, setDriverId] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [fieldErrors, setFieldErrors] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [responseError, setResponseError] = useState(null);
  const [groupedDespatches, setGroupedDespatches] = useState({});

  useEffect(() => {
    transport && transport.length && setTransportData(transport.map(v => ({ ...v, label: v.name })));
  }, [transport]);

  /** Запрашиваем информацию по транспорту и водителям */
  useEffect(() => {
    dispatch(loadTransport({}))
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });

    dispatch(loadDrivers())
      .then(() => {})
      .catch(() => {})
      .finally(() => {});

    dispatch(loadDespatches({ 'status[inTransit]': false }))
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [dispatch]);

  useEffect(() => {
    const groupedDespatches = groupBy(despatches, item => item.assignedCarId ? 'carAssigned' : 'notAssigned');

    setGroupedDespatches(groupedDespatches);
  }, [despatches]);

  const activateAddTransportFormModal = () => {
    setAddTransportActive(true);
  };

  const deactivateAddTransportFormModal = () => {
    setAddTransportActive(false);
  };

  const activateAssignTransportModal = () => {
    setAssignTransportActive(true);
  };

  const deactivateAssignTransportModal = () => {
    setAssignTransportActive(false);
  };

  const activateAssignDriverModal = () => {
    setAssignDriverActive(true);
  };

  const deactivateAssignDriverModal = () => {
    setAssignDriverActive(false);
  };

  const activateTransportSendModal = () => {
    setTransportSendActive(true);
  };

  const deactivateTransportSendModal = () => {
    setTransportSendActive(false);
  };

  const handleReturnProblem = (id, problemId) => {
    // eslint-disable-next-line no-restricted-globals, no-alert
  };

  const handleCommentChange = value => {
    setComment(value);
  };

  const handlePdoApproved = bid => {
    dispatch(setDespatchPdoAppoved(bid, comment))
      .then(() => {})
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const handleOmtsApproved = bid => {
    dispatch(setDespatchOmtsAppoved(bid, comment))
      .then(() => {})
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const handlePdoApprovedClear = bid => {
    dispatch(clearDespatchPdoAppoved(bid, comment))
      .then(() => {})
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleOmtsApprovedClear = bid => {
    dispatch(clearDespatchOmtsAppoved(bid, comment))
      .then(() => {})
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleTransportUnAssignedClick = bid => {
    dispatch(clearTransportAssigned(bid, user.id))
      .then(() => {})
      .catch(error => {})
      .finally(() => setIsLoading(false));
  }

  const handleUnAssignDriverClick = (transportId, driverId, bid) => {
    dispatch(clearDriverAssigned(transportId, driverId, bid, user.id))
      .then(() => {})
      .catch(error => {})
      .finally(() => setIsLoading(false));
  }

  const handleTransportAssignedClick = (bid, transportId) => {
    setNodeId(bid);
    setTransportId(transportId);

    activateAssignTransportModal();
  };

  const handleAssignDriverClick = (transportId, driverId, bid) => {
    setTransportId(transportId);
    setDriverId(driverId);
    setNodeId(bid);

    activateAssignDriverModal();
  };

  // const handleTransportClick = bid => {

  // };

  const handleTransportSendClick = (bid) => {
    setNodeId(bid);

    activateTransportSendModal();
  };

  const handleTransportSend = (bid, dateFact, responsible) => {
    dispatch(sendDespatch(bid, dateFact, responsible))
      .then(() => {
        deactivateTransportSendModal();
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <React.Fragment>
      {isLoading && <Loader className="loader_page" />}

      {!isLoading && (
        <div className="transport-page">
          <div className="transport-page__container container">
            <h1 className="transport-page__title">Транспортный участок</h1>
            <div className="transport-page__content">
              <div className="transport-page__header">
                <TransportNavigation
                  url={match.url}
                  className={cx({
                    'transport-nav_wide':
                      location &&
                      (location.pathname.indexOf('transport') > -1 ||
                        location.pathname.indexOf('mtr') > -1),
                  })}
                />
                {/*<h2 className="transport-page__title">Отправления</h2>*/}
              </div>

              <div className="" />

              <div className="transport-page__col">
                <Switch>
                  <Route
                    path={match.path}
                    exact
                    render={() => (
                      <React.Fragment>
                        <div className="transport-content__header">
                          <h2 className="transport-content__title">Отправления</h2>
                          <hr />
                        </div>

                        {isLoading && <Loader />}

                        {!isLoading && 
                          despatches && 
                          Array.isArray(despatches) && 
                          despatches.length > 0 && 
                          Object.keys(groupedDespatches).map(loc => (
                            <TableAssignTransport
                              userRole={user.userRole}
                              canEdit={canEdit}
                              data={groupedDespatches[loc]}
                              group={loc}
                              onPdoApproved={handlePdoApproved}
                              onOmtsApproved={handleOmtsApproved}
                              onPdoApprovedClear={handlePdoApprovedClear}
                              onOmtsApprovedClear={handleOmtsApprovedClear}
                              onTransportAssign={handleTransportAssignedClick}
                              onTransportUnAssign={handleTransportUnAssignedClick}
                              onDriverAssign={handleAssignDriverClick}
                              onDriverUnAssign={handleUnAssignDriverClick}
                              onSendDespatchClick={handleTransportSendClick}
                              onReturnClick={handleReturnProblem}
                            />
                          ))
                        }

                        {!isLoading && despatches && Array.isArray(despatches) && despatches.length <= 0 && (
                          <Warning msg="Нет отправлений" />
                        )}
                      </React.Fragment>
                    )}
                  />

                  <Route
                    exact
                    path={`${match.path}/transport`}
                    render={() => (
                      <React.Fragment>
                        <div className="transport-content__header">
                          <h2 className="transport-content__title">Доступный транспорт</h2>
                          <hr />
                        </div>

                        {isLoading && <Loader />}

                        {!isLoading && transportData && Array.isArray(transportData) && transportData.length > 0 && (
                          <>
                          <TableTransport
                            canEdit={canEdit}
                            data={transportData}
                            showTools
                            onAssignDriverClick={handleAssignDriverClick}
                            onTransportSendClick={handleTransportSendClick}
                          />
                          {(canEdit) && (
                            <div className="transport-page__tools">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={activateAddTransportFormModal}
                              >
                                <FaPlusSquare className="icon" /> Добавить транспорт
                              </button>
                            </div>
                          )}
                          </>
                        )}

                        {!isLoading && transportData && Array.isArray(transportData) && transportData.length <= 0 && (
                          <Warning msg="Нет транспорта" />
                        )}
                      </React.Fragment>
                    )}
                  />
                </Switch>
              </div>
            </div>
          </div>

          {isAddTransportActive && canEdit && (
            <Modal title="Добавление транспорта" closeHandler={deactivateAddTransportFormModal}>
              <AddTransportFormModal closeHandler={deactivateAddTransportFormModal} />
            </Modal>
          )}

          {isAssignDriverActive && (
            <Modal title="Подкрепление водителя" closeHandler={deactivateAssignDriverModal}>
              <AssignDriverModal
                despatchId={nodeId}
                transportId={transportId}
                userId={user.id}
                driverId={driverId}
                closeHandler={deactivateAssignDriverModal}
                comment={comment}
                onCommentChange={handleCommentChange}
              />
            </Modal>
          )}

          {isAssignTransportActive && (
            <Modal title="Подкрепление транспорта" closeHandler={deactivateAssignTransportModal}>
              <AssignTransportModal
                transportData={transportData}
                despatchId={nodeId}
                transportId={transportId}
                closeHandler={deactivateAssignTransportModal}
                onCommentChange={handleCommentChange}
                comment={comment}
              />
            </Modal>
          )}
          {isTransportSendActive && (
            <Modal title="Отправка транспорта" closeHandler={deactivateTransportSendModal}>
              <TransportSendModal
                closeHandler={deactivateTransportSendModal}
                users={users}
                despatchId={nodeId}
                onTransportSendSubmit={handleTransportSend}
              />
            </Modal>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

Transport.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      rolename: PropTypes.string,
      name_1: PropTypes.string,
    }),
  ).isRequired,
  transport: PropTypes.arrayOf(propTypes.transportData),
  user: PropTypes.shape({
    id: PropTypes.number,
    userRole: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = state => ({
  dispatch: state.mainReducer.dispatch,
  canEdit: state.despatchReducer.canEdit,
  users: state.mainReducer.headerData.users,
  transport: state.commonReducer.transport,
  despatches: state.despatchReducer.content,
  despatchesTransportStatuses: state.despatchReducer.transportStatuses,
  user: state.mainReducer.user,
});

export default connect(mapStateToProps)(Transport);
