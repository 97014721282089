import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Modal from 'components/Modal/Modal';
import RemoveCustomDocConfirmModal from 'components/RemoveCustomDocConfirmModal/RemoveCustomDocConfirmModal';
import DocMultiRow, { docTypeMultiFiles } from 'components/DocMultiRow/DocMultiRow';

// Actions
import { uploadRepairMultiDocument } from 'actions';

// Misc
import { browseServer } from 'helpers/file-manager';

// Styles
import './NonUrgentDocs.scss';

const NonUrgentDocs = ({ canEdit, veddefect, actdefect, customDocuments, repairId, dispatch }) => {
  const [isRemoveCustomDocConfirmActive, setRemoveCustomDocConfirmActive] = useState(false);
  const [removeCustomDocId, setRemoveCustomDocId] = useState(null);

  const activateRemoveCustomDocConfirmModal = docId => {
    setRemoveCustomDocId(docId);
    setRemoveCustomDocConfirmActive(true);
  };
  const deactivateRemoveCustomDocConfirmModal = () => {
    setRemoveCustomDocConfirmActive(false);
  };

  const selectFileForMultiDocument = (type, index, isCustomDocument = false) => {
    browseServer({
      onSuccess: fileUrl => {
        dispatch(
          uploadRepairMultiDocument(repairId, type, index, fileUrl, 'add', isCustomDocument),
        ).catch(() => {});
      },
    });
  };

  const deleteFileForMultiDocument = (type, index, isCustomDocument = false) => {
    dispatch(
      uploadRepairMultiDocument(repairId, type, index, 'fake', 'delete', isCustomDocument),
    ).catch(() => {});
  };

  return (
    <div className="panel panel_default">
      <div className="panel__heading">Бессрочные документы</div>

      <table className="table table-striped table-bordered table-docs">
        <thead>
          <tr>
            <th>Документ</th>
            <th>Файл</th>
          </tr>
        </thead>

        <tbody>
          {veddefect && (
            <DocMultiRow
              repairId={repairId}
              canEdit={canEdit}
              isAllowedToEditNoNeed={false}
              doc={veddefect}
              docName="Дефектные ведомости"
              docType="veddefect"
              selectFileForDocument={selectFileForMultiDocument}
              deleteFileForDocument={deleteFileForMultiDocument}
              isShownAuthor={false}
              isShownDate={false}
            />
          )}

          {actdefect && (
            <DocMultiRow
              repairId={repairId}
              canEdit={canEdit}
              isAllowedToEditNoNeed={false}
              doc={actdefect}
              docName="Акты дефектоскопии"
              docType="actdefect"
              selectFileForDocument={selectFileForMultiDocument}
              deleteFileForDocument={deleteFileForMultiDocument}
              isShownAuthor={false}
              isShownDate={false}
            />
          )}

          {customDocuments &&
            Array.isArray(customDocuments) &&
            customDocuments.map(customDoc => (
              <DocMultiRow
                key={customDoc.id}
                repairId={repairId}
                canEdit={canEdit}
                isAllowedToEditNoNeed={false}
                doc={customDoc}
                docName={customDoc.name}
                docType={customDoc.spname}
                selectFileForDocument={selectFileForMultiDocument}
                deleteFileForDocument={deleteFileForMultiDocument}
                isShownAuthor={false}
                isShownDate={false}
                customDocument={{
                  id: customDoc.id,
                  removeCustomDoc: activateRemoveCustomDocConfirmModal,
                }}
              />
            ))}

          {isRemoveCustomDocConfirmActive && (
            <Modal title="Удаление документа" closeHandler={deactivateRemoveCustomDocConfirmModal}>
              <RemoveCustomDocConfirmModal
                closeHandler={deactivateRemoveCustomDocConfirmModal}
                removeCustomDocId={removeCustomDocId}
                repairId={repairId}
              />
            </Modal>
          )}
        </tbody>
      </table>
    </div>
  );
};

NonUrgentDocs.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  repairId: PropTypes.number.isRequired,
  veddefect: docTypeMultiFiles.isRequired,
  actdefect: docTypeMultiFiles.isRequired,
  customDocuments: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      spname: PropTypes.string,
      files: PropTypes.arrayOf(
        PropTypes.shape({
          file: PropTypes.string,
          shortfile: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
};

export default connect()(NonUrgentDocs);
