import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Icons
import { FaFilter, FaTimes } from 'react-icons/fa';

// Components
import Modal from 'components/Modal/Modal';
import DespatchFiltersModal from 'components/DespatchFiltersModal/DespatchFiltersModal';

import './DespatchTableFilter.scss';

const DespatchTableFilter = ({
  statuses,
  userRole,
  isFilterActive,
  despatchFilter,
  handleSelectFilterOptions,
  handleResetFilter,
}) => {
  const [isDespatchFiltersActive, setDespatchFiltersActive] = useState(false);

  const activateDespatchFiltersModal = () => {
    setDespatchFiltersActive(true);
  };

  const deactivateDespatchFiltersModal = () => {
    setDespatchFiltersActive(false);
  };

  return (
    <>
      <button
        type="button"
        className="work-table-filter__btn btn btn-primary"
        onClick={activateDespatchFiltersModal}
      >
        Фильтры <FaFilter className="icon" />
      </button>

      {isFilterActive && (
        <button
          type="button"
          className="work-table-filter__btn btn btn-danger"
          onClick={handleResetFilter}
        >
          Сбросить фильтрацию <FaTimes className="icon" />
        </button>
      )}

      {isDespatchFiltersActive && (
        <Modal title="Подобрать по параметрам" closeHandler={deactivateDespatchFiltersModal}>
          <DespatchFiltersModal
            closeHandler={deactivateDespatchFiltersModal}
            statuses={statuses}
            userRole={userRole}
            isFilterActive={isFilterActive}
            despatchFilter={despatchFilter}
            handleSelectFilterOptions={handleSelectFilterOptions}
            handleResetFilter={handleResetFilter}
          />
        </Modal>
      )}
    </>
  );
};

DespatchTableFilter.propTypes = {
  statuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userRole: PropTypes.number.isRequired,
  isFilterActive: PropTypes.bool.isRequired,
  despatchFilter: PropTypes.shape({}).isRequired,
  handleSelectFilterOptions: PropTypes.func.isRequired,
  handleResetFilter: PropTypes.func.isRequired,
};

export default DespatchTableFilter;
