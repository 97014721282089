import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Icons
import { FaIdCard } from 'react-icons/fa';

// Utils
import * as propTypes from 'utils/propTypes';
import { TRANSPORT_STATUSES } from 'utils/constants';

// Styles
import './TableTransport.scss';

const TableTransport = ({ data, canEdit, showTools, onAssignDriverClick }) => {
  return (
    <table className="table table-transport table-bordered table-striped">
      <thead>
        <tr className="info">
          <th>Название</th>
          <th>Гос. номер</th>
          <th>Статус</th>
          <th>Грузопоъемность</th>
          <th>Вместимость</th>
          <th>Водитель</th>
        </tr>
      </thead>

      <colgroup>
        <col style={{ width: '300px' }} />
        <col style={{ width: '41px' }} />
        <col style={{ width: '150px' }} />
        <col style={{ width: '112px' }} />
        <col style={{ width: '167px' }} />
        <col style={{ width: '167px' }} />
      </colgroup>

      <tbody>
        {data &&
          Array.isArray(data) &&
          data.length > 0 &&
          data.map(transport => (
            <React.Fragment key={transport.id}>
              <tr className="table-transport-tr">
                <td className="table-transport-td">
                  <Link to={`despatch/${transport.name}`}>{transport.name}</Link>
                </td>
                <td className="table-transport-td">{transport.carNumber}</td>
                <td className="table-transport-td">{TRANSPORT_STATUSES[transport.status]}</td>
                <td className="table-transport-td">{transport.liftingCapacity}</td>
                <td className="table-transport-td">{transport.passengers}</td>
                <td className="table-transport-td">
                  <button
                    type="button"
                    className="btn btn-xs btn-warning"
                    title="Подкрепить водителя"
                    onClick={() => onAssignDriverClick()}
                  >
                    <FaIdCard className="icon" />
                  </button>
                </td>
              </tr>
            </React.Fragment>
          ))}
      </tbody>
    </table>
  );
};

TableTransport.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  showTools: PropTypes.bool,
  data: propTypes.despatchInfo,
  onAssignDriverClick: PropTypes.func.isRequired,
};

TableTransport.defaultProps = {
  data: null,
  showTools: true,
};

export default connect()(TableTransport);
