import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Icons
import { FaLongArrowAltRight } from 'react-icons/fa';

// Components
import Modal from 'components/Modal/Modal';
import TaskBigInfoModal from 'components/TaskBigInfoModal/TaskBigInfoModal';

// Styles
import './TasksListReport.scss';

const TasksListReport = ({ tasks }) => {
  const [activeModal, setActiveModal] = useState(null);

  const [currentTaskId, setCurrentTaskId] = useState(null);
  const [currentTask, setCurrentTask] = useState(null);

  const activateModal = taskId => {
    setCurrentTaskId(taskId);
    setActiveModal(true);
  };

  const deactivateModal = () => {
    setCurrentTaskId(null);
    setActiveModal(null);
  };

  useEffect(() => {
    if (currentTaskId) {
      setCurrentTask(tasks.find(item => item.id === currentTaskId));
    } else {
      setCurrentTask(null);
    }
  }, [currentTaskId, tasks]);

  return (
    <>
      <ul className="tasks-list-report">
        {Array.isArray(tasks) &&
          tasks.map(task => (
            <li key={task.id} className="tasks-list-report__item">
              <div className="tasks-list-report__item-info">
                {task.date_1 && (
                  <>
                    <span className="label label_primary">{task.date_1}</span> -{' '}
                  </>
                )}

                {task.dateend_1 && (
                  <>
                    <span className="label label_danger">{task.dateend_1}</span> -{' '}
                  </>
                )}

                <button
                  type="button"
                  className="tasks-list-report__item-link"
                  onClick={() => {
                    activateModal(task.id);
                  }}
                >
                  {task.name} {task.done && '(Выполнена, ждем подтверждения)'}
                </button>
              </div>

              <div className="tasks-list-report__item-tools">
                <span className="tasks-list-report__item-user label label_primary">
                  {task.authorname_1} ({task.authorrole})
                </span>{' '}
                <FaLongArrowAltRight className="icon" />{' '}
                <span className="label label_danger">
                  {task.targetname_1} ({task.targetrole})
                </span>
              </div>
            </li>
          ))}
      </ul>

      {activeModal && currentTask && (
        <Modal
          title={`Задача №${currentTaskId} «${currentTask.name}»`}
          closeHandler={deactivateModal}
        >
          <TaskBigInfoModal task={currentTask} closeHandler={deactivateModal} />
        </Modal>
      )}
    </>
  );
};

TasksListReport.propTypes = {
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      date_1: PropTypes.string,
      dateend_1: PropTypes.string,
      name: PropTypes.string,
      done: PropTypes.bool,
      authorname_1: PropTypes.string,
      authorrole: PropTypes.string,
      targetname_1: PropTypes.string,
      targetrole: PropTypes.string,
    }),
  ),
};

TasksListReport.defaultProps = {
  tasks: null,
};

export default TasksListReport;
