import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalError from 'components/ModalError/ModalError';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import TextArea from 'components/TextArea/TextArea';
import SelectForm from 'components/SelectForm/SelectForm';
import InputDate from 'components/InputDate/InputDate';
import FormGroup from 'components/FormGroup/FormGroup';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import Loader from 'components/Loader/Loader';

// Actions
import { getRepairProblemTargets, addRepairProblem } from 'actions';

// Helpers
import { formatDateYYYYmmDD } from 'helpers/formatDateYYYYmmDD';

const AddProblemModal = ({ closeHandler, dispatch, repairId, targets }) => {
  const [isModalLoading, setIsModalLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [nameProblem, setNameProblem] = useState('');
  const [targetProblem, setTargetProblem] = useState(null);
  const [dateEndProblem, setDateEndProblem] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  /** Запрашиваем список таргетов для проблемы */
  useEffect(() => {
    dispatch(getRepairProblemTargets())
      .then(data => {
        setTargetProblem(
          data.map(item => ({ value: item.id, label: `${item.name} (${item.role})` }))[0],
        );
        setIsModalLoading(false);
      })
      .catch(() => setIsModalLoading(false));
  }, [dispatch]);

  const submitRepairProblem = () => {
    /** Показываем прелоадер на кнопку */
    setIsSubmitLoading(true);

    /** Записываем новую проблему в базу данных */
    dispatch(
      addRepairProblem(
        repairId,
        nameProblem,
        targetProblem.value,
        formatDateYYYYmmDD(dateEndProblem),
      ),
    )
      .then(() => {
        /** Скрываем прелоадер на кнопку */
        setIsSubmitLoading(false);

        /** Закрываем модалку */
        closeHandler();
      })
      .catch(error => {
        /** Если есть ошибки, выводим */
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        /** Скрываем прелоадер на кнопку */
        setIsSubmitLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Добавление проблемы" />

      {isModalLoading && <Loader className="loader_modal" />}

      {!isModalLoading && targets && (
        <ModalBody>
          <FormGroup title="Проблема" error={fieldErrors.name}>
            <TextArea onChange={({ target }) => setNameProblem(target.value)} value={nameProblem} />
          </FormGroup>

          <FormGroup title="Исполнитель" error={fieldErrors.target}>
            <SelectForm
              selectedValue={targetProblem}
              options={targets.map(item => ({
                value: item.id,
                label: `${item.name} (${item.role})`,
              }))}
              onChange={selectedOption => setTargetProblem(selectedOption)}
            />
          </FormGroup>

          <FormGroup title="Срок исполнения" error={fieldErrors.dateEnd}>
            <InputDate selectedDate={dateEndProblem} onChange={date => setDateEndProblem(date)} />
          </FormGroup>
        </ModalBody>
      )}

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isValid={nameProblem === '' || !targetProblem || !dateEndProblem}
          isLoading={isSubmitLoading}
          isLoadingText="Добавляю..."
          clickHandler={submitRepairProblem}
        >
          Добавить
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

AddProblemModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  repairId: PropTypes.number.isRequired,
  targets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      userRole: PropTypes.string,
    }),
  ).isRequired,
};

const mapStateToProps = state => ({
  targets: state.mainReducer.repairProblemTargets,
});

export default connect(mapStateToProps)(AddProblemModal);
