import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Icons
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

// Components
import Modal from 'components/Modal/Modal';
import RemoveCoopWorkConfirmModal from 'components/RemoveCoopWorkConfirmModal/RemoveCoopWorkConfirmModal';
import EditCoopWorkModal from 'components/EditCoopWorkModal/EditCoopWorkModal';

const TableRepairCoop = ({ canEdit, coWork, repairId }) => {
  const [isRemoveCoopWorkConfirmActive, setRemoveCoopWorkConfirmActive] = useState(false);
  const [isEditCoopWorkActive, setEditCoopWorkActive] = useState(false);
  const [removeCoWorkId, setRemoveCoWorkId] = useState(null);
  const [editCoWork, setEditCoWork] = useState(null);
  const [removeCoWorkMakerId, setRemoveCoWorkMakerId] = useState(null);

  const activateRemoveCoopWorkConfirmModal = (makerId, workId) => {
    setRemoveCoWorkMakerId(makerId);
    setRemoveCoWorkId(workId);
    setRemoveCoopWorkConfirmActive(true);
  };

  const deactivateRemoveCoopWorkConfirmModal = () => {
    setRemoveCoopWorkConfirmActive(false);
  };

  const activateEditCoopWorkModal = coWorkItem => {
    setEditCoWork(coWorkItem);
    setEditCoopWorkActive(true);
  };

  const deactivateEditCoopWorkModal = () => {
    setEditCoopWorkActive(false);
  };

  return (
    <div className="panel panel_default">
      <div className="panel__heading">{coWork.maker}</div>

      <table className="table table-striped table-bordered">
        <colgroup>
          {canEdit && (
            <React.Fragment>
              <col style={{ width: '10%' }} />
              <col style={{ width: '80%' }} />
              <col style={{ width: '10%' }} />
            </React.Fragment>
          )}

          {!canEdit && (
            <React.Fragment>
              <col style={{ width: '10%' }} />
              <col style={{ width: '90%' }} />
            </React.Fragment>
          )}
        </colgroup>

        <thead>
          <tr>
            <th>Дата</th>
            <th>Работы</th>
            {canEdit && <th> </th>}
          </tr>
        </thead>

        <tbody>
          {coWork.works &&
            Array.isArray(coWork.works) &&
            coWork.works.map(work => (
              <tr key={work.id}>
                <td>{work.date_1}</td>
                <td>{work.name}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-primary btn-xs"
                    title="Редактировать"
                    onClick={() => activateEditCoopWorkModal(work)}
                  >
                    <FaEdit className="icon" />
                  </button>{' '}
                  <button
                    type="button"
                    className="btn btn-danger btn-xs"
                    title="Удалить"
                    onClick={() => activateRemoveCoopWorkConfirmModal(coWork.id, work.id)}
                  >
                    <FaTrashAlt className="icon" />
                  </button>
                </td>
              </tr>
            ))}

          {isRemoveCoopWorkConfirmActive && (
            <Modal title="Удаление работы" closeHandler={deactivateRemoveCoopWorkConfirmModal}>
              <RemoveCoopWorkConfirmModal
                closeHandler={deactivateRemoveCoopWorkConfirmModal}
                repairId={repairId}
                removeCoWorkMakerId={removeCoWorkMakerId}
                removeCoWorkId={removeCoWorkId}
              />
            </Modal>
          )}

          {isEditCoopWorkActive && (
            <Modal title="Редактирование работы" closeHandler={deactivateEditCoopWorkModal}>
              <EditCoopWorkModal
                closeHandler={deactivateEditCoopWorkModal}
                repairId={repairId}
                coWork={editCoWork}
              />
            </Modal>
          )}
        </tbody>
      </table>
    </div>
  );
};

TableRepairCoop.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  repairId: PropTypes.number.isRequired,
  coWork: PropTypes.shape({
    id: PropTypes.number,
    maker: PropTypes.string,
    works: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        date_1: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default TableRepairCoop;
