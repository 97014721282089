import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Icons
import { FaPaperclip, FaTrashAlt } from 'react-icons/fa';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { setTaskDone, uploadTaskDocument } from 'actions';

// Misc
import { browseServer } from 'helpers/file-manager';

const TaskInInfoModal = ({ closeHandler, task, dispatch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseError, setResponseError] = useState(null);

  const selectFile = index => {
    browseServer({
      onSuccess: fileUrl => {
        dispatch(uploadTaskDocument(task.id, 'toMe', index, fileUrl)).catch(() => {});
      },
    });
  };

  const deleteFile = index => {
    dispatch(uploadTaskDocument(task.id, 'toMe', index, 'fake', 'delete')).catch(() => {});
  };

  const handleTaskDone = () => {
    setIsLoading(true);

    dispatch(setTaskDone(task.id))
      .then(() => {
        setIsLoading(false);

        closeHandler();
      })
      .catch(error => {
        if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader className="modal-header_has-sub-title">
        <h4 className="modal-header__title">
          Задача №{task.id} «{task.name}»
        </h4>
        <h5 className="modal-header__sub-title">
          Назначена кем: <span className="label label_primary">{task.authorname_1}</span>{' '}
          <span className="label label_info">{task.authorrole}</span>
        </h5>
      </ModalHeader>

      <ModalBody>
        <div className="modal__section">
          <div className="modal__section-title">Текст задачи:</div>
          <div className="modal__section-text">{task.text}</div>
        </div>

        <div className="modal__section">
          {/* UPLOAD DOCS */}

          {/* eslint-disable react/no-array-index-key */}
          {task.files &&
            Array.isArray(task.files) &&
            task.files.length > 0 &&
            task.files.map((item, index) => (
              <div className="upload-docs" key={index}>
                <button
                  type="button"
                  className="btn btn-xs btn-primary"
                  onClick={() => {
                    selectFile(index);
                  }}
                >
                  <FaPaperclip className="icon" />
                </button>{' '}
                {item.file && (
                  <React.Fragment>
                    <a
                      href={item.file}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="label label_info"
                    >
                      {item.shortfile}
                    </a>{' '}
                  </React.Fragment>
                )}
                {item.file && (
                  <button
                    type="button"
                    className="btn btn-danger btn-xs"
                    onClick={() => {
                      deleteFile(index);
                    }}
                  >
                    <FaTrashAlt className="icon" />
                  </button>
                )}
              </div>
            ))}
          {/* eslint-enable react/no-array-index-key */}

          {/* UPLOAD DOCS END */}
        </div>

        {task.done && (
          <div className="modal__task-status">Задача выполнена {task.datetimefact_3}</div>
        )}
      </ModalBody>

      {responseError && <ModalError>{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        {!task.done && !task.doneapproved && (
          <SubmitButton
            isLoading={isLoading}
            isLoadingText="Сохранаю..."
            clickHandler={handleTaskDone}
          >
            Задача выполнена
          </SubmitButton>
        )}

        {task.done && !task.doneapproved && (
          <button type="button" className="btn btn-default btn-disabled">
            Ждем подтверждения
          </button>
        )}

        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>
      </ModalFooter>
    </React.Fragment>
  );
};

TaskInInfoModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  task: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    done: PropTypes.bool,
    authorname_1: PropTypes.string,
    authorrole: PropTypes.string,
    text: PropTypes.string,
    datetimefact_3: PropTypes.string,
    doneapproved: PropTypes.bool,
    files: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        file: PropTypes.string,
        shortfile: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default connect()(TaskInInfoModal);
