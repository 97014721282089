import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { NavLink } from 'react-router-dom';

// Styles
import './Pagination.scss';

const Pagination = ({ pagesCount, currentPage, onChangeCurrentPage, asLinks, linkTemplate }) => {
  const pages = new Array(pagesCount).fill(true).map((item, index) => index);

  const renderPage = (page, content) => {
    if (asLinks) {
      return (
        <NavLink
          className="pagination__list-item-btn"
          activeClassName="pagination__list-item-btn_current"
          to={linkTemplate.replace('%%PAGE%%', page)}
        >
          {content}
        </NavLink>
      );
    }
    return (
      <button
        type="button"
        className={cx('pagination__list-item-btn', {
          'pagination__list-item-btn_current': currentPage === page.number,
        })}
        onClick={() => onChangeCurrentPage(page)}
      >
        {content}
      </button>
    );
  };

  return (
    <nav className="pagination">
      <ul className="pagination__list">
        <li className="pagination__list-item">
          {asLinks ? (
            <NavLink
              className="pagination__list-item-btn"
              disabled={currentPage === 0}
              to={linkTemplate.replace('%%PAGE%%', currentPage - 1)}
            >
              <span className="pagination__list-item-btn-text">&laquo;</span>
            </NavLink>
          ) : (
            <button
              type="button"
              className="pagination__list-item-btn"
              disabled={currentPage === 0}
              onClick={() => onChangeCurrentPage(currentPage - 1)}
            >
              <span className="pagination__list-item-btn-text">&laquo;</span>
            </button>
          )}
        </li>

        {pages &&
          Array.isArray(pages) &&
          pages.map(page => (
            <li key={page} className="pagination__list-item">
              {renderPage(page, <span className="pagination__list-item-btn-text">{page + 1}</span>)}
            </li>
          ))}

        <li className="pagination__list-item">
          {asLinks ? (
            <NavLink
              className="pagination__list-item-btn"
              disabled={currentPage === pagesCount - 1}
              to={linkTemplate.replace('%%PAGE%%', currentPage + 1)}
            >
              <span className="pagination__list-item-btn-text">&raquo;</span>
            </NavLink>
          ) : (
            <button
              type="button"
              className="pagination__list-item-btn"
              disabled={currentPage === pagesCount - 1}
              onClick={() => onChangeCurrentPage(currentPage + 1)}
            >
              <span className="pagination__list-item-btn-text">&raquo;</span>
            </button>
          )}
        </li>
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  pagesCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onChangeCurrentPage: PropTypes.func,
  asLinks: PropTypes.bool,
  linkTemplate: PropTypes.string,
};

Pagination.defaultProps = {
  asLinks: false,
  linkTemplate: null,
  onChangeCurrentPage: () => {},
};

export default Pagination;
