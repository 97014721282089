import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import WorkTableLegend from 'components/WorkTableLegend/WorkTableLegend';
import WorkTableFilter from 'components/WorkTableFilter/WorkTableFilter';
import WorkTable from 'components/WorkTable/WorkTable';
import Loader from 'components/Loader/Loader';
import Warning from 'components/Warning/Warning';

// Actions
import { getRepairsList } from 'actions';

// Styles
import './Main.scss';

const Main = ({ dispatch, repairsData, user }) => {
  const filterOptions = {
    orderMan: [],
    ks: [],
    gpa: [],
    type: [],
    status: [],
    self: [],
    master: [],
    nu: [],
  };
  const [isLoading, setIsLoading] = useState(true);

  const [isFilterActive, setIsFilterActive] = useState(false);
  const [repairFilter, setRepairFilter] = useState({
    ...filterOptions,
  });

  useEffect(() => {
    dispatch(getRepairsList())
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch]);

  return (
    <React.Fragment>
      {isLoading && <Loader className="loader_page" />}

      {!isLoading && repairsData && repairsData.ordermantoperiod && user && user.userRole && (
        <div className="main">
          <div className="main__container container">
            <h1 className="main__title">План-график на {repairsData.periodname} год</h1>

            <WorkTableLegend />

            {Array.isArray(repairsData.ordermantoperiod) &&
              repairsData.ordermantoperiod.length > 0 && (
                <>
                  <WorkTableFilter
                    userRole={user.userRole}
                    orderManToPeriod={repairsData.ordermantoperiod}
                    gpas={repairsData.gpas}
                    types={repairsData.types}
                    statuses={repairsData.statuses}
                    master={repairsData.master}
                    nu={repairsData.nu}
                    repairFilter={repairFilter}
                    isFilterActive={isFilterActive}
                    handleSelectFilterOptions={(type, value) => {
                      setIsFilterActive(true);

                      setRepairFilter(prevState => ({
                        ...prevState,
                        [type]: prevState[type].includes(value)
                          ? prevState[type].filter(item => item !== value)
                          : [value, ...prevState[type]],
                      }));
                    }}
                    handleResetFilter={() => {
                      setIsFilterActive(false);

                      setRepairFilter({
                        ...filterOptions,
                      });
                    }}
                  />

                  <WorkTable
                    userRole={user.userRole}
                    userId={user.id}
                    repairFilter={repairFilter}
                    orderManToPeriod={repairsData.ordermantoperiod}
                    needCheck={repairsData.needCheck}
                  />
                </>
              )}

            {Array.isArray(repairsData.ordermantoperiod) &&
              repairsData.ordermantoperiod.length === 0 && (
                <Warning msg="Пока не добавлено объектов" />
              )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

Main.propTypes = {
  dispatch: PropTypes.func.isRequired,
  repairsData: PropTypes.shape({
    periodname: PropTypes.string,
    needCheck: PropTypes.bool,
    ordermantoperiod: PropTypes.arrayOf(PropTypes.shape({})),
    gpas: PropTypes.arrayOf(PropTypes.shape({})),
    types: PropTypes.arrayOf(PropTypes.shape({})),
    statuses: PropTypes.arrayOf(PropTypes.shape({})),
    master: PropTypes.arrayOf(PropTypes.shape({})),
    nu: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  user: PropTypes.shape({
    userRole: PropTypes.number,
    id: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = state => ({
  repairsData: state.mainReducer.repairsData,
  user: state.mainReducer.user,
});

export default connect(mapStateToProps)(Main);
