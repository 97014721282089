import React, { useState } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import useToggleAndOutClick from 'use-toggle-and-outclick';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Icons
import { FaPowerOff, FaUser, FaSignOutAlt } from 'react-icons/fa';

// Components
import Modal from 'components/Modal/Modal';
import EmulateUserModal from 'components/EmulateUserModal/EmulateUserModal';
import ChoosePeriodModal from 'components/ChoosePeriodModal/ChoosePeriodModal';

// Actions
import { logOut, finishEmulateUser } from 'actions';

// Styles
import '../TopNavigation/TopNavigation.scss';

const UserNavigation = ({ isSuper, user, period, dispatch, userData }) => {
  const [
    isDropUserNavOpened,
    dropUserNavRef,
    toggleDropUserNav,
    closeExplicitlyUserNav,
  ] = useToggleAndOutClick();

  const [isEmulateUserActive, setEmulateUserActive] = useState(false);
  const [isChoosePeriodActive, setChoosePeriodActive] = useState(false);

  const signOut = () => dispatch(logOut()).catch(() => {});

  const activateEmulateUserModal = () => {
    setEmulateUserActive(true);
  };

  const deactivateEmulateUserModal = () => {
    setEmulateUserActive(false);
  };

  const activateChoosePeriodModal = () => {
    setChoosePeriodActive(true);
  };

  const deactivateChoosePeriodModal = () => {
    setChoosePeriodActive(false);
  };

  return (
    <nav className="nav nav_user">
      <ul className="nav__list">
        <li className="nav__list-item">
          <button
            type="button"
            className={cx('nav__list-item-link', {
              'nav__list-item-link_active': isDropUserNavOpened,
            })}
            onClick={toggleDropUserNav}
          >
            {userData && userData.name_1}{' '}
            {user && user.emulatedBy && (
              <span className="label label_primary">{userData && userData.roleName} (Э)</span>
            )}
            {user && !user.emulatedBy && (
              <span className="label label_warning">{userData && userData.roleName}</span>
            )}{' '}
            <span className="nav__list-item-link-icon" />
          </button>

          {isDropUserNavOpened && (
            <nav className="nav nav_sub-nav" ref={dropUserNavRef}>
              <div className="nav__scroll-wrap">
                <ul className="nav__list">
                  {(user.userRole === 10 || isSuper) && (
                    <>
                      <li className="nav__list-item">
                        <Link
                          to="/admin"
                          className="nav__list-item-link"
                          onClick={closeExplicitlyUserNav}
                        >
                          <FaPowerOff className="icon" /> Администрирование
                        </Link>
                      </li>
                      <li className="nav__list-item">
                        <Link
                          to="/admin-users"
                          className="nav__list-item-link"
                          onClick={closeExplicitlyUserNav}
                        >
                          <FaUser className="icon" /> Пользователи базы
                        </Link>
                      </li>
                      <li className="nav__list-item nav__list-item_divider" />
                    </>
                  )}

                  <li className="nav__list-item">
                    <button
                      type="button"
                      className="nav__list-item-link"
                      onClick={() => {
                        closeExplicitlyUserNav();
                        activateChoosePeriodModal();
                      }}
                    >
                      Период: {period && period.name}
                    </button>
                  </li>

                  {isSuper && (
                    <>
                      <li className="nav__list-item">
                        <button
                          type="button"
                          className="nav__list-item-link"
                          onClick={() => {
                            closeExplicitlyUserNav();
                            activateEmulateUserModal();
                          }}
                        >
                          Эмуляция
                        </button>
                      </li>

                      {user && user.emulatedBy && (
                        <li className="nav__list-item">
                          <button
                            type="button"
                            className="nav__list-item-link"
                            onClick={() => {
                              dispatch(finishEmulateUser())
                                .then(() => {
                                  if (window && window.location && window.location.href) {
                                    window.location.href = '/';
                                  }
                                })
                                .catch(() => {});

                              closeExplicitlyUserNav();
                            }}
                          >
                            Закончить эмуляцию
                          </button>
                        </li>
                      )}
                    </>
                  )}

                  <li className="nav__list-item">
                    <button
                      type="button"
                      className="nav__list-item-link"
                      onClick={() => {
                        closeExplicitlyUserNav();
                        signOut();
                      }}
                    >
                      <FaSignOutAlt className="icon" /> Выйти
                    </button>
                  </li>
                </ul>
              </div>
            </nav>
          )}

          {isEmulateUserActive && (
            <Modal
              title="Выберите пользователя для эмуляции"
              closeHandler={deactivateEmulateUserModal}
            >
              <EmulateUserModal closeHandler={deactivateEmulateUserModal} />
            </Modal>
          )}

          {isChoosePeriodActive && (
            <Modal title="Выберите период для работы" closeHandler={deactivateChoosePeriodModal}>
              <ChoosePeriodModal closeHandler={deactivateChoosePeriodModal} />
            </Modal>
          )}
        </li>
      </ul>
    </nav>
  );
};

UserNavigation.propTypes = {
  isSuper: PropTypes.bool.isRequired,
  userData: PropTypes.shape({
    name_1: PropTypes.string,
    roleName: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    userRole: PropTypes.number,
    emulatedBy: PropTypes.shape({}),
  }).isRequired,
  period: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.mainReducer.user,
});

export default connect(mapStateToProps)(UserNavigation);
