import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import Modal from 'components/Modal/Modal';
import RejectedProlongModal from 'components/RejectedProlongModal/RejectedProlongModal';
import ReactionProlongModal from 'components/ReactionProlongModal/ReactionProlongModal';

const TableRepairProlong = ({ canConfirm, prolongs, repairId }) => {
  const [isRejectedProlongActive, setRejectedProlongActive] = useState(false);
  const [isReactionProlongActive, setReactionProlongActive] = useState(false);
  const [rejectedProlong, setRejectedProlong] = useState(null);
  const [reactionProlongId, setReactionProlongId] = useState(null);

  const activateRejectedProlongModal = itemProlong => {
    setRejectedProlong(itemProlong);
    setRejectedProlongActive(true);
  };

  const deactivateRejectedProlongModal = () => {
    setRejectedProlongActive(false);
  };

  const activateReactionProlongModal = id => {
    setReactionProlongId(id);
    setReactionProlongActive(true);
  };

  const deactivateReactionProlongModal = () => {
    setReactionProlongActive(false);
  };

  return (
    <table className="table table-bordered table-striped">
      <thead>
        <tr>
          <th>Отправлен</th>
          <th>Новая дата</th>
          <th>Новая дата (для проч.)</th>
          <th>График ремонта</th>
          <th>Акт расследования</th>
          <th>Причина</th>
          <th>Комментарий</th>
          <th>Статус</th>
        </tr>
      </thead>

      <tbody>
        {prolongs.map(item => (
          <tr key={item.id}>
            <td>{item.datetime_1}</td>
            <td>
              <span className="label label_default">{item.newdate_1}</span>
            </td>
            <td>
              {item.newdateforother && (
                <span className="label label_default">{item.newdateforother_1}</span>
              )}
            </td>
            <td>
              <a
                href={item.schedule}
                target="_blank"
                rel="noopener noreferrer"
                className="label label_info"
              >
                {item.shortschedule}
              </a>
            </td>
            <td>
              <a
                href={item.act}
                target="_blank"
                rel="noopener noreferrer"
                className="label label_info"
              >
                {item.shortact}
              </a>
            </td>
            <td>{item.reason}</td>
            <td>
              {item.comment} {item.movetoother && '(Авто прим.: перевод в прочий)'}
            </td>
            <td>
              {!item.confirm && !canConfirm && (
                <span className="label label_warning">Рассматривается</span>
              )}

              {!item.confirm && canConfirm && (
                <button
                  type="button"
                  className="label label_warning"
                  onClick={() => activateReactionProlongModal(item.id)}
                >
                  Рассмотреть
                </button>
              )}

              {item.confirm === '3' && (
                <button
                  type="button"
                  className="label label_success"
                  onClick={() => activateRejectedProlongModal(item)}
                >
                  Одобрено
                </button>
              )}

              {item.confirm === '2' && (
                <button
                  type="button"
                  className="label label_danger"
                  onClick={() => activateRejectedProlongModal(item)}
                >
                  Отклонено
                </button>
              )}
            </td>
          </tr>
        ))}

        {isRejectedProlongActive && (
          <Modal
            title="Результаты запроса на продление сроков"
            closeHandler={deactivateRejectedProlongModal}
          >
            <RejectedProlongModal
              prolong={rejectedProlong}
              closeHandler={deactivateRejectedProlongModal}
            />
          </Modal>
        )}

        {isReactionProlongActive && (
          <Modal
            title="Ответ на запрос о продлении сроков ремонта"
            closeHandler={deactivateReactionProlongModal}
          >
            <ReactionProlongModal
              closeHandler={deactivateReactionProlongModal}
              repairId={repairId}
              prolongId={reactionProlongId}
            />
          </Modal>
        )}
      </tbody>
    </table>
  );
};

TableRepairProlong.propTypes = {
  canConfirm: PropTypes.bool.isRequired,
  repairId: PropTypes.number.isRequired,
  prolongs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      datetime_1: PropTypes.string,
      newdate_1: PropTypes.string,
      newdateforother: PropTypes.string,
      newdateforother_1: PropTypes.string,
      schedule: PropTypes.string,
      shortschedule: PropTypes.string,
      act: PropTypes.string,
      shortact: PropTypes.string,
      reason: PropTypes.string,
      comment: PropTypes.string,
      movetoother: PropTypes.bool,
      confirm: PropTypes.string,
      datetimereaction_1: PropTypes.string,
      authorreactionname: PropTypes.string,
      rejectreason: PropTypes.string,
    }),
  ).isRequired,
};

export default TableRepairProlong;
