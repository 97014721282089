import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import TableRepairProlong from 'components/TableRepairProlong/TableRepairProlong';
import Modal from 'components/Modal/Modal';
import AddProlongModal from 'components/AddProlongModal/AddProlongModal';
import MoveToOtherFormModal from 'components/MoveToOtherFormModal/MoveToOtherFormModal';
import Loader from 'components/Loader/Loader';
import Warning from 'components/Warning/Warning';

// Actions
import { getRepairProlongs } from 'actions/index';

const RepairProlong = ({ canEdit, open, finished, repairId, dispatch, prolongs, reasons }) => {
  const [isAddProlongModalActive, setAddProlongModalActive] = useState(false);
  const [isMoveToOtherFormActive, setMoveToOtherFormActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const activateAddProlongModal = () => {
    setAddProlongModalActive(true);
  };

  const deactivateAddProlongModal = () => {
    setAddProlongModalActive(false);
  };

  const activateMoveToOtherFormModal = () => {
    setMoveToOtherFormActive(true);
  };

  const deactivateMoveToOtherFormModal = () => {
    setMoveToOtherFormActive(false);
  };

  /** Запрашиваем информацию по ремонту (Продления ремонта) */
  useEffect(() => {
    dispatch(getRepairProlongs(repairId))
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch, repairId]);

  return (
    <React.Fragment>
      <div className="repair-content__header">
        <h2 className="repair-content__title">Запросы на продление ремонта</h2>

        {canEdit && open && !finished && (
          <div className="repair-content__tools">
            <button type="button" className="btn btn-primary" onClick={activateAddProlongModal}>
              Добавить запрос
            </button>

            {isAddProlongModalActive && (
              <Modal
                title="Добавление запроса на продление сроков ремонта"
                closeHandler={deactivateAddProlongModal}
              >
                <AddProlongModal
                  openModal={activateMoveToOtherFormModal}
                  closeHandler={deactivateAddProlongModal}
                  reasons={reasons}
                  repairId={repairId}
                />
              </Modal>
            )}
          </div>
        )}
      </div>

      {isLoading && <Loader />}

      {!isLoading && prolongs && Array.isArray(prolongs) && prolongs.length > 0 && (
        <TableRepairProlong canConfirm prolongs={prolongs} repairId={repairId} />
      )}

      {!isLoading && prolongs && Array.isArray(prolongs) && prolongs.length === 0 && (
        <Warning msg="Нет запросов на продление" />
      )}

      {canEdit && isMoveToOtherFormActive && (
        <Modal title={'Перевод ремонта в "Прочий"'} closeHandler={deactivateMoveToOtherFormModal}>
          <MoveToOtherFormModal
            repairId={repairId}
            reasons={reasons}
            closeHandler={deactivateMoveToOtherFormModal}
          />
        </Modal>
      )}
    </React.Fragment>
  );
};

RepairProlong.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  finished: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  repairId: PropTypes.number.isRequired,
  prolongs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  reasons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = state => ({
  prolongs: state.mainReducer.repairProlongs,
  reasons: state.mainReducer.repairProlongReasons,
});

export default connect(mapStateToProps)(RepairProlong);
