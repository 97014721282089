import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalError from 'components/ModalError/ModalError';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import InputForm from 'components/InputForm/InputForm';
import SubmitButton from 'components/SubmitButton/SubmitButton';

// Actions
import { addTransport } from 'actions/common';

const AddTransportFormModal = ({ dispatch, closeHandler }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [carName, setCarName] = useState(null);
  const [modelYear, setModelYear] = useState(null);
  const [carNumber, setCarNumber] = useState(null);
  const [liftingCapacity, setLiftingCapacity] = useState('');
  const [passengers, setPassengers] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const handleSubmitTransport = () => {
    /** Показываем прелоадер на кнопку */
    setIsLoading(true);

    let params = {
      name: carName,
      carNumber,
      liftingCapacity,
      passengers,
      modelYear,
    };

    /** Записываем новый транспорт в базу данных */
    dispatch(addTransport(params))
      .then(() => {
        /** Скрываем прелоадер на кнопку */
        setIsLoading(false);

        /** Закрываем модалку */
        closeHandler();
      })
      .catch(error => {
        /** Если есть ошибки, выводим */
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        /** Скрываем прелоадер на кнопку */
        setIsLoading(false);
      });
  };

  const canSubmit = () => {
    return carName || carNumber || liftingCapacity !== '' || passengers !== '' || modelYear !== '';
  };

  return (
    <React.Fragment>
      <ModalHeader>
        <h4 style={{ marginRight: 8 }}>Добавление транспорта</h4>
      </ModalHeader>

      <ModalBody>
        <FormGroup title="Наименование" error={fieldErrors.name}>
          <InputForm
            type="text"
            value={carName}
            placeholder="Введите наименование машины"
            onChange={({ target }) => setCarName(target.value)}
          />
        </FormGroup>

        <FormGroup title="Гос. номер" error={fieldErrors.carNumber}>
          <InputForm
            type="text"
            value={carNumber}
            placeholder="Введите гос. номер машины"
            onChange={({ target }) => setCarNumber(target.value)}
          />
        </FormGroup>

        <FormGroup title="Год машины" error={fieldErrors.modelYear}>
          <InputForm
            type="text"
            value={modelYear}
            placeholder="Введите год машины"
            onChange={({ target }) => setModelYear(target.value)}
          />
        </FormGroup>

        <FormGroup title="Грузоподъемность (кг)" error={fieldErrors.liftingCapacity}>
          <InputForm
            type="text"
            value={liftingCapacity}
            placeholder="50000 кг"
            onChange={({ target }) => setLiftingCapacity(target.value)}
          />
        </FormGroup>

        <FormGroup title="Вместимость (чел)" error={fieldErrors.passengers}>
          <InputForm
            type="text"
            value={passengers}
            placeholder="5 чел"
            onChange={({ target }) => setPassengers(target.value)}
          />
        </FormGroup>
      </ModalBody>

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isValid={!canSubmit()}
          isLoading={isLoading}
          isLoadingText="Создаю..."
          clickHandler={handleSubmitTransport}
        >
          Создать
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

AddTransportFormModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  closeHandler: PropTypes.func.isRequired,
};

export default connect()(AddTransportFormModal);
