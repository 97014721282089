import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Icons
import { FaEdit, FaPlus, FaPaperclip } from 'react-icons/fa';

// Components
import DespatchObject from 'components/DespatchObject/DespatchObject';
import FormGroup from 'components/FormGroup/FormGroup';
import SelectForm from 'components/SelectForm/SelectForm';
import Loader from 'components/Loader/Loader';

// Actions
import { loadDespatchInfo } from 'actions/despatch';

// Utils
import * as propTypes from 'utils/propTypes';
import { DESPATCH_STATUSES } from 'utils/constants';

const urgencyData = [
  { value: 1, label: 'Низкий приоритет' },
  { value: 2, label: 'Средний приоритет' },
  { value: 3, label: 'Высокий приоритет' },
];

const DespatchDetails = ({
  canEdit,
  despatchId,
  users,
  dispatch,
  despatchInfo,
  onEditDespatchClick,
  onAddChildDespatchClick,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [urgency, setUrgency] = useState({ value: 0, label: 0 });
  const [fieldErrors, setFieldErrors] = useState({}); // eslint-disable-line no-unused-vars

  /** Запрашиваем информацию по отправлению */
  useEffect(() => {
    setIsLoading(true);

    dispatch(loadDespatchInfo(despatchId))
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch, despatchId]);

  /** Запрашиваем информацию по отправлению */
  useEffect(() => {
    setUrgency(
      despatchInfo // eslint-disable-next-line
        ? urgencyData.find(v => v.value == despatchInfo.urgency)
        : { value: 0, label: 0 },
    );
  }, [despatchInfo]);

  return (
    <React.Fragment>
      <div className="despatch-details">
        <div className="despatch-details__header" style={{ display: 'flex', alignItems: 'center' }}>
          <h2 className="despatch-details__title">Детали отправления {despatchInfo.isAdditional && <div className="label label_info" style={{ verticalAlign: 'middle' }}>Вложенное</div>}</h2>
          {(canEdit || despatchInfo.canEdit) && (
            <div>
              <button
                type="button"
                className="btn btn-warning"
                style={{ marginLeft: 12, marginRight: 8 }}
                onClick={() => {
                  onEditDespatchClick(despatchInfo.id);
                }}
              >
                <FaEdit className="icon" /> Изменить...
              </button>
              {!despatchInfo.isAdditional && (
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={() => {
                    onAddChildDespatchClick(despatchInfo.id);
                  }}
                >
                  <FaPlus className="icon" /> Создать вложенное отправление
                </button>
              )}
            </div>
          )}
        </div>

        <div className="despatch-details__content">
          {despatchInfo.additionalDespatches && despatchInfo.additionalDespatches.length && (
            <div style={{ overflow: 'auto' }}>
              <h5 style={{ marginTop: 24, marginBottom: 24 }}>Вложенные отправления</h5>
              <ul className="list-group mb-3">
                {despatchInfo.additionalDespatches.map(despatch => (
                  <li className="list-group-item u-d-flex u-justify-content-between lh-sm">
                    <DespatchObject data={despatch} />
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="despatch-details__row">
            <div className="despatch-details__col">
              <div className="despatch-details__label">
                <strong>Дата создания</strong>
              </div>
            </div>
            <div className="despatch-details__col">
              <div className="despatch-details__text tb-mg-l-5 tb-mg-md-l-0">
                {despatchInfo && despatchInfo.dateStart_1}
              </div>
            </div>
          </div>
          <div className="despatch-details__row">
            <div className="despatch-details__col">
              <div className="despatch-details__label">
                <strong>Дата исполнения</strong>
              </div>
            </div>
            <div className="despatch-details__col">
              <div className="despatch-details__text">
                {despatchInfo && despatchInfo.dateReady_1}
              </div>
            </div>
          </div>
          <div className="despatch-details__row">
            <div className="despatch-details__col">
              <div className="despatch-details__label">
                <strong>Дата готовности к отгрузке</strong>
              </div>
            </div>
            <div className="despatch-details__col">
              <div className="despatch-details__text tb-mg-l-5 tb-mg-md-l-0">
                {despatchInfo && despatchInfo.dateStart_1}
              </div>
            </div>
          </div>
          <div className="despatch-details__row">
            <div className="despatch-details__col">
              <div className="despatch-details__label">
                <strong>Срок проведения перевозки</strong>
              </div>
            </div>
            <div className="despatch-details__col">
              <div className="despatch-details__text tb-mg-l-5 tb-mg-md-l-0">
                {despatchInfo && despatchInfo.dateEnd_1}
              </div>
            </div>
          </div>
          <div className="despatch-details__row">
            <div className="despatch-details__col">
              <div className="despatch-details__label">
                <strong>Фактический срок перевозки</strong>
              </div>
            </div>
            <div className="despatch-details__col">
              <div className="despatch-details__text tb-mg-l-5 tb-mg-md-l-0">
                {despatchInfo && despatchInfo.dateFact ? despatchInfo.dateFact_1 : 'Не назнач.'}
              </div>
            </div>
          </div>
          <div className="despatch-details__row">
            <div className="despatch-details__col">
              <div className="despatch-details__label">
                <strong>Место отправления</strong>
              </div>
            </div>
            <div className="despatch-details__col">
              <div className="despatch-details__text tb-mg-l-5 tb-mg-md-l-0">
                {despatchInfo && despatchInfo.startLocation && despatchInfo.startLocation.city}
              </div>
            </div>
          </div>
          <div className="despatch-details__row">
            <div className="despatch-details__col">
              <div className="despatch-details__label">
                <strong>Место назначения</strong>
              </div>
            </div>
            <div className="despatch-details__col">
              <div className="despatch-details__text tb-mg-l-5 tb-mg-md-l-0">
                {despatchInfo && despatchInfo.destLocation && despatchInfo.destLocation.city}
              </div>
            </div>
          </div>
          <div className="despatch-details__row">
            <div className="despatch-details__col">
              <div className="despatch-details__label">
                <strong>Габариты (д-ш-в)</strong>
              </div>
            </div>
            <div className="despatch-details__col">
              <div className="despatch-details__text tb-mg-l-5 tb-mg-md-l-0">
                {despatchInfo && despatchInfo.size}
              </div>
            </div>
          </div>
          <div className="despatch-details__row">
            <div className="despatch-details__col">
              <div className="despatch-details__label">
                <strong>Вес (кг)</strong>
              </div>
            </div>
            <div className="despatch-details__col">
              <div className="despatch-details__text tb-mg-l-5 tb-mg-md-l-0">
                {despatchInfo && despatchInfo.weight}
              </div>
            </div>
          </div>
          <div className="despatch-details__row">
            <div className="despatch-details__col">
              <div className="despatch-details__label">
                <strong>Номенкулатурный перечень</strong>
              </div>
            </div>
            <div className="despatch-details__col">
              <div className="despatch-details__text tb-mg-l-5 tb-mg-md-l-0">
                {despatchInfo && despatchInfo.nomenclature}
                <div className="upload-docs">
                  {despatchInfo && despatchInfo.nomenclatureUrl && (
                    <a
                      href={despatchInfo.nomenclatureUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="label label_info"
                    >
                      <FaPaperclip className="icon" /> {despatchInfo.shortNomenclature}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="despatch-details__row">
            <div className="despatch-details__col">
              <div className="despatch-details__label">
                <strong>Срочность</strong>
              </div>
            </div>
            <div className="despatch-details__col">
              <div className="despatch-details__text tb-mg-l-5 tb-mg-md-l-0">
                <FormGroup error={fieldErrors.urgency}>
                  <SelectForm
                    options={urgencyData}
                    selectedValue={urgency}
                    onChange={selectedOption => setUrgency(selectedOption)}
                  />
                </FormGroup>
              </div>
            </div>
          </div>
          <div className="despatch-details__row">
            <div className="despatch-details__col">
              <div className="despatch-details__label">
                <strong>Получатель</strong>
              </div>
            </div>
            <div className="despatch-details__col">
              <div className="despatch-details__text tb-mg-l-5 tb-mg-md-l-0">
                {despatchInfo && despatchInfo.recipientFullName}
              </div>
            </div>
          </div>
          <div className="despatch-details__row">
            <div className="despatch-details__col">
              <div className="despatch-details__label">
                <strong>Статус</strong>
              </div>
            </div>
            <div className="despatch-details__col">
              <div className="despatch-details__text tb-mg-l-5 tb-mg-md-l-0">
                {despatchInfo && DESPATCH_STATUSES[despatchInfo.status]}
              </div>
            </div>
          </div>
          <div className="despatch-details__row">
            <div className="despatch-details__col">
              <div className="despatch-details__label">
                <strong>Дополнительная информация</strong>
              </div>
            </div>
            <div className="despatch-details__col">
              <div className="despatch-details__text tb-mg-l-5 tb-mg-md-l-0">
                {despatchInfo && despatchInfo.other}
              </div>
            </div>
          </div>
        </div>

        {false && isLoading && <Loader />}
      </div>
    </React.Fragment>
  );
};

DespatchDetails.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  despatchId: PropTypes.number.isRequired,
  despatchInfo: propTypes.despatchInfo.isRequired,
  user: PropTypes.shape({
    userRole: PropTypes.number,
  }).isRequired,
  onEditDespatchClick: PropTypes.func.isRequired,
  onAddChildDespatchClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.mainReducer.user,
  users: state.mainReducer.headerData.users,
  despatchInfo: state.despatchReducer.despatchInfo,
});

export default connect(mapStateToProps)(DespatchDetails);
