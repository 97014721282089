import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import SelectForm from 'components/SelectForm/SelectForm';
import TextArea from 'components/TextArea/TextArea';
import InputDate from 'components/InputDate/InputDate';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { getRepairWorkProgress, editRepairWork } from 'actions';

// Helpers
import { formatDateYYYYmmDD } from 'helpers/formatDateYYYYmmDD';

const EditWorkFormModal = ({ closeHandler, editWorkItem, repairId, dispatch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);
  const [workName, setWorkName] = useState(editWorkItem.name);
  const [workDate, setWorkDate] = useState(new Date(editWorkItem.date));
  const [workAccordance, setWorkAccordance] = useState({
    value: editWorkItem.accordance,
    label: `${editWorkItem.accordance}`,
  });

  const submitEditRepairWork = () => {
    /** Показываем прелоадер на кнопку */
    setIsLoading(true);

    /** Записываем отредактированную работу в базу данных */
    dispatch(
      editRepairWork(
        workName,
        formatDateYYYYmmDD(workDate),
        workAccordance.value,
        repairId,
        editWorkItem.id,
      ),
    )
      .then(() => {
        /** Запрашиваем обновленный список работ */
        return dispatch(getRepairWorkProgress(repairId));
      })
      .then(() => {
        /** Скрываем прелоадер на кнопку */
        setIsLoading(false);

        /** Закрываем модалку */
        closeHandler();
      })
      .catch(error => {
        /** Если есть ошибки, выводим */
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        /** Скрываем прелоадер на кнопку */
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Редактирование работы" />

      <ModalBody>
        <FormGroup title="Работы" error={fieldErrors.name}>
          <TextArea value={workName} onChange={({ target }) => setWorkName(target.value)} />
        </FormGroup>

        <FormGroup title="Дата" error={fieldErrors.date}>
          <InputDate selectedDate={workDate} onChange={date => setWorkDate(date)} />
        </FormGroup>

        <FormGroup title="Соответствие с графиком" error={fieldErrors.accordance}>
          <SelectForm
            options={[
              { value: -20, label: -20 },
              { value: -19, label: -19 },
              { value: -18, label: -18 },
              { value: -17, label: -17 },
              { value: -16, label: -16 },
              { value: -15, label: -15 },
              { value: -14, label: -14 },
              { value: -13, label: -13 },
              { value: -12, label: -12 },
              { value: -11, label: -11 },
              { value: -10, label: -10 },
              { value: -9, label: -9 },
              { value: -8, label: -8 },
              { value: -7, label: -7 },
              { value: -6, label: -6 },
              { value: -5, label: -5 },
              { value: -4, label: -4 },
              { value: -3, label: -3 },
              { value: -2, label: -2 },
              { value: -1, label: -1 },
              { value: 0, label: 0 },
              { value: +1, label: +1 },
              { value: +2, label: +2 },
              { value: +3, label: +3 },
              { value: +4, label: +4 },
              { value: +5, label: +5 },
              { value: +6, label: +6 },
              { value: +7, label: +7 },
              { value: +8, label: +8 },
              { value: +9, label: +9 },
              { value: +10, label: +10 },
              { value: +11, label: +11 },
              { value: +12, label: +12 },
              { value: +13, label: +13 },
              { value: +14, label: +14 },
              { value: +15, label: +15 },
              { value: +16, label: +16 },
              { value: +17, label: +17 },
              { value: +18, label: +18 },
              { value: +19, label: +19 },
              { value: +20, label: +20 },
            ]}
            selectedValue={workAccordance}
            onChange={selectedOption => setWorkAccordance(selectedOption)}
          />
        </FormGroup>
      </ModalBody>

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isValid={workName === '' || !workDate || !workAccordance}
          isLoading={isLoading}
          isLoadingText="Сохраняю..."
          clickHandler={submitEditRepairWork}
        >
          Сохранить
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

EditWorkFormModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  repairId: PropTypes.number.isRequired,
  editWorkItem: PropTypes.shape({
    id: PropTypes.number,
    date: PropTypes.string,
    name: PropTypes.string,
    accordance: PropTypes.number,
  }).isRequired,
};

export default connect()(EditWorkFormModal);
