import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import TableAdminOrderMan from 'components/TableAdminOrderMan/TableAdminOrderMan';
import Modal from 'components/Modal/Modal';
import CreateOrderManModal from 'components/CreateOrderManModal/CreateOrderManModal';
import CreatePeriodModal from 'components/CreatePeriodModal/CreatePeriodModal';
import Loader from 'components/Loader/Loader';

// Actions
import { getAdminOrderMan, getAdminPeriods } from 'actions';

// Styles
import './Admin.scss';
import './block.scss';
import './block-list.scss';

const Admin = ({ periods, orderMan, dispatch }) => {
  const [isCreateOrderManActive, setCreateOrderManActive] = useState(false);
  const [isCreatePeriodActive, setCreatePeriodActive] = useState(false);

  const [isOrderManLoading, setIsOrderManLoading] = useState(true);
  const [isPeriodsLoading, setIsPeriodsLoading] = useState(true);

  const activateCreateOrderManModal = () => {
    setCreateOrderManActive(true);
  };

  const deactivateCreateOrderManModal = () => {
    setCreateOrderManActive(false);
  };

  const activateCreatePeriodModal = () => {
    setCreatePeriodActive(true);
  };

  const deactivateCreatePeriodModal = () => {
    setCreatePeriodActive(false);
  };

  /** Заказчики */
  useEffect(() => {
    dispatch(getAdminOrderMan())
      .then(() => setIsOrderManLoading(false))
      .catch(() => setIsOrderManLoading(false));
  }, [dispatch]);

  /** Периоды */
  useEffect(() => {
    dispatch(getAdminPeriods())
      .then(() => setIsPeriodsLoading(false))
      .catch(() => setIsPeriodsLoading(false));
  }, [dispatch]);

  return (
    <div className="admin-page">
      <div className="admin-page__container container">
        <div className="admin-page__content-wrap">
          <div className="admin-page__col admin-page__col_years">
            {/* BLOCK */}
            <div className="block">
              <h2 className="block__title">Отчетные периоды</h2>
              {isPeriodsLoading && <Loader className="loader_modal" />}

              {!isPeriodsLoading && periods && Array.isArray(periods) && periods.length > 0 && (
                <ul className="block-list">
                  {periods.map(period => (
                    <li key={period.id} className="block-list__item">
                      <Link to={`/admin/period/${period.id}`} className="block-list__item-link">
                        {period.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}

              {!isPeriodsLoading && periods && Array.isArray(periods) && periods.length === 0 && (
                <div className="block__label">Пока не добавлено отчетных периодов</div>
              )}

              {!isPeriodsLoading && (
                <div className="block__footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={activateCreatePeriodModal}
                  >
                    Добавить отчетный период
                  </button>
                </div>
              )}
            </div>
            {/* BLOCK END */}
          </div>
          <div className="admin-page__col admin-page__col_orderMan">
            {/* BLOCK */}
            <div className="block">
              <h2 className="block__title">Заказчики</h2>
              {isOrderManLoading && <Loader className="loader_modal" />}

              {!isOrderManLoading && orderMan && Array.isArray(orderMan) && orderMan.length > 0 && (
                <TableAdminOrderMan orderMan={orderMan} />
              )}

              {!isOrderManLoading &&
                orderMan &&
                Array.isArray(orderMan) &&
                orderMan.length === 0 && (
                  <div className="block__label">Пока не добавлено заказчиков</div>
                )}

              {!isOrderManLoading && (
                <div className="block__footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={activateCreateOrderManModal}
                  >
                    Добавить заказчика
                  </button>
                </div>
              )}
            </div>
            {/* BLOCK END */}
          </div>
        </div>

        {isCreatePeriodActive && (
          <Modal title="Добавление отчетного периода" closeHandler={deactivateCreatePeriodModal}>
            <CreatePeriodModal closeHandler={deactivateCreatePeriodModal} />
          </Modal>
        )}

        {isCreateOrderManActive && (
          <Modal title="Добавление заказчика" closeHandler={deactivateCreateOrderManModal}>
            <CreateOrderManModal closeHandler={deactivateCreateOrderManModal} />
          </Modal>
        )}
      </div>
    </div>
  );
};

Admin.propTypes = {
  dispatch: PropTypes.func.isRequired,
  periods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  orderMan: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = state => ({
  orderMan: state.mainReducer.adminOrderMan,
  periods: state.mainReducer.adminPeriods,
});

export default connect(mapStateToProps)(Admin);
