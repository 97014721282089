import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import TextArea from 'components/TextArea/TextArea';
import TaskComment from 'components/TaskComment/TaskComment';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';
import Loader from 'components/Loader/Loader';

// Actions
import { addEmploymentComment, getEmploymentComments } from 'actions';

const CommentsEmployeeModal = ({ closeHandler, comments, dispatch, userId, departmentId }) => {
  const [comment, setComment] = useState('');

  const [isModalLoading, setIsModalLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  useEffect(() => {
    dispatch(getEmploymentComments(userId))
      .then(() => setIsModalLoading(false))
      .catch(() => setIsModalLoading(false));
  }, [dispatch, userId]);

  const submitTaskComment = () => {
    setIsSubmitLoading(true);

    dispatch(addEmploymentComment(userId, departmentId, comment))
      .then(() => {
        setIsSubmitLoading(false);

        closeHandler();
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsSubmitLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Примечания" />

      {isModalLoading && <Loader className="loader_modal" />}

      {!isModalLoading && comments && (
        <ModalBody>
          <FormGroup title="Добавить примечание" error={fieldErrors.comment}>
            <TextArea value={comment} onChange={({ target }) => setComment(target.value)} />
          </FormGroup>

          <FormGroup>
            <SubmitButton
              isLoading={isSubmitLoading}
              isValid={comment === ''}
              isLoadingText="Отправка..."
              clickHandler={submitTaskComment}
            >
              Отправить
            </SubmitButton>
          </FormGroup>

          {Array.isArray(comments) &&
            comments.map(commentItem => (
              <TaskComment className="panel_primary" key={commentItem.id} comment={commentItem} />
            ))}
        </ModalBody>
      )}

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler} />
    </React.Fragment>
  );
};

CommentsEmployeeModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  departmentId: PropTypes.number.isRequired,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ).isRequired,
};

const mapStateToProps = state => ({
  comments: state.mainReducer.employmentComments,
});

export default connect(mapStateToProps)(CommentsEmployeeModal);
