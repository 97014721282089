import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import InputForm from 'components/InputForm/InputForm';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { addAdminOrderMan } from 'actions';

const CreateOrderManModal = ({ closeHandler, dispatch }) => {
  const [orderManName, setOrderManName] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const submitOrderMan = () => {
    setIsLoading(true);

    dispatch(addAdminOrderMan(orderManName))
      .then(() => {
        setIsLoading(false);

        closeHandler();
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Добавление заказчика" />

      <ModalBody>
        <FormGroup title="Наименование заказчика" error={fieldErrors.name}>
          <InputForm
            type="text"
            value={orderManName}
            onChange={({ target }) => setOrderManName(target.value)}
          />
        </FormGroup>
      </ModalBody>

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isLoading={isLoading}
          isValid={orderManName === ''}
          isLoadingText="Отправка..."
          clickHandler={submitOrderMan}
        >
          Создать
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

CreateOrderManModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(CreateOrderManModal);
