import React from 'react';
import './TableDespatchLegend.scss';

const TableDespatchLegend = () => (
  <div className="table-despatch-legend">
    <div className="table-despatch-legend__item">
      <div className="table-despatch-legend__item-color table-despatch-legend__item-color_active">
        Отправление
      </div>
    </div>
    <div className="table-despatch-legend__item hidden">
      <div className="table-despatch-legend__item-color table-despatch-legend__item-color_plus">
        Дозаявка
      </div>
    </div>
    <div className="table-despatch-legend__item">
      <div className="table-despatch-legend__item-color table-despatch-legend__item-color_open">
        В обработке
      </div>
    </div>
    <div className="table-despatch-legend__item hidden">
      <div className="table-despatch-legend__item-color table-despatch-legend__item-color_car_assigned">
        Назначена машина
      </div>
    </div>
    <div className="table-despatch-legend__item">
      <div className="table-despatch-legend__item-color table-despatch-legend__item-color_in_transit">
        Отправлено
      </div>
    </div>
    <div className="table-despatch-legend__item ">
      <div className="table-despatch-legend__item-color table-despatch-legend__item-color_returned">
        Возврат
      </div>
    </div>
    <div className="table-despatch-legend__item">
      <div className="table-despatch-legend__item-color table-despatch-legend__item-color_finished">
        Завершен
      </div>
    </div>
    <div className="table-despatch-legend__item">
      <div className="table-despatch-legend__item-color table-despatch-legend__item-color_canceled">
        Отменен
      </div>
    </div>
  </div>
);

export default TableDespatchLegend;
