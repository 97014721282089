import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Icons
import { FaTruck, FaIdCard, FaFileAlt } from 'react-icons/fa';

// Components
import FormGroup from 'components/FormGroup/FormGroup';
import Checkbox from 'components/Checkbox/Checkbox';
// import DespatchObjectContent from 'components/DespatchObjectContent/DespatchObjectContent';

// Utils
import * as propTypes from 'utils/propTypes';
import { DESPATCH_STATUSES } from 'utils/constants';

// Styles
import './DespatchObject.scss';

const DespatchObject = ({
  showTransportCol,
  showTools,
  isChecked,
  // isTransportAssigned,
  // isDriverAssigned,
  data,
  shortView,
  onTransportStatusChange,
}) => (
  <div className="despatch-object">
    {showTransportCol && (
      <div>
        <FormGroup title="Взят на транспортировку">
          <div className="mr-1">
            <Checkbox
              ariaLabel="Взят на транспортировку"
              checked={isChecked}
              onClick={() => onTransportStatusChange(data.id)}
            />
          </div>
        </FormGroup>
        <span className="label label_default">
          <FaTruck className="icon" />
        </span>
        <span className="label label_default">
          <FaIdCard className="icon" />
        </span>
      </div>
    )}
    <div className="mr-1">
      <h6 className="my-0">
        <span className="label label_default"><FaFileAlt className="icon" /></span>
      </h6>
      <Link to={`/despatch/${data.id}/details`}>{data.id}</Link>
    </div>
    <div className="mr-1 hidden">
      <h6 className="my-0">Дата создания</h6>
      <small className="text-muted">{data.dateCreation}</small>
    </div>
    <div className="mr-1">
      <h6 className="my-0">
        <span className="label label_default">Дата исполнения</span>
      </h6>
      <small className="text-muted">
        <Link to={`/despatch/${data.id}/details`}>{data.dateReady}</Link>
      </small>
    </div>
    <div className="mr-1">
      <h6 className="my-0">
        <span className="label label_default">Дата готовности к отгрузке</span>
      </h6>
      <small className="text-muted">
        <Link to={`/despatch/${data.id}/details`}>{data.dateStart}</Link>
      </small>
    </div>
    <div className="mr-1">
      <h6 className="my-0">
        <span className="label label_default">Срок проведения перевозки</span>
      </h6>
      <small className="text-muted">
        <Link to={`/despatch/${data.id}/details`}>{data.dateEnd}</Link>
      </small>
    </div>
    <div className="mr-1 hidden">
      <h6 className="my-0">Место отправления</h6>
      <small className="text-muted">{data.startLocation && data.startLocation.city}</small>
    </div>
    <div className="mr-1 hidden">
      <h6 className="my-0">Место назначения</h6>
      <small className="text-muted">{data.destLocation && data.destLocation.city}</small>
    </div>
    <div className="mr-1 hidden">
      <h6 className="my-0">Габариты (м)</h6>
      <small className="text-muted">{data.size}</small>
    </div>
    <div className="mr-1 hidden">
      <h6 className="my-0">Вес (кг)</h6>
      <small className="text-muted">{data.weight}</small>
    </div>
    <div className="mr-1 hidden">
      <h6 className="my-0">Номенкулатурный перечень</h6>
      <small className="text-muted">{data.nomenclature}</small>
    </div>
    <div className="mr-1 hidden">
      <h6 className="my-0">Срочность</h6>
      <small className="text-muted">{data.urgency}</small>
    </div>
    <div className="mr-1 hidden">
      <h6 className="my-0">Статус</h6>
      <small className="text-muted">{DESPATCH_STATUSES[data.status]}</small>
    </div>
    <div className="mr-1 hidden">
      <h6 className="my-0">Дополнительная информация</h6>
      <small className="text-muted">{data.other}</small>
    </div>
    {showTools && (
      <div className="mr-1 hidden">
        <h6 className="my-0">Отметка о выполнении</h6>
        <small className="text-muted">{data.dateCreation}</small>
      </div>
    )}
    {/*<div className="mr-1 hidden">
      <h6 className="my-0"><div className="label label_default">Дата готовности к отгрузке</div></h6>
      <small className="text-muted">2021-06-20</small>
    </div>
    <div className="mr-1 hidden">
      <h6 className="my-0"><div className="label label_default">Срок проведения перевозки</div></h6>
      <small className="text-muted">2021-06-30</small>
    </div>*/}
  </div>
);

DespatchObject.propTypes = {
  showTransportCol: PropTypes.bool,
  showTools: PropTypes.bool,
  isChecked: PropTypes.bool,
  // isTransportAssigned,
  // isDriverAssigned,
  data: propTypes.despatchInfo,
  shortView: PropTypes.bool,
  onTransportStatusChange: PropTypes.func,
};

DespatchObject.defaultProps = {
  showTransportCol: false,
  showTools: false,
  shortView: true,
  isChecked: false,
  data: {},
  onTransportStatusChange: () => {},
};

export default DespatchObject;
