import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import InputDate from 'components/InputDate/InputDate';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { setFinishRepair } from 'actions';

// Helpers
import { formatDateYYYYmmDD } from 'helpers/formatDateYYYYmmDD';

const FinishRepairModal = ({ closeHandler, toother, dateOtherFinished, repairId, dispatch }) => {
  const [finishDate, setFinishDate] = useState(
    toother ? new Date(Date.parse(dateOtherFinished)) : null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const submitFinishRepair = () => {
    /** Показываем прелоадер на кнопку */
    setIsLoading(true);

    /** Записываем окончание ремонта в базу данных */
    dispatch(setFinishRepair(repairId, formatDateYYYYmmDD(finishDate)))
      .then(() => {
        /** Скрываем прелоадер на кнопку */
        setIsLoading(false);

        /** Закрываем модалку */
        closeHandler();
      })
      .catch(error => {
        /** Если есть ошибки, выводим */
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        /** Скрываем прелоадер на кнопку */
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Закончить ремонт" />

      <ModalBody>
        <FormGroup
          title="Дата"
          style={{ display: toother ? 'none' : 'block' }}
          error={fieldErrors.date}
        >
          <InputDate selectedDate={finishDate} onChange={date => setFinishDate(date)} />
        </FormGroup>

        <h4 className="modal-header__title">Вы уверены,</h4>
        <h5 className="modal-header__sub-title">что хотите закончить ремонт?</h5>
      </ModalBody>

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Отмена
        </button>

        <SubmitButton
          isValid={!finishDate}
          isLoading={isLoading}
          isLoadingText="Заканчиваю..."
          clickHandler={submitFinishRepair}
        >
          Закончить
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

FinishRepairModal.propTypes = {
  toother: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  dateOtherFinished: PropTypes.string,
  repairId: PropTypes.number.isRequired,
};

FinishRepairModal.defaultProps = {
  dateOtherFinished: PropTypes.null,
};

export default connect()(FinishRepairModal);
