import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';

// Components
import Loader from 'components/Loader/Loader';
import ProblemsListReport from 'components/ProblemsListReport/ProblemsListReport';
import TasksListReport from 'components/TasksListReport/TasksListReport';
import OverdueListReport from 'components/OverdueListReport/OverdueListReport';
import NoReportsListReport from 'components/NoReportsListReport/NoReportsListReport';
import NoNarListReport from 'components/NoNarListReport/NoNarListReport';
import NoForecastListReport from 'components/NoForecastListReport/NoForecastListReport';
import NoReceiveListReport from 'components/NoReceiveListReport/NoReceiveListReport';
import OverdueNodesListReport from 'components/OverdueNodesListReport/OverdueNodesListReport';
import MissDocsListReport from 'components/MissDocsListReport/MissDocsListReport';
import MissDocsRmmListReport from 'components/MissDocsRmmListReport/MissDocsRmmListReport';

// Actions
import {
  getReportProblems,
  getReportTasks,
  getReportRepairsMissDocs,
  getReportMissDocsRmm,
  getReportNodesNotReceived,
  getReportRepairsOverdue,
  getReportNodesNoForecast,
  getReportNodesOverdue,
  getReportNoNar,
  getReportNoReportRepairs,
} from 'actions';

// Styles
import './ReportAll.scss';
import './report-panel.scss';

const ReportAll = ({
  noReports,
  noNar,
  noForecast,
  overdue,
  overdueNodes,
  notReceived,
  missDocs,
  missDocsRmm,
  problems,
  tasks,
  dispatch,
}) => {
  const [isProblemsLoading, setIsProblemsLoading] = useState(true);
  const [isTasksLoading, setIsTasksLoading] = useState(true);
  const [isMissDocsLoading, setIsMissDocsLoading] = useState(true);
  const [isMissDocsRmmLoading, setIsMissDocsRmmLoading] = useState(true);
  const [isNotReceivedLoading, setIsNotReceivedLoading] = useState(true);
  const [isOverdueLoading, setIsOverdueLoading] = useState(true);
  const [isOverdueNodesLoading, setIsOverdueNodesLoading] = useState(true);
  const [isNoForecastLoading, setIsNoForecastLoading] = useState(true);
  const [isNoNarLoading, setIsNoNarLoading] = useState(true);
  const [isNoReportsLoading, setIsNoReportsLoading] = useState(true);

  /** Текущий отчет - проблемы */
  useEffect(() => {
    dispatch(getReportProblems())
      .then(() => setIsProblemsLoading(false))
      .catch(() => setIsProblemsLoading(false));
  }, [dispatch]);

  /** Текущий отчет - задачи */
  useEffect(() => {
    dispatch(getReportTasks())
      .then(() => setIsTasksLoading(false))
      .catch(() => setIsTasksLoading(false));
  }, [dispatch]);

  /** Текущий отчет - Отстутствуют документы */
  useEffect(() => {
    dispatch(getReportRepairsMissDocs())
      .then(() => setIsMissDocsLoading(false))
      .catch(() => setIsMissDocsLoading(false));
  }, [dispatch]);

  /** Текущий отчет - Отстутствуют документы РММ */
  useEffect(() => {
    dispatch(getReportMissDocsRmm())
      .then(() => setIsMissDocsRmmLoading(false))
      .catch(() => setIsMissDocsRmmLoading(false));
  }, [dispatch]);

  /** Текущий отчет - непринятые узлы */
  useEffect(() => {
    dispatch(getReportNodesNotReceived())
      .then(() => setIsNotReceivedLoading(false))
      .catch(() => setIsNotReceivedLoading(false));
  }, [dispatch]);

  /** Текущий отчет - просроченные ремонты */
  useEffect(() => {
    dispatch(getReportRepairsOverdue())
      .then(() => setIsOverdueLoading(false))
      .catch(() => setIsOverdueLoading(false));
  }, [dispatch]);

  /** Текущий отчет - просроченные ремонты */
  useEffect(() => {
    dispatch(getReportNodesNoForecast())
      .then(() => setIsNoForecastLoading(false))
      .catch(() => setIsNoForecastLoading(false));
  }, [dispatch]);

  /** Текущий отчет - просроченные ремонты */
  useEffect(() => {
    dispatch(getReportNodesOverdue())
      .then(() => setIsOverdueNodesLoading(false))
      .catch(() => setIsOverdueNodesLoading(false));
  }, [dispatch]);

  /** Текущий отчет - Не заполнена наработка агрегатов */
  useEffect(() => {
    dispatch(getReportNoNar())
      .then(() => setIsNoNarLoading(false))
      .catch(() => setIsNoNarLoading(false));
  }, [dispatch]);

  /** Текущий отчет - Отсутствуют доклады */
  useEffect(() => {
    dispatch(getReportNoReportRepairs())
      .then(() => setIsNoReportsLoading(false))
      .catch(() => setIsNoReportsLoading(false));
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="report-all">
        <div className="report-all__container container">
          <h1 className="report-all__title">Текущий отчет</h1>

          <div className="report-all__content">
            <div className="report-all__row report-all__row_view-3">
              <div className="report-all__col">
                {/** ОТСУТСВУЮТ ДОКЛАДЫ */}
                <div
                  className={cx('report-panel panel', {
                    panel_danger: noReports && Array.isArray(noReports) && noReports.length > 0,
                    panel_success: noReports && Array.isArray(noReports) && noReports.length === 0,
                  })}
                >
                  <div className="panel__heading">
                    <div className="panel__heading-inner">
                      Отсутствуют доклады{' '}
                      <span className="label label_danger">
                        {noReports &&
                          Array.isArray(noReports) &&
                          noReports.length > 0 &&
                          noReports.length}
                      </span>
                    </div>
                  </div>
                  <div className="panel__body report-panel__scroll-wrap">
                    {isNoReportsLoading && <Loader />}

                    {!isNoReportsLoading && noReports && (
                      <NoReportsListReport noReports={noReports} />
                    )}

                    {!isNoReportsLoading &&
                      noReports &&
                      Array.isArray(noReports) &&
                      noReports.length === 0 &&
                      'Все доклады предоставлены'}
                  </div>
                </div>
                {/** ОТСУТСВУЮТ ДОКЛАДЫ END */}
              </div>

              <div className="report-all__col">
                {/** НЕ ЗАПОЛНЕНА НАРАБОТКА АГРЕГАТОВ */}
                <div
                  className={cx('report-panel panel', {
                    panel_danger: noNar && Array.isArray(noNar) && noNar.length > 0,
                    panel_success: noNar && Array.isArray(noNar) && noNar.length === 0,
                  })}
                >
                  <div className="panel__heading">
                    <div className="panel__heading-inner">
                      Не заполнена наработка агрегатов{' '}
                      <span className="label label_danger">
                        {noNar && Array.isArray(noNar) && noNar.length > 0 && noNar.length}
                      </span>
                    </div>
                  </div>
                  <div className="panel__body report-panel__scroll-wrap">
                    {isNoNarLoading && <Loader />}

                    {!isNoNarLoading && noNar && <NoNarListReport noNar={noNar} />}

                    {!isNoNarLoading &&
                      noNar &&
                      Array.isArray(noNar) &&
                      noNar.length === 0 &&
                      'Все наработки заполнены'}
                  </div>
                </div>
                {/** НЕ ЗАПОЛНЕНА НАРАБОТКА АГРЕГАТОВ END */}
              </div>

              <div className="report-all__col">
                {/** НЕ СОГЛАСОВАНЫ ДАТЫ ОТПРАВКИ ИЗ РММ */}
                <div
                  className={cx('report-panel panel', {
                    panel_danger: noForecast && Array.isArray(noForecast) && noForecast.length > 0,
                    panel_success:
                      noForecast && Array.isArray(noForecast) && noForecast.length === 0,
                  })}
                >
                  <div className="panel__heading">
                    <div className="panel__heading-inner">
                      Не согласованы даты отправки из РММ{' '}
                      <span className="label label_danger">
                        {noForecast &&
                          Array.isArray(noForecast) &&
                          noForecast.length > 0 &&
                          noForecast.length}
                      </span>
                    </div>
                  </div>
                  <div className="panel__body report-panel__scroll-wrap">
                    {isNoForecastLoading && <Loader />}

                    {!isNoForecastLoading && noForecast && (
                      <NoForecastListReport noForecast={noForecast} />
                    )}

                    {!isNoForecastLoading &&
                      noForecast &&
                      Array.isArray(noForecast) &&
                      noForecast.length === 0 &&
                      'Все даты согласованы'}
                  </div>
                </div>
                {/** НЕ СОГЛАСОВАНЫ ДАТЫ ОТПРАВКИ ИЗ РММ END */}
              </div>
            </div>

            <div className="report-all__row report-all__row_view-3">
              <div className="report-all__col">
                {/** ПРОСРОЧЕННЫЕ РЕМОНТЫ */}
                <div
                  className={cx('report-panel panel', {
                    panel_danger: overdue && Array.isArray(overdue) && overdue.length > 0,
                    panel_success: overdue && Array.isArray(overdue) && overdue.length === 0,
                  })}
                >
                  <div className="panel__heading">
                    <div className="panel__heading-inner">
                      Просрочены ремонты{' '}
                      <span className="label label_danger">
                        {overdue && Array.isArray(overdue) && overdue.length > 0 && overdue.length}
                      </span>
                    </div>
                  </div>
                  <div className="panel__body report-panel__scroll-wrap">
                    {isOverdueLoading && <Loader />}

                    {!isOverdueLoading && overdue && <OverdueListReport overdue={overdue} />}

                    {!isOverdueLoading &&
                      overdue &&
                      Array.isArray(overdue) &&
                      overdue.length === 0 &&
                      'Нет просроченных ремонтов'}
                  </div>
                </div>
                {/** ПРОСРОЧЕННЫЕ РЕМОНТЫ END */}
              </div>

              <div className="report-all__col">
                {/** ПРОСРОЧЕННЫЕ РЕМОНТЫ РММ */}
                <div
                  className={cx('report-panel panel', {
                    panel_danger:
                      overdueNodes && Array.isArray(overdueNodes) && overdueNodes.length > 0,
                    panel_success:
                      overdueNodes && Array.isArray(overdueNodes) && overdueNodes.length === 0,
                  })}
                >
                  <div className="panel__heading">
                    <div className="panel__heading-inner">
                      Просрочены ремонты узлов{' '}
                      <span className="label label_danger">
                        {overdueNodes &&
                          Array.isArray(overdueNodes) &&
                          overdueNodes.length > 0 &&
                          overdueNodes.length}
                      </span>
                    </div>
                  </div>
                  <div className="panel__body report-panel__scroll-wrap">
                    {isOverdueNodesLoading && <Loader />}

                    {!isOverdueNodesLoading && overdueNodes && (
                      <OverdueNodesListReport overdueNodes={overdueNodes} />
                    )}

                    {!isOverdueNodesLoading &&
                      overdueNodes &&
                      Array.isArray(overdueNodes) &&
                      overdueNodes.length === 0 &&
                      'Нет просроченных ремонтов'}
                  </div>
                </div>
                {/** ПРОСРОЧЕННЫЕ РЕМОНТЫ РММ END */}
              </div>

              <div className="report-all__col">
                {/** НЕПРИНЯТЫЕ УЗЛЫ */}
                <div
                  className={cx('report-panel panel', {
                    panel_danger:
                      notReceived && Array.isArray(notReceived) && notReceived.length > 0,
                    panel_success:
                      notReceived && Array.isArray(notReceived) && notReceived.length === 0,
                  })}
                >
                  <div className="panel__heading">
                    <div className="panel__heading-inner">
                      Не приняты узлы{' '}
                      <span className="label label_danger">
                        {notReceived &&
                          Array.isArray(notReceived) &&
                          notReceived.length > 0 &&
                          notReceived.length}
                      </span>
                    </div>
                  </div>
                  <div className="panel__body report-panel__scroll-wrap">
                    {isNotReceivedLoading && <Loader />}

                    {!isNotReceivedLoading && notReceived && (
                      <NoReceiveListReport noReceive={notReceived} />
                    )}

                    {!isNotReceivedLoading &&
                      notReceived &&
                      Array.isArray(notReceived) &&
                      notReceived.length === 0 &&
                      'Нет непринятых узлов'}
                  </div>
                </div>
                {/** НЕПРИНЯТЫЕ УЗЛЫ END */}
              </div>
            </div>

            <div className="report-all__row report-all__row_view-2">
              <div className="report-all__col">
                {/** ОТСУТСТВУЮТ ДОКУМЕНТЫ */}
                <div
                  className={cx('report-panel panel', {
                    panel_danger: missDocs && Array.isArray(missDocs) && missDocs.length > 0,
                    panel_success: missDocs && Array.isArray(missDocs) && missDocs.length === 0,
                  })}
                >
                  <div className="panel__heading">
                    <div className="panel__heading-inner">
                      Отстутствуют документы{' '}
                      <span className="label label_danger">
                        {missDocs &&
                          Array.isArray(missDocs) &&
                          missDocs.length > 0 &&
                          missDocs.length}
                      </span>
                    </div>
                  </div>
                  <div className="panel__body report-panel__scroll-wrap">
                    {isMissDocsLoading && <Loader />}

                    {!isMissDocsLoading && missDocs && <MissDocsListReport missDocs={missDocs} />}

                    {!isMissDocsLoading &&
                      missDocs &&
                      Array.isArray(missDocs) &&
                      missDocs.length === 0 &&
                      'Все документы загружены'}
                  </div>
                </div>
                {/** ОТСУТСТВУЮТ ДОКУМЕНТЫ END */}
              </div>

              <div className="report-all__col">
                {/** ОТСУТСТВУЮТ ДОКУМЕНТЫ РММ */}
                <div
                  className={cx('report-panel panel', {
                    panel_danger:
                      missDocsRmm && Array.isArray(missDocsRmm) && missDocsRmm.length > 0,
                    panel_success:
                      missDocsRmm && Array.isArray(missDocsRmm) && missDocsRmm.length === 0,
                  })}
                >
                  <div className="panel__heading">
                    <div className="panel__heading-inner">
                      Отстутствуют документы (РММ){' '}
                      <span className="label label_danger">
                        {missDocsRmm &&
                          Array.isArray(missDocsRmm) &&
                          missDocsRmm.length > 0 &&
                          missDocsRmm.length}
                      </span>
                    </div>
                  </div>
                  <div className="panel__body report-panel__scroll-wrap">
                    {isMissDocsRmmLoading && <Loader />}

                    {!isMissDocsRmmLoading && missDocsRmm && (
                      <MissDocsRmmListReport missDocsRmm={missDocsRmm} />
                    )}

                    {!isMissDocsRmmLoading &&
                      missDocsRmm &&
                      Array.isArray(missDocsRmm) &&
                      missDocsRmm.length === 0 &&
                      'Все документы загружены (РММ)'}
                  </div>
                </div>
                {/** ОТСУТСТВУЮТ ДОКУМЕНТЫ РММ END */}
              </div>
            </div>

            <div className="report-all__row report-all__row_view-2">
              <div className="report-all__col">
                {/** ПРОБЛЕМЫ НА ОБЪЕКТАХ */}
                <div
                  className={cx('report-panel panel', {
                    panel_danger: problems && Array.isArray(problems) && problems.length > 0,
                    panel_success: problems && Array.isArray(problems) && problems.length === 0,
                  })}
                >
                  <div className="panel__heading">
                    <div className="panel__heading-inner">
                      Текущие проблемы на объектах{' '}
                      <span className="label label_danger">
                        {problems &&
                          Array.isArray(problems) &&
                          problems.length > 0 &&
                          problems.length}
                      </span>
                    </div>
                  </div>
                  <div className="panel__body report-panel__scroll-wrap">
                    {isProblemsLoading && <Loader />}

                    {!isProblemsLoading && problems && <ProblemsListReport problems={problems} />}

                    {!isProblemsLoading &&
                      problems &&
                      Array.isArray(problems) &&
                      problems.length === 0 &&
                      'Нет текущих проблем на объектах'}
                  </div>
                </div>
                {/** ПРОБЛЕМЫ НА ОБЪЕКТАХ END */}
              </div>

              <div className="report-all__col">
                {/** ЗАДАЧИ */}
                <div
                  className={cx('report-panel panel', {
                    panel_danger: tasks && Array.isArray(tasks) && tasks.length > 0,
                    panel_success: tasks && Array.isArray(tasks) && tasks.length === 0,
                  })}
                >
                  <div className="panel__heading">
                    <div className="panel__heading-inner">
                      Задачи{' '}
                      <span className="label label_danger">
                        {tasks && Array.isArray(tasks) && tasks.length > 0 && tasks.length}
                      </span>
                    </div>
                  </div>
                  <div className="panel__body report-panel__scroll-wrap">
                    {isTasksLoading && <Loader />}

                    {!isTasksLoading && tasks && <TasksListReport tasks={tasks} />}

                    {!isTasksLoading &&
                      tasks &&
                      Array.isArray(tasks) &&
                      tasks.length === 0 &&
                      'Нет задач'}
                  </div>
                </div>
                {/** ЗАДАЧИ END */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

ReportAll.propTypes = {
  dispatch: PropTypes.func.isRequired,
  noReports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  noNar: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  noForecast: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  overdue: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  overdueNodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  notReceived: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  missDocs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  missDocsRmm: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  problems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = state => ({
  problems: state.mainReducer.reportAll.problems,
  tasks: state.mainReducer.reportAll.tasks,
  missDocs: state.mainReducer.reportAll.missDocs,
  missDocsRmm: state.mainReducer.reportAll.missDocsRmm,
  notReceived: state.mainReducer.reportAll.notReceived,
  overdue: state.mainReducer.reportAll.overdue,
  noForecast: state.mainReducer.reportAll.noForecast,
  overdueNodes: state.mainReducer.reportAll.overdueNodes,
  noNar: state.mainReducer.reportAll.noNar,
  noReports: state.mainReducer.reportAll.noReports,
});

export default connect(mapStateToProps)(ReportAll);
