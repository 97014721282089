import React from 'react';
import PropTypes from 'prop-types';

const TextArea = ({ rows, cols, value, onChange }) => {
  return (
    <textarea className="form-control" rows={rows} cols={cols} value={value} onChange={onChange} />
  );
};

TextArea.propTypes = {
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cols: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

TextArea.defaultProps = {
  cols: null,
  rows: null,
};

export default TextArea;
