import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// Components
import Modal from 'components/Modal/Modal';
import AddTaskFormModal from 'components/AddTaskFormModal/AddTaskFormModal';
import ProblemsList from 'components/ProblemsList/ProblemsList';
import TasksList from 'components/TasksList/TasksList';
import MyTasksList from 'components/MyTasksList/MyTasksList';
import OverdueList from 'components/OverdueList/OverdueList';
import NoReportsList from 'components/NoReportsList/NoReportsList';
import NoNarList from 'components/NoNarList/NoNarList';
import NoForecastList from 'components/NoForecastList/NoForecastList';
import NoReceiveList from 'components/NoReceiveList/NoReceiveList';
import OverdueNodesList from 'components/OverdueNodesList/OverdueNodesList';
import DespatchProblemsList from 'components/DespatchProblemsList/DespatchProblemsList';
import MissDocsList from 'components/MissDocsList/MissDocsList';
import ProlongList from 'components/ProlongList/ProlongList';

// Styles
import './BottomNavigation.scss';
import './Tabs.scss';

const BottomNavigation = ({
  userRole,
  canAddTask,
  notifications: {
    prolong = [],
    despatchProblems = [],
    missDocs = [],
    myTasks = [],
    tasks = [],
    problems = [],
    overdue = [],
    noReports = [],
    noNar = [],
    noForecast = [],
    noReceive = [],
    overdueNodes = [],
  } = {},
}) => {
  const [isTabPaneOpen, setTabPaneOpen] = useState(false);
  const [currentTabContentID, setCurrentTabContentID] = useState(null);
  const [currentSubTabContentID, setCurrentSubTabContentID] = useState(null);
  const [isAddTaskFormActive, setTaskFormActive] = useState(false);

  const activateAddTaskFormModal = () => {
    setTaskFormActive(true);
  };

  const deactivateAddTaskFormModal = () => {
    setTaskFormActive(false);
  };

  const [totalTasksCount, setTotalTasksCount] = useState(0);
  const [totalMyProblemsCount, setTotalMyProblemsTasksCount] = useState(0);

  /** Считаем общее кол-во задач */
  useEffect(() => {
    let count = 0;
    if (Array.isArray(problems)) {
      count += problems.length;
    }
    if (Array.isArray(tasks)) {
      count += tasks.length;
    }
    if (Array.isArray(myTasks)) {
      count += myTasks.length;
    }
    setTotalTasksCount(count);
  }, [problems, tasks, myTasks]);

  /** Считаем общее кол-во моих проблем */
  useEffect(() => {
    let count = 0;
    if (Array.isArray(noReports)) {
      count += noReports.length;
    }
    if (Array.isArray(noNar)) {
      count += noNar.length;
    }
    if (Array.isArray(noForecast)) {
      count += noForecast.length;
    }
    if (Array.isArray(noReceive)) {
      count += noReceive.length;
    }
    if (Array.isArray(overdueNodes)) {
      count += overdueNodes.length;
    }
    if (Array.isArray(overdue)) {
      count += overdue.length;
    }
    if (Array.isArray(despatchProblems)) {
      count += despatchProblems.length;
    }
    setTotalMyProblemsTasksCount(count);
  }, [noReports, noNar, noForecast, noReceive, overdueNodes, overdue, despatchProblems]);

  const closePanel = () => {
    setTabPaneOpen(false);
    setCurrentTabContentID(null);
    setCurrentSubTabContentID(null);
  };

  return (
    <nav className="tab-panel">
      {isTabPaneOpen && (
        <button type="button" className="btn btn-danger tab-panel__close" onClick={closePanel}>
          ×
        </button>
      )}

      <div className="tab-panel__container container">
        {/* TABS */}
        <div className="tabs">
          {/* TABS MENU */}
          <ul className="tabs__menu">
            <li
              className={cx('tabs__menu-item', {
                'tabs__menu-item_active': currentTabContentID === 'notif-tasks',
              })}
            >
              <button
                type="button"
                className="tabs__menu-item-btn"
                onClick={() => {
                  setTabPaneOpen(true);
                  setCurrentTabContentID('notif-tasks');
                  setCurrentSubTabContentID('notif-problems-to-me');
                }}
              >
                <span className="tabs__menu-item-btn-name">Задачи</span>{' '}
                {totalTasksCount > 0 && (
                  <span className="label label_danger">{totalTasksCount}</span>
                )}
              </button>
            </li>

            <li
              className={cx('tabs__menu-item', {
                'tabs__menu-item_active': currentTabContentID === 'notif-problems',
              })}
            >
              <button
                type="button"
                className="tabs__menu-item-btn"
                onClick={() => {
                  setTabPaneOpen(true);
                  setCurrentTabContentID('notif-problems');
                }}
              >
                <span className="tabs__menu-item-btn-name">Мои проблемы</span>{' '}
                {totalMyProblemsCount > 0 && (
                  <span className="label label_danger">{totalMyProblemsCount}</span>
                )}
              </button>
            </li>

            <li
              className={cx('tabs__menu-item', {
                'tabs__menu-item_active': currentTabContentID === 'notif-docs',
              })}
            >
              <button
                type="button"
                className="tabs__menu-item-btn"
                onClick={() => {
                  setTabPaneOpen(true);
                  setCurrentTabContentID('notif-docs');
                }}
              >
                <span className="tabs__menu-item-btn-name">Отсутствуют документы</span>{' '}
                {missDocs && Array.isArray(missDocs) && missDocs.length > 0 && (
                  <span className="label label_danger">{missDocs.length}</span>
                )}
              </button>
            </li>

            {(userRole === 6 || userRole === 14) && (
              <li
                className={cx('tabs__menu-item', {
                  'tabs__menu-item_active': currentTabContentID === 'notif-prolongs',
                })}
              >
                <button
                  type="button"
                  className="tabs__menu-item-btn"
                  onClick={() => {
                    setTabPaneOpen(true);
                    setCurrentTabContentID('notif-prolongs');
                  }}
                >
                  <span className="tabs__menu-item-btn-name">Запросы на продление</span>{' '}
                  {Array.isArray(prolong) && prolong.length > 0 && (
                    <span className="label label_danger">{prolong.length}</span>
                  )}
                </button>
              </li>
            )}
          </ul>
          {/* TABS MENU END */}

          <div className="tabs__content-wrap">
            {/** ЗАДАЧИ */}
            {currentTabContentID === 'notif-tasks' && (
              <div id="notif-tasks" className="tabs__content">
                {canAddTask && (
                  <React.Fragment>
                    {/** КНОПКИ ВКЛАДОК ВНУТРИ ЗАДАЧИ */}
                    <ul className="tabs__menu">
                      {problems && (
                        <li
                          className={cx('tabs__menu-item', {
                            'tabs__menu-item_active':
                              currentSubTabContentID === 'notif-problems-to-me',
                          })}
                        >
                          <button
                            type="button"
                            className="tabs__menu-item-btn"
                            onClick={() => {
                              setCurrentSubTabContentID('notif-problems-to-me');
                            }}
                          >
                            <span className="tabs__menu-item-btn-name">Текущие проблемы</span>{' '}
                            {problems.length > 0 && (
                              <span className="label label_danger">{problems.length}</span>
                            )}
                          </button>
                        </li>
                      )}

                      <li
                        className={cx('tabs__menu-item', {
                          'tabs__menu-item_active':
                            currentSubTabContentID === 'notif-tasks-to-me' ||
                            (!problems && currentSubTabContentID !== 'notif-tasks-my'),
                        })}
                      >
                        <button
                          type="button"
                          className="tabs__menu-item-btn"
                          onClick={() => {
                            setCurrentSubTabContentID('notif-tasks-to-me');
                          }}
                        >
                          <span className="tabs__menu-item-btn-name">На меня</span>{' '}
                          {Array.isArray(tasks) && tasks.length > 0 && (
                            <span className="label label_danger">{tasks.length}</span>
                          )}
                        </button>
                      </li>

                      <li
                        className={cx('tabs__menu-item', {
                          'tabs__menu-item_active': currentSubTabContentID === 'notif-tasks-my',
                        })}
                      >
                        <button
                          type="button"
                          className="tabs__menu-item-btn"
                          onClick={() => {
                            setCurrentSubTabContentID('notif-tasks-my');
                          }}
                        >
                          <span className="tabs__menu-item-btn-name">Мной</span>{' '}
                          {Array.isArray(myTasks) && myTasks.length > 0 && (
                            <span className="label label_danger">{myTasks.length}</span>
                          )}
                        </button>
                      </li>
                    </ul>
                    {/** КНОПКИ ВКЛАДОК ВНУТРИ ЗАДАЧИ END */}

                    <div className="tabs__content-wrap">
                      {/** ТЕКУЩИЕ ПРОБЛЕМЫ */}
                      {problems && currentSubTabContentID === 'notif-problems-to-me' && (
                        <div id="notif-tasks-to-me" className="tabs__content">
                          <div className="tabs__content-inner">
                            <div className="tabs__content-scroll-wrap">
                              <ProblemsList problems={problems} closePanel={closePanel} />
                            </div>
                          </div>
                        </div>
                      )}
                      {/** ТЕКУЩИЕ ПРОБЛЕМЫ END */}

                      {/** НА МЕНЯ */}
                      {(currentSubTabContentID === 'notif-tasks-to-me' ||
                        (!problems && currentSubTabContentID !== 'notif-tasks-my')) && (
                        <div id="notif-tasks-to-me" className="tabs__content">
                          <div className="tabs__content-inner">
                            <div className="tabs__content-scroll-wrap">
                              <TasksList tasks={tasks} canAddTask={canAddTask} />
                            </div>
                          </div>
                        </div>
                      )}
                      {/** НА МЕНЯ END */}

                      {/** МНОЙ */}
                      {currentSubTabContentID === 'notif-tasks-my' && (
                        <div id="notif-tasks-my" className="tabs__content">
                          <div className="tabs__content-inner">
                            <div className="tabs__content-scroll-wrap">
                              <div className="tabs__btn-wrap">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={activateAddTaskFormModal}
                                >
                                  Поставить задачу
                                </button>

                                {isAddTaskFormActive && canAddTask && (
                                  <Modal
                                    title="Добавление задачи"
                                    closeHandler={deactivateAddTaskFormModal}
                                  >
                                    <AddTaskFormModal
                                      files={[]}
                                      closeHandler={deactivateAddTaskFormModal}
                                    />
                                  </Modal>
                                )}
                              </div>
                              <MyTasksList myTasks={myTasks} />
                            </div>
                          </div>
                        </div>
                      )}
                      {/** МНОЙ END */}
                    </div>
                  </React.Fragment>
                )}

                {!canAddTask && (
                  <div className="tabs__content-inner">
                    <div className="tabs__content-scroll-wrap">
                      <TasksList tasks={tasks} canAddTask={canAddTask} />
                    </div>
                  </div>
                )}
              </div>
            )}
            {/** ЗАДАЧИ END */}

            {/** МОИ ПРОБЛЕМЫ */}
            {currentTabContentID === 'notif-problems' && (
              <div id="notif-problems" className="tabs__content">
                <div className="tabs__content-inner">
                  <div className="tabs__content-scroll-wrap">
                    <OverdueList overdue={overdue} closePanel={closePanel} />
                    <NoReportsList noReports={noReports} closePanel={closePanel} />
                    <NoNarList noNar={noNar} closePanel={closePanel} />
                    <NoForecastList noForecast={noForecast} closePanel={closePanel} />
                    <NoReceiveList noReceive={noReceive} closePanel={closePanel} />
                    <OverdueNodesList overdueNodes={overdueNodes} closePanel={closePanel} />
                    <DespatchProblemsList despatchProblems={despatchProblems} closePanel={closePanel} userRole={userRole} />
                  </div>
                </div>
              </div>
            )}
            {/** МОИ ПРОБЛЕМЫ END */}

            {/** ОТСУТСТВУЮТ ДОКУМЕНТЫ */}
            {currentTabContentID === 'notif-docs' && (
              <div id="notif-docs" className="tabs__content">
                <div className="tabs__content-inner">
                  <div className="tabs__content-scroll-wrap">
                    <MissDocsList missDocs={missDocs} userRole={userRole} closePanel={closePanel} />
                  </div>
                </div>
              </div>
            )}
            {/** ОТСУТСТВУЮТ ДОКУМЕНТЫ END */}

            {/** ЗАПРОСЫ НА ПРОДЛЕНИЕ */}
            {(userRole === 6 || userRole === 14) && currentTabContentID === 'notif-prolongs' && (
              <div id="notif-prolongs" className="tabs__content">
                <div className="tabs__content-inner">
                  <div className="tabs__content-scroll-wrap">
                    <ProlongList prolong={prolong} closePanel={closePanel} />
                  </div>
                </div>
              </div>
            )}
            {/** ЗАПРОСЫ НА ПРОДЛЕНИЕ END */}
          </div>
        </div>
        {/* TABS END */}
      </div>
    </nav>
  );
};

BottomNavigation.propTypes = {
  userRole: PropTypes.number.isRequired,
  canAddTask: PropTypes.bool.isRequired,

  notifications: PropTypes.shape({
    prolong: PropTypes.arrayOf(PropTypes.shape({})),
    despatchProblems: PropTypes.arrayOf(PropTypes.shape({})),
    tasks: PropTypes.arrayOf(PropTypes.shape({})),
    myTasks: PropTypes.arrayOf(PropTypes.shape({})),
    missDocs: PropTypes.arrayOf(PropTypes.shape({})),
    problems: PropTypes.arrayOf(PropTypes.shape({})),
    overdue: PropTypes.arrayOf(PropTypes.shape({})),
    noReports: PropTypes.arrayOf(PropTypes.shape({})),
    noNar: PropTypes.arrayOf(PropTypes.shape({})),
    noForecast: PropTypes.arrayOf(PropTypes.shape({})),
    noReceive: PropTypes.arrayOf(PropTypes.shape({})),
    overdueNodes: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default BottomNavigation;
