import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Icons
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

// Components
import Checkbox from 'components/Checkbox/Checkbox';
import Modal from 'components/Modal/Modal';
import RemoveAdditionalWorkConfirmModal from 'components/RemoveAdditionalWorkConfirmModal/RemoveAdditionalWorkConfirmModal';
import EditAdditionalWorkModal from 'components/EditAdditionalWorkModal/EditAdditionalWorkModal';

// Actions
import { setRepairAdditionalWorkReady, setRepairAdditionalWorkPaid } from 'actions';

const TableRepairAddWorks = ({ canEdit, works, repairId, dispatch }) => {
  const [editWorkItem, setEditWorkItem] = useState(null);
  const [removeWorkId, setRemoveWorkId] = useState(null);
  const [isRemoveAdditionalWorkConfirmActive, setRemoveAdditionalWorkConfirmActive] = useState(
    false,
  );
  const [isEditAdditionalWorkActive, setEditAdditionalWorkActive] = useState(false);

  const handleAddWorkReady = (id, workId, value) => {
    dispatch(setRepairAdditionalWorkReady(id, workId, value))
      .then(() => {})
      .catch(() => {});
  };

  const handleAddWorkPaid = (id, workId, value) => {
    dispatch(setRepairAdditionalWorkPaid(id, workId, value))
      .then(() => {})
      .catch(() => {});
  };

  const activateRemoveAdditionalWorkConfirmModal = workId => {
    setRemoveWorkId(workId);
    setRemoveAdditionalWorkConfirmActive(true);
  };

  const deactivateRemoveAdditionalWorkConfirmModal = () => {
    setRemoveAdditionalWorkConfirmActive(false);
  };

  const activateEditAdditionalWorkModal = work => {
    setEditWorkItem(work);
    setEditAdditionalWorkActive(true);
  };

  const deactivateEditAdditionalWorkModal = () => {
    setEditAdditionalWorkActive(false);
  };

  return (
    <table className="table table-bordered table-striped">
      <thead>
        <tr>
          <th>Дата</th>
          <th>Работы</th>
          <th>Выполнена</th>
          <th>Оплачена</th>
          {canEdit && <th> </th>}
        </tr>
      </thead>

      <tbody>
        {works.map(work => (
          <tr key={work.id}>
            <td>{work.date_1}</td>
            <td>{work.name}</td>
            <td>
              {canEdit && (
                <Checkbox
                  ariaLabel="Отметить работу как выполненную"
                  checked={work.ready}
                  onClick={() => handleAddWorkReady(repairId, work.id, !work.ready)}
                />
              )}

              {!canEdit && work.ready && <span className="label label_success">Да</span>}

              {!canEdit && !work.ready && <span className="label label_danger">Нет</span>}
            </td>
            <td>
              {canEdit && (
                <Checkbox
                  ariaLabel="Поставить отметку об оплате за работу"
                  checked={work.paid}
                  onClick={() => handleAddWorkPaid(repairId, work.id, !work.paid)}
                />
              )}

              {!canEdit && work.paid && <span className="label label_success">Да</span>}

              {!canEdit && !work.paid && <span className="label label_danger">Нет</span>}
            </td>

            {canEdit && (
              <td>
                <button
                  type="button"
                  className="btn btn-primary btn-xs"
                  title="Редактировать"
                  onClick={() => activateEditAdditionalWorkModal(work)}
                >
                  <FaEdit className="icon" />
                </button>{' '}
                <button
                  type="button"
                  className="btn btn-danger btn-xs"
                  title="Удалить"
                  onClick={() => activateRemoveAdditionalWorkConfirmModal(work.id)}
                >
                  <FaTrashAlt className="icon" />
                </button>
              </td>
            )}
          </tr>
        ))}

        {isRemoveAdditionalWorkConfirmActive && (
          <Modal
            title="Удаление дополнительной работы"
            closeHandler={deactivateRemoveAdditionalWorkConfirmModal}
          >
            <RemoveAdditionalWorkConfirmModal
              closeHandler={deactivateRemoveAdditionalWorkConfirmModal}
              repairId={repairId}
              removeWorkId={removeWorkId}
            />
          </Modal>
        )}

        {isEditAdditionalWorkActive && (
          <Modal
            title="Удаление дополнительной работы"
            closeHandler={deactivateEditAdditionalWorkModal}
          >
            <EditAdditionalWorkModal
              closeHandler={deactivateEditAdditionalWorkModal}
              repairId={repairId}
              work={editWorkItem}
            />
          </Modal>
        )}
      </tbody>
    </table>
  );
};

TableRepairAddWorks.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  repairId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  works: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      date_1: PropTypes.string,
      name: PropTypes.string,
      ready: PropTypes.bool,
      paid: PropTypes.bool,
    }),
  ).isRequired,
};

export default connect()(TableRepairAddWorks);
