import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Styles
import './NoNarList.scss';

const NoNarList = ({ noNar, closePanel }) => {
  return (
    <ul className="no-nar-list">
      {Array.isArray(noNar) &&
        noNar.map(item => (
          <li key={item.id} className="no-nar-list__item">
            <div className="no-nar-list__item-info">
              <span className="label label_danger">Не заполнена наработка агрегатов</span> -{' '}
              <Link
                to={`/repair/${item.id}/docs`}
                className="no-nar-list__item-link"
                onClick={closePanel}
              >
                {item.ksname} {item.gpa} №{item.stationnumber}
              </Link>
            </div>
          </li>
        ))}
    </ul>
  );
};

NoNarList.propTypes = {
  closePanel: PropTypes.func.isRequired,
  noNar: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      ksname: PropTypes.string,
      gpa: PropTypes.string,
      stationnumber: PropTypes.string,
    }),
  ),
};

NoNarList.defaultProps = {
  noNar: null,
};

export default NoNarList;
