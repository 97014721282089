import React from 'react';
import PropTypes from 'prop-types';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';

// Styles
import './rejected-prolong-result.scss';

const RejectedProlongModal = ({ closeHandler, prolong }) => {
  return (
    <React.Fragment>
      <ModalHeader title="Результаты запроса на продление сроков" />

      <ModalBody>
        <div className="panel panel_primary">
          <div className="panel__body">
            <div className="rejected-prolong-result">
              <p className="rejected-prolong-result__text">
                Ответ получен {prolong.datetimereaction_1} от {prolong.authorreactionname}{' '}
                {prolong.confirm === '3' && <span className="label label_success">Одобрено</span>}
                {prolong.confirm === '2' && <span className="label label_danger">Не одобрено</span>}
              </p>

              {prolong.confirm === '2' && (
                <div className="rejected-prolong-result__reject">
                  <h3 className="rejected-prolong-result__title">Причина отказа:</h3>
                  <p className="rejected-prolong-result__text">{prolong.rejectreason}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalBody>

      <ModalFooter closeHandler={closeHandler} />
    </React.Fragment>
  );
};

RejectedProlongModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  prolong: PropTypes.shape({
    datetimereaction_1: PropTypes.string,
    authorreactionname: PropTypes.string,
    confirm: PropTypes.string,
    rejectreason: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
};

export default RejectedProlongModal;
