import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Actions
import { getRulesFiles, deleteRulesFile } from 'actions';

// Components
import FormGroup from 'components/FormGroup/FormGroup';
import InputForm from 'components/InputForm/InputForm';
import AddFileModal from 'components/AddFileModal/AddFileModal';
import Modal from 'components/Modal/Modal';
import Loader from 'components/Loader/Loader';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';

// Icons
import { FaTrashAlt } from 'react-icons/fa';

// Styles
import './Rules.scss';

const Rules = ({ items, userRole, dispatch }) => {
  const [searchRule, setSearchRule] = useState('');
  const [isAddFileActive, setAddFileActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deletingFile, setDeletingFile] = useState(null);

  const activateAddFileModal = () => {
    setAddFileActive(true);
  };

  const deactivateAddFileModal = () => {
    setAddFileActive(false);
  };

  useEffect(() => {
    if (!items.length) {
      setIsLoading(true);
      dispatch(getRulesFiles())
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [dispatch, items]);

  const [filteredList, setFilteredList] = useState([]);
  useEffect(() => {
    if (!items) {
      setFilteredList([]);
      return;
    }
    if (items && !searchRule) {
      setFilteredList(items);
      return;
    }
    const newItems = items.map(item => {
      return {
        ...item,
        files: item.files.filter(file =>
          file.name ? file.name.indexOf(searchRule) !== -1 : file.file.indexOf(searchRule) !== -1,
        ),
      };
    });
    setFilteredList(newItems);
  }, [searchRule, items]);

  const deleteFile = () => {
    return dispatch(deleteRulesFile(deletingFile));
  };

  const closeConfirmation = () => {
    setDeletingFile(null);
  };

  const deleteWithConfirm = (fileId, roleId) => {
    setDeletingFile({
      id: fileId,
      role: roleId,
    });
  };

  const canAddAndDelete = ![4, 5, 12].includes(userRole);

  if (isLoading) {
    return <Loader className="loader_page" />;
  }

  return (
    <div className="rules-page">
      <div className="rules-page__container container">
        {canAddAndDelete && (
          <div className="rules-page__head">
            <button type="button" className="btn btn-primary" onClick={activateAddFileModal}>
              Добавить файл
            </button>

            {isAddFileActive && (
              <Modal title="Добавить файл" closeHandler={deactivateAddFileModal}>
                <AddFileModal closeHandler={deactivateAddFileModal} />
              </Modal>
            )}
          </div>
        )}

        {Array.isArray(items) && items.length > 0 && (
          <div className="rules-page__search">
            <div className="rules-page__search-inner">
              <FormGroup title="Поиск документов">
                <InputForm
                  type="text"
                  name="search-rules"
                  value={searchRule}
                  onChange={({ target }) => setSearchRule(target.value)}
                />
              </FormGroup>
            </div>
          </div>
        )}

        <div className="rules-page__content">
          {filteredList.map(item => (
            <div key={item.id} className="rules-page__doc-row">
              <div className="rules-page__doc-row-title">{item.rolename}</div>
              <blockquote className="rules-page__doc">
                {Array.isArray(item.files) &&
                  item.files.map(file => (
                    <div key={file.id} className="rules-page__doc-wrap">
                      <a
                        href={file.file}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="label label_info"
                      >
                        {file.name ? file.name : file.file}
                      </a>{' '}
                      {canAddAndDelete && (
                        <button
                          type="button"
                          className="btn btn-danger btn-xs"
                          onClick={() => {
                            deleteWithConfirm(file.id, item.role);
                          }}
                        >
                          <FaTrashAlt className="icon" />
                        </button>
                      )}
                    </div>
                  ))}
              </blockquote>
            </div>
          ))}
        </div>
      </div>

      {deletingFile && (
        <Modal title="Вы уверены?" closeHandler={closeConfirmation}>
          <ConfirmModal
            closeHandler={closeConfirmation}
            confirmAction={deleteFile}
            title="Вы уверены"
            question="что хотите удалить этот файл?"
            actionLabel="Удалить"
          />
        </Modal>
      )}
    </div>
  );
};

Rules.propTypes = {
  dispatch: PropTypes.func.isRequired,
  userRole: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      rolename: PropTypes.string,
      files: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          file: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
    }),
  ),
};

Rules.defaultProps = {
  items: [],
};

const mapStateToProps = state => ({
  items: state.mainReducer.rules,
  userRole: state.mainReducer.user.userRole,
});

export default connect(mapStateToProps)(Rules);
