import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Icons
import { FaPaperclip } from 'react-icons/fa';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import InputDate from 'components/InputDate/InputDate';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { receiveRepairNode, uploadRMMDocument } from 'actions';

// Misc
import { browseServer } from 'helpers/file-manager';

const ReceiptNodeModal = ({ closeHandler, currentNodeId, repairId, dispatch }) => {
  const [dateReceiptNode, setDateReceiptNode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [files, setFiles] = useState({});

  const submitReceiveRepairNode = () => {
    setIsLoading(true);

    dispatch(receiveRepairNode(repairId, currentNodeId))
      .then(() => {
        setIsLoading(false);

        closeHandler();
      })
      .catch(error => {
        if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsLoading(false);
      });
  };

  const selectFileForDocument = type => {
    browseServer({
      onSuccess: fileUrl => {
        dispatch(uploadRMMDocument(currentNodeId, type, fileUrl))
          .then(res => {
            setFiles(prev => ({
              ...prev,
              ...res,
            }));
          })
          .catch(() => {});
      },
    });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Принять узел" />

      <ModalBody>
        <FormGroup title="Дата приемки">
          <InputDate selectedDate={dateReceiptNode} onChange={date => setDateReceiptNode(date)} />
        </FormGroup>

        <FormGroup title="Акт приемки">
          <div className="upload-docs">
            <button
              type="button"
              className="btn btn-xs btn-primary"
              onClick={() => {
                selectFileForDocument('actreceipt');
              }}
            >
              <FaPaperclip className="icon" />
            </button>{' '}
            {files && !!files.actreceipt && (
              <a
                href={files.actreceipt}
                rel="noopener noreferrer"
                target="_blank"
                className="label label_info"
              >
                {files.shortactreceipt}
              </a>
            )}
          </div>
        </FormGroup>

        <FormGroup title="Накладная">
          <div className="upload-docs">
            <button
              type="button"
              className="btn btn-xs btn-primary"
              onClick={() => {
                selectFileForDocument('naklad');
              }}
            >
              <FaPaperclip className="icon" />
            </button>{' '}
            {files && !!files.naklad && (
              <a
                href={files.naklad}
                rel="noopener noreferrer"
                target="_blank"
                className="label label_info"
              >
                {files.shortnaklad}
              </a>
            )}
          </div>
        </FormGroup>
      </ModalBody>

      {responseError && <ModalError>{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isLoading={isLoading}
          isLoadingText="Принимаю..."
          clickHandler={submitReceiveRepairNode}
        >
          Принять
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

ReceiptNodeModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  repairId: PropTypes.number.isRequired,
  currentNodeId: PropTypes.number.isRequired,
};

export default connect()(ReceiptNodeModal);
