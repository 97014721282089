import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Styles
import './ProlongList.scss';

const ProlongList = ({ prolong, closePanel }) => {
  return (
    <ul className="prolong-list">
      {Array.isArray(prolong) &&
        prolong.map(item => (
          <li key={item.id} className="prolong-list__item">
            <div className="prolong-list__item-info">
              <span className="label label_danger">{item.datetime_1}</span> -{' '}
              {item.object && (
                <Link
                  to={`/repair/${item.object.id}/prolong`}
                  className="prolong-list__item-link"
                  onClick={closePanel}
                >
                  {item.object.ksname} {item.object.gpa} №{item.object.stationnumber}{' '}
                  {item.reason && `(${item.reason})`}
                </Link>
              )}
            </div>
          </li>
        ))}
    </ul>
  );
};

ProlongList.propTypes = {
  closePanel: PropTypes.func.isRequired,
  prolong: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      datetime_1: PropTypes.string,
      object: PropTypes.shape({
        id: PropTypes.number,
        ksname: PropTypes.string,
        gpa: PropTypes.string,
        stationnumber: PropTypes.string,
      }),
      reason: PropTypes.string,
    }),
  ),
};

ProlongList.defaultProps = {
  prolong: null,
};

export default ProlongList;
