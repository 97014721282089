import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Loader from 'components/Loader/Loader';
import TableTsControl from 'components/TableTsControl/TableTsControl';

// Styles
import './Tscontrol.scss';

//Actions
import {
  loadDespatches,
  setResponsibleFinishedShipping,
  clearResponsibleFinishedShipping,
  setRecipientCompletedShipping,
  clearRecipientCompletedShipping,
  returnDespatch,
  sendDespatchToArchive,
} from 'actions/despatch';

const Tscontrol = ({ dispatch, despatches, user, page }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(loadDespatches({ 'status[inTransit]': true }, false, page))
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch, page]);

  const handleResponsibleShipped = bid => {
    setIsLoading(true);

    dispatch(setResponsibleFinishedShipping(bid, user.id))
      .then(() => {})
      .catch(error => {})
      .finally(() => setIsLoading(false));
  };

  const handleResponsibleShippedClear = bid => {
    setIsLoading(true);

    dispatch(clearResponsibleFinishedShipping(bid, user.id))
      .then(() => {})
      .catch(error => {})
      .finally(() => setIsLoading(false));
  };

  const handleDespatchCompleted = bid => {
    dispatch(setRecipientCompletedShipping(bid, user.id))
      .then(() => {})
      .catch(error => {})
      .finally(() => setIsLoading(false));
  };

  const handleDespatchCompletedClear = bid => {
    dispatch(clearRecipientCompletedShipping(bid, user.id))
      .then(() => {})
      .catch(error => {})
      .finally(() => setIsLoading(false));
  };

  const handleReturnDespatchClick = bid => {
    dispatch(returnDespatch(bid))
      .then(() => {
        return dispatch(loadDespatches({ 'status[inTransit]': true }, false, page))
      })
      .catch(error => {})
      .finally(() => setIsLoading(false));
  };

  const submitSendDespatchToArchive = bid => {
    setIsLoading(true);

    dispatch(sendDespatchToArchive(bid))
      .then(() => {
        setIsLoading(false);
      })
      // .catch(error => {
      //   if (error && error.error) {
      //     setResponseError(error.error);
      //   } else {
      //     setResponseError('Произошла неизвестная ошибка');
      //   }

      //   setIsLoading(false);
      // })
      .catch(error => {})
      .finally(() => setIsLoading(false));
  };

  return (
    <React.Fragment>
      {false && isLoading && <Loader className="loader_page" />}

      <div className="tscontrol-page">
        <div className="tscontrol-page__container container">
          <h1 className="tscontrol-page__title">Контроль поездок</h1>
          <div className="tscontrol-page__content">
            <TableTsControl
              despatches={despatches}
              onDespatchShipped={handleResponsibleShipped}
              onDespatchShippedCleared={handleResponsibleShippedClear}
              onDespatchCompleted={handleDespatchCompleted}
              onDespatchCompletedCleared={handleDespatchCompletedClear}
              onReturnDespatchClick={handleReturnDespatchClick}
              onSendDespatchToArchive={submitSendDespatchToArchive}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

Tscontrol.defaultProps = {
  despatches: [],
  page: 0, // TODO: do we need pagination here???
};

Tscontrol.propTypes = {
  dispatch: PropTypes.func.isRequired,
  despatches: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({
    userRole: PropTypes.number,
    id: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = state => ({
  dispatch: state.mainReducer.dispatch,
  despatches: state.despatchReducer.inTransit,
  user: state.mainReducer.user,
});

export default connect(mapStateToProps)(Tscontrol);
