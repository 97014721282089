import * as types from 'actions/action-types';

const initialState = {
  /** Данные по странице отправления (информация в шапке???) */
  despatchInfo: {}, // Store individual despatch
  canEdit: false,
  canSee: false,
  content: [], // Store all despatches
  inTransit: [], // Despatches for Transportation Control
  comments: [], // Store all comments for despatches
  problemComments: [], // Store all comments for despatches
  isFetching: false,
};

// TODO: update endpoint response in camelCase
const formatDriverName = (driver) => `${driver.lastName} ${driver.firstName}. ${driver.middleName}.`;

export const despatchReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DESPATCHES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.DESPATCHES_SUCCESS:
      return Object.assign(
        {}, 
        state, 
        { isFetching: false, canEdit: action.payload.resp.canEdit, canSee: action.payload.resp.canSee },
        action.meta.status
          ? { [action.meta.status]: action.payload.resp.content } 
          : action.payload.resp, 
        action.meta.isArchive && { archivedDespatches: action.payload.resp.content }
      );

    case types.DESPATCHES_FAIL:
      return {
        ...state,
        isFetching: false,
      };

    case types.DESPATCH_INFO_REQUEST:
    case types.EDIT_DESPATCH_REQUEST:
    case types.SET_DESPATCH_PDO_APPROVED_REQUEST:
    case types.SET_DESPATCH_OMTS_APPROVED_REQUEST:
    case types.CLEAR_DESPATCH_PDO_APPROVED_REQUEST:
    case types.CLEAR_DESPATCH_OMTS_APPROVED_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case types.DESPATCH_INFO_SUCCESS:
      return {
        ...state,
        despatchInfo: action.payload.resp,
        isFetching: false,
      };

    case types.DESPATCH_INFO_FAIL:
      return {
        ...state,
        isFetching: false,
      };

    case types.ADD_DESPATCH_SUCCESS:
      return {
        ...state,
        ...action.payload.resp,
        isFetching: false,
      };

    case types.ADD_DESPATCH_FAIL:
      return {
        ...state,
      };

    case types.EDIT_DESPATCH_FAIL:
      return {
        ...state,
        isFetching: false,
      };

    case types.EDIT_DESPATCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };

    case types.DESPATCH_COMMENTS_REQUEST:
      return {
        ...state,
        isFetching: false,
      };

    case types.DESPATCH_COMMENTS_SUCCESS:
    case types.ADD_DESPATCH_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: action.payload.resp,
        isFetching: false,
      };

    case types.DESPATCH_COMMENTS_FAIL:
      return {
        ...state,
      };

    case types.DESPATCH_PROBLEM_COMMENTS_REQUEST:
      return {
        ...state,
        isFetching: false,
      };

    case types.DESPATCH_PROBLEM_COMMENTS_SUCCESS:
      return {
        ...state,
        problemComments: action.payload.resp,
        isFetching: false,
      };

    case types.DESPATCH_PROBLEM_COMMENTS_FAIL:
      return {
        ...state,
      };

    // TODO: fix BE response, so we don't have to update only the status
    case types.SET_DESPATCH_PROBLEM_SOLVED_SUCCESS:
      return {
        ...state,
        // eslint-disable-next-line eqeqeq
        content: state.content.map(despatch => despatch.id == action.meta.bid
          ? { ...despatch, status: action.payload.resp.status }
          : (despatch.additionalDespatches && despatch.additionalDespatches.length
            // eslint-disable-next-line eqeqeq
            ? { ...despatch, additionalDespatches: despatch.additionalDespatches.map(d => d.id == action.meta.bid ? { ...d, status: action.payload.resp.status } : d) }
            : despatch))
      };

    case types.SET_DESPATCH_PDO_APPROVED_SUCCESS:
    case types.SET_DESPATCH_OMTS_APPROVED_SUCCESS:
    case types.CLEAR_DESPATCH_PDO_APPROVED_SUCCESS:
    case types.CLEAR_DESPATCH_OMTS_APPROVED_SUCCESS:
    case types.DESPATCH_ASSIGN_TRANSPORT_SUCCESS:
    case types.SET_DESPATCH_TU_RETURNS_PROBLEM_SUCCESS:
    case types.CLEAR_DESPATCH_ASSIGN_TRANSPORT_SUCCESS:
      return {
        ...state,
        despatchInfo: action.payload.resp,
        // eslint-disable-next-line eqeqeq
        content: state.content.map(despatch => despatch.id == action.meta.bid
          ? action.payload.resp
          : (despatch.additionalDespatches && despatch.additionalDespatches.length
            // eslint-disable-next-line eqeqeq
            ? { ...despatch, additionalDespatches: despatch.additionalDespatches.map(d => d.id == action.meta.bid ? action.payload.resp : d) }
            : despatch))
      };

    case types.SEND_DESPATCH_SUCCESS:
      return {
        ...state,
        // eslint-disable-next-line eqeqeq
        content: state.content.map(despatch => despatch.id == action.meta.bid
          ? undefined
          : (despatch.additionalDespatches && despatch.additionalDespatches.length
            // eslint-disable-next-line eqeqeq
            ? { ...despatch, additionalDespatches: despatch.additionalDespatches.map(d => d.id == action.meta.bid ? undefined : d).filter(v => v) } 
            : despatch)).filter(v => v)
      };

    case types.DESPATCH_ASSIGN_DRIVER_SUCCESS:
    case types.CLEAR_DESPATCH_ASSIGN_DRIVER_SUCCESS:
      return {
        ...state,
        content: state.content
          // eslint-disable-next-line eqeqeq
          .map(despatch => despatch.id == action.meta.bid
            ? {...despatch, assignedDriverId: action.payload.data.content.driver.id, assignedDriver: formatDriverName(action.payload.data.content.driver), assignedDriver_1: action.payload.data.content.driver.fullName_1 }
            : (despatch.additionalDespatches && despatch.additionalDespatches.length
              // eslint-disable-next-line eqeqeq
              ? { ...despatch, additionalDespatches: despatch.additionalDespatches.map(d => Object.assign({}, d, d.id == action.meta.bid && { assignedDriverId: action.payload.data.content.driver.id, assignedDriver: formatDriverName(action.payload.data.content.driver), assignedDriver_1: action.payload.data.content.driver.fullName_1 })) }
              : despatch))
      };

    case types.SET_DESPATCH_RESPONSIBLE_SHIPPED_SUCCESS:
    case types.SET_DESPATCH_RECIPIENT_COMPLETED_SUCCESS:
    case types.CLEAR_DESPATCH_RESPONSIBLE_SHIPPED_SUCCESS:
    case types.CLEAR_DESPATCH_RECIPIENT_COMPLETED_SUCCESS:
      return {
        ...state,
        // eslint-disable-next-line eqeqeq
        inTransit: state.inTransit.map(despatch => despatch.id == action.meta.bid
          ? action.payload.resp
          : (despatch.additionalDespatches && despatch.additionalDespatches.length
            // eslint-disable-next-line eqeqeq
            ? { ...despatch, additionalDespatches: despatch.additionalDespatches.map(d => d.id == action.meta.bid ? action.payload.resp : d) }
            : despatch))
      };

    case types.SEND_DESPATCH_TO_ARCHIVE_SUCCESS:
      return {
        ...state,
        inTransit: state.inTransit.filter(despatch => despatch.id !== action.payload.id)
      };

    default:
      return state;
  }
};

export default despatchReducer;
