import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as types from 'actions/action-types';

// Icons
import { FaFilter, FaTimes } from 'react-icons/fa';

// Components
import Pagination from 'components/Pagination/Pagination';
import TableMtrList from 'components/TableMtrList/TableMtrList';
import Modal from 'components/Modal/Modal';
import MtrFiltersModal from 'components/MtrFiltersModal/MtrFiltersModal';
import Loader from 'components/Loader/Loader';
import Warning from 'components/Warning/Warning';

// Actions
import { getMtrList, setMtrFilter } from 'actions';

// Styles
import './MtrList.scss';

const defaultOptions = [
  {
    value: 0,
    label: 'Любой',
  },
  {
    value: 0,
    label: 'Любая',
  },
];

const specExistOptions = [
  {
    value: 0,
    label: 'Не важно',
  },
  {
    value: 1,
    label: 'Есть',
  },
  {
    value: 2,
    label: 'Нет',
  },
];

const MtrList = ({ dispatch, mtrObject, page, mtrFilter, mtrFilterTemp }) => {
  /** Открытие закрытие модалки с фильтром */
  const [isMtrFilterModalActive, setMtrFilterModalActive] = useState(false);
  const activateMtrFiltersModal = () => {
    setMtrFilterModalActive(true);
  };
  const deactivateMtrFiltersModal = () => {
    setMtrFilterModalActive(false);
  };

  const [isLoading, setIsLoading] = useState(true);

  /** Получаем список запчастей */
  useEffect(() => {
    setMtrFilterModalActive(false);

    /** При изменении фильтров */
    if (mtrFilterTemp) {
      dispatch(setMtrFilter(mtrFilterTemp));
      return;
    }

    setIsLoading(true);
    dispatch(getMtrList(page, mtrFilter))
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [dispatch, page, mtrFilter, mtrFilterTemp]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /** Пагинация */
  const renderPagination = () => {
    if (mtrObject.pagesCount > 0) {
      return (
        <Pagination
          pagesCount={mtrObject.pagesCount}
          currentPage={page}
          asLinks
          linkTemplate="/mtr/list/%%PAGE%%"
        />
      );
    }

    return null;
  };

  const [gpaOptions, setGpaOptions] = useState([]);
  useEffect(() => {
    if (Array.isArray(mtrObject.gpas)) {
      setGpaOptions([
        defaultOptions[0],
        ...mtrObject.gpas.map(item => ({ value: +item.id, label: item.gpa })),
      ]);
    }
  }, [mtrObject.gpas]);

  /** Формируем новый массив objectOptions */
  const [objectOptions, setObjectOptions] = useState([]);
  useEffect(() => {
    if (Array.isArray(mtrObject.objects)) {
      setObjectOptions([
        defaultOptions[0],
        ...mtrObject.objects.map(item => ({
          value: +item.id,
          label: `${item.ksname} ${item.gpa} №${item.stationnumber} ${item.type_s}`,
        })),
      ]);
    }
  }, [mtrObject.objects]);

  /** Формируем новый массив deliveryOptions */
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  useEffect(() => {
    if (Array.isArray(mtrObject.delivery)) {
      setDeliveryOptions([
        defaultOptions[0],
        ...mtrObject.delivery.map(item => ({ value: +item.id, label: item.delivery })),
      ]);
    }
  }, [mtrObject.delivery]);

  return (
    <>
      {isLoading && <Loader className="loader_page" />}

      {!isLoading && mtrObject && mtrObject.item && (
        <div className="mtr-list">
          <div className="mtr-list__container container">
            <h1 className="mtr-list__title">Ведомость МТР</h1>

            <div className="mtr-list__menu">
              <button type="button" className="btn btn-success">
                Загрузка документов
              </button>{' '}
              <button type="button" className="btn btn-primary" onClick={activateMtrFiltersModal}>
                Фильтры <FaFilter className="icon" />
              </button>{' '}
              {mtrFilter && (
                <button
                  type="button"
                  onClick={() => {
                    dispatch({
                      type: types.RESET_MTR_FILTER_SUCCESS,
                    });
                  }}
                  className="btn btn-danger"
                >
                  Сбросить фильтрацию <FaTimes className="icon" />
                </button>
              )}
              {isMtrFilterModalActive && (
                <Modal title="Подобрать по параметрам" closeHandler={deactivateMtrFiltersModal}>
                  <MtrFiltersModal
                    page={page}
                    gpaOptions={gpaOptions}
                    objectOptions={objectOptions}
                    deliveryOptions={deliveryOptions}
                    specExistOptions={specExistOptions}
                    closeHandler={deactivateMtrFiltersModal}
                  />
                </Modal>
              )}
            </div>

            {renderPagination()}

            <div className="mtr-list__content">
              {Array.isArray(mtrObject.item) && mtrObject.item.length > 0 ? (
                <TableMtrList items={mtrObject.item} />
              ) : (
                <Warning
                  msg={
                    mtrFilter
                      ? 'По вашему запросу ничего не найдено...'
                      : 'Пока не добавлено деталей'
                  }
                />
              )}
            </div>

            {renderPagination()}
          </div>
        </div>
      )}
    </>
  );
};

MtrList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  page: PropTypes.number,
  mtrObject: PropTypes.shape({
    objects: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        ksname: PropTypes.string,
        gpa: PropTypes.string,
        stationnumber: PropTypes.string,
        type_s: PropTypes.string,
      }),
    ),
    gpas: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        gpa: PropTypes.string,
      }),
    ),
    delivery: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        delivery: PropTypes.string,
      }),
    ),
    item: PropTypes.arrayOf(PropTypes.shape({})),
    pagesCount: PropTypes.number,
  }).isRequired,

  mtrFilter: PropTypes.shape({}),
  mtrFilterTemp: PropTypes.shape({}),
};

MtrList.defaultProps = {
  page: 0,
  mtrFilter: null,
  mtrFilterTemp: null,
};

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;

  return {
    page: match && match.params ? parseInt(match.params.page, 10) : 0,
    mtrObject: state.mainReducer.mtrList,
    mtrFilter: state.mainReducer.mtrFilter,
    mtrFilterTemp: state.mainReducer.mtrFilterTemp,
  };
};

export default connect(mapStateToProps)(MtrList);
