import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import ModalError from 'components/ModalError/ModalError';
import FormGroup from 'components/FormGroup/FormGroup';
import SelectForm from 'components/SelectForm/SelectForm';
import TextArea from 'components/TextArea/TextArea';
import InputForm from 'components/InputForm/InputForm';
import InputDate from 'components/InputDate/InputDate';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import Loader from 'components/Loader/Loader';

// Actions
import { getMyTaskTargets, addTask } from 'actions';

// Helpers
import { formatDateYYYYmmDD } from 'helpers/formatDateYYYYmmDD';

// Styles
import './AddTaskFormModal.scss';

const AddTaskFormModal = ({ closeHandler, myTaskTargets, dispatch }) => {
  const [isModalLoading, setIsModalLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const [taskText, setTaskText] = useState('');
  const [taskName, setTaskName] = useState('');
  const [taskDateEnd, setTaskDateEnd] = useState(null);
  const [taskTarget, setTaskTarget] = useState(null);

  /** Запрашиваем список таргетов */
  useEffect(() => {
    dispatch(getMyTaskTargets())
      .then(targets => {
        setTaskTarget(
          targets.map(item => ({
            value: item.id,
            label: `${item.name} (${item.role})`,
            role: item.role,
          }))[0],
        );
        setIsModalLoading(false);
      })
      .catch(() => setIsModalLoading(false));
  }, [dispatch]);

  const submitMyTask = () => {
    setIsSubmitLoading(true);

    dispatch(addTask(taskName, taskText, formatDateYYYYmmDD(taskDateEnd), taskTarget))
      .then(() => {
        setIsSubmitLoading(false);

        closeHandler();
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsSubmitLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Добавление задачи" />

      {isModalLoading && <Loader className="loader_modal" />}

      {!isModalLoading && myTaskTargets && (
        <ModalBody>
          <FormGroup title="Название задачи" error={fieldErrors.name}>
            <InputForm
              type="text"
              value={taskName}
              onChange={({ target }) => setTaskName(target.value)}
            />
          </FormGroup>

          <FormGroup title="Текст задачи" error={fieldErrors.text}>
            <TextArea value={taskText} onChange={({ target }) => setTaskText(target.value)} />
          </FormGroup>

          <FormGroup title="Сроки выполнения" error={fieldErrors.dateEnd}>
            <InputDate selectedDate={taskDateEnd} onChange={date => setTaskDateEnd(date)} />
          </FormGroup>

          <FormGroup title="Исполнитель задачи" error={fieldErrors.target}>
            <SelectForm
              options={myTaskTargets.map(item => ({
                value: item.id,
                label: `${item.name} (${item.role})`,
                role: item.role,
              }))}
              selectedValue={taskTarget}
              onChange={selectedOption => setTaskTarget(selectedOption)}
            />
          </FormGroup>
        </ModalBody>
      )}

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isValid={taskName === '' || taskText === '' || !taskTarget || !taskDateEnd}
          isLoading={isSubmitLoading}
          isLoadingText="Добавление..."
          clickHandler={submitMyTask}
        >
          Добавить
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

AddTaskFormModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  myTaskTargets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      role: PropTypes.string,
    }),
  ).isRequired,
};

const mapStateToProps = state => ({
  myTaskTargets: state.mainReducer.myTaskTargets,
});

export default connect(mapStateToProps)(AddTaskFormModal);
