import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import TableReportAddWork from 'components/TableReportAddWork/TableReportAddWork';
import Loader from 'components/Loader/Loader';

// Actions
import { getReportAdditionalWorks } from 'actions';

// Styles
import './ReportAddWork.scss';

const ReportAddWork = ({ dispatch, report }) => {
  const [isLoading, setIsLoading] = useState(true);

  /** Запрашиваем информацию по своду дпоплнительных работ */
  useEffect(() => {
    dispatch(getReportAdditionalWorks())
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch]);

  return (
    <React.Fragment>
      {isLoading && <Loader className="loader_page" />}

      {!isLoading && report && (
        <div className="report-page">
          <div className="report-page__container container">
            <h1 className="report-page__title">
              Свод дополнительных работ на {report.periodName} год
            </h1>

            <div className="report-page__content">
              <TableReportAddWork ordermantoperiod={report.ordermantoperiod} />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

ReportAddWork.propTypes = {
  dispatch: PropTypes.func.isRequired,
  report: PropTypes.shape({
    periodName: PropTypes.string,
    ordermantoperiod: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

ReportAddWork.defaultProps = {
  report: null,
};

const mapStateToProps = state => ({
  report: state.mainReducer.reportAdditionalWorks,
});

export default connect(mapStateToProps)(ReportAddWork);
