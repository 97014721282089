import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import SelectForm from 'components/SelectForm/SelectForm';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { changePeriod } from 'actions';

const ChoosePeriodModal = ({ closeHandler, periods, dispatch }) => {
  const [period, setPeriod] = useState(
    periods.map(item => ({ value: item.id, label: item.name }))[0],
  );

  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const submitChangePeriod = () => {
    setIsLoading(true);

    dispatch(changePeriod(period.value))
      .then(() => {
        setIsLoading(false);

        if (window && window.location && window.location.href) {
          window.location.href = '/';
        }

        closeHandler();
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Выберите период для работы" />

      <ModalBody>
        <FormGroup title="Период" error={fieldErrors.period}>
          <SelectForm
            options={periods.map(item => ({ value: item.id, label: item.name }))}
            selectedValue={period}
            onChange={selectedOption => setPeriod(selectedOption)}
          />
        </FormGroup>
      </ModalBody>

      {responseError && <ModalError>{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isLoading={isLoading}
          isValid={!period}
          isLoadingText="Смена периода..."
          clickHandler={submitChangePeriod}
        >
          Сменить период
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

ChoosePeriodModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  periods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
};

const mapStateToProps = state => ({
  periods: state.mainReducer.headerData.periods,
});

export default connect(mapStateToProps)(ChoosePeriodModal);
