import React from 'react';
import PropTypes from 'prop-types';

// Components
import GpaObject from 'components/GpaObject/GpaObject';

import './WorkTable.scss';

const WorkTable = ({ orderManToPeriod, needCheck, repairFilter, userRole, userId }) => {
  const applyFilterByOrderMan = orderMan => {
    if (repairFilter.orderMan.length > 0) {
      return repairFilter.orderMan.includes(orderMan.id);
    }

    return true;
  };

  const applyFilterByKs = ks => {
    if (repairFilter.ks.length > 0) {
      return repairFilter.ks.includes(ks.id);
    }

    return true;
  };

  const applyFilterByRepair = repair => {
    let isFilterPassed = true;

    if (repairFilter.gpa.length > 0 && isFilterPassed) {
      isFilterPassed = repairFilter.gpa.includes(repair.gpa_sel);
    }

    if (repairFilter.type.length > 0 && isFilterPassed) {
      isFilterPassed = repairFilter.type.includes(repair.type_sel);
    }

    if (repairFilter.status.length > 0 && isFilterPassed) {
      isFilterPassed = repairFilter.status.includes(repair.status);
    }

    if (
      repairFilter.self.length > 0 &&
      repairFilter.self.includes(1) &&
      (userRole === 4 || userRole === 12) &&
      isFilterPassed
    ) {
      isFilterPassed = +repair.master_sel === userId;
    }

    if (
      repairFilter.self.length > 0 &&
      repairFilter.self.includes(2) &&
      (userRole === 4 || userRole === 12) &&
      isFilterPassed
    ) {
      isFilterPassed = +repair.master_sel !== userId;
    }

    if (
      repairFilter.self.length > 0 &&
      repairFilter.self.includes(1) &&
      userRole === 5 &&
      isFilterPassed
    ) {
      isFilterPassed = +repair.nu_sel === userId;
    }

    if (
      repairFilter.self.length > 0 &&
      repairFilter.self.includes(2) &&
      userRole === 5 &&
      isFilterPassed
    ) {
      isFilterPassed = +repair.nu_sel !== userId;
    }

    if (
      repairFilter.master &&
      repairFilter.master.length > 0 &&
      !(userRole === 4 || userRole === 5 || userRole === 12) &&
      isFilterPassed
    ) {
      isFilterPassed = repairFilter.master.includes(+repair.master_sel);
    }

    if (
      repairFilter.nu &&
      repairFilter.nu.length > 0 &&
      !(userRole === 4 || userRole === 5 || userRole === 12) &&
      isFilterPassed
    ) {
      isFilterPassed = repairFilter.nu.includes(+repair.nu_sel);
    }

    return isFilterPassed;
  };

  return (
    <table className="work-table table table-bordered">
      <thead>
        <tr>
          <th> </th>
          <th className="work-table__month-th">
            <span className="label label_default">Январь</span>
          </th>
          <th className="work-table__month-th">
            <span className="label label_default">Февраль</span>
          </th>
          <th className="work-table__month-th">
            <span className="label label_default">Март</span>
          </th>
          <th className="work-table__month-th">
            <span className="label label_default">Апрель</span>
          </th>
          <th className="work-table__month-th">
            <span className="label label_default">Май</span>
          </th>
          <th className="work-table__month-th">
            <span className="label label_default">Июнь</span>
          </th>
          <th className="work-table__month-th">
            <span className="label label_default">Июль</span>
          </th>
          <th className="work-table__month-th">
            <span className="label label_default">Август</span>
          </th>
          <th className="work-table__month-th">
            <span className="label label_default">Сентябрь</span>
          </th>
          <th className="work-table__month-th">
            <span className="label label_default">Октябрь</span>
          </th>
          <th className="work-table__month-th">
            <span className="label label_default">Ноябрь</span>
          </th>
          <th className="work-table__month-th">
            <span className="label label_default">Декабрь</span>
          </th>
        </tr>
      </thead>

      <tbody>
        {orderManToPeriod.filter(applyFilterByOrderMan).map(orderMan => (
          <React.Fragment key={orderMan.id}>
            {orderMan.name && (
              <tr>
                <td colSpan="13" className="work-table__orderman">
                  {orderMan.name}
                </td>
              </tr>
            )}

            {orderMan.ks &&
              Array.isArray(orderMan.ks) &&
              orderMan.ks.filter(applyFilterByKs).map(ks => (
                <tr key={ks.id}>
                  <td className="work-table__ks">
                    <span className="label label_primary">{ks.name}</span>
                  </td>

                  <td className="work-table__month">
                    {ks.month1 &&
                      Array.isArray(ks.month1) &&
                      ks.month1
                        .filter(applyFilterByRepair)
                        .map(object => (
                          <GpaObject key={object.id} unit={object} needCheck={needCheck} />
                        ))}
                  </td>

                  <td className="work-table__month">
                    {ks.month2 &&
                      Array.isArray(ks.month2) &&
                      ks.month2
                        .filter(applyFilterByRepair)
                        .map(object => (
                          <GpaObject key={object.id} unit={object} needCheck={needCheck} />
                        ))}
                  </td>

                  <td className="work-table__month">
                    {ks.month3 &&
                      Array.isArray(ks.month3) &&
                      ks.month3
                        .filter(applyFilterByRepair)
                        .map(object => (
                          <GpaObject key={object.id} unit={object} needCheck={needCheck} />
                        ))}
                  </td>

                  <td className="work-table__month">
                    {ks.month4 &&
                      Array.isArray(ks.month4) &&
                      ks.month4
                        .filter(applyFilterByRepair)
                        .map(object => (
                          <GpaObject key={object.id} unit={object} needCheck={needCheck} />
                        ))}
                  </td>

                  <td className="work-table__month">
                    {ks.month5 &&
                      Array.isArray(ks.month5) &&
                      ks.month5
                        .filter(applyFilterByRepair)
                        .map(object => (
                          <GpaObject key={object.id} unit={object} needCheck={needCheck} />
                        ))}
                  </td>

                  <td className="work-table__month">
                    {ks.month6 &&
                      Array.isArray(ks.month6) &&
                      ks.month6
                        .filter(applyFilterByRepair)
                        .map(object => (
                          <GpaObject key={object.id} unit={object} needCheck={needCheck} />
                        ))}
                  </td>

                  <td className="work-table__month">
                    {ks.month7 &&
                      Array.isArray(ks.month7) &&
                      ks.month7
                        .filter(applyFilterByRepair)
                        .map(object => (
                          <GpaObject key={object.id} unit={object} needCheck={needCheck} />
                        ))}
                  </td>

                  <td className="work-table__month">
                    {ks.month8 &&
                      Array.isArray(ks.month8) &&
                      ks.month8
                        .filter(applyFilterByRepair)
                        .map(object => (
                          <GpaObject key={object.id} unit={object} needCheck={needCheck} />
                        ))}
                  </td>

                  <td className="work-table__month">
                    {ks.month9 &&
                      Array.isArray(ks.month9) &&
                      ks.month9
                        .filter(applyFilterByRepair)
                        .map(object => (
                          <GpaObject key={object.id} unit={object} needCheck={needCheck} />
                        ))}
                  </td>

                  <td className="work-table__month">
                    {ks.month10 &&
                      Array.isArray(ks.month10) &&
                      ks.month10
                        .filter(applyFilterByRepair)
                        .map(object => (
                          <GpaObject key={object.id} unit={object} needCheck={needCheck} />
                        ))}
                  </td>

                  <td className="work-table__month">
                    {ks.month11 &&
                      Array.isArray(ks.month11) &&
                      ks.month11
                        .filter(applyFilterByRepair)
                        .map(object => (
                          <GpaObject key={object.id} unit={object} needCheck={needCheck} />
                        ))}
                  </td>

                  <td className="work-table__month">
                    {ks.month12 &&
                      Array.isArray(ks.month12) &&
                      ks.month12
                        .filter(applyFilterByRepair)
                        .map(object => (
                          <GpaObject key={object.id} unit={object} needCheck={needCheck} />
                        ))}
                  </td>
                </tr>
              ))}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

const monthPropType = PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number }));

WorkTable.propTypes = {
  needCheck: PropTypes.bool.isRequired,
  userRole: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  orderManToPeriod: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      ks: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          month1: monthPropType,
          month2: monthPropType,
          month3: monthPropType,
          month4: monthPropType,
          month5: monthPropType,
          month6: monthPropType,
          month7: monthPropType,
          month8: monthPropType,
          month9: monthPropType,
          month10: monthPropType,
          month11: monthPropType,
          month12: monthPropType,
        }),
      ),
    }),
  ).isRequired,
  repairFilter: PropTypes.shape({
    orderMan: PropTypes.arrayOf(PropTypes.number),
    ks: PropTypes.arrayOf(PropTypes.number),
    gpa: PropTypes.arrayOf(PropTypes.number),
    type: PropTypes.arrayOf(PropTypes.number),
    status: PropTypes.arrayOf(PropTypes.number),
    self: PropTypes.arrayOf(PropTypes.number),
    master: PropTypes.arrayOf(PropTypes.number),
    nu: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
};

export default WorkTable;
