import React from 'react';
import PropTypes from 'prop-types';

// Components
import Checkbox from 'components/Checkbox/Checkbox';

// Icons
import { FaPaperclip, FaTrashAlt } from 'react-icons/fa';

export const docsType = PropTypes.shape({
  authorname: PropTypes.string,
  datetime_1: PropTypes.string,
  file: PropTypes.string,
  shortfile: PropTypes.string,
  noneed: PropTypes.bool,
});

export const docTypeMultiFiles = PropTypes.shape({
  files: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string,
      shortfile: PropTypes.string,
    }),
  ),
  noneed: PropTypes.bool,
});

const DocMultiRow = ({
  canEdit,
  isAllowedToEditNoNeed,
  doc,
  docName,
  docType,
  selectFileForDocument,
  changeRepairDocNoNeed,
  deleteFileForDocument,
  isShownAuthor,
  isShownDate,
  customDocument,
}) => {
  return (
    <tr>
      <td>
        {docName}
        {customDocument && canEdit && (
          <>
            {' '}
            <button
              type="button"
              className="btn btn-danger btn-xs"
              onClick={() => customDocument.removeCustomDoc(customDocument.id)}
            >
              <FaTrashAlt className="icon" />
            </button>
          </>
        )}
      </td>
      {isShownAuthor && <td> </td>}
      {isShownDate && <td> </td>}
      <td>
        {/* eslint-disable react/no-array-index-key */}
        {doc.files &&
          Array.isArray(doc.files) &&
          doc.files.map((item, index) => (
            <div className="upload-docs" key={index}>
              {canEdit && (
                <React.Fragment>
                  <button
                    type="button"
                    className="btn btn-xs btn-primary"
                    onClick={() => {
                      selectFileForDocument(docType, index, Boolean(customDocument));
                    }}
                  >
                    <FaPaperclip className="icon" />
                  </button>{' '}
                </React.Fragment>
              )}
              {item.shortfile && item.file && (
                <React.Fragment>
                  <a
                    href={item.file}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="label label_info"
                  >
                    {item.shortfile}
                  </a>{' '}
                  <button
                    type="button"
                    className="btn btn-danger btn-xs"
                    onClick={() => {
                      deleteFileForDocument(docType, index, Boolean(customDocument));
                    }}
                  >
                    <FaTrashAlt className="icon" />
                  </button>
                </React.Fragment>
              )}
            </div>
          ))}
        {/* eslint-enable react/no-array-index-key */}
      </td>

      {isAllowedToEditNoNeed && (
        <td>
          <Checkbox
            checked={doc.noneed}
            onClick={() => {
              changeRepairDocNoNeed(docType, !doc.noneed);
            }}
            ariaLabel={docName}
          />
        </td>
      )}
    </tr>
  );
};

DocMultiRow.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  isAllowedToEditNoNeed: PropTypes.bool.isRequired,
  doc: docsType.isRequired,
  docName: PropTypes.string.isRequired,
  docType: PropTypes.string.isRequired,
  selectFileForDocument: PropTypes.func.isRequired,
  deleteFileForDocument: PropTypes.func.isRequired,
  changeRepairDocNoNeed: PropTypes.func,
  isShownAuthor: PropTypes.bool,
  isShownDate: PropTypes.bool,
  customDocument: PropTypes.shape({
    id: PropTypes.number,
    removeCustomDoc: PropTypes.func,
  }),
};

DocMultiRow.defaultProps = {
  changeRepairDocNoNeed: () => {},
  isShownAuthor: true,
  isShownDate: true,
  customDocument: null,
};

export default DocMultiRow;
