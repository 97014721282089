import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import ModalError from 'components/ModalError/ModalError';
import FormGroup from 'components/FormGroup/FormGroup';
import InputForm from 'components/InputForm/InputForm';
import InputDate from 'components/InputDate/InputDate';
import SelectForm from 'components/SelectForm/SelectForm';
import Checkbox from 'components/Checkbox/Checkbox';
import TextArea from 'components/TextArea/TextArea';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import Loader from 'components/Loader/Loader';

// Actions
import { getAdminRepairDictionary, addAdminRepair } from 'actions';

// Helpers
import { formatDateYYYYmmDD } from 'helpers/formatDateYYYYmmDD';

const AddRepairModal = ({
  closeHandler,
  gpa,
  privod,
  blower,
  types,
  masterList,
  nuList,
  dispatch,
  month,
  ks,
  orderManId,
}) => {
  const [outOfPlan, setOutOfPlan] = useState(false);
  const [noPrintProblems, setNoPrintProblems] = useState(false);

  const [gpaRepair, setGpaRepair] = useState(null);
  const [privodRepair, setPrivodRepair] = useState(null);
  const [blowerRepair, setBlowerRepair] = useState(null);

  const [invNumber, setInvNumber] = useState('');
  const [stationNumber, setStationNumber] = useState('');

  const [markRepair, setMarkRepair] = useState({
    value: null,
    label: 'Выберите примечение при необходимости',
  });
  const [markHandRepair, setMarkHandRepair] = useState('');
  const [isMarkTextAreaVisible, setMarkTextAreaVisible] = useState(false);

  const [typeRepair, setTypeRepair] = useState(null);

  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  const [masterRepair, setMasterRepair] = useState(null);
  const [master2Repair, setMaster2Repair] = useState(null);

  const [nuRepair, setNuRepair] = useState(null);

  const [isModalLoading, setIsModalLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  useEffect(() => {
    dispatch(getAdminRepairDictionary())
      .then(dictionary => {
        if (
          dictionary &&
          dictionary.gpa &&
          dictionary.privod &&
          dictionary.blower &&
          dictionary.types &&
          dictionary.masterList &&
          dictionary.nuList
        ) {
          setGpaRepair(dictionary.gpa.map(item => ({ value: item.id, label: item.name }))[0]);

          setPrivodRepair(dictionary.privod.map(item => ({ value: item.id, label: item.name }))[0]);

          setBlowerRepair(dictionary.blower.map(item => ({ value: item.id, label: item.name }))[0]);

          setTypeRepair(dictionary.types.map(item => ({ value: item.id, label: item.name }))[0]);

          setMasterRepair(
            dictionary.masterList.map(item => ({ value: item.id, label: item.name }))[0],
          );

          setMaster2Repair(
            [
              {
                value: '',
                label: 'Нет',
              },
              ...[dictionary.masterList.map(item => ({ value: item.id, label: item.name }))],
            ][0],
          );

          setNuRepair(dictionary.nuList.map(item => ({ value: item.id, label: item.name }))[0]);
        }
        setIsModalLoading(false);
      })
      .catch(() => setIsModalLoading(false));
  }, [dispatch]);

  const submitRepair = () => {
    setIsSubmitLoading(true);

    dispatch(
      addAdminRepair(
        gpaRepair.value,
        privodRepair.value,
        blowerRepair.value,
        invNumber,
        stationNumber,
        typeRepair.value,
        formatDateYYYYmmDD(dateStart),
        formatDateYYYYmmDD(dateEnd),
        ks.id,
        orderManId,
        month,
        masterRepair.value,
        master2Repair.value,
        nuRepair.value,
        outOfPlan,
        noPrintProblems,
        markRepair.value,
        markHandRepair,
      ),
    )
      .then(() => {
        setIsSubmitLoading(false);

        closeHandler();
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsSubmitLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Добавление ремонта" />

      {isModalLoading && <Loader className="loader_modal" />}

      {!isModalLoading && gpa && privod && blower && types && masterList && nuList && (
        <ModalBody>
          <div className="modal-body__row">
            <div className="modal-body__col">
              <FormGroup title="Внеплановый объект" error={fieldErrors.isOutOfPlan}>
                <div>
                  <Checkbox
                    ariaLabel="Внеплановый объект"
                    checked={outOfPlan}
                    onClick={() => setOutOfPlan(!outOfPlan)}
                  />
                </div>
              </FormGroup>
            </div>

            <div className="modal-body__col">
              <FormGroup title="Не выводить проблемы" error={fieldErrors.noPrintProblems}>
                <div>
                  <Checkbox
                    ariaLabel="Не выводить проблемы"
                    checked={noPrintProblems}
                    onClick={() => setNoPrintProblems(!noPrintProblems)}
                  />
                </div>
              </FormGroup>
            </div>
          </div>

          <FormGroup title="Тип ГПА" error={fieldErrors.gpa}>
            <SelectForm
              selectedValue={gpaRepair}
              options={gpa.map(item => ({ value: item.id, label: item.name }))}
              onChange={selectedOption => setGpaRepair(selectedOption)}
            />
          </FormGroup>

          <FormGroup title="Тип привода" error={fieldErrors.privod}>
            <SelectForm
              selectedValue={privodRepair}
              options={privod.map(item => ({ value: item.id, label: item.name }))}
              onChange={selectedOption => setPrivodRepair(selectedOption)}
            />
          </FormGroup>

          <FormGroup title="Тип нагнетателя" error={fieldErrors.blower}>
            <SelectForm
              selectedValue={blowerRepair}
              options={blower.map(item => ({ value: item.id, label: item.name }))}
              onChange={selectedOption => setBlowerRepair(selectedOption)}
            />
          </FormGroup>

          <FormGroup title="Инвентарный номер" error={fieldErrors.invnumber}>
            <InputForm
              type="text"
              value={invNumber}
              onChange={({ target }) => setInvNumber(target.value)}
            />
          </FormGroup>

          <FormGroup title="Станционный номер" error={fieldErrors.stationnumber}>
            <InputForm
              type="text"
              value={stationNumber}
              onChange={({ target }) => setStationNumber(target.value)}
            />
          </FormGroup>

          <FormGroup title="Примечание" error={fieldErrors.mark}>
            <SelectForm
              selectedValue={markRepair}
              options={[
                {
                  value: 'с ЭПБ ЦБН',
                  label: 'с ЭПБ ЦБН',
                },
                {
                  value: 'с ЭПБ ГТУ',
                  label: 'с ЭПБ ГТУ',
                },
                {
                  value: 'hand',
                  label: 'Ввести примечание вручную',
                },
              ]}
              onChange={selectedOption => {
                setMarkTextAreaVisible(selectedOption.value === 'hand');
                setMarkRepair(selectedOption);
              }}
            />
          </FormGroup>

          {isMarkTextAreaVisible && (
            <FormGroup title="Текст примечания" error={fieldErrors.markHand}>
              <TextArea
                value={markHandRepair}
                onChange={({ target }) => setMarkHandRepair(target.value)}
              />
            </FormGroup>
          )}

          <FormGroup title="Вид ремонта" error={fieldErrors.type}>
            <SelectForm
              selectedValue={typeRepair}
              options={types.map(item => ({ value: item.id, label: item.name }))}
              onChange={selectedOption => setTypeRepair(selectedOption)}
            />
          </FormGroup>

          <FormGroup title="Дата начала" error={fieldErrors.datestart}>
            <InputDate selectedDate={dateStart} onChange={date => setDateStart(date)} />
          </FormGroup>

          <FormGroup title="Дата окончания" error={fieldErrors.dateend}>
            <InputDate selectedDate={dateEnd} onChange={date => setDateEnd(date)} />
          </FormGroup>

          <FormGroup title="Мастер" error={fieldErrors.master}>
            <SelectForm
              selectedValue={masterRepair}
              options={masterList.map(item => ({ value: item.id, label: item.name }))}
              onChange={selectedOption => setMasterRepair(selectedOption)}
            />
          </FormGroup>

          <FormGroup title="Второй Мастер" error={fieldErrors.master2}>
            <SelectForm
              selectedValue={master2Repair}
              options={[
                {
                  value: '',
                  label: 'Нет',
                },
                ...masterList.map(item => ({ value: item.id, label: item.name })),
              ]}
              onChange={selectedOption => setMaster2Repair(selectedOption)}
            />
          </FormGroup>

          <FormGroup title="Начальник участка" error={fieldErrors.nu}>
            <SelectForm
              selectedValue={nuRepair}
              options={nuList.map(item => ({ value: item.id, label: item.name }))}
              onChange={selectedOption => setNuRepair(selectedOption)}
            />
          </FormGroup>
        </ModalBody>
      )}

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isLoading={isSubmitLoading}
          isValid={
            !gpaRepair ||
            !privodRepair ||
            !blowerRepair ||
            invNumber === '' ||
            stationNumber === '' ||
            !typeRepair ||
            !dateStart ||
            !dateEnd ||
            !masterRepair ||
            !master2Repair ||
            !nuRepair ||
            !markRepair
          }
          isLoadingText="Отправка..."
          clickHandler={submitRepair}
        >
          Создать
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

const defaultDataType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
);

AddRepairModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  orderManId: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  ks: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  gpa: defaultDataType,
  privod: defaultDataType,
  blower: defaultDataType,
  types: defaultDataType,
  masterList: defaultDataType,
  nuList: defaultDataType,
};

AddRepairModal.defaultProps = {
  gpa: null,
  privod: null,
  blower: null,
  types: null,
  masterList: null,
  nuList: null,
};

const mapStateToProps = state => ({
  gpa: state.mainReducer.adminRepairDictionary.gpa,
  privod: state.mainReducer.adminRepairDictionary.privod,
  blower: state.mainReducer.adminRepairDictionary.blower,
  types: state.mainReducer.adminRepairDictionary.types,
  masterList: state.mainReducer.adminRepairDictionary.masterList,
  nuList: state.mainReducer.adminRepairDictionary.nuList,
});

export default connect(mapStateToProps)(AddRepairModal);
