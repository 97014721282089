import React from 'react';
import PropTypes from 'prop-types';

// Components
import ProgressBar from 'components/ProgressBar/ProgressBar';

// Styles
import './PercentNode.scss';

const PercentNode = ({ percent, status, date }) => {
  return (
    <div className="rmm-percents">
      <div className="rmm-percents__date">
        <span className="label label_default">{date}</span>
      </div>
      <div className="rmm-percents__bar">
        <ProgressBar percent={percent} status={status} />
      </div>
    </div>
  );
};

PercentNode.propTypes = {
  status: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
};

export default PercentNode;
