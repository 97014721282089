import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Styles
import './ProgressBar.scss';

const ProgressBar = ({ status, percent, date, handleOpenModal }) => {
  return (
    <div
      className="progress"
      style={{
        marginBottom: status ? '0' : '5px',
      }}
    >
      <div
        className={cx('progress__bar', {
          [`progress__bar_${status}`]: status,
        })}
        role="progressbar"
        data-progress={percent}
        style={{
          width: `${percent}%`,
        }}
      >
        {date ? (
          <button
            type="button"
            className={cx('progress__btn', {
              'progress__btn_color-gray': percent <= 20,
            })}
            onClick={handleOpenModal}
          >
            {`${percent}%`}
          </button>
        ) : (
          `${percent}%`
        )}
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  handleOpenModal: PropTypes.func,
  status: PropTypes.string,
  percent: PropTypes.number.isRequired,
  date: PropTypes.string,
};

ProgressBar.defaultProps = {
  status: null,
  date: null,
  handleOpenModal: null,
};

export default ProgressBar;
