/* eslint-disable import/prefer-default-export */
import { disposeUser } from 'actions';

export const authMiddleware = ({ dispatch, getState }) => next => action => {
  if (typeof action === 'function') {
    const res = action(dispatch, getState);
    if (res.then) {
      res.catch(e => {
        if (e && e.status === 403 && e.error === '') {
          dispatch(disposeUser());
        }
      });
    }
    return res;
  }

  return next(action);
};
