import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Icons
import { FaEdit, FaTrashAlt, FaEyeSlash, FaEye, FaSort } from 'react-icons/fa';

// Components
import Modal from 'components/Modal/Modal';
import RemoveUserConfirmModal from 'components/RemoveUserConfirmModal/RemoveUserConfirmModal';
import Warning from 'components/Warning/Warning';

// Actions
import { toggleAdminUserActive } from 'actions';

// Styles
import './TableAdminUsers.scss';

const TableAdminUsers = ({ users, dispatch, url, searchQuery }) => {
  const [isRemoveUserConfirmActive, setRemoveUserConfirmActive] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);

  const activateRemoveUserConfirmModal = userId => {
    setCurrentUserId(userId);
    setRemoveUserConfirmActive(true);
  };

  const deactivateRemoveUserConfirmModal = () => {
    setRemoveUserConfirmActive(false);
  };

  const applyFilterBySearchQuery = user => {
    let isFilterPassed = true;

    if (user.name && searchQuery) {
      isFilterPassed = user.name.toLowerCase().indexOf(searchQuery) > -1;
    }

    return isFilterPassed;
  };

  const filteredUsers = users.filter(applyFilterBySearchQuery);

  return (
    <>
      {filteredUsers.length > 0 && (
        <table className="table table-bordered table-striped table-admin-users">
          <thead>
            <tr>
              <th>
                <div className="table-admin-users__th-inner">
                  № <FaSort className="icon" />
                </div>
              </th>
              <th>
                <div className="table-admin-users__th-inner">
                  ФИО <FaSort className="icon" />
                </div>
              </th>
              <th>
                <div className="table-admin-users__th-inner">
                  Роль <FaSort className="icon" />
                </div>
              </th>
              <th>
                <div className="table-admin-users__th-inner">
                  Разряд <FaSort className="icon" />
                </div>
              </th>
              <th>
                <div className="table-admin-users__th-inner">
                  Участок <FaSort className="icon" />
                </div>
              </th>
              <th>
                <div className="table-admin-users__th-inner">
                  Оп. пункт <FaSort className="icon" />
                </div>
              </th>
              <th>
                <div className="table-admin-users__th-inner">
                  Логин <FaSort className="icon" />
                </div>
              </th>
              <th>
                <div className="table-admin-users__th-inner">
                  Был в сети <FaSort className="icon" />
                </div>
              </th>
              <th>
                <div className="table-admin-users__th-inner">
                  {' '}
                  <FaSort className="icon" />
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            {filteredUsers.map(user => (
              <tr key={user.id}>
                <td
                  className={cx('table-admin-users__td', {
                    'table-admin-users__td_inactive': !user.active,
                  })}
                >
                  {user.number}
                </td>
                <td
                  className={cx('table-admin-users__td', {
                    'table-admin-users__td_inactive': !user.active,
                  })}
                >
                  {user.name}
                </td>
                <td
                  className={cx('table-admin-users__td', {
                    'table-admin-users__td_inactive': !user.active,
                  })}
                >
                  {user.role}
                </td>
                <td
                  className={cx('table-admin-users__td', {
                    'table-admin-users__td_inactive': !user.active,
                  })}
                >
                  {user.stage}
                </td>
                <td
                  className={cx('table-admin-users__td', {
                    'table-admin-users__td_inactive': !user.active,
                  })}
                >
                  {user.department}
                </td>
                <td
                  className={cx('table-admin-users__td', {
                    'table-admin-users__td_inactive': !user.active,
                  })}
                >
                  {user.op}
                </td>
                <td
                  className={cx('table-admin-users__td', {
                    'table-admin-users__td_inactive': !user.active,
                  })}
                >
                  {user.login}
                </td>
                <td>{user.lastlogin !== '0000-00-00 00:00:00' && user.lastlogin_3}</td>
                <td>
                  <Link
                    to={`${url}/user/${user.id}`}
                    className="btn btn-primary btn-xs"
                    title="Редактировать"
                  >
                    <FaEdit className="icon" />
                  </Link>{' '}
                  {user.login !== '' && user.active && (
                    <React.Fragment>
                      <button
                        type="button"
                        className="btn btn-warning btn-xs"
                        title="Деактивировать"
                        onClick={() => {
                          dispatch(toggleAdminUserActive(user.id, !user.active))
                            .then(() => {})
                            .catch(() => {});
                        }}
                      >
                        <FaEyeSlash className="icon" />
                      </button>{' '}
                    </React.Fragment>
                  )}
                  {user.login !== '' && !user.active && (
                    <React.Fragment>
                      <button
                        type="button"
                        className="btn btn-success btn-xs"
                        title="Активировать"
                        onClick={() => {
                          dispatch(toggleAdminUserActive(user.id, !user.active))
                            .then(() => {})
                            .catch(() => {});
                        }}
                      >
                        <FaEye className="icon" />
                      </button>{' '}
                    </React.Fragment>
                  )}
                  <button
                    type="button"
                    className="btn btn-danger btn-xs"
                    title="Удалить"
                    onClick={() => activateRemoveUserConfirmModal(user.id)}
                  >
                    <FaTrashAlt className="icon" />
                  </button>
                </td>
              </tr>
            ))}

            {isRemoveUserConfirmActive && (
              <Modal
                title="Удалить этого пользователя"
                closeHandler={deactivateRemoveUserConfirmModal}
              >
                <RemoveUserConfirmModal
                  userId={currentUserId}
                  closeHandler={deactivateRemoveUserConfirmModal}
                />
              </Modal>
            )}
          </tbody>
        </table>
      )}

      {filteredUsers.length === 0 && <Warning msg="По вашему запросу ничего не найдено..." />}
    </>
  );
};

TableAdminUsers.propTypes = {
  dispatch: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  searchQuery: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      active: PropTypes.bool,
      number: PropTypes.string,
      name: PropTypes.string,
      userRole: PropTypes.string,
      stage: PropTypes.string,
      department: PropTypes.string,
      op: PropTypes.string,
      login: PropTypes.string,
      lastlogin: PropTypes.string,
      lastlogin_3: PropTypes.string,
    }),
  ).isRequired,
};

export default connect()(TableAdminUsers);
