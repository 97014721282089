import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import TableUrgentDocs from 'components/TableUrgentDocs/TableUrgentDocs';
import UnitStatistics from 'components/UnitStatistics/UnitStatistics';
import NonUrgentDocs from 'components/NonUrgentDocs/NonUrgentDocs';
import Modal from 'components/Modal/Modal';
import AddDocumentModal from 'components/AddDocumentModal/AddDocumentModal';
import Loader from 'components/Loader/Loader';

// Actions
import { getRepairDocs } from 'actions';

const RepairDocs = ({ repairId, repairDocs, dispatch, canEdit, userRole }) => {
  const [isAddDocumentActive, setAddDocumentActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const activateAddDocumentModal = () => {
    setAddDocumentActive(true);
  };
  const deactivateAddDocumentModal = () => {
    setAddDocumentActive(false);
  };

  /** Запрашиваем информацию по ремонту (Документы) */
  useEffect(() => {
    dispatch(getRepairDocs(repairId))
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch, repairId]);

  return (
    <React.Fragment>
      <div className="repair-content__header">
        <h2 className="repair-content__title">Документы</h2>
      </div>

      {isLoading && <Loader />}

      {!isLoading &&
        repairDocs &&
        repairDocs.lopata &&
        repairDocs.schedule &&
        repairDocs.actin &&
        repairDocs.vedbefore &&
        repairDocs.vedafter &&
        repairDocs.vedreplace &&
        repairDocs.actout &&
        repairDocs.vedwork &&
        repairDocs.formular &&
        repairDocs.ks6 &&
        repairDocs.actquality &&
        repairDocs.mtr &&
        repairDocs.techacts &&
        repairDocs.por &&
        repairDocs.veddefect &&
        repairDocs.actdefect &&
        repairDocs.nar1 &&
        repairDocs.nar2 &&
        repairDocs.nar3 &&
        repairDocs.nar4 &&
        repairDocs.nar5 &&
        repairDocs.customDocuments && (
          <>
            <TableUrgentDocs
              userRole={userRole}
              canEdit={canEdit}
              repairId={repairId}
              lopata={repairDocs.lopata}
              mtr={repairDocs.mtr}
              schedule={repairDocs.schedule}
              actin={repairDocs.actin}
              vedbefore={repairDocs.vedbefore}
              vedwork={repairDocs.vedwork}
              vedreplace={repairDocs.vedreplace}
              techacts={repairDocs.techacts}
              actout={repairDocs.actout}
              vedafter={repairDocs.vedafter}
              formular={repairDocs.formular}
              ks6={repairDocs.ks6}
              actquality={repairDocs.actquality}
              por={repairDocs.por}
            />

            <UnitStatistics
              userRole={userRole}
              repairId={repairId}
              nar1={repairDocs.nar1}
              nar2={repairDocs.nar2}
              nar3={repairDocs.nar3}
              nar4={repairDocs.nar4}
              nar5={repairDocs.nar5}
            />

            <NonUrgentDocs
              canEdit={canEdit}
              veddefect={repairDocs.veddefect}
              actdefect={repairDocs.actdefect}
              customDocuments={repairDocs.customDocuments}
              repairId={repairId}
            />

            <div className="repair-content__footer">
              <button type="button" className="btn btn-primary" onClick={activateAddDocumentModal}>
                Добавить документ
              </button>

              {isAddDocumentActive && (
                <Modal title="Добавить документ" closeHandler={deactivateAddDocumentModal}>
                  <AddDocumentModal closeHandler={deactivateAddDocumentModal} repairId={repairId} />
                </Modal>
              )}
            </div>
          </>
        )}
    </React.Fragment>
  );
};

RepairDocs.propTypes = {
  repairId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  userRole: PropTypes.number.isRequired,
  repairDocs: PropTypes.shape({
    lopata: PropTypes.shape({}),
    schedule: PropTypes.shape({}),
    actin: PropTypes.shape({}),
    vedbefore: PropTypes.shape({}),
    vedafter: PropTypes.shape({}),
    vedreplace: PropTypes.shape({}),
    actout: PropTypes.shape({}),
    vedwork: PropTypes.shape({}),
    formular: PropTypes.shape({}),
    ks6: PropTypes.shape({}),
    actquality: PropTypes.shape({}),
    mtr: PropTypes.shape({}),
    techacts: PropTypes.shape({}),
    por: PropTypes.shape({}),
    veddefect: PropTypes.shape({}),
    actdefect: PropTypes.shape({}),
    customDocuments: PropTypes.arrayOf(PropTypes.shape({})),
    nar1: PropTypes.shape({}),
    nar2: PropTypes.shape({}),
    nar3: PropTypes.shape({}),
    nar4: PropTypes.shape({}),
    nar5: PropTypes.shape({}),
  }).isRequired,
};

const mapStateToProps = state => ({
  repairDocs: state.mainReducer.repairDocs,
});

export default connect(mapStateToProps)(RepairDocs);
