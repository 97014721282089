import { fetchWrapper } from 'helpers/fetch-wrapper';
import * as types from 'actions/action-types';
import { toQueryString } from 'utils';

/**
 * User adds a new despatch
 */

export const addDespatch = (
  parentId,
  params,
) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}despatch/`;

  if (parentId) {
    params.append('parentId', parentId);
  }

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      multipart: true,
      body: params,
    })
      .then(resp => {
        dispatch({
          type: types.ADD_DESPATCH_SUCCESS,
          payload: {
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_DESPATCH_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * User fetches all despatches
 */

export const loadDespatches = (query, isArchive, currentPage = 0) => dispatch => {
  let url = `${process.env.REACT_APP_API_URL}despatch/_p${currentPage}${isArchive ? `_archive` : ''}${query && Object.keys(query).length ? '?' + toQueryString(query) : ''}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
    })
      .then(resp => {
        dispatch({
          type: types.DESPATCHES_SUCCESS,
          meta: {
            status: query['status[inTransit]'] ? 'inTransit' : '',
            isArchive: isArchive
          },
          payload: {
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.DESPATCHES_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * User fetches a despatch info
 */

export const loadDespatchInfo = (despatchId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}despatch/_ainfo_b${despatchId}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
    })
      .then(resp => {
        dispatch({
          type: types.DESPATCH_INFO_SUCCESS,
          payload: {
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.DESPATCH_INFO_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * Master updates the despatch
 */

export const updateDespatch = (
  despatchId,
  {
    name,
    // dateready,
    dateStart,
    dateEnd,
    startLocation,
    destLocation,
    size,
    weight,
    nomenclature,
    urgency,
    recipient,
    other,
  },
) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}despatch/_b${despatchId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        // id: despatchId // problemId,
        // dateready,
        datestart: dateStart,
        dateend: dateEnd,
        startlocation: startLocation,
        destlocation: destLocation,
        size,
        weight,
        nomenclature,
        urgency,
        recipient,
        other,
      }),
    })
      .then(resp => {
        dispatch({
          type: types.EDIT_DESPATCH_SUCCESS,
          payload: {
            resp,
            // problemId,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.EDIT_DESPATCH_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * Pdo returns the problem to the responsible
 */

export const setDespatchTuReturnsProblem = (despatchId, problemId, comment) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}despatch/_atuReturnProblem_b${despatchId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        id: '1', //problemId,
        comment,
      }),
    })
      .then(resp => {
        dispatch({
          type: types.SET_DESPATCH_TU_RETURNS_PROBLEM_SUCCESS,
          meta: {
            bid: despatchId,
          },
          payload: {
            problemId,
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.SET_DESPATCH_TU_RETURNS_PROBLEM_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * User solves the problem
 */

export const setDespatchProblemSolved = (despatchId, problemId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}despatch/_aproblemSolved_b${despatchId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        id: despatchId, // problemId,
      }),
    })
      .then(resp => {
        dispatch({
          type: types.SET_DESPATCH_PROBLEM_SOLVED_SUCCESS,
          meta: {
            bid: despatchId,
          },
          payload: {
            problemId,
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.SET_DESPATCH_PROBLEM_SOLVED_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * Pdo approves the despatch
 */

export const setDespatchPdoAppoved = (despatchId, problemId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}despatch/_apdoApproved_b${despatchId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        id: despatchId, // problemId,
      }),
    })
      .then(resp => {
        dispatch({
          type: types.SET_DESPATCH_PDO_APPROVED_SUCCESS,
          meta: {
            bid: despatchId,
          },
          payload: {
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.SET_DESPATCH_PDO_APPROVED_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * Pdo removes the approved status of the despatch
 */

export const clearDespatchPdoAppoved = (despatchId, problemId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}despatch/_apdoApproved_b${despatchId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
      body: JSON.stringify({
        id: despatchId, // problemId,
      }),
    })
      .then(resp => {
        dispatch({
          type: types.CLEAR_DESPATCH_PDO_APPROVED_SUCCESS,
          meta: {
            bid: despatchId
          },
          payload: {
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.CLEAR_DESPATCH_PDO_APPROVED_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * Omts approves the despatch
 */

export const setDespatchOmtsAppoved = (despatchId, problemId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}despatch/_aomtsApproved_b${despatchId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        id: problemId,
        // comment,
      }),
    })
      .then(resp => {
        dispatch({
          type: types.SET_DESPATCH_OMTS_APPROVED_SUCCESS,
          meta: {
            bid: despatchId,
          },
          payload: {
            // problemId,
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.SET_DESPATCH_OMTS_APPROVED_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * Omts removes the approved status of the despatch
 */

export const clearDespatchOmtsAppoved = (despatchId, problemId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}despatch/_aomtsApproved_b${despatchId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
      body: JSON.stringify({
        id: problemId,
        // comment,
      }),
    })
      .then(resp => {
        dispatch({
          type: types.CLEAR_DESPATCH_OMTS_APPROVED_SUCCESS,
          meta: {
            bid: despatchId,
          },
          payload: {
            // problemId,
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.CLEAR_DESPATCH_OMTS_APPROVED_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * Pdo??? returns the despatch
 */

export const returnDespatch = (despatchId, comment) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}despatch/_areturn_b${despatchId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        comment,
      }),
    })
      .then(resp => {
        dispatch({
          type: types.RETURN_DESPATCH_SUCCESS,
          payload: {
            // problemId,
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.RETURN_DESPATCH_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * User loads comments for the despatch
 */

export const loadDespatchComments = (despatchId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}despatch/_acomment_b${despatchId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
    })
      .then(resp => {
        dispatch({
          type: types.DESPATCH_COMMENTS_SUCCESS,
          payload: {
            // problemId,
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.DESPATCH_COMMENTS_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * User loads comments for the despatch
 */

export const loadDespatchProblemComments = (despatchId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}despatch/_aproblemHistory_b${despatchId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
    })
      .then(resp => {
        dispatch({
          type: types.DESPATCH_PROBLEM_COMMENTS_SUCCESS,
          payload: {
            // problemId,
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.DESPATCH_PROBLEM_COMMENTS_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * User adds a comment for the despatch
 */

export const addDespatchComment = (despatchId, problemId, comment) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}despatch/_acomment_b${despatchId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        id: problemId,
        comment,
      }),
    })
      .then(resp => {
        dispatch({
          type: types.ADD_DESPATCH_COMMENTS_SUCCESS,
          payload: {
            // problemId,
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_DESPATCH_COMMENTS_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * User assigns a transport for the despatch
 */

export const assignTransport = (despatchId, transportId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}despatch/_atransportAssigned_b${despatchId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        transportid: transportId,
      }),
    })
      .then(resp => {
        dispatch({
          type: types.DESPATCH_ASSIGN_TRANSPORT_SUCCESS,
          meta: {
            bid: despatchId
          },
          payload: {
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.DESPATCH_ASSIGN_TRANSPORT_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * User assigns a driver for the despatch
 */

export const assignDriver = (transportId, driverid, userid, despatchId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}transport/_adriverAssigned_b${transportId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        driverid,
        userid,
      }),
    })
      .then(data => {
        dispatch({
          type: types.DESPATCH_ASSIGN_DRIVER_SUCCESS,
          meta: {
            bid: despatchId
          },
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.DESPATCH_ASSIGN_DRIVER_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * User sends the transport for the despatch
 */

export const sendDespatch = (despatchId, dateFact, responsible) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}despatch/_asend_b${despatchId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        datefact: dateFact,
        responsible: responsible,
      }),
    })
      .then(resp => {
        dispatch({
          type: types.SEND_DESPATCH_SUCCESS,
          meta: {
            bid: despatchId
          },
          payload: {
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.SEND_DESPATCH_FAIL,
        });
        reject(error);
      });
  });
};


/**
 * Responsible completed processing the despatch
 */

export const setResponsibleFinishedShipping = (despatchId, userId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}despatch/_ashipped_b${despatchId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        // driverid,
        userid: userId,
      }),
    })
      .then(resp => {
        dispatch({
          type: types.SET_DESPATCH_RESPONSIBLE_SHIPPED_SUCCESS,
          meta: {
            bid: despatchId,
          },
          payload: {
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.SET_DESPATCH_RESPONSIBLE_SHIPPED_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * Responsible removes "Shipped" status of the despatch
 */

export const clearResponsibleFinishedShipping = (despatchId, userId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}despatch/_ashipped_b${despatchId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
      body: JSON.stringify({
        userid: userId,
      }),
    })
      .then(resp => {
        dispatch({
          type: types.CLEAR_DESPATCH_RESPONSIBLE_SHIPPED_SUCCESS,
          meta: {
            bid: despatchId
          },
          payload: {
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.CLEAR_DESPATCH_RESPONSIBLE_SHIPPED_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * Recipient completed the despatch
 */

export const setRecipientCompletedShipping = (despatchId, userId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}despatch/_acompleted_b${despatchId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        // driverid,
        userid: userId,
      }),
    })
      .then(resp => {
        dispatch({
          type: types.SET_DESPATCH_RECIPIENT_COMPLETED_SUCCESS,
          meta: {
            bid: despatchId,
          },
          payload: {
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.SET_DESPATCH_RECIPIENT_COMPLETED_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * Responsible removes "completed" status of the despatch
 */

export const clearRecipientCompletedShipping = (despatchId, userId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}despatch/_acompleted_b${despatchId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
      body: JSON.stringify({
        userid: userId,
      }),
    })
      .then(resp => {
        dispatch({
          type: types.CLEAR_DESPATCH_RECIPIENT_COMPLETED_SUCCESS,
          meta: {
            bid: despatchId
          },
          payload: {
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.CLEAR_DESPATCH_RECIPIENT_COMPLETED_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * Department chief sends the despatch to archive
 */

export const sendDespatchToArchive = despatchId => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}despatch/_aarchive_b${despatchId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
    })
      .then(data => {
        dispatch({
          type: types.SEND_DESPATCH_TO_ARCHIVE_SUCCESS,
          payload: {
            id: despatchId,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.SEND_DESPATCH_TO_ARCHIVE_FAIL,
        });
        reject(error);
      });
  });
};

export const clearTransportAssigned = (despatchId, userId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}despatch/_atransportAssigned_b${despatchId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
      body: JSON.stringify({
        userid: userId,
      }),
    })
      .then(resp => {
        dispatch({
          type: types.CLEAR_DESPATCH_ASSIGN_TRANSPORT_SUCCESS,
          meta: {
            bid: despatchId
          },
          payload: {
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.CLEAR_DESPATCH_ASSIGN_TRANSPORT_FAIL,
        });
        reject(error);
      });
  });
};

export const clearDriverAssigned = (transportId, driverId, despatchId, userId) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}transport/_adriverUnassigned_b${transportId}/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
      body: JSON.stringify({
        userid: userId,
        driverId: driverId,
      }),
    })
      .then(data => {
        dispatch({
          type: types.CLEAR_DESPATCH_ASSIGN_DRIVER_SUCCESS,
          meta: {
            bid: despatchId
          },
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(error => {
        dispatch({
          type: types.CLEAR_DESPATCH_ASSIGN_DRIVER_FAIL,
        });
        reject(error);
      });
  });
};
