import React from 'react';
import PropTypes from 'prop-types';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';

const ReportDepartmentModal = ({ closeHandler, openModal }) => {
  return (
    <React.Fragment>
      <ModalHeader title="Сформировать отчеты по участку" />

      <ModalBody>
        <FormGroup>
          <button
            type="button"
            className="btn btn-primary btn-block"
            onClick={() => openModal('month')}
          >
            Отчет за месяц
          </button>
        </FormGroup>

        <FormGroup>
          <button
            type="button"
            className="btn btn-primary btn-block"
            onClick={() => openModal('quarter')}
          >
            Отчет за квартал
          </button>
        </FormGroup>

        <FormGroup>
          <button
            type="button"
            className="btn btn-primary btn-block"
            onClick={() => openModal('year')}
          >
            Отчет за год
          </button>
        </FormGroup>

        <FormGroup>
          <button
            type="button"
            className="btn btn-primary btn-block"
            onClick={() => openModal('current')}
          >
            Отчет на текущую дату
          </button>
        </FormGroup>
      </ModalBody>

      <ModalFooter closeHandler={closeHandler} />
    </React.Fragment>
  );
};

ReportDepartmentModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default ReportDepartmentModal;
