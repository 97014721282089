import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components
import ProgressBar from 'components/ProgressBar/ProgressBar';

// Styles
import './OverdueNodesListReport.scss';

const OverdueNodesListReport = ({ overdueNodes }) => {
  return (
    <ul className="overdue-nodes-list-report">
      {Array.isArray(overdueNodes) &&
        overdueNodes.map(node => (
          <li key={node.id} className="overdue-nodes-list-report__item">
            <div className="overdue-nodes-list-report__item-info">
              <ProgressBar percent={node.percent} />
              {node.name}{' '}
              {node.object && (
                <>
                  (
                  <Link
                    to={`/repair/${node.object.id}`}
                    className="overdue-nodes-list-report__item-link"
                  >
                    {node.object.ksname} {node.object.gpa} №{node.object.stationnumber}
                  </Link>
                  )
                </>
              )}{' '}
              {node.diff && <span className="label label_danger">{node.diff}</span>}
            </div>
          </li>
        ))}
    </ul>
  );
};

OverdueNodesListReport.propTypes = {
  overdueNodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      percent: PropTypes.number,
      name: PropTypes.string,
      object: PropTypes.shape({
        id: PropTypes.number,
        ksname: PropTypes.string,
        gpa: PropTypes.string,
        stationnumber: PropTypes.string,
      }),
      diff: PropTypes.string,
    }),
  ),
};

OverdueNodesListReport.defaultProps = {
  overdueNodes: null,
};

export default OverdueNodesListReport;
