import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Icons
import { FaDownload } from 'react-icons/fa';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import Loader from 'components/Loader/Loader';

// Actions
import { getEmploymentDepartmentReport, clearEmploymentDepartmentReport } from 'actions';

// Styles
import './response-table.scss';

const ReportResponseDepartmentModal = ({
  closeHandler,
  reportType,
  departmentId,
  dispatch,
  report,
}) => {
  const [isModalLoading, setIsModalLoading] = useState(true);

  useEffect(() => {
    dispatch(getEmploymentDepartmentReport(departmentId, reportType))
      .then(() => setIsModalLoading(false))
      .catch(() => setIsModalLoading(false));
  }, [dispatch, departmentId, reportType]);

  return (
    <React.Fragment>
      <ModalHeader title={report && report.name ? report.name : 'Формируется отчет'} />

      {isModalLoading && <Loader className="loader_modal" />}

      {!isModalLoading && report && (
        <ModalBody>
          {report.users && Array.isArray(report.users) && report.users.length > 0 && (
            <table className="table table-stripped table-bordered response-table">
              <thead>
                <tr>
                  <th>Объект</th>
                  <th>Событие</th>
                  <th>Дата</th>
                  <th>Примечание</th>
                </tr>
              </thead>

              <tbody>
                {/* eslint-disable react/no-array-index-key */}
                {report.users.map((user, index) => (
                  <React.Fragment key={index}>
                    <tr className="response-table__user">
                      <td colSpan="4">
                        {user.name_1} ({user.role_1})
                      </td>
                    </tr>

                    {user.eventsLong &&
                      Array.isArray(user.eventsLong) &&
                      user.eventsLong.map((log, logIndex) => (
                        <tr key={logIndex}>
                          <td>
                            {log.repair && (
                              <>
                                {log.repair.ksname} {log.repair.gpa} Ст. №{' '}
                                {log.repair.stationnumber}{' '}
                                <span className={`label label_${log.repair.labelclass}`}>
                                  {log.repair.type_s}
                                </span>
                              </>
                            )}
                          </td>

                          <td>{log.name}</td>

                          <td>
                            {log.date_start && log.date_end && (
                              <React.Fragment>
                                <b style={{ whiteSpace: 'nowrap' }}>{log.date_start}</b> <br />{' '}
                                &mdash; <br />{' '}
                                <b style={{ whiteSpace: 'nowrap' }}>{log.date_end}</b>
                              </React.Fragment>
                            )}

                            {log.date_start && !log.date_end && (
                              <React.Fragment>
                                <b style={{ whiteSpace: 'nowrap' }}>{log.date_start}</b> <br />{' '}
                                {/*&mdash; <br />{' '}*/}
                                {/*<b style={{ whiteSpace: 'nowrap' }}>текущий момент</b>*/}
                              </React.Fragment>
                            )}
                            {log.date_end && !log.date_start &&  (
                              <React.Fragment>
                                <b style={{ whiteSpace: 'nowrap' }}>{log.date_end}</b> <br />{' '}
                              </React.Fragment>
                            )}

                            {!log.date_start && log.date_end && (
                              <React.Fragment>
                                <b style={{ whiteSpace: 'nowrap' }}>{log.date_end}</b>
                              </React.Fragment>
                            )}
                            {!log.date_start && !log.date_end && (
                              <React.Fragment>
                                <b style={{ whiteSpace: 'nowrap' }}>{log.datetime_1}</b>
                              </React.Fragment>
                            )}
                          </td>

                          <td>
                            {log.text}
                            {log.task && (
                              <React.Fragment>
                                <span className="label label_primary">{log.task.author_name}</span>
                                <span className="label label_danger">{log.task.role}</span>
                              </React.Fragment>
                            )}

                            {log.problem && (
                              <React.Fragment>
                                <span className="label label_primary">
                                  {log.problem.author_name}
                                </span>
                                <span className="label label_danger">{log.problem.role}</span>
                              </React.Fragment>
                            )}
                          </td>
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
                {/* eslint-enable react/no-array-index-key */}
              </tbody>
            </table>
          )}

          {report.users && Array.isArray(report.users) && report.users.length === 0 && (
            <div className="modal-body__noty">Отчет за выбранный период отсутствует</div>
          )}
        </ModalBody>
      )}

      <ModalFooter closeHandler={closeHandler}>
        {!isModalLoading &&
          report &&
          report.users &&
          Array.isArray(report.users) &&
          report.users.length > 0 && (
            <a
              href={`${process.env.REACT_APP_API_URL}employment/_areportDepDownload_b${departmentId}_type${reportType}/`}
              download
              className="btn btn-primary"
            >
              <FaDownload className="icon" /> Скачать
            </a>
          )}

        <button
          type="button"
          className="btn btn-default"
          onClick={() => {
            dispatch(clearEmploymentDepartmentReport());
            closeHandler();
          }}
        >
          Закрыть
        </button>
      </ModalFooter>
    </React.Fragment>
  );
};

ReportResponseDepartmentModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  reportType: PropTypes.string.isRequired,
  departmentId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  report: PropTypes.shape({
    name: PropTypes.string,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name_1: PropTypes.string,
        role_1: PropTypes.string,
        eventsLong: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            date_start: PropTypes.string,
            date_end: PropTypes.string,
            datetime_1: PropTypes.string,
            text: PropTypes.string,
            repair: PropTypes.shape({
              gpa: PropTypes.string,
              ksname: PropTypes.string,
              stationnumber: PropTypes.string,
              labelclass: PropTypes.string,
              type_s: PropTypes.string,
            }),
            task: PropTypes.shape({
              author_name: PropTypes.string,
              userRole: PropTypes.string,
            }),
            problem: PropTypes.shape({
              author_name: PropTypes.string,
              userRole: PropTypes.string,
            }),
          }),
        ),
      }),
    ),
  }).isRequired,
};

const mapStateToProps = state => ({
  report: state.mainReducer.employmentDepartmentReport,
});

export default connect(mapStateToProps)(ReportResponseDepartmentModal);
