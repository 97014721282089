import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

// Components
import SelectForm from 'components/SelectForm/SelectForm';
import Warning from 'components/Warning/Warning';

// Actions
import { linkMtr } from 'actions';

// Styles
import './TableMtr.scss';

const TableMtr = ({ objects, dispatch, repairOptions }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const [currentObjectId, setCurrentObjectId] = useState(null);

  /** Функция возвращает объект для того чтобы засунуть его в селект в selectedValue */
  const getLinkedObjectValue = objNumber => {
    if (!repairOptions) {
      return null;
    }
    return repairOptions.find(item => item.value === objNumber);
  };

  const handleChangeLinkedObject = (objectId, repairId) => {
    setCurrentObjectId(objectId);

    setIsLoading(true);

    dispatch(linkMtr(objectId, repairId))
      .then(() => {
        setIsLoading(false);
        setCurrentObjectId(null);
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsLoading(false);
      });
  };

  const cellCache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 30,
  });

  /* eslint-disable-next-line react/prop-types */
  const rowRenderer = ({ key, index, parent, style }) => (
    <CellMeasurer cache={cellCache} key={key} rowIndex={index} parent={parent}>
      {({ registerChild }) => (
        <div ref={registerChild} style={style} className="table-mtr-list__tr">
          <div className="table-mtr-list__td table-mtr-list__td_info">{objects[index].name}</div>
          <div className="table-mtr-list__td table-mtr-list__td_select">
            <SelectForm
              isDisabled={isLoading}
              selectedValue={
                getLinkedObjectValue(objects[index].object) || {
                  label: 'Нет привязки',
                  value: -1,
                }
              }
              options={[
                {
                  label: 'Нет привязки',
                  value: -1,
                },
                ...repairOptions,
              ]}
              onChange={selectedValue =>
                handleChangeLinkedObject(objects[index].id, selectedValue.value)
              }
            />
            {(responseError || fieldErrors.repairId) && currentObjectId === objects[index].id && (
              <Warning
                className="table-mtr-list__warning"
                msg={responseError || fieldErrors.repairId}
              />
            )}
          </div>
        </div>
      )}
    </CellMeasurer>
  );

  return (
    <AutoSizer disableHeight className="table-mtr-list">
      {({ width }) => {
        return (
          <div style={{ width }}>
            <div className="table-mtr-list__head">
              <div className="table-mtr-list__th table-mtr-list__th_info">Объект текстом</div>
              <div className="table-mtr-list__th table-mtr-list__th_select">Привязка к объекту</div>
            </div>
            <List
              width={width}
              height={55 * 8}
              overscanRowCount={0}
              rowCount={objects.length}
              rowHeight={cellCache.rowHeight}
              rowRenderer={rowRenderer}
              className="table-mtr-list__body"
            />
          </div>
        );
      }}
    </AutoSizer>
  );
};

TableMtr.propTypes = {
  dispatch: PropTypes.func.isRequired,
  repairOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
  objects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      object: PropTypes.number,
    }),
  ).isRequired,
};

export default connect()(TableMtr);
