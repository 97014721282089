import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { confirmDateRepairNode } from 'actions';

const ConfirmDateModal = ({ closeHandler, currentNodeId, repairId, dispatch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseError, setResponseError] = useState(false);

  const submitConfirmDate = () => {
    setIsLoading(true);

    dispatch(confirmDateRepairNode(repairId, currentNodeId))
      .then(() => {
        setIsLoading(false);

        closeHandler();
      })
      .catch(error => {
        if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Подтверждение даты" />

      <ModalBody>
        <div className="modal__section">
          <div className="modal__section-text">
            Вы действительно хотите подтвердить ожидаемую дату отправки узла из РММ?
          </div>
        </div>
      </ModalBody>

      {responseError && <ModalError>{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isLoading={isLoading}
          isLoadingText="Подтверждаю..."
          clickHandler={submitConfirmDate}
        >
          Подтвердить
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

ConfirmDateModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  repairId: PropTypes.number.isRequired,
  currentNodeId: PropTypes.number.isRequired,
};

export default connect()(ConfirmDateModal);
