import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Switch, Route } from 'react-router-dom';
import { FaRegArrowAltCircleDown } from 'react-icons/fa';
import { connect } from 'react-redux';

// Components
import RepairInfo from 'components/RepairInfo/RepairInfo';
import RepairNavigation from 'components/RepairNavigation/RepairNavigation';
import RepairWorkProgress from 'components/RepairWorkProgress/RepairWorkProgress';
import RepairNode from 'components/RepairNode/RepairNode';
import RepairProblems from 'components/RepairProblems/RepairProblems';
import RepairMtr from 'components/RepairMtr/RepairMtr';
import RepairRoster from 'components/RepairRoster/RepairRoster';
import RepairProlong from 'components/RepairProlong/RepairProlong';
import RepairDocs from 'components/RepairDocs/RepairDocs';
import RepairAdd from 'components/RepairAdd/RepairAdd';
import RepairCoop from 'components/RepairCoop/RepairCoop';
import Loader from 'components/Loader/Loader';

// Actions
import { getRepairInfo } from 'actions';

// Styles
import './Repair.scss';
import './repair-content.scss';

const Repair = ({ match, location, dispatch, repairInfo, user }) => {
  const [isLoading, setIsLoading] = useState(true);

  /** Запрашиваем информацию по ремонту */
  useEffect(() => {
    dispatch(getRepairInfo(+match.params.id))
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch, match.params.id]);

  return (
    <React.Fragment>
      {isLoading && <Loader className="loader_page" />}

      {!isLoading && repairInfo && (
        <div className="repair">
          <div className="repair__container container">
            <div className="repair__header">
              <div className="repair__heading">
                <h1 className="repair__title">Объект</h1>
                <a
                  target="_blank"
                  href={`${process.env.REACT_APP_API_URL}repair/_asave_b${+match.params.id}/`}
                  className="label label_info"
                  download
                  rel="noopener noreferrer"
                >
                  Скачать архив <FaRegArrowAltCircleDown className="icon" />
                </a>
              </div>

              <RepairInfo
                repairInfo={repairInfo}
                userRole={user.userRole}
                repairId={+match.params.id}
              />
            </div>
            <div
              className={cx('repair__content repair-content', {
                'repair-content_wide':
                  location &&
                  (location.pathname.indexOf('node') > -1 || location.pathname.indexOf('mtr') > -1),
              })}
            >
              <div className="repair-content__col repair-content__col_nav">
                <RepairNavigation
                  url={match.url}
                  className={cx({
                    'repair-nav_wide':
                      location &&
                      (location.pathname.indexOf('node') > -1 ||
                        location.pathname.indexOf('mtr') > -1),
                  })}
                />
              </div>
              <div className="repair-content__col repair-content__col_content">
                <Switch>
                  <Route
                    path={match.path}
                    exact
                    render={() => (
                      <RepairWorkProgress
                        repairId={+match.params.id}
                        userRole={user.userRole}
                        canEdit={repairInfo.canEdit}
                        open={repairInfo.open}
                        closed={repairInfo.closed}
                        finished={repairInfo.finished}
                        toother={repairInfo.toother}
                      />
                    )}
                  />

                  <Route
                    exact
                    path={`${match.path}/node`}
                    render={() => (
                      <RepairNode canEdit={repairInfo.canEdit} repairId={+match.params.id} />
                    )}
                  />

                  <Route
                    path={`${match.path}/problems`}
                    render={() => (
                      <RepairProblems
                        repairId={+match.params.id}
                        userRole={user.userRole}
                        canEdit={repairInfo.canEdit}
                        open={repairInfo.open}
                        finished={repairInfo.finished}
                      />
                    )}
                  />

                  <Route
                    path={`${match.path}/mtr`}
                    render={() => <RepairMtr repairId={+match.params.id} />}
                  />

                  <Route
                    path={`${match.path}/roster`}
                    render={() => (
                      <RepairRoster
                        canEdit={repairInfo.canEdit}
                        repairId={+match.params.id}
                        finished={repairInfo.finished}
                      />
                    )}
                  />

                  <Route
                    path={`${match.path}/prolong`}
                    component={() => (
                      <RepairProlong
                        canEdit={repairInfo.canEdit}
                        repairId={+match.params.id}
                        open={repairInfo.open}
                        finished={repairInfo.finished}
                      />
                    )}
                  />

                  <Route
                    path={`${match.path}/docs`}
                    render={() => (
                      <RepairDocs
                        repairId={+match.params.id}
                        userRole={user.userRole}
                        canEdit={repairInfo.canEdit}
                      />
                    )}
                  />

                  <Route
                    path={`${match.path}/add`}
                    render={() => (
                      <RepairAdd canEdit={repairInfo.canEdit} repairId={+match.params.id} />
                    )}
                  />

                  <Route
                    path={`${match.path}/coop`}
                    render={() => (
                      <RepairCoop repairId={+match.params.id} canEdit={repairInfo.canEdit} />
                    )}
                  />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

Repair.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  repairInfo: PropTypes.shape({
    canEdit: PropTypes.bool,
    open: PropTypes.bool,
    closed: PropTypes.bool,
    finished: PropTypes.bool,
    toother: PropTypes.bool,
  }).isRequired,
  user: PropTypes.shape({
    userRole: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = state => ({
  dispatch: state.mainReducer.dispatch,
  repairInfo: state.mainReducer.repairInfo,
  user: state.mainReducer.user,
});

export default connect(mapStateToProps)(Repair);
