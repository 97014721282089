import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import TableAdminUsers from 'components/TableAdminUsers/TableAdminUsers';
import UsersSearch from 'components/UsersSearch/UsersSearch';
import Modal from 'components/Modal/Modal';
import CreateUserModal from 'components/CreateUserModal/CreateUserModal';
import Loader from 'components/Loader/Loader';
import Warning from 'components/Warning/Warning';

// Actions
import { getAdminUsers } from 'actions';

// Styles
import './AdminUsers.scss';

const AdminUsers = ({ dispatch, users, match }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isCreateUserActive, setCreateUserActive] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const activateCreateUserModal = () => {
    setCreateUserActive(true);
  };

  const deactivateCreateUserModal = () => {
    setCreateUserActive(false);
  };

  useEffect(() => {
    dispatch(getAdminUsers())
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch]);

  return (
    <>
      {isLoading && <Loader className="loader_page" />}

      {!isLoading && users && (
        <div className="admin-users">
          <div className="admin-users__container container">
            <h2 className="admin-users__title">Пользователи</h2>

            <div className="admin-users__menu">
              <button type="button" className="btn btn-primary" onClick={activateCreateUserModal}>
                Добавить пользователя
              </button>
            </div>

            <div className="admin-users__search">
              <UsersSearch
                value={searchQuery}
                changeHandler={({ target }) => setSearchQuery(target.value)}
                placeholder="Поиск по пользователям"
              />
            </div>

            {Array.isArray(users) && users.length > 0 && match && match.url && (
              <TableAdminUsers
                users={users}
                url={match.url}
                searchQuery={searchQuery.toLowerCase()}
              />
            )}

            {Array.isArray(users) && users.length === 0 && (
              <Warning msg="Пока не добавлено пользователей" />
            )}

            {isCreateUserActive && (
              <Modal title="Добавление пользователя" closeHandler={deactivateCreateUserModal}>
                <CreateUserModal closeHandler={deactivateCreateUserModal} />
              </Modal>
            )}
          </div>
        </div>
      )}
    </>
  );
};

AdminUsers.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = state => ({
  users: state.mainReducer.adminUsers,
});

export default connect(mapStateToProps)(AdminUsers);
