import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import SelectForm from 'components/SelectForm/SelectForm';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { assignTransport } from 'actions/despatch';

// Utils
import * as propTypes from 'utils/propTypes';

const AssignTransportModal = ({
  closeHandler,
  dispatch,
  despatchId,
  transportAssignedId,
  transportData,
  onSubmit,
  onAssignTransportSuccess,
}) => {
  const [transportOptions, setTransportOptions] = useState(null);
  const [selectedTransport, setSelectedTransport] = useState(transportAssignedId
    ? transportAssignedId
    : transportData && transportData.length
      ? transportData.map(v => ({ id: v.id, value: v.id, label: `${v.carNumber} - ${v.name}` }))[0]
      : {}
    // users.map(user => ({ value: user.id, label: `${user.name_1} (${user.rolename})` }))[0],
  );
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    transportData && transportData.length
      && setTransportOptions(
        transportData.map(v => ({ id: v.id, value: v.id, label: `${v.carNumber} - ${v.name}` }))
      )
  }, [transportData]);

  const handleSubmit = (id, problemId) => {
    setIsLoading(true);
    dispatch(assignTransport(despatchId, selectedTransport.id))
      .then(() => {
        onAssignTransportSuccess && onAssignTransportSuccess(id);

        closeHandler();
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {}, [dispatch, selectedTransport]);

  return (
    <React.Fragment>
      <ModalHeader title="Подкрепить машину" />

      <ModalBody>
        <FormGroup title="Доступный транспорт" error={fieldErrors.reaction}>
          <SelectForm
            options={transportOptions}
            // options={drivers.map(driver => ({
            //   value: driver.id,
            //   label: `${driver.name_1} (${driver.rolename})`,
            // }))}
            selectedValue={selectedTransport}
            onChange={selectedOption => setSelectedTransport(selectedOption)}
          />
        </FormGroup>
      </ModalBody>

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isLoading={isLoading}
          isLoadingText="Отправляю..."
          clickHandler={handleSubmit}
        >
          Назначить
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

AssignTransportModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  despatchId: PropTypes.number.isRequired,
  transportData: PropTypes.arrayOf(propTypes.transportData).isRequired,
  onSubmit: PropTypes.func,
  onAssignTransportSuccess: PropTypes.func,
};

export default connect()(AssignTransportModal);
