import React from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';

// Styles
import 'react-datepicker/dist/react-datepicker.css';
import './InputDate.scss';

registerLocale('ru', ru);

const InputDate = ({ selectedDate, onChange, notInModal }) => {
  return (
    <DatePicker
      dateFormat="dd.MM.yyyy"
      locale="ru"
      className="form-control"
      selected={selectedDate}
      onChange={onChange}
      popperModifiers={{
        preventOverflow: {
          enabled: true,
          escapeWithReference: notInModal,
        },
        hide: {
          enabled: true,
        },
      }}
    />
  );
};

InputDate.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  notInModal: PropTypes.bool,
};

InputDate.defaultProps = {
  selectedDate: null,
  notInModal: false,
};

export default InputDate;
