import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Styles
import './NoReportsList.scss';

const NoReportsList = ({ noReports, closePanel }) => {
  return (
    <ul className="no-reports-list">
      {/* eslint-disable react/no-array-index-key */}
      {Array.isArray(noReports) &&
        noReports.map((item, index) => (
          <li key={index} className="no-reports-list__item">
            <div className="no-reports-list__item-info">
              <span className="label label_danger">Нет отчета</span> -{' '}
              {item.object && (
                <Link
                  to={`/repair/${item.object.id}`}
                  className="no-reports-list__item-link"
                  onClick={closePanel}
                >
                  {item.object.ksname} {item.object.gpa} №{item.object.stationnumber}
                </Link>
              )}{' '}
              {item.dates &&
                Array.isArray(item.dates) &&
                item.dates.map((date, dateIndex) => (
                  <React.Fragment key={dateIndex}>
                    <span className="label label_default">{date.realdate_1}</span>{' '}
                  </React.Fragment>
                ))}
              {item.weeks &&
                Array.isArray(item.weeks) &&
                item.weeks.map((week, weekIndex) => (
                  <React.Fragment key={weekIndex}>
                    <span key={weekIndex} className="label label_default">
                      На неделю {week.dates}
                    </span>{' '}
                  </React.Fragment>
                ))}
            </div>
          </li>
        ))}
      {/* eslint-enable react/no-array-index-key */}
    </ul>
  );
};

NoReportsList.propTypes = {
  closePanel: PropTypes.func.isRequired,
  noReports: PropTypes.arrayOf(
    PropTypes.shape({
      object: PropTypes.shape({
        id: PropTypes.number,
        ksname: PropTypes.string,
        gpa: PropTypes.string,
        stationnumber: PropTypes.string,
      }),
      dates: PropTypes.arrayOf(
        PropTypes.shape({
          realdate_1: PropTypes.string,
        }),
      ),
      weeks: PropTypes.arrayOf(
        PropTypes.shape({
          dates: PropTypes.string,
        }),
      ),
    }),
  ),
};

NoReportsList.defaultProps = {
  noReports: null,
};

export default NoReportsList;
