import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Icons
import {
  FaUpload,
  FaPaperclip,
  FaDownload,
  FaExchangeAlt,
  FaCheck,
  FaCommentAlt,
  FaServer,
} from 'react-icons/fa';

// Components
import Modal from 'components/Modal/Modal';
import PercentNodeModal from 'components/PercentNodeModal/PercentNodeModal';
import ConfirmNodeModal from 'components/ConfirmNodeModal/ConfirmNodeModal';
import ChangeForecastDateModal from 'components/ChangeForecastDateModal/ChangeForecastDateModal';
import ReturnNodeModal from 'components/ReturnNodeModal/ReturnNodeModal';
import ArchiveNodeConfirmModal from 'components/ArchiveNodeConfirmModal/ArchiveNodeConfirmModal';
import ChangeNodePriorityConfirmModal from 'components/ChangeNodePriorityConfirmModal/ChangeNodePriorityConfirmModal';
import RemoveNodeConfirmModal from 'components/RemoveNodeConfirmModal/RemoveNodeConfirmModal';
import MoveNodeConfirmModal from 'components/MoveNodeConfirmModal/MoveNodeConfirmModal';
import DocsNodeModal from 'components/DocsNodeModal/DocsNodeModal';
import CommentsNodeModal from 'components/CommentsNodeModal/CommentsNodeModal';
import TableRmmRow, { ModalTypes } from 'components/TableRmmRow/TableRmmRow';

// Actions
import { getMtrLinks } from 'actions';

// Styles
import './TableRmm.scss';

const TableRmm = ({
  canEdit,
  userRole,
  canSetPriority,
  nodes,
  selectFileForDocument,
  dispatch,
}) => {
  const [currentNodeId, setCurrentNodeId] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const [repairsForLinking, setRepairsForLinking] = useState([]);

  useEffect(() => {
    dispatch(getMtrLinks())
      .then(data => {
        if (data && Array.isArray(data.objects)) {
          setRepairsForLinking([
            {
              value: -1,
              label: 'Без привязки',
            },
            ...data.objects.map(item => ({
              value: item.object,
              label: item.name,
            })),
          ]);
        }
      })
      .catch(() => {});
  }, [dispatch]);

  const activateModal = useCallback((nodeId, modalType) => {
    setCurrentNodeId(nodeId);
    setActiveModal(modalType);
  }, []);

  const deactivateModal = useCallback(() => {
    setActiveModal(false);
    setCurrentNodeId(null);
  }, []);

  return (
    <table className="table table-striped table-bordered table-rmm">
      <thead>
        <tr className="info">
          <th>Объект</th>
          <th>Узел</th>
          <th>
            <abbr title="Дата отправки из КС">
              <FaUpload className="icon" /> из КС
            </abbr>
          </th>
          <th>
            <abbr title="Акт передачи в ремонт">
              <FaPaperclip className="icon" /> Акт
            </abbr>
          </th>
          <th>
            <abbr title="Дата поступления в РММ">
              <FaDownload className="icon" /> в РММ
            </abbr>
          </th>
          <th>
            <abbr title="Ожидаемая дата отправки из ремонта">
              <FaExchangeAlt className="icon" /> Прогноз
            </abbr>
          </th>
          <th>
            <abbr title="График ремонта">
              <FaPaperclip className="icon" /> График
            </abbr>
          </th>
          <th>
            <abbr title="Процент готовности">
              <FaServer className="icon" /> Процент
            </abbr>
          </th>
          <th>
            <abbr title="Дата отправки из РММ">
              <FaUpload className="icon" /> из РММ
            </abbr>
          </th>
          <th>
            <abbr title="Сопроводительные документы">
              <FaPaperclip className="icon" /> Док.
            </abbr>
          </th>
          <th>
            <abbr title="Дата отгрузки на КС">
              <FaCheck className="icon" /> в КС
            </abbr>
          </th>
          <th>
            <abbr title="Примечания">
              <FaCommentAlt className="icon" /> Прим.
            </abbr>
          </th>
        </tr>
      </thead>

      <tbody>
        {nodes &&
          Array.isArray(nodes) &&
          nodes.map(node => (
            <TableRmmRow
              node={node}
              canEdit={canEdit}
              canSetPriority={canSetPriority}
              activateModal={activateModal}
              selectFileForDocument={selectFileForDocument}
              repairsForLinking={repairsForLinking}
              userRole={userRole}
              key={node.id}
            />
          ))}

        {activeModal === ModalTypes.Percent && currentNodeId !== null && (
          <Modal title="Статус ремонта" closeHandler={deactivateModal}>
            <PercentNodeModal
              closeHandler={deactivateModal}
              node={nodes.find(n => n.id === currentNodeId)}
              canEdit={canEdit}
            />
          </Modal>
        )}

        {activeModal === ModalTypes.Receive && currentNodeId !== null && (
          <Modal title="Принять узел" closeHandler={deactivateModal}>
            <ConfirmNodeModal nodeId={currentNodeId} closeHandler={deactivateModal} />
          </Modal>
        )}

        {activeModal === ModalTypes.ChangeForecast && currentNodeId !== null && (
          <Modal title="Изменить дату" closeHandler={deactivateModal}>
            <ChangeForecastDateModal
              node={nodes.find(node => node.id === currentNodeId)}
              closeHandler={deactivateModal}
            />
          </Modal>
        )}

        {activeModal === ModalTypes.ReturnToKs && currentNodeId !== null && (
          <Modal title="Отправить узел на КС" closeHandler={deactivateModal}>
            <ReturnNodeModal nodeId={currentNodeId} closeHandler={deactivateModal} />
          </Modal>
        )}

        {activeModal === ModalTypes.ToArchive && currentNodeId !== null && (
          <Modal title="Отправить узел в архив" closeHandler={deactivateModal}>
            <ArchiveNodeConfirmModal nodeId={currentNodeId} closeHandler={deactivateModal} />
          </Modal>
        )}

        {activeModal === ModalTypes.ChangePriority && currentNodeId !== null && (
          <Modal title="Смена приоритета узла" closeHandler={deactivateModal}>
            <ChangeNodePriorityConfirmModal nodeId={currentNodeId} closeHandler={deactivateModal} />
          </Modal>
        )}

        {activeModal === ModalTypes.RemoveNode && currentNodeId !== null && (
          <Modal title="Удалить узел" closeHandler={deactivateModal}>
            <RemoveNodeConfirmModal nodeId={currentNodeId} closeHandler={deactivateModal} />
          </Modal>
        )}

        {activeModal === ModalTypes.MoveNode && currentNodeId !== null && (
          <Modal title="Переместить узел" closeHandler={deactivateModal}>
            <MoveNodeConfirmModal nodeId={currentNodeId} closeHandler={deactivateModal} />
          </Modal>
        )}

        {activeModal === ModalTypes.Docs && currentNodeId !== null && (
          <Modal title="Сопроводительные документы" closeHandler={deactivateModal}>
            <DocsNodeModal
              closeHandler={deactivateModal}
              userRole={userRole}
              canEdit={canEdit}
              node={nodes.find(n => n.id === currentNodeId)}
            />
          </Modal>
        )}

        {activeModal === ModalTypes.Comments && currentNodeId !== null && (
          <Modal title="Примечания" closeHandler={deactivateModal}>
            <CommentsNodeModal
              closeHandler={deactivateModal}
              canEdit={canEdit}
              nodeId={currentNodeId}
            />
          </Modal>
        )}
      </tbody>
    </table>
  );
};

TableRmm.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  userRole: PropTypes.number.isRequired,
  canSetPriority: PropTypes.bool.isRequired,
  nodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectFileForDocument: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(TableRmm);
