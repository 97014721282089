import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import InputDate from 'components/InputDate/InputDate';
import SelectForm from 'components/SelectForm/SelectForm';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { sendDespatch } from 'actions/despatch';

const TransportSendModal = ({
  closeHandler,
  dispatch,
  users,
  despatchId,
  onSubmit,
  onTransportSendSubmit,
}) => {
  const [despatchResponsible, setDespatchResponsible] = useState(
    users.map(user => ({ value: user.id, label: `${user.name_1} (${user.rolename})` }))[0],
  );
  const [dateFact, setDateFact] = useState(new Date());
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    // onTransportSendSubmit && onTransportSendSubmit(despatchId, dateFact, despatchResponsible.value);

    dispatch(sendDespatch(despatchId, dateFact, despatchResponsible.value))
      .then(() => {
        closeHandler();
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <React.Fragment>
      <ModalHeader title="Отправить на поездку" />

      <ModalBody>
        <FormGroup title="Дата отправки" error={fieldErrors.datefact}>
          <InputDate selectedDate={dateFact} onChange={date => setDateFact(date)} />
        </FormGroup>

        <FormGroup title="Ответственный за отправку" error={fieldErrors.responsible}>
          <SelectForm
            options={users.map(user => ({
              value: user.id,
              label: `${user.name_1} (${user.rolename})`,
            }))}
            selectedValue={despatchResponsible}
            onChange={selectedOption => setDespatchResponsible(selectedOption)}
          />
        </FormGroup>
      </ModalBody>

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isLoading={isLoading}
          isValid={!dateFact}
          isLoadingText="Отправляю..."
          clickHandler={handleSubmit}
        >
          Отправить
        </SubmitButton>
      </ModalFooter>

    </React.Fragment>
  );
};

TransportSendModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onTransportSendSuccess: PropTypes.func,
};

export default connect()(TransportSendModal);
