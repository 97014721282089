import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const SubmitButton = ({ isValid, isLoading, isLoadingText, children, clickHandler, className }) => {
  return (
    <button
      type="button"
      className={cx('btn', {
        'btn-primary': !className,
        [className]: className,
      })}
      disabled={isLoading || isValid}
      onClick={clickHandler}
    >
      {isLoading ? isLoadingText : children}
    </button>
  );
};

SubmitButton.propTypes = {
  isValid: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isLoadingText: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
};

SubmitButton.defaultProps = {
  isValid: false,
  className: null,
};

export default SubmitButton;
