import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';

// Styles
import { selectStyles } from 'scss/select';

const AsyncSelectForm = ({ onLoadOptions, selectedValue, onChange }) => {
  return (
    <AsyncSelect
      cacheOptions
      value={selectedValue}
      loadOptions={onLoadOptions}
      classNamePrefix="indicators-select"
      styles={selectStyles}
      onChange={onChange}
    />
  );
};

AsyncSelectForm.propTypes = {
  onLoadOptions: PropTypes.func.isRequired,
  selectedValue: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  onChange: PropTypes.func.isRequired,
};

AsyncSelectForm.defaultProps = {
  selectedValue: null,
};

export default AsyncSelectForm;
