import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import FormGroup from 'components/FormGroup/FormGroup';
import TextArea from 'components/TextArea/TextArea';
import Checkbox from 'components/Checkbox/Checkbox';
import Modal from 'components/Modal/Modal';
import OpenRepairModal from 'components/OpenRepairModal/OpenRepairModal';
import SubmitButton from 'components/SubmitButton/SubmitButton';

// Actions
import { setNoDispatch, setRepairComment } from 'actions/index';

// Styles
import './RepairInfo.scss';
import './repair-comment.scss';
import ModalError from '../ModalError/ModalError';

const RepairInfo = ({ repairInfo, userRole, repairId, dispatch }) => {
  const [commentText, setCommentText] = useState(repairInfo.comment);
  const [isCommentEditing, setIsCommentEditing] = useState(false);
  const [isOpenRepairActive, setOpenRepairActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});

  /** Переключаем чекбокс не выводить в диспетчерский отчет */
  const handleNoDispatch = value => {
    dispatch(setNoDispatch(repairId, value))
      .then(() => {})
      .catch(() => {});
  };

  /** Записываем комментарий к ремонту */
  const submitComment = () => {
    /** Показываем прелоадер на кнопку */
    setIsLoading(true);
    setIsCommentEditing(false);

    dispatch(setRepairComment(repairId, commentText))
      .then(() => {
        /** Скрываем прелоадер на кнопку */
        setIsLoading(false);
      })
      .catch(error => {
        /** Если есть ошибки, выводим */
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        /** Скрываем прелоадер на кнопку */
        setIsLoading(false);
      });
  };

  const activateOpenRepairModal = () => {
    setOpenRepairActive(true);
  };

  const deactivateOpenRepairModal = () => {
    setOpenRepairActive(false);
  };

  return (
    <div className="repair-info panel panel_default">
      <div className="panel__heading">
        <div className="panel__heading-inner">
          {repairInfo.gpa} <span className="label label_default">{repairInfo.ksname}</span>{' '}
          <span className="label label_primary">{repairInfo.type_s}</span>
        </div>
        <div className="panel__to-right">
          {repairInfo.open &&
            repairInfo.finished &&
            !repairInfo.closed &&
            (userRole === 10 || userRole === 14 || userRole === 6) && (
              <Checkbox
                onClick={() => handleNoDispatch(!repairInfo.nodispatch)}
                checked={repairInfo.nodispatch}
                ariaLabel="Не выводить в диспетчерский отчет"
                labelLeft="Не выводить в диспетчерский отчет"
                style={{ marginRight: '10px' }}
              />
            )}

          {repairInfo.open && !repairInfo.finished && (
            <span className="label label_success">Объект открыт</span>
          )}

          {repairInfo.open && repairInfo.finished && repairInfo.closed && (
            <span className="label label_danger">Ремонт закрыт</span>
          )}

          {repairInfo.open && repairInfo.finished && !repairInfo.closed && (
            <span className="label label_primary">Ремонт закончен</span>
          )}

          {repairInfo.open && repairInfo.finished && !repairInfo.closed && repairInfo.toother && (
            <span className="label label_warning">Переведен в прочий</span>
          )}

          {!repairInfo.open && repairInfo.canEdit && (
            <button type="button" className="label label_danger" onClick={activateOpenRepairModal}>
              Объект не открыт (открыть)
            </button>
          )}

          {!repairInfo.open && !repairInfo.canEdit && (
            <span className="label label_danger">Объект не открыт</span>
          )}
        </div>
      </div>

      {!repairInfo.open && repairInfo.canEdit && isOpenRepairActive && (
        <Modal title="Открыть ремонт" closeHandler={deactivateOpenRepairModal}>
          <OpenRepairModal repairId={repairInfo.id} closeHandler={deactivateOpenRepairModal} />
        </Modal>
      )}

      <ul className="repair-info__list">
        <li className="repair-info__list-item">
          Станционный номер
          {repairInfo.mark ? (
            <span className="badge">
              {repairInfo.stationnumber} ({repairInfo.mark})
            </span>
          ) : (
            <span className="badge">{repairInfo.stationnumber}</span>
          )}
        </li>

        {!repairInfo.open && (
          <li className="repair-info__list-item">
            Сроки
            <span className="badge">
              {repairInfo.datestart_1} - {repairInfo.dateend_1}
            </span>
          </li>
        )}

        {Boolean(repairInfo.open && repairInfo.prolongdate_1) && (
          <li className="repair-info__list-item">
            Фактические сроки
            <span className="badge">
              {repairInfo.factdatestart_1} - {repairInfo.prolongdate_1}
            </span>
          </li>
        )}

        {Boolean(repairInfo.open && !repairInfo.prolongdate_1) && (
          <li className="repair-info__list-item">
            Фактические сроки
            <span className="badge">
              {repairInfo.factdatestart_1} - {repairInfo.datefinished ? repairInfo.datefinished_1 : repairInfo.factdateend_1}
            </span>
          </li>
        )}

        <li className="repair-info__list-item">
          Заказчик
          <span className="badge">{repairInfo.ordermanname}</span>
        </li>

        {repairInfo.newmaster && (
          <li className="repair-info__list-item">
            Мастер
            <span className="badge">{repairInfo.newmaster_1}</span>
          </li>
        )}

        {!repairInfo.newmaster && (
          <li className="repair-info__list-item">
            Мастер
            <span className="badge">{repairInfo.master_1}</span>
          </li>
        )}

        {repairInfo.master2 && repairInfo.newmaster2 && (
          <li className="repair-info__list-item">
            Второй мастер
            <span className="badge">{repairInfo.newmaster2_1}</span>
          </li>
        )}

        {repairInfo.master2 && !repairInfo.newmaster2 && (
          <li className="repair-info__list-item">
            Второй мастер
            <span className="badge">{repairInfo.master2_1}</span>
          </li>
        )}

        <li className="repair-info__list-item">
          Начальник участка
          <span className="badge">{repairInfo.nu_1}</span>
        </li>

        {repairInfo.edit_comment && (
          <li className="repair-info__list-item repair-info__list-item_comment">
            {/* REPAIR COMMENT */}
            <div className="repair-comment">
              {isCommentEditing && (
                <>
                  <div className="repair-comment__head">Комментарий:</div>

                  <div
                    className="repair-comment__body"
                    style={{ display: isCommentEditing ? 'block' : 'none' }}
                  >
                    <FormGroup error={fieldErrors.comment}>
                      <TextArea
                        rows={10}
                        value={commentText}
                        onChange={({ target }) => setCommentText(target.value)}
                      />
                    </FormGroup>

                    {responseError && <ModalError>{responseError}</ModalError>}

                    <div className="repair-comment__footer">
                      <SubmitButton
                        isLoading={isLoading}
                        isLoadingText="Сохраняю..."
                        isValid={false}
                        clickHandler={submitComment}
                      >
                        Изменить
                      </SubmitButton>
                    </div>
                  </div>
                </>
              )}

              {!isCommentEditing && (
                <>
                  <div className="repair-comment__head">
                    <button
                      type="button"
                      className="label label_primary"
                      onClick={() => setIsCommentEditing(true)}
                    >
                      Написать комментарий
                    </button>
                  </div>
                  {commentText && (
                    <div className="repair-comment__body well well_sm">{commentText}</div>
                  )}
                </>
              )}
            </div>
            {/* REPAIR COMMENT END */}
          </li>
        )}

        {!repairInfo.edit_comment && commentText && (
          <li className="repair-info__list-item repair-info__list-item_comment">
            <div className="repair-comment">
              <div className="repair-comment__head">Комментарий:</div>
              <div className="repair-comment__body well well_sm">{commentText}</div>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
};

RepairInfo.propTypes = {
  userRole: PropTypes.number.isRequired,
  repairId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  repairInfo: PropTypes.shape({
    open: PropTypes.bool,
    id: PropTypes.number,
    canEdit: PropTypes.bool,
    finished: PropTypes.bool,
    closed: PropTypes.bool,
    toother: PropTypes.bool,
    nodispatch: PropTypes.bool,
    gpa: PropTypes.string,
    ksname: PropTypes.string,
    type_s: PropTypes.string,
    stationnumber: PropTypes.string,
    mark: PropTypes.string,
    datestart_1: PropTypes.string,
    dateend_1: PropTypes.string,
    prolongdate_1: PropTypes.string,
    factdatestart_1: PropTypes.string,
    factdateend_1: PropTypes.string,
    ordermanname: PropTypes.string,
    newmaster: PropTypes.string,
    newmaster_1: PropTypes.string,
    master_1: PropTypes.string,
    master2: PropTypes.string,
    newmaster2: PropTypes.string,
    newmaster2_1: PropTypes.string,
    master2_1: PropTypes.string,
    nu_1: PropTypes.string,
    edit_comment: PropTypes.bool,
    comment: PropTypes.string,
  }).isRequired,
};

export default connect()(RepairInfo);
