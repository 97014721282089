import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';

// Icons
import { FaListAlt, FaPlus, FaServer, FaAsterisk } from 'react-icons/fa';

// Components
import Modal from 'components/Modal/Modal';
import OutOfWorkUserModal from 'components/OutOfWorkUserModal/OutOfWorkUserModal';
import InToWorkUserModal from 'components/InToWorkUserModal/InToWorkUserModal';
import ReportUserModal from 'components/ReportUserModal/ReportUserModal';
import ReportDepartmentModal from 'components/ReportDepartmentModal/ReportDepartmentModal';
import CommentsEmployeeModal from 'components/CommentsEmployeeModal/CommentsEmployeeModal';
import DocsEmployeeModal from 'components/DocsEmployeeModal/DocsEmployeeModal';
import ContactsEmployeeModal from 'components/ContactsEmployeeModal/ContactsEmployeeModal';
import ReportResponseDepartmentModal from 'components/ReportResponseDepartmentModal/ReportResponseDepartmentModal';
import ReportResponseUserModal from 'components/ReportResponseUserModal/ReportResponseUserModal';
import Warning from 'components/Warning/Warning';

// Actions
import { uploadEmployeeDocument } from 'actions';

// Styles
import './EmploymentTable.scss';

const EmploymentTable = ({ canEdit, priority, user, departments, dispatch, searchQuery }) => {
  const [isOutOfWorkUserActive, setOutOfWorkUserActive] = useState(false);
  const [isInToWorkUserActive, setInToWorkUserActive] = useState(false);
  const [isReportUserActive, setReportUserActive] = useState(false);
  const [isReportDepartmentActive, setReportDepartmentActive] = useState(false);
  const [isCommentsEmployeeActive, setCommentsEmployeeActive] = useState(false);
  const [isDocsEmployeeActive, setDocsEmployeeActive] = useState(false);
  const [isContactsEmployeeActive, setContactsEmployeeActive] = useState(false);
  const [isReportResponseDepartmentActive, setReportResponseDepartmentActive] = useState(false);
  const [isReportResponseUserActive, setReportResponseUserActive] = useState(false);

  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [currentEmployeeId, setCurrentEmployeeId] = useState(null);
  const [currentDepartmentId, setCurrentDepartmentId] = useState(null);
  const [reportType, setReportType] = useState(null);

  const activateReportResponseUserModal = type => {
    setReportType(type);
    setReportResponseUserActive(true);
  };

  const activateReportResponseDepartmentModal = type => {
    setReportType(type);
    setReportResponseDepartmentActive(true);
  };

  const activateOutOfWorkUserModal = (employeeId, departmentId) => {
    setCurrentEmployeeId(employeeId);
    setCurrentDepartmentId(departmentId);
    setOutOfWorkUserActive(true);
  };

  const activateInToWorkUserModal = (employeeId, departmentId) => {
    setCurrentEmployeeId(employeeId);
    setCurrentDepartmentId(departmentId);
    setInToWorkUserActive(true);
  };

  const activateReportUserModal = employeeId => {
    setCurrentEmployeeId(employeeId);
    setReportUserActive(true);
  };

  const activateReportDepartmentModal = departmentId => {
    setCurrentDepartmentId(departmentId);
    setReportDepartmentActive(true);
  };

  const activateCommentsEmployeeModal = (employeeId, departmentId) => {
    setCurrentEmployeeId(employeeId);
    setCurrentDepartmentId(departmentId);
    setCommentsEmployeeActive(true);
  };

  const activateDocsEmployeeModal = (employeeId, departmentId) => {
    setCurrentEmployeeId(employeeId);
    setCurrentDepartmentId(departmentId);
    setDocsEmployeeActive(true);
  };

  const activateContactsEmployeeModal = (employeeId, departmentId) => {
    setCurrentEmployeeId(employeeId);
    setCurrentDepartmentId(departmentId);
    setContactsEmployeeActive(true);
  };

  const deactivateModal = () => {
    setReportResponseDepartmentActive(false);
    setReportResponseUserActive(false);
    setOutOfWorkUserActive(false);
    setInToWorkUserActive(false);
    setReportUserActive(false);
    setReportDepartmentActive(false);
    setContactsEmployeeActive(false);
    setDocsEmployeeActive(false);
    setCommentsEmployeeActive(false);

    setCurrentEmployeeId(null);
    setCurrentDepartmentId(null);
  };

  /** Фильтруем список пользователей по поиску */
  const applyFilterBySearchQuery = staff => {
    let isFilterPassed = true;

    if (staff.name_1 && searchQuery) {
      isFilterPassed = staff.name_1.toLowerCase().indexOf(searchQuery) > -1;
    }

    return isFilterPassed;
  };

  /** Отфильтрованный список департаментов,
   * содержит в себе департаменты в которых есть пользователи,
   * которые прошли фильтр по поиску */
  const filteredDepartments = departments.filter(
    department => department.staff.filter(applyFilterBySearchQuery).length > 0,
  );

  useEffect(() => {
    if (!(currentDepartmentId && currentEmployeeId)) {
      setCurrentEmployee(null);
      return;
    }

    const currentDepartment = departments.find(dep => dep.id === currentDepartmentId);
    if (currentDepartment) {
      const employee = currentDepartment.staff.find(empl => empl.id === currentEmployeeId);
      if (employee) {
        setCurrentEmployee(employee);
      }
    }
  }, [currentEmployeeId, currentDepartmentId, departments]);

  return (
    <>
      {filteredDepartments.length > 0 && (
        <table className="table table-condensed table-bordered table-hover employment-table">
          <thead>
            <tr className="info">
              <th>Таб. номер</th>
              <th>ФИО</th>
              <th>Должность</th>
              <th>Разряд</th>
              <th>ОП</th>
              <th>Занятость</th>
              <th>Прим.</th>
              <th>Тел.</th>
              <th>E-mail</th>
              <th>Док.</th>
              <th> </th>
            </tr>
          </thead>

          <colgroup>
            <col style={{ width: '120px' }} />
            <col style={{ width: '198px' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: '194px' }} />
            <col style={{ width: '174px' }} />
            <col style={{ width: '284px' }} />
            <col style={{ width: '519px' }} />
            <col style={{ width: '43px' }} />
            <col style={{ width: '43px' }} />
            <col style={{ width: '41px' }} />
            <col style={{ width: '41px' }} />
          </colgroup>

          <tbody>
            {filteredDepartments.map(department => {
              const { staff } = department;
              const filteredStaff = staff.filter(applyFilterBySearchQuery);

              return (
                <React.Fragment key={department.id}>
                  {filteredStaff.length > 0 && (
                    <>
                      <tr className="employment-table__department">
                        <td colSpan="10">Участок {department.department}</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-xs btn-info report-department"
                            title="Отчет"
                            onClick={() => activateReportDepartmentModal(department.id)}
                          >
                            <FaListAlt className="icon" />
                          </button>
                        </td>
                      </tr>

                      {Array.isArray(staff) &&
                        filteredStaff.map(employee => {
                          return (
                            <tr key={employee.id} className="employment-table__user">
                              <td>{employee.number}</td>

                              <td>
                                <abbr title={employee.name}>{employee.name_1}</abbr>
                              </td>

                              <td>{employee.role_1}</td>

                              <td>{employee.stage}</td>

                              <td>{employee.op}</td>

                              <td>
                                {employee.outofwork !== '' && (
                                  <span
                                    className={cx('label', {
                                      label_primary: employee.outofwork === 'Отпуск',
                                      label_success: employee.outofwork === 'Учеба',
                                      label_danger: employee.outofwork === 'Больничный',
                                    })}
                                  >
                                    {employee.outofwork}
                                  </span>
                                )}

                                {employee.outofwork === '' && (
                                  <>
                                    {employee.gpa && (
                                      <>
                                        {employee.future && (
                                          <span className="label label_danger">назначен на</span>
                                        )}
                                        <Link
                                          to={`/repair/${employee.repairId}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {employee.gpa} {employee.ksname} №{employee.stationnumber}{' '}
                                          <span className="label label_primary">
                                            {employee.type_s}
                                          </span>
                                        </Link>
                                      </>
                                    )}

                                    {Array.isArray(employee.masterObjects) &&
                                      employee.masterObjects.map((item, moIndex) => (
                                        <React.Fragment key={item.id}>
                                          <Link to={`/repair/${item.id}`}>
                                            {item.gpa} {item.ksname} №{item.stationnumber}{' '}
                                            <span className="label label_primary">
                                              {item.type_s}
                                            </span>
                                          </Link>
                                          {moIndex < employee.masterObjects.length && (
                                            <>
                                              <br />
                                              <br />
                                            </>
                                          )}
                                        </React.Fragment>
                                      ))}

                                    {employee.rmmObject && employee.rmmObject.repairObject && (
                                      <>
                                        {employee.rmmObject.text}{' '}
                                        {employee.rmmObject.object &&
                                          employee.rmmObject.object.name}{' '}
                                        {employee.rmmObject.repairObject && (
                                          <Link
                                            to={`/repair/${employee.rmmObject.repairObject.id}`}
                                          >
                                            {employee.rmmObject.repairObject.gpa}{' '}
                                            {employee.rmmObject.repairObject.ksname} №
                                            {employee.rmmObject.repairObject.stationnumber}{' '}
                                            <span className="label label_primary">
                                              {employee.rmmObject.repairObject.type_s}
                                            </span>
                                          </Link>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </td>

                              <td>
                                {employee.comment && (
                                  <button
                                    type="button"
                                    className="employment-table__btn"
                                    onClick={() =>
                                      activateCommentsEmployeeModal(employee.id, department.id)
                                    }
                                  >
                                    {employee.comment}
                                  </button>
                                )}

                                {!employee.comment && (
                                  <button
                                    type="button"
                                    title="Добавить примечание"
                                    className="btn btn-xs btn-primary"
                                    onClick={() =>
                                      activateCommentsEmployeeModal(employee.id, department.id)
                                    }
                                  >
                                    <FaPlus className="icon" />
                                  </button>
                                )}
                              </td>

                              <td>
                                {(canEdit || (employee.canEdit && employee.canEdit)) && (
                                  <>
                                    {employee.phone !== '' && (
                                      <button
                                        type="button"
                                        className="employment-table__btn"
                                        onClick={() =>
                                          activateContactsEmployeeModal(employee, department.id)
                                        }
                                      >
                                        {employee.phone}
                                      </button>
                                    )}

                                    {employee.phone === '' && (
                                      <button
                                        type="button"
                                        className="employment-table__btn"
                                        onClick={() =>
                                          activateContactsEmployeeModal(employee, department.id)
                                        }
                                      >
                                        Доб.
                                      </button>
                                    )}
                                  </>
                                )}

                                {(!canEdit || (employee.canEdit && !employee.canEdit)) && (
                                  <>{employee.phone}</>
                                )}
                              </td>

                              <td>
                                {(canEdit || (employee.canEdit && employee.canEdit)) && (
                                  <>
                                    {employee.email !== '' && (
                                      <button
                                        type="button"
                                        className="employment-table__btn"
                                        onClick={() =>
                                          activateContactsEmployeeModal(employee, department.id)
                                        }
                                      >
                                        {employee.email}
                                      </button>
                                    )}

                                    {employee.email === '' && (
                                      <button
                                        type="button"
                                        className="employment-table__btn"
                                        onClick={() =>
                                          activateContactsEmployeeModal(employee.id, department.id)
                                        }
                                      >
                                        Доб.
                                      </button>
                                    )}
                                  </>
                                )}

                                {(!canEdit || (employee.canEdit && !employee.canEdit)) && (
                                  <>{employee.email}</>
                                )}
                              </td>

                              <td>
                                <button
                                  type="button"
                                  className="employment-table__btn"
                                  onClick={() => {
                                    activateDocsEmployeeModal(employee.id, department.id);
                                  }}
                                >
                                  <FaServer className="icon" />
                                </button>
                              </td>

                              <td>
                                {(canEdit || employee.canEdit) && employee.outofwork === '' && (
                                  <React.Fragment>
                                    <button
                                      type="button"
                                      className="btn btn-xs btn-danger"
                                      title="Вывести с работы"
                                      onClick={() =>
                                        activateOutOfWorkUserModal(employee.id, department.id)
                                      }
                                    >
                                      <FaAsterisk className="icon" />
                                    </button>
                                  </React.Fragment>
                                )}

                                {(canEdit || employee.canEdit) && employee.outofwork !== '' && (
                                  <button
                                    type="button"
                                    className="btn btn-xs btn-success"
                                    title="Вывести на работу"
                                    onClick={() =>
                                      activateInToWorkUserModal(employee.id, department.id)
                                    }
                                  >
                                    <FaAsterisk className="icon" />
                                  </button>
                                )}

                                {(priority >= employee.priority || user.userRole === 10) && (
                                  <button
                                    type="button"
                                    className="btn btn-xs btn-info"
                                    title="Отчет"
                                    onClick={() => activateReportUserModal(employee.id)}
                                  >
                                    <FaListAlt className="icon" />
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  )}
                </React.Fragment>
              );
            })}

            {isOutOfWorkUserActive && (
              <Modal title="Вывести с работы" closeHandler={deactivateModal}>
                <OutOfWorkUserModal
                  closeHandler={deactivateModal}
                  userId={currentEmployeeId}
                  departmentId={currentDepartmentId}
                />
              </Modal>
            )}

            {isInToWorkUserActive && (
              <Modal title="Вывести на работу" closeHandler={deactivateModal}>
                <InToWorkUserModal
                  closeHandler={deactivateModal}
                  userId={currentEmployeeId}
                  departmentId={currentDepartmentId}
                />
              </Modal>
            )}

            {isReportUserActive && (
              <Modal title="Сформировать отчеты по работнику" closeHandler={deactivateModal}>
                <ReportUserModal
                  openModal={activateReportResponseUserModal}
                  closeHandler={deactivateModal}
                />
              </Modal>
            )}

            {isReportDepartmentActive && (
              <Modal title="Сформировать отчеты по участку" closeHandler={deactivateModal}>
                <ReportDepartmentModal
                  openModal={activateReportResponseDepartmentModal}
                  closeHandler={deactivateModal}
                />
              </Modal>
            )}

            {isCommentsEmployeeActive && (
              <Modal title="Примечания" closeHandler={deactivateModal}>
                <CommentsEmployeeModal
                  closeHandler={deactivateModal}
                  userId={currentEmployeeId}
                  departmentId={currentDepartmentId}
                />
              </Modal>
            )}

            {isDocsEmployeeActive && currentEmployee !== null && (
              <Modal title="Сопроводительные документы" closeHandler={deactivateModal}>
                <DocsEmployeeModal
                  closeHandler={deactivateModal}
                  canEdit={canEdit}
                  employee={currentEmployee}
                  departmentId={currentDepartmentId}
                  uploadEmployeeDocument={(...args) => {
                    return dispatch(uploadEmployeeDocument(...args));
                  }}
                />
              </Modal>
            )}

            {isContactsEmployeeActive && currentEmployee !== null && (
              <Modal title="Контактные данные" closeHandler={deactivateModal}>
                <ContactsEmployeeModal
                  closeHandler={deactivateModal}
                  user={currentEmployee}
                  departmentId={currentDepartmentId}
                />
              </Modal>
            )}

            {isReportResponseDepartmentActive && (
              <Modal title="Формируется отчет" closeHandler={deactivateModal}>
                <ReportResponseDepartmentModal
                  closeHandler={deactivateModal}
                  reportType={reportType}
                  departmentId={currentDepartmentId}
                />
              </Modal>
            )}

            {isReportResponseUserActive && (
              <Modal title="Формируется отчет" closeHandler={deactivateModal}>
                <ReportResponseUserModal
                  closeHandler={deactivateModal}
                  reportType={reportType}
                  userId={currentEmployeeId}
                />
              </Modal>
            )}
          </tbody>
        </table>
      )}

      {filteredDepartments.length === 0 && <Warning msg="По вашему запросу ничего не найдено..." />}
    </>
  );
};

EmploymentTable.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  priority: PropTypes.number.isRequired,
  user: PropTypes.shape({
    userRole: PropTypes.number,
  }).isRequired,
  departments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      department: PropTypes.string,
      staff: PropTypes.arrayOf(
        PropTypes.shape({
          number: PropTypes.string,
          name: PropTypes.string,
          name_1: PropTypes.string,
          role_1: PropTypes.string,
          stage: PropTypes.string,
          op: PropTypes.string,
          outofwork: PropTypes.string,
          gpa: PropTypes.string,
          future: PropTypes.bool,
          repairUrl: PropTypes.string,
          ksname: PropTypes.string,
          stationnumber: PropTypes.string,
          type_s: PropTypes.string,
          masterObjects: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              url: PropTypes.string,
              gpa: PropTypes.string,
              ksname: PropTypes.string,
              stationnumber: PropTypes.string,
              type_s: PropTypes.string,
            }),
          ),
          rmmObject: PropTypes.shape({
            text: PropTypes.string,
            object: PropTypes.shape({
              name: PropTypes.string,
            }),
            repairObject: PropTypes.shape({
              url: PropTypes.string,
              gpa: PropTypes.string,
              ksname: PropTypes.string,
              stationnumber: PropTypes.string,
              type_s: PropTypes.string,
            }),
          }),
          comment: PropTypes.string,
          id: PropTypes.number,
          canEdit: PropTypes.bool,
          phone: PropTypes.string,
          email: PropTypes.string,
          priority: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
  dispatch: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  user: state.mainReducer.user,
});

export default connect(mapStateToProps)(EmploymentTable);
