import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Icons
import { FaTrashAlt, FaEdit, FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa';

// Components
import GpaObjectAdmin from 'components/GpaObjectAdmin/GpaObjectAdmin';
import Modal from 'components/Modal/Modal';
import AddKsModal from 'components/AddKsModal/AddKsModal';
import RemoveKsConfirmModal from 'components/RemoveKsConfirmModal/RemoveKsConfirmModal';
import EditKsModal from 'components/EditKsModal/EditKsModal';
import AddRepairModal from 'components/AddRepairModal/AddRepairModal';

// Actions
import { moveKsAdmin } from 'actions';

// Styles
import './WorkTableAdmin.scss';

const WorkTableAdmin = ({ url, orderMenOfThisPeriod, dispatch }) => {
  const [isAddKsActive, setAddKsActive] = useState(false);
  const [isRemoveKsConfirmActive, setRemoveKsConfirmActive] = useState(false);
  const [isEditKsActive, setEditKsActive] = useState(false);
  const [isAddRepairActive, setAddRepairActive] = useState(false);

  const [currentOrderManId, setCurrentOrderManId] = useState(null);
  const [currentKs, setCurrentKs] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(null);

  const activateAddKsModal = orderManId => {
    setCurrentOrderManId(orderManId);
    setAddKsActive(true);
  };

  const deactivateAddKsModal = () => {
    setAddKsActive(false);
  };

  const activateRemoveKsConfirmModal = (ks, orderManId) => {
    setCurrentKs(ks);
    setCurrentOrderManId(orderManId);
    setRemoveKsConfirmActive(true);
  };

  const deactivateRemoveKsConfirmModal = () => {
    setRemoveKsConfirmActive(false);
  };

  const activateEditKsModal = (ks, orderManId) => {
    setCurrentKs(ks);
    setCurrentOrderManId(orderManId);
    setEditKsActive(true);
  };

  const deactivateEditKsModal = () => {
    setEditKsActive(false);
  };

  const activateAddRepairModal = (month, ks, orderManId) => {
    setCurrentMonth(month);
    setCurrentKs(ks);
    setCurrentOrderManId(orderManId);
    setAddRepairActive(true);
  };

  const deactivateAddRepairModal = () => {
    setAddRepairActive(false);
  };

  const handleMoveKs = (ksId, type, orderManId) => {
    dispatch(moveKsAdmin(ksId, type, orderManId))
      .then(() => {
        if (window && window.location) {
          window.location.reload();
        }
      })
      .catch(() => {});
  };

  return (
    <table className="work-table-admin table table-bordered">
      <thead>
        <tr>
          <th colSpan="2"> </th>
          <th className="work-table-admin__month-th">
            <span className="label label_default">Январь</span>
          </th>
          <th className="work-table-admin__month-th">
            <span className="label label_default">Февраль</span>
          </th>
          <th className="work-table-admin__month-th">
            <span className="label label_default">Март</span>
          </th>
          <th className="work-table-admin__month-th">
            <span className="label label_default">Апрель</span>
          </th>
          <th className="work-table-admin__month-th">
            <span className="label label_default">Май</span>
          </th>
          <th className="work-table-admin__month-th">
            <span className="label label_default">Июнь</span>
          </th>
          <th className="work-table-admin__month-th">
            <span className="label label_default">Июль</span>
          </th>
          <th className="work-table-admin__month-th">
            <span className="label label_default">Август</span>
          </th>
          <th className="work-table-admin__month-th">
            <span className="label label_default">Сентябрь</span>
          </th>
          <th className="work-table-admin__month-th">
            <span className="label label_default">Октябрь</span>
          </th>
          <th className="work-table-admin__month-th">
            <span className="label label_default">Ноябрь</span>
          </th>
          <th className="work-table-admin__month-th">
            <span className="label label_default">Декабрь</span>
          </th>
        </tr>
      </thead>

      <tbody>
        {orderMenOfThisPeriod.map(orderMan => (
          <React.Fragment key={orderMan.id}>
            {orderMan.name &&
              orderMan.ks &&
              Array.isArray(orderMan.ks) &&
              orderMan.ks.length === 0 && (
                <tr>
                  <td className="work-table-admin__orderMan" rowSpan={orderMan.ks.length + 1}>
                    <span className="work-table-admin__orderMan-text">
                      {orderMan.name} {orderMan.id}
                    </span>
                  </td>
                  <td className="work-table-admin__ks">
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => activateAddKsModal(orderMan.id)}
                    >
                      + Добавить КС
                    </button>
                  </td>
                  <td className="work-table-admin__month" />
                  <td className="work-table-admin__month" />
                  <td className="work-table-admin__month" />
                  <td className="work-table-admin__month" />
                  <td className="work-table-admin__month" />
                  <td className="work-table-admin__month" />
                  <td className="work-table-admin__month" />
                  <td className="work-table-admin__month" />
                  <td className="work-table-admin__month" />
                  <td className="work-table-admin__month" />
                  <td className="work-table-admin__month" />
                  <td className="work-table-admin__month" />
                </tr>
              )}

            {orderMan.name && orderMan.ks && Array.isArray(orderMan.ks) && orderMan.ks.length > 0 && (
              <React.Fragment>
                {orderMan.ks.map((ks, index) => (
                  <tr key={ks.id}>
                    {index === 0 && (
                      <td className="work-table-admin__orderMan" rowSpan={orderMan.ks.length + 1}>
                        <span className="work-table-admin__orderMan-text">{orderMan.name}</span>
                      </td>
                    )}

                    <td className="work-table-admin__ks">
                      <span className="label label_primary">{ks.name}</span>
                      <br />
                      <br />
                      <button
                        type="button"
                        className="btn btn-xs btn-danger"
                        onClick={() => activateRemoveKsConfirmModal(ks, orderMan.id)}
                      >
                        <FaTrashAlt className="icon" />
                      </button>{' '}
                      <button
                        type="button"
                        className="btn btn-xs btn-primary"
                        onClick={() => activateEditKsModal(ks, orderMan.id)}
                      >
                        <FaEdit className="icon" />
                      </button>{' '}
                      {index !== 0 && (
                        <button
                          type="button"
                          className="btn btn-xs btn-primary"
                          onClick={() => handleMoveKs(ks.id, 'up', orderMan.id, index)}
                        >
                          <FaLongArrowAltUp className="icon" />
                        </button>
                      )}{' '}
                      {index !== orderMan.ks.length - 1 && (
                        <button
                          type="button"
                          className="btn btn-xs btn-primary"
                          onClick={() => handleMoveKs(ks.id, 'down', orderMan.id, index)}
                        >
                          <FaLongArrowAltDown className="icon" />
                        </button>
                      )}{' '}
                    </td>

                    <td className="work-table-admin__month">
                      {ks.month1 &&
                        Array.isArray(ks.month1) &&
                        ks.month1.length > 0 &&
                        ks.month1.map(repair => (
                          <GpaObjectAdmin
                            key={repair.id}
                            url={url}
                            orderManId={orderMan.id}
                            ksId={ks.id}
                            month={1}
                            repair={repair}
                          />
                        ))}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => activateAddRepairModal(1, ks, orderMan.id)}
                      >
                        +
                      </button>
                    </td>
                    <td className="work-table-admin__month">
                      {ks.month2 &&
                        Array.isArray(ks.month2) &&
                        ks.month2.length > 0 &&
                        ks.month2.map(repair => (
                          <GpaObjectAdmin
                            key={repair.id}
                            url={url}
                            orderManId={orderMan.id}
                            ksId={ks.id}
                            month={2}
                            repair={repair}
                          />
                        ))}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => activateAddRepairModal(2, ks, orderMan.id)}
                      >
                        +
                      </button>
                    </td>
                    <td className="work-table-admin__month">
                      {ks.month3 &&
                        Array.isArray(ks.month3) &&
                        ks.month3.length > 0 &&
                        ks.month3.map(repair => (
                          <GpaObjectAdmin
                            key={repair.id}
                            url={url}
                            orderManId={orderMan.id}
                            ksId={ks.id}
                            month={3}
                            repair={repair}
                          />
                        ))}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => activateAddRepairModal(3, ks, orderMan.id)}
                      >
                        +
                      </button>
                    </td>
                    <td className="work-table-admin__month">
                      {ks.month4 &&
                        Array.isArray(ks.month4) &&
                        ks.month4.length > 0 &&
                        ks.month4.map(repair => (
                          <GpaObjectAdmin
                            key={repair.id}
                            url={url}
                            orderManId={orderMan.id}
                            ksId={ks.id}
                            month={4}
                            repair={repair}
                          />
                        ))}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => activateAddRepairModal(4, ks, orderMan.id)}
                      >
                        +
                      </button>
                    </td>
                    <td className="work-table-admin__month">
                      {ks.month5 &&
                        Array.isArray(ks.month5) &&
                        ks.month5.length > 0 &&
                        ks.month5.map(repair => (
                          <GpaObjectAdmin
                            key={repair.id}
                            url={url}
                            orderManId={orderMan.id}
                            ksId={ks.id}
                            month={5}
                            repair={repair}
                          />
                        ))}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => activateAddRepairModal(5, ks, orderMan.id)}
                      >
                        +
                      </button>
                    </td>
                    <td className="work-table-admin__month">
                      {ks.month6 &&
                        Array.isArray(ks.month6) &&
                        ks.month6.length > 0 &&
                        ks.month6.map(repair => (
                          <GpaObjectAdmin
                            key={repair.id}
                            url={url}
                            orderManId={orderMan.id}
                            ksId={ks.id}
                            month={6}
                            repair={repair}
                          />
                        ))}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => activateAddRepairModal(6, ks, orderMan.id)}
                      >
                        +
                      </button>
                    </td>
                    <td className="work-table-admin__month">
                      {ks.month7 &&
                        Array.isArray(ks.month7) &&
                        ks.month7.length > 0 &&
                        ks.month7.map(repair => (
                          <GpaObjectAdmin
                            key={repair.id}
                            url={url}
                            orderManId={orderMan.id}
                            ksId={ks.id}
                            month={7}
                            repair={repair}
                          />
                        ))}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => activateAddRepairModal(7, ks, orderMan.id)}
                      >
                        +
                      </button>
                    </td>
                    <td className="work-table-admin__month">
                      {ks.month8 &&
                        Array.isArray(ks.month8) &&
                        ks.month8.length > 0 &&
                        ks.month8.map(repair => (
                          <GpaObjectAdmin
                            key={repair.id}
                            url={url}
                            orderManId={orderMan.id}
                            ksId={ks.id}
                            month={8}
                            repair={repair}
                          />
                        ))}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => activateAddRepairModal(8, ks, orderMan.id)}
                      >
                        +
                      </button>
                    </td>
                    <td className="work-table-admin__month">
                      {ks.month9 &&
                        Array.isArray(ks.month9) &&
                        ks.month9.length > 0 &&
                        ks.month9.map(repair => (
                          <GpaObjectAdmin
                            key={repair.id}
                            url={url}
                            orderManId={orderMan.id}
                            ksId={ks.id}
                            month={9}
                            repair={repair}
                          />
                        ))}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => activateAddRepairModal(9, ks, orderMan.id)}
                      >
                        +
                      </button>
                    </td>
                    <td className="work-table-admin__month">
                      {ks.month10 &&
                        Array.isArray(ks.month10) &&
                        ks.month10.length > 0 &&
                        ks.month10.map(repair => (
                          <GpaObjectAdmin
                            key={repair.id}
                            url={url}
                            orderManId={orderMan.id}
                            ksId={ks.id}
                            month={10}
                            repair={repair}
                          />
                        ))}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => activateAddRepairModal(10, ks, orderMan.id)}
                      >
                        +
                      </button>
                    </td>
                    <td className="work-table-admin__month">
                      {ks.month11 &&
                        Array.isArray(ks.month11) &&
                        ks.month11.length > 0 &&
                        ks.month11.map(repair => (
                          <GpaObjectAdmin
                            key={repair.id}
                            url={url}
                            orderManId={orderMan.id}
                            ksId={ks.id}
                            month={11}
                            repair={repair}
                          />
                        ))}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => activateAddRepairModal(11, ks, orderMan.id)}
                      >
                        +
                      </button>
                    </td>
                    <td className="work-table-admin__month">
                      {ks.month12 &&
                        Array.isArray(ks.month12) &&
                        ks.month12.length > 0 &&
                        ks.month12.map(repair => (
                          <GpaObjectAdmin
                            key={repair.id}
                            url={url}
                            orderManId={orderMan.id}
                            ksId={ks.id}
                            month={12}
                            repair={repair}
                          />
                        ))}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => activateAddRepairModal(12, ks, orderMan.id)}
                      >
                        +
                      </button>
                    </td>
                  </tr>
                ))}

                <tr>
                  <td className="work-table-admin__ks">
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => activateAddKsModal(orderMan.id)}
                    >
                      + Добавить КС
                    </button>
                  </td>
                  <td className="work-table-admin__month" />
                  <td className="work-table-admin__month" />
                  <td className="work-table-admin__month" />
                  <td className="work-table-admin__month" />
                  <td className="work-table-admin__month" />
                  <td className="work-table-admin__month" />
                  <td className="work-table-admin__month" />
                  <td className="work-table-admin__month" />
                  <td className="work-table-admin__month" />
                  <td className="work-table-admin__month" />
                  <td className="work-table-admin__month" />
                  <td className="work-table-admin__month" />
                </tr>
              </React.Fragment>
            )}
          </React.Fragment>
        ))}

        {isAddKsActive && (
          <Modal title="Добавление КС к заказчику" closeHandler={deactivateAddKsModal}>
            <AddKsModal orderManId={currentOrderManId} closeHandler={deactivateAddKsModal} />
          </Modal>
        )}

        {isRemoveKsConfirmActive && (
          <Modal title="Удаление КС" closeHandler={deactivateRemoveKsConfirmModal}>
            <RemoveKsConfirmModal
              ks={currentKs}
              orderManId={currentOrderManId}
              closeHandler={deactivateRemoveKsConfirmModal}
            />
          </Modal>
        )}

        {isEditKsActive && (
          <Modal title="Редактирование КС" closeHandler={deactivateEditKsModal}>
            <EditKsModal
              ks={currentKs}
              orderManId={currentOrderManId}
              closeHandler={deactivateEditKsModal}
            />
          </Modal>
        )}

        {isAddRepairActive && (
          <Modal title="Редактирование КС" closeHandler={deactivateAddRepairModal}>
            <AddRepairModal
              closeHandler={deactivateAddRepairModal}
              ks={currentKs}
              orderManId={currentOrderManId}
              month={currentMonth}
            />
          </Modal>
        )}
      </tbody>
    </table>
  );
};

const monthPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    gpa: PropTypes.string,
    stationnumber: PropTypes.string,
    mark: PropTypes.string,
    datestart_1: PropTypes.string,
    dateend_1: PropTypes.string,
    labelclass: PropTypes.string,
    type_s: PropTypes.string,
    outofplan: PropTypes.bool,
  }),
);

WorkTableAdmin.propTypes = {
  url: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  orderMenOfThisPeriod: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      rowspan: PropTypes.number,
      name: PropTypes.string,
      id: PropTypes.number,
      ks: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          month1: monthPropType,
          month2: monthPropType,
          month3: monthPropType,
          month4: monthPropType,
          month5: monthPropType,
          month6: monthPropType,
          month7: monthPropType,
          month8: monthPropType,
          month9: monthPropType,
          month10: monthPropType,
          month11: monthPropType,
          month12: monthPropType,
        }),
      ),
    }),
  ).isRequired,
};

export default connect()(WorkTableAdmin);
