import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Styles
import './Warning.scss';

const Warning = ({ className, msg }) => {
  return (
    <div
      className={cx('warning', {
        [className]: className,
      })}
    >
      {msg}
    </div>
  );
};

Warning.propTypes = {
  className: PropTypes.string,
  msg: PropTypes.string.isRequired,
};

Warning.defaultProps = {
  className: null,
};

export default Warning;
