import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Icons
import { FaTimes } from 'react-icons/fa';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';

// Styles
import './gpa-filters.scss';
import './gpa-filters-list.scss';

const GpaFiltersModal = ({
  closeHandler,
  orderManToPeriod,
  gpas,
  types,
  statuses,
  userRole,
  isFilterActive,
  master,
  nu,
  repairFilter,
  handleSelectFilterOptions,
  handleResetFilter,
}) => (
  <React.Fragment>
    <ModalHeader>
      <h4 className="modal-header__title">Подобрать по параметрам</h4>

      {isFilterActive && (
        <button type="button" className="btn btn-danger" onClick={handleResetFilter}>
          Сбросить фильтрацию <FaTimes className="icon" />
        </button>
      )}
    </ModalHeader>

    <ModalBody>
      <div className="gpa-filters">
        {/** ЗАКАЗЧИК */}
        {orderManToPeriod && Array.isArray(orderManToPeriod) && orderManToPeriod.length > 0 && (
          <div className="gpa-filters__group">
            <div className="gpa-filters__group-name">Заказчик</div>
            <div className="gpa-filters__group-list-wrap">
              <div className="gpa-filters-list">
                {orderManToPeriod.map(orderMan => (
                  <button
                    key={orderMan.id}
                    type="button"
                    className={cx('gpa-filters-list__item', {
                      'gpa-filters-list__item_active': repairFilter.orderMan.includes(orderMan.id),
                    })}
                    onClick={() => handleSelectFilterOptions('orderMan', orderMan.id)}
                  >
                    {orderMan.name}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
        {/** ЗАКАЗЧИК END */}

        {/** КС */}
        {orderManToPeriod && Array.isArray(orderManToPeriod) && orderManToPeriod.length > 0 && (
          <div className="gpa-filters__group">
            <div className="gpa-filters__group-name">Кс</div>
            <div className="gpa-filters__group-list-wrap">
              <div className="gpa-filters-list">
                {orderManToPeriod.map(orderMan => (
                  <React.Fragment key={orderMan.id}>
                    {orderMan.ks &&
                      Array.isArray(orderMan.ks) &&
                      orderMan.ks.length > 0 &&
                      orderMan.ks.map(ks => (
                        <button
                          key={ks.id}
                          type="button"
                          className={cx('gpa-filters-list__item', {
                            'gpa-filters-list__item_active': repairFilter.ks.includes(ks.id),
                          })}
                          onClick={() => handleSelectFilterOptions('ks', ks.id)}
                        >
                          {ks.name}
                        </button>
                      ))}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        )}
        {/** КС END */}

        {/** ТИП ГПА */}
        {gpas && Array.isArray(gpas) && gpas.length > 0 && (
          <div className="gpa-filters__group">
            <div className="gpa-filters__group-name">Тип ГПА</div>
            <div className="gpa-filters__group-list-wrap">
              <div className="gpa-filters-list">
                {gpas.map(gpa => (
                  <button
                    key={gpa.id}
                    type="button"
                    className={cx('gpa-filters-list__item', {
                      'gpa-filters-list__item_active': repairFilter.gpa.includes(gpa.id),
                    })}
                    onClick={() => handleSelectFilterOptions('gpa', gpa.id)}
                  >
                    {gpa.name}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
        {/** ТИП ГПА END */}

        {/** ТИП РЕМОНТА */}
        {types && Array.isArray(types) && types.length > 0 && (
          <div className="gpa-filters__group">
            <div className="gpa-filters__group-name">Тип ремонта</div>
            <div className="gpa-filters__group-list-wrap">
              <div className="gpa-filters-list">
                {types.map(type => (
                  <button
                    key={type.id}
                    type="button"
                    className={cx('gpa-filters-list__item', {
                      'gpa-filters-list__item_active': repairFilter.type.includes(type.id),
                    })}
                    onClick={() => handleSelectFilterOptions('type', type.id)}
                  >
                    {type.name}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
        {/** ТИП РЕМОНТА END */}

        {/** СТАТУС РЕМОНТА */}
        {statuses && Array.isArray(statuses) && statuses.length > 0 && (
          <div className="gpa-filters__group">
            <div className="gpa-filters__group-name">Статус ремонта</div>
            <div className="gpa-filters__group-list-wrap">
              <div className="gpa-filters-list">
                {statuses.map(status => (
                  <button
                    key={status.id}
                    type="button"
                    className={cx('gpa-filters-list__item', {
                      'gpa-filters-list__item_active': repairFilter.status.includes(status.id),
                    })}
                    onClick={() => handleSelectFilterOptions('status', status.id)}
                  >
                    {status.name}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
        {/** СТАТУС РЕМОНТА END */}

        {/** ПРИНАДЛЕЖНОСТЬ */}
        {(userRole === 4 || userRole === 5 || userRole === 12) && (
          <div className="gpa-filters__group">
            <div className="gpa-filters__group-name">Принадлежность</div>
            <div className="gpa-filters__group-list-wrap">
              <div className="gpa-filters-list">
                <button
                  type="button"
                  className={cx('gpa-filters-list__item', {
                    'gpa-filters-list__item_active': repairFilter.self.includes(1),
                  })}
                  onClick={() => {
                    handleSelectFilterOptions('self', 1);

                    if (repairFilter.self.includes(2)) {
                      handleSelectFilterOptions('self', 2);
                    }
                  }}
                >
                  Только мои объекты
                </button>

                <button
                  type="button"
                  className={cx('gpa-filters-list__item', {
                    'gpa-filters-list__item_active': repairFilter.self.includes(2),
                  })}
                  onClick={() => {
                    handleSelectFilterOptions('self', 2);

                    if (repairFilter.self.includes(1)) {
                      handleSelectFilterOptions('self', 1);
                    }
                  }}
                >
                  Только не мои объекты
                </button>
              </div>
            </div>
          </div>
        )}
        {/** ПРИНАДЛЕЖНОСТЬ END */}

        {!(userRole === 4 || userRole === 5 || userRole === 12) && (
          <>
            {/** МАСТЕР */}
            {master && Array.isArray(master) && master.length > 0 && (
              <div className="gpa-filters__group">
                <div className="gpa-filters__group-name">Мастер</div>
                <div className="gpa-filters__group-list-wrap">
                  <div className="gpa-filters-list">
                    {master.map(item => (
                      <button
                        key={item.id}
                        type="button"
                        className={cx('gpa-filters-list__item', {
                          'gpa-filters-list__item_active': repairFilter.master.includes(item.id),
                        })}
                        onClick={() => handleSelectFilterOptions('master', item.id)}
                      >
                        {item.name}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {/** МАСТЕРА END */}

            {/** НАЧАЛЬНИК УЧАСТКА */}
            {nu && Array.isArray(nu) && nu.length > 0 && (
              <div className="gpa-filters__group">
                <div className="gpa-filters__group-name">Начальник участка</div>
                <div className="gpa-filters__group-list-wrap">
                  <div className="gpa-filters-list">
                    {nu.map(item => (
                      <button
                        key={item.id}
                        type="button"
                        className={cx('gpa-filters-list__item', {
                          'gpa-filters-list__item_active': repairFilter.nu.includes(item.id),
                        })}
                        onClick={() => handleSelectFilterOptions('nu', item.id)}
                      >
                        {item.name}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {/** НАЧАЛЬНИК УЧАСТКА END */}
          </>
        )}
      </div>
    </ModalBody>

    <ModalFooter closeHandler={closeHandler} />
  </React.Fragment>
);

GpaFiltersModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  orderManToPeriod: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  gpas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  master: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  nu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  repairFilter: PropTypes.shape({
    orderMan: PropTypes.arrayOf(PropTypes.number),
    ks: PropTypes.arrayOf(PropTypes.number),
    gpa: PropTypes.arrayOf(PropTypes.number),
    type: PropTypes.arrayOf(PropTypes.number),
    status: PropTypes.arrayOf(PropTypes.number),
    master: PropTypes.arrayOf(PropTypes.number),
    nu: PropTypes.arrayOf(PropTypes.number),
    self: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  isFilterActive: PropTypes.bool.isRequired,
  userRole: PropTypes.number.isRequired,
  handleSelectFilterOptions: PropTypes.func.isRequired,
  handleResetFilter: PropTypes.func.isRequired,
};

export default GpaFiltersModal;
