import React from 'react';
import PropTypes from 'prop-types';

const GpaObjectContent = ({ unit }) => {
  return (
    <React.Fragment>
      <p className="gpa-object__name">{unit.gpa}</p>
      <p className="gpa-object__number">
        Ст. № {unit.stationnumber} {unit.mark && `(${unit.mark})`}
      </p>

      {!unit.open && (
        <p className="gpa-object__dates">
          {unit.datestart_1} - {unit.dateend_1}
        </p>
      )}

      {unit.open && unit.prolongdate_1 && (
        <p className="gpa-object__dates">
          {unit.factdatestart_1} - {unit.prolongdate_1}
        </p>
      )}

      {unit.open && !unit.prolongdate_1 && (
        <p className="gpa-object__dates">
          {unit.factdatestart_1} - {unit.factdateend_1}
        </p>
      )}

      <p className="gpa-object__type">
        <span className={`label label_${unit.labelclass}`}>
          {unit.type_s}
          {unit.toother && ' (Проч.)'}
        </span>{' '}
        {unit.outofplan && <span className="label label_black">Вп</span>}
      </p>

      {unit.show_comment && (
        <span className="gpa-object__indicator gpa-object__indicator_comment" />
      )}

      {unit.open && !unit.finished && (
        <span
          className={`gpa-object__indicator gpa-object__indicator_accordance label label_${unit.accordanceStatus}`}
        >
          {unit.accordance}
        </span>
      )}

      {unit.open && !unit.finished && unit.noReport && (
        <span className="gpa-object__indicator gpa-object__indicator_no-report label label_danger">
          д
        </span>
      )}

      {unit.open && !unit.finished && unit.overdue && (
        <span className="gpa-object__indicator gpa-object__indicator_overdue label label_danger">
          п
        </span>
      )}

      {unit.toother && unit.overdue_other && (
        <span className="gpa-object__indicator gpa-object__indicator_overdue label label_danger">
          п
        </span>
      )}

      {unit.problem && (
        <span className="gpa-object__indicator gpa-object__indicator_problem label label_danger">
          тп
        </span>
      )}
    </React.Fragment>
  );
};

GpaObjectContent.propTypes = {
  unit: PropTypes.shape({
    finished: PropTypes.bool,
    open: PropTypes.bool,
    type_s: PropTypes.string,
    toother: PropTypes.bool,
    gpa: PropTypes.string,
    stationnumber: PropTypes.string,
    mark: PropTypes.string,
    datestart_1: PropTypes.string,
    dateend_1: PropTypes.string,
    factdatestart_1: PropTypes.string,
    factdateend_1: PropTypes.string,
    labelclass: PropTypes.string,
    outofplan: PropTypes.bool,
    problem: PropTypes.bool,
    prolongdate_1: PropTypes.string,
    accordanceStatus: PropTypes.string,
    accordance: PropTypes.number,
    noReport: PropTypes.bool,
    overdue: PropTypes.bool,
    overdue_other: PropTypes.bool,
    show_comment: PropTypes.bool,
  }).isRequired,
};

export default GpaObjectContent;
