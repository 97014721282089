import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import PercentNode from 'components/PercentNode/PercentNode';
import FormGroup from 'components/FormGroup/FormGroup';
import InputForm from 'components/InputForm/InputForm';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { addRmmNodePercent } from 'actions';

// Helpers
import { formatDateDDmmYYYY } from 'helpers/formatDateDDmmYYYY';
import { formatDateYYYYmmDD } from 'helpers/formatDateYYYYmmDD';

const PercentNodeModal = ({ closeHandler, node, dispatch, canEdit }) => {
  /**
   * Если в массиве node.percentnode есть айтем у которого date равна сегодняшней дате
   * или у айтема процент равен 100
   * либо у node в datesendreturn_1 записана дата
   * либо нет прав на редактирование canEdit
   * скрывается форма и кнопка обновить
   */
  const noForm =
    (node &&
      node.percentnode &&
      Array.isArray(node.percentnode) &&
      node.percentnode.filter(item => {
        return formatDateYYYYmmDD(new Date()) === item.date || item.percent === 100;
      }).length > 0) ||
    (node && node.datesendreturn_1) ||
    !canEdit;

  const [percent, setPercent] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const submitPercent = () => {
    setIsLoading(true);

    dispatch(addRmmNodePercent(node.id, +percent))
      .then(() => {
        setIsLoading(false);
        closeHandler();
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Статус ремонта" />

      <ModalBody>
        {node &&
          node.percentnode &&
          Array.isArray(node.percentnode) &&
          node.percentnode.map(item => (
            <PercentNode
              key={item.id}
              percent={item.percent}
              status={item.status}
              date={item.date_1}
            />
          ))}

        {!noForm && (
          <FormGroup
            title={`Процент выполения ремонта на сегодня (${formatDateDDmmYYYY(new Date())})`}
            error={fieldErrors.percent}
          >
            <InputForm
              type="text"
              value={percent}
              onChange={({ target }) => setPercent(target.value)}
            />
          </FormGroup>
        )}
      </ModalBody>

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        {!noForm && (
          <SubmitButton
            isLoading={isLoading}
            isValid={percent === '' || +percent > 100}
            isLoadingText="Сохранение..."
            clickHandler={submitPercent}
          >
            Обновить
          </SubmitButton>
        )}
      </ModalFooter>
    </React.Fragment>
  );
};

PercentNodeModal.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number,
    datesendreturn_1: PropTypes.string,
    percentnode: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        date_1: PropTypes.string,
        status: PropTypes.string,
        percent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    ),
  }).isRequired,
};

export default connect()(PercentNodeModal);
