import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import InputDate from 'components/InputDate/InputDate';
import TextArea from 'components/TextArea/TextArea';
import SelectForm from 'components/SelectForm/SelectForm';
import InputGroup from 'components/InputGroup/InputGroup';
import FormGroup from 'components/FormGroup/FormGroup';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { repairProlongMoveToOther } from 'actions';

// Helpers
import { formatDateYYYYmmDD } from 'helpers/formatDateYYYYmmDD';
import { browseServer } from 'helpers/file-manager';

const MoveToOtherFormModal = ({ closeHandler, reasons, repairId, dispatch }) => {
  const [newDateMoveToOther, setNewDateMoveToOther] = useState(null);
  const [newDateForOtherMoveToOther, setNewDateForOtherMoveToOther] = useState(null);
  const [commentMoveToOther, setCommentMoveToOther] = useState('');
  const [reasonMoveToOther, setReasonMoveToOther] = useState(
    reasons.map(item => ({ value: item.id, label: item.name }))[0],
  );
  const [scheduleProlong, setSchedule] = useState(null);
  const [actProlong, setAct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const selectSchedule = () => {
    browseServer({
      onSuccess: fileUrl => {
        setSchedule(fileUrl);
      },
    });
  };

  const selectAct = () => {
    browseServer({
      onSuccess: fileUrl => {
        setAct(fileUrl);
      },
    });
  };

  const submitProlong = () => {
    setIsLoading(true);

    dispatch(
      repairProlongMoveToOther(
        repairId,
        scheduleProlong,
        actProlong,
        formatDateYYYYmmDD(newDateMoveToOther),
        formatDateYYYYmmDD(newDateForOtherMoveToOther),
        reasonMoveToOther.value,
        commentMoveToOther,
      ),
    )
      .then(() => {
        setIsLoading(false);

        closeHandler();
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title={'Перевод ремонта в "Прочий"'} />

      <ModalBody title="При переводе в прочий, основной ремонт будет закончен">
        <FormGroup title="Дата окончания основного ремонта" error={fieldErrors.newdate}>
          <InputDate
            selectedDate={newDateMoveToOther}
            onChange={date => setNewDateMoveToOther(date)}
          />
        </FormGroup>

        <FormGroup title="Дата окончания прочего ремонта" error={fieldErrors.newdateforother}>
          <InputDate
            selectedDate={newDateForOtherMoveToOther}
            onChange={date => setNewDateForOtherMoveToOther(date)}
          />
        </FormGroup>

        <FormGroup title="Комментарий" error={fieldErrors.comment}>
          <TextArea
            value={commentMoveToOther}
            onChange={({ target }) => setCommentMoveToOther(target.value)}
          />
        </FormGroup>

        <FormGroup title="Причина" error={fieldErrors.reason}>
          <SelectForm
            selectedValue={reasonMoveToOther}
            options={reasons.map(item => ({ value: item.id, label: item.name }))}
            onChange={selectedOption => setReasonMoveToOther(selectedOption)}
          />
        </FormGroup>

        <FormGroup title="График ремонта" error={fieldErrors.schedule}>
          <InputGroup onClick={selectSchedule} value={scheduleProlong} />
        </FormGroup>

        <FormGroup title="Акт расследования" error={fieldErrors.act}>
          <InputGroup onClick={selectAct} value={actProlong} />
        </FormGroup>
      </ModalBody>

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isValid={
            actProlong === '' ||
            scheduleProlong === '' ||
            commentMoveToOther === '' ||
            !newDateMoveToOther ||
            !newDateForOtherMoveToOther ||
            !reasonMoveToOther
          }
          isLoading={isLoading}
          isLoadingText="Перевожу..."
          clickHandler={submitProlong}
        >
          Перевести
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

MoveToOtherFormModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  repairId: PropTypes.number.isRequired,
  reasons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
};

export default connect()(MoveToOtherFormModal);
