import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Checkbox from 'components/Checkbox/Checkbox';

// Actions
import { setRepairMtrIncluded, setRepairMtrReceived, setRepairMtrCharged } from 'actions';

// Styles
import './TableRepairMtr.scss';

const TableRepairMtr = ({ mtrList, repairId, dispatch }) => {
  return (
    <table className="table table-bordered table-hover table-mtr">
      <thead>
        <tr className="info">
          <th>Инв номер</th>
          <th>УНС</th>
          <th>Номенклатура</th>
          <th>Чертеж 1</th>
          <th>Чертеж 2</th>
          <th>Ед. изм.</th>
          <th>Кол-во</th>
          <th>Способ пост.</th>
          <th>Дата заявки</th>
          <th>Спецификация</th>

          <th>Статус спецификации</th>
          <th>Кол-во аннулирования</th>
          <th>Кол-во резервирования</th>

          <th>Дата пост</th>
          <th>Док. пост.</th>
          <th>Док. реал.</th>
          <th>Кол-во пост.</th>
          <th>Акт</th>
          <th>№ накладной</th>
          <th>Деталь получена</th>
          <th>Деталь включена в КС-2</th>
          <th>Деталь списана</th>
          <th>Прим.</th>
        </tr>
      </thead>
      <tbody>
        {mtrList.map(mtr => (
          <tr key={mtr.id}>
            <td>{mtr.invnumber}</td>
            <td>{mtr.uns}</td>
            <td>{mtr.name}</td>
            <td>{mtr.drawing1}</td>
            <td>{mtr.drawing1}</td>
            <td>{mtr.unit}</td>
            <td>{mtr.countplan}</td>
            <td>{mtr.delivery}</td>
            <td>{mtr.dateorder}</td>
            <td>{mtr.specification}</td>

            <td>{mtr.statusspec}</td>
            <td>{mtr.countnull}</td>
            <td>{mtr.countreserve}</td>

            <td>{mtr.datedelivery}</td>
            <td>{mtr.document}</td>
            <td>{mtr.documentr}</td>
            <td>{mtr.countfact}</td>
            <td>{mtr.act}</td>
            <td>{mtr.naklad}</td>
            <td>
              <Checkbox
                checked={mtr.received}
                onClick={() => {
                  dispatch(setRepairMtrReceived(repairId, mtr.id, 'received'))
                    .then(() => {})
                    .catch(() => {});
                }}
                ariaLabel="Отметить получение детали"
              />
            </td>
            <td>
              <Checkbox
                checked={mtr.included}
                onClick={() => {
                  dispatch(setRepairMtrIncluded(repairId, mtr.id, 'included'))
                    .then(() => {})
                    .catch(() => {});
                }}
                ariaLabel="Отметить включение детали в КС-2"
              />
            </td>
            <td>
              <Checkbox
                checked={mtr.charged}
                onClick={() => {
                  dispatch(setRepairMtrCharged(repairId, mtr.id, 'charged'))
                    .then(() => {})
                    .catch(() => {});
                }}
                ariaLabel="Отметить списание детали"
              />
            </td>
            <td>{mtr.comment}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

TableRepairMtr.propTypes = {
  repairId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  mtrList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      invnumber: PropTypes.string,
      uns: PropTypes.string,
      name: PropTypes.string,
      drawing1: PropTypes.string,
      unit: PropTypes.string,
      countplan: PropTypes.string,
      delivery: PropTypes.string,
      dateorder: PropTypes.string,
      specification: PropTypes.string,
      statusspec: PropTypes.string,
      countnull: PropTypes.string,
      countreserve: PropTypes.string,
      datedelivery: PropTypes.string,
      document: PropTypes.string,
      documentr: PropTypes.string,
      countfact: PropTypes.string,
      act: PropTypes.string,
      naklad: PropTypes.string,
      received: PropTypes.bool,
      included: PropTypes.bool,
      charged: PropTypes.bool,
      comment: PropTypes.string,
    }),
  ).isRequired,
};

export default connect()(TableRepairMtr);
