import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import FormGroup from 'components/FormGroup/FormGroup';
import InputForm from 'components/InputForm/InputForm';
import SubmitButton from 'components/SubmitButton/SubmitButton';

// Actions
import { logIn } from 'actions';

// Styles
import './Authorization.scss';

const Authorization = ({ dispatch }) => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});

  const signIn = () => {
    setIsLoading(true);
    dispatch(logIn(login, password))
      .then(() => {
        setIsLoading(false);
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        }
        setIsLoading(false);
      });
  };

  return (
    <div className="auth-page">
      <div className="auth-page__container container">
        <div className="auth-page__form-wrap">
          <h3 className="auth-page__title">Авторизуйтесь, пожалуйста</h3>

          <FormGroup title="Логин" error={fieldErrors.email}>
            <InputForm
              type="text"
              value={login}
              onChange={({ target }) => setLogin(target.value)}
            />
          </FormGroup>

          <FormGroup title="Пароль" error={fieldErrors.password}>
            <InputForm
              type="password"
              value={password}
              onChange={({ target }) => setPassword(target.value)}
            />
          </FormGroup>

          <div className="auth-page__form-footer">
            <SubmitButton
              isValid={login === '' || password === ''}
              clickHandler={signIn}
              isLoading={isLoading}
              isLoadingText="Загружаю..."
            >
              Продолжить
            </SubmitButton>
          </div>
        </div>
      </div>
    </div>
  );
};

Authorization.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(Authorization);
