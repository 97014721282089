import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import ModalError from 'components/ModalError/ModalError';
import FormGroup from 'components/FormGroup/FormGroup';
import InputForm from 'components/InputForm/InputForm';
import SelectForm from 'components/SelectForm/SelectForm';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import Loader from 'components/Loader/Loader';

// Actions
import { addAdminUser, getAdminUsersRoles } from 'actions';

const CreateUserModal = ({ closeHandler, roles, dispatch }) => {
  const [userName, setUserName] = useState('');
  const [userNumber, setUserNumber] = useState('');
  const [userRole, setUserRole] = useState(null);
  const [userStage, setUserStage] = useState('');
  const [userDepartment, setUserDepartment] = useState('');
  const [userOp, setUserOp] = useState('');
  const [userLogin, setUserLogin] = useState('');
  const [userPassword, setUserPassword] = useState('');

  const [isModalLoading, setIsModalLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  useEffect(() => {
    dispatch(getAdminUsersRoles())
      .then(usersRoles => {
        setUserRole(usersRoles.map(item => ({ value: item.id, label: item.name }))[0]);
        setIsModalLoading(false);
      })
      .catch(() => setIsModalLoading(false));
  }, [dispatch]);

  const submitUser = () => {
    setIsSubmitLoading(true);

    dispatch(
      addAdminUser(
        userName,
        userNumber,
        userRole.value,
        userStage,
        userDepartment,
        userOp,
        userLogin,
        userPassword,
      ),
    )
      .then(() => {
        setIsSubmitLoading(false);

        closeHandler();
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsSubmitLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Добавление пользователя" />

      {isModalLoading && <Loader className="loader_modal" />}

      {!isModalLoading && roles && (
        <ModalBody>
          <FormGroup title="Фамилия И.О." error={fieldErrors.name}>
            <InputForm
              type="text"
              value={userName}
              onChange={({ target }) => setUserName(target.value)}
              placeholder="Например, Иванов Д.И."
            />
          </FormGroup>

          <FormGroup title="Табельный номер" error={fieldErrors.number}>
            <InputForm
              type="text"
              value={userNumber}
              onChange={({ target }) => setUserNumber(target.value)}
            />
          </FormGroup>

          <FormGroup title="Роль" error={fieldErrors.role}>
            <SelectForm
              selectedValue={userRole}
              options={roles.map(item => ({ value: item.id, label: item.name }))}
              onChange={selectedOption => setUserRole(selectedOption)}
            />
          </FormGroup>

          <FormGroup title="Разряд" error={fieldErrors.stage}>
            <InputForm
              type="text"
              value={userStage}
              onChange={({ target }) => setUserStage(target.value)}
            />
          </FormGroup>

          <FormGroup title="Принадлежность к участку" error={fieldErrors.department}>
            <InputForm
              type="text"
              value={userDepartment}
              onChange={({ target }) => setUserDepartment(target.value)}
            />
          </FormGroup>

          <FormGroup title="Принадлежность к ОП" error={fieldErrors.op}>
            <InputForm
              type="text"
              value={userOp}
              onChange={({ target }) => setUserOp(target.value)}
            />
          </FormGroup>

          <FormGroup title="Логин (E-mail)" error={fieldErrors.login}>
            <InputForm
              type="text"
              value={userLogin}
              onChange={({ target }) => setUserLogin(target.value)}
            />
          </FormGroup>

          <FormGroup title="Пароль" error={fieldErrors.password}>
            <InputForm
              type="password"
              value={userPassword}
              onChange={({ target }) => setUserPassword(target.value)}
            />
          </FormGroup>
        </ModalBody>
      )}

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isLoading={isSubmitLoading}
          isValid={userName === '' || userNumber === '' || !userRole}
          isLoadingText="Отправка..."
          clickHandler={submitUser}
        >
          Создать
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

CreateUserModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
};

const mapStateToProps = state => ({
  roles: state.mainReducer.adminUsersRoles,
});

export default connect(mapStateToProps)(CreateUserModal);
