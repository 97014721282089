import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import InputForm from 'components/InputForm/InputForm';
import SelectForm from 'components/SelectForm/SelectForm';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import Loader from 'components/Loader/Loader';
import ModalError from 'components/ModalError/ModalError';

// Actions
import {
  getRmmEmploymentWorkNodes,
  addRmmEmploymentWork,
  getRmmEmploymentWorkerWorks,
} from 'actions';

const AddWorkRmmModal = ({ closeHandler, dispatch, workerId }) => {
  const [workName, setWorkName] = useState('');
  const [workNodes, setWorkNodes] = useState(null);
  const [workNode, setWorkNode] = useState(null);

  const [isModalLoading, setIsModalLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  useEffect(() => {
    dispatch(getRmmEmploymentWorkNodes())
      .then(result => {
        if (result && Array.isArray(result) && result.length > 0) {
          setWorkNodes(result);
          setWorkNode(result.map(node => ({ label: node.name, value: node.id }))[0]);
        }

        setIsModalLoading(false);
      })
      .catch(() => setIsModalLoading(false));
  }, [dispatch]);

  const submitWork = () => {
    setIsSubmitLoading(true);

    dispatch(addRmmEmploymentWork(workerId, workName, workNode.value))
      .then(() => dispatch(getRmmEmploymentWorkerWorks(workerId)))
      .then(() => {
        setIsSubmitLoading(false);

        closeHandler();
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsSubmitLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Добавить работу" />

      {isModalLoading && <Loader className="loader_modal" />}

      {!isModalLoading && workNode && workNodes && (
        <ModalBody>
          <FormGroup title="Наименование работ:" error={fieldErrors.work}>
            <InputForm
              type="text"
              value={workName}
              onChange={({ target }) => setWorkName(target.value)}
            />
          </FormGroup>

          <FormGroup title="Узел:" error={fieldErrors.node}>
            <SelectForm
              selectedValue={workNode}
              options={workNodes.map(node => ({ label: node.name, value: node.id }))}
              onChange={selectedOption => setWorkNode(selectedOption)}
            />
          </FormGroup>
        </ModalBody>
      )}

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isLoading={isSubmitLoading}
          isValid={workName === '' || !workNode}
          isLoadingText="Добавление..."
          clickHandler={submitWork}
        >
          Добавить
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

AddWorkRmmModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  workerId: PropTypes.number.isRequired,
};

export default connect()(AddWorkRmmModal);
