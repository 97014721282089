import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Styles
import './NoNarListReport.scss';

const NoNarListReport = ({ noNar }) => {
  return (
    <ul className="no-nar-list-report">
      {Array.isArray(noNar) &&
        noNar.map(item => (
          <li key={item.id} className="no-nar-list-report__item">
            <div className="no-nar-list-report__item-info">
              <Link to={`/repair/${item.id}/docs`} className="no-nar-list-report__item-link">
                {item.ksname} {item.gpa} №{item.stationnumber}
              </Link>
            </div>
            <div className="no-nar-list-report__item-tools">
              <span className="label label_primary">{item.master_1}</span>
            </div>
          </li>
        ))}
    </ul>
  );
};

NoNarListReport.propTypes = {
  noNar: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      ksname: PropTypes.string,
      gpa: PropTypes.string,
      stationnumber: PropTypes.string,
      master_1: PropTypes.string,
    }),
  ),
};

NoNarListReport.defaultProps = {
  noNar: null,
};

export default NoNarListReport;
