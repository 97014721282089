import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// Icons
import { FaCheck } from 'react-icons/fa';

// Styles
import './Checkbox.scss';

const Checkbox = ({
  onClick,
  checked,
  className,
  disabled,
  ariaLabel,
  labelLeft,
  labelRight,
  ...dataAttrs
}) => (
  <button
    role="checkbox"
    aria-checked={checked}
    className={cx('checkbox', {
      [className]: className,
    })}
    type="button"
    onClick={onClick}
    disabled={disabled}
    aria-label={ariaLabel}
    {...dataAttrs}
  >
    {labelLeft && <React.Fragment>{labelLeft} </React.Fragment>}
    <span className="checkbox__icon">
      {checked === true && <FaCheck className="checkbox__checked" />}
    </span>
    {labelRight && <React.Fragment> {labelRight}</React.Fragment>}
  </button>
);

Checkbox.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string.isRequired,
  labelLeft: PropTypes.string,
  labelRight: PropTypes.string,
};

Checkbox.defaultProps = {
  className: null,
  checked: false,
  disabled: false,
  labelLeft: null,
  labelRight: null,
};

export default Checkbox;
