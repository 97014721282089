import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import InputForm from 'components/InputForm/InputForm';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { setEmploymentUserContacts } from 'actions';

const ContactsEmployeeModal = ({ closeHandler, user, departmentId, dispatch }) => {
  const [phone, setPhone] = useState(user.phone);
  const [email, setEmail] = useState(user.email);

  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const submitUserContacts = () => {
    setIsLoading(true);

    dispatch(setEmploymentUserContacts(user.id, departmentId, email, phone))
      .then(() => {
        setIsLoading(false);

        closeHandler();
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Контактные данные" />

      <ModalBody>
        <FormGroup title="Телефон" error={fieldErrors.phone}>
          <InputForm type="text" value={phone} onChange={({ target }) => setPhone(target.value)} />
        </FormGroup>

        <FormGroup title="E-mail" error={fieldErrors.email}>
          <InputForm type="text" value={email} onChange={({ target }) => setEmail(target.value)} />
        </FormGroup>
      </ModalBody>

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isLoading={isLoading}
          isValid={phone === '' || email === ''}
          isLoadingText="Отправка..."
          clickHandler={submitUserContacts}
        >
          Сохранить
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

ContactsEmployeeModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    phone: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  departmentId: PropTypes.number.isRequired,
};

export default connect()(ContactsEmployeeModal);
