import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import TableRepairNode from 'components/TableRepairNode/TableRepairNode';
import Modal from 'components/Modal/Modal';
import AddNodeModal from 'components/AddNodeModal/AddNodeModal';
import Loader from 'components/Loader/Loader';
import Warning from 'components/Warning/Warning';

// Actions
import { getRepairNodes } from 'actions';

const RepairNode = ({ user, canEdit, dispatch, repairId, nodes, rmmList }) => {
  const [isAddNodeActive, setAddNodeActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const activateAddNodeModal = () => {
    setAddNodeActive(true);
  };

  const deactivateAddNodeModal = () => {
    setAddNodeActive(false);
  };

  /** Запрашиваем информацию по ремонту (МТР) */
  useEffect(() => {
    dispatch(getRepairNodes(repairId))
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch, repairId]);

  return (
    <React.Fragment>
      <div className="repair-content__header">
        <h2 className="repair-content__title">Узловой ремонт</h2>

        {canEdit && (
          <div className="repair-content__tools">
            <button type="button" className="btn btn-primary" onClick={activateAddNodeModal}>
              Добавить
            </button>

            {isAddNodeActive && (
              <Modal title="Добавление узла" closeHandler={deactivateAddNodeModal}>
                <AddNodeModal
                  closeHandler={deactivateAddNodeModal}
                  repairId={repairId}
                  rmmList={rmmList}
                />
              </Modal>
            )}
          </div>
        )}
      </div>

      {isLoading && <Loader />}

      {!isLoading && nodes && Array.isArray(nodes) && nodes.length > 0 && (
        <TableRepairNode
          canEdit={canEdit}
          nodes={nodes}
          repairId={repairId}
          userRole={user.userRole}
        />
      )}

      {!isLoading && nodes && Array.isArray(nodes) && nodes.length <= 0 && (
        <Warning msg="Нет узлов в ремонте" />
      )}
    </React.Fragment>
  );
};

RepairNode.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  repairId: PropTypes.number.isRequired,
  nodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rmmList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({
    userRole: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = state => ({
  nodes: state.mainReducer.repairNodes,
  rmmList: state.mainReducer.repairRmmList,
  user: state.mainReducer.user,
});

export default connect(mapStateToProps)(RepairNode);
