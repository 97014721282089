import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Styles
import './ModalHeader.scss';

const ModalHeader = ({ title, children, className }) => {
  return (
    <React.Fragment>
      {children ? (
        <header
          className={cx('modal-header', {
            [className]: className,
          })}
        >
          {children}
        </header>
      ) : (
        <header className="modal-header">
          <h4 className="modal-header__title">{title}</h4>
        </header>
      )}
    </React.Fragment>
  );
};

ModalHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

ModalHeader.defaultProps = {
  title: null,
  children: null,
  className: null,
};

export default ModalHeader;
