import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import Modal from 'components/Modal/Modal';
import AddWorkFormModal from 'components/AddWorkFormModal/AddWorkFormModal';
import CloseRepairModal from 'components/CloseRepairModal/CloseRepairModal';
import FinishRepairModal from 'components/FinishRepairModal/FinishRepairModal';
import TableRepairWorks from 'components/TableRepairWorks/TableRepairWorks';
import Loader from 'components/Loader/Loader';
import Warning from 'components/Warning/Warning';

// Actions
import { getRepairWorkProgress } from 'actions';

const RepairWorkProgress = ({
  canEdit,
  open,
  closed,
  finished,
  toother,
  userRole,
  dispatch,
  repairId,
  repairWorkProgress,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAddWorkActive, setAddWorkActive] = useState(false);
  const [isFinishRepairActive, setFinishRepairActive] = useState(false);
  const [isCloseRepairActive, setCloseRepairActive] = useState(false);

  /** Запрашиваем информацию по ремонту (Ход работ) */
  useEffect(() => {
    dispatch(getRepairWorkProgress(repairId))
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch, repairId]);

  const activateAddWorkFormModal = () => {
    setAddWorkActive(true);
  };

  const deactivateAddWorkFormModal = () => {
    setAddWorkActive(false);
  };

  const activateFinishRepairFormModal = () => {
    setFinishRepairActive(true);
  };

  const deactivateFinishRepairFormModal = () => {
    setFinishRepairActive(false);
  };

  const activateCloseRepairFormModal = () => {
    setCloseRepairActive(true);
  };

  const deactivateCloseRepairFormModal = () => {
    setCloseRepairActive(false);
  };

  return (
    <React.Fragment>
      <div className="repair-content__header">
        <h2 className="repair-content__title">Ход работ</h2>

        {canEdit && (
          <div className="repair-content__tools">
            {open && !closed && (
              <button type="button" className="btn btn-primary" onClick={activateAddWorkFormModal}>
                Добавить работу
              </button>
            )}

            {open && !finished && (
              <button
                type="button"
                className="btn btn-danger"
                onClick={activateFinishRepairFormModal}
              >
                Закончить ремонт
              </button>
            )}

            {open && finished && toother && (
              <button
                type="button"
                className="btn btn-danger"
                onClick={activateFinishRepairFormModal}
              >
                Закончить прочий ремонт
              </button>
            )}

            {open &&
              finished &&
              !closed &&
              !toother &&
              (userRole === 6 || userRole === 10 || userRole === 14) && (
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={activateCloseRepairFormModal}
                >
                  Закрыть ремонт
                </button>
              )}
          </div>
        )}
      </div>

      {isAddWorkActive && canEdit && (
        <Modal title="Добавление работы" closeHandler={deactivateAddWorkFormModal}>
          <AddWorkFormModal repairId={repairId} closeHandler={deactivateAddWorkFormModal} />
        </Modal>
      )}

      {isCloseRepairActive && open && finished && (
        <Modal title="Закрыть ремонт" closeHandler={deactivateCloseRepairFormModal}>
          <CloseRepairModal repairId={repairId} closeHandler={deactivateCloseRepairFormModal} />
        </Modal>
      )}

      {isFinishRepairActive && (
        <Modal title="Закончить ремонт" closeHandler={deactivateFinishRepairFormModal}>
          <FinishRepairModal
            repairId={repairId}
            toother={toother}
            dateOtherFinished={repairWorkProgress.dateOtherFinished}
            closeHandler={deactivateFinishRepairFormModal}
          />
        </Modal>
      )}

      {isLoading && <Loader />}

      {!isLoading &&
        repairWorkProgress &&
        repairWorkProgress.work &&
        Array.isArray(repairWorkProgress.work) &&
        repairWorkProgress.work.length > 0 && (
          <TableRepairWorks
            repairId={repairId}
            works={repairWorkProgress.work}
            canEdit={canEdit}
            closed={closed}
          />
        )}

      {!isLoading &&
        repairWorkProgress &&
        repairWorkProgress.work &&
        Array.isArray(repairWorkProgress.work) &&
        repairWorkProgress.work.length === 0 && <Warning msg="Пока не добавлено работ" />}
    </React.Fragment>
  );
};

RepairWorkProgress.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  closed: PropTypes.bool.isRequired,
  finished: PropTypes.bool.isRequired,
  toother: PropTypes.bool.isRequired,
  userRole: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  repairId: PropTypes.number.isRequired,
  repairWorkProgress: PropTypes.shape({
    work: PropTypes.arrayOf(PropTypes.shape({})),
    dateOtherFinished: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => ({
  dispatch: state.mainReducer.dispatch,
  repairWorkProgress: state.mainReducer.repairWorkProgress,
});

export default connect(mapStateToProps)(RepairWorkProgress);
