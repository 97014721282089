import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';

// Icons
import { FaTruck, FaIdCard, FaUpload, FaUser, FaCheck, FaLevelUpAlt, FaBan } from 'react-icons/fa';

// Components
import FormGroup from 'components/FormGroup/FormGroup';
import Checkbox from 'components/Checkbox/Checkbox';

// Utils
import * as propTypes from 'utils/propTypes';
import { groupBy } from 'utils/_';
import { USER_ROLES } from 'utils/constants';

import './TableAssignTransport.scss';

const TransportRow = ({
  canEdit,
  userRole,
  showTools,
  isChecked,
  data,
  even,
  onCheckboxClick,
  onDespatchPdoApproved,
  onDespatchOmtsApproved,
  onDespatchPdoApprovedClear,
  onDespatchOmtsApprovedClear,
  onTransportAssign,
  onTransportUnAssign,
  onDriverAssign,
  onDriverUnAssign,
  onSendDespatchClick,
}) => {
  const handleTransportAssign = useCallback(() => {
    onTransportAssign(data.id, data.assignedCarId);
  }, [data, onTransportAssign]);

  const handleDriverAssign = useCallback(() => {
    onDriverAssign(data.assignedCarId, data.assignedDriverId, data.id);
  }, [data, onDriverAssign]);

  const handleTransportUnAssign = useCallback(() => {
    onTransportUnAssign(data.id, data.assignedCarId);
  }, [data, onTransportUnAssign]);

  const handleDriverUnAssign = useCallback(() => {
    onDriverUnAssign(data.assignedCarId, data.assignedDriverId, data.id);
  }, [data, onDriverUnAssign]);

  const handleSendDespatchClick = useCallback(() => {
    onSendDespatchClick(data.id);
  }, [data, onSendDespatchClick]);

  const handleDespatchPdoApprovedClick = useCallback(() => {
    // Toggle PdoApproved checkbox
    data.isPdoApproved ? onDespatchPdoApprovedClear(data.id) : onDespatchPdoApproved(data.id);
  }, [data.id, data.isPdoApproved, onDespatchPdoApproved, onDespatchPdoApprovedClear]);

  const handleDespatchOmtsApprovedClick = useCallback(() => {
    // Toggle OmtsApproved checkbox
    data.isOmtsApproved ? onDespatchOmtsApprovedClear(data.id) : onDespatchOmtsApproved(data.id)
  }, [data.id, data.isOmtsApproved, onDespatchOmtsApproved, onDespatchOmtsApprovedClear]);

  const canSend = data.isPdoApproved && data.isOmtsApproved && 
    data.status !== 'AWAITING' && 
    canEdit &&
    (userRole === USER_ROLES.ROLE_TU || userRole === USER_ROLES.ROLE_BOSSES || userRole === USER_ROLES.ROLE_ADMIN);

  return (
    <tr className={cx('table-despatch-tr', { 'table-despatch-tr__open': isChecked, 'table-despatch-tr-plus': data.isAdditional, 'table-despatch-tr__even': even })}>
      <td>
        <Link to={`/despatch/${data.id}/details`}><span className="label label_tr">{data.isAdditional ? <FaLevelUpAlt className="icon" style={{ transform: 'rotate(90deg)' }} /> : data.id}</span></Link>
      </td>
      <td>
        {false && !data.isAdditional && (
          <FormGroup title="Взят на транспортировку">
            <div>
              <Checkbox
                ariaLabel="Взят на транспортировку"
                checked={isChecked}
                onClick={onCheckboxClick}
              />
            </div>
          </FormGroup>
        )}
        {data.isAdditional ? (
          <>
            <Link to={`/despatch/${data.id}/details`}>
              <span className="label label_default mr-1">{data.id}</span>
            </Link>
            <div className="label label_default" style={{ textAlign: 'left' }}>
              Вложенное
            </div>
          </>
        ) : (
          <div className="u-d-flex">
            <span>
            <span 
              className="link-like mr-1" 
              disabled={
                userRole !== USER_ROLES.ROLE_TU && 
                userRole !== USER_ROLES.ROLE_BOSSES && 
                userRole !== USER_ROLES.ROLE_ADMIN
              }
              onClick={handleTransportAssign} 
            >
              <FaTruck className="icon" /> {data.assignedCarNumber || 'Не назнач.'}
            </span>
            {data.assignedCarNumber &&
              <span
                className="link-like mr-1"
                disabled={
                  userRole !== USER_ROLES.ROLE_TU &&
                  userRole !== USER_ROLES.ROLE_BOSSES &&
                  userRole !== USER_ROLES.ROLE_ADMIN
                }
                onClick={handleTransportUnAssign}
              > <FaBan className="icon" />
              </span>
            }</span>
            {data.assignedCarNumber && (
              <span>
                <span
                  className="link-like"
                  disabled={
                    userRole !== USER_ROLES.ROLE_TU &&
                    userRole !== USER_ROLES.ROLE_BOSSES &&
                    userRole !== USER_ROLES.ROLE_ADMIN
                  }
                  onClick={handleDriverAssign}
                >
                  <FaIdCard className="icon" /> {data.assignedDriver_1 || 'Не назнач.'}
                </span>
                {data.assignedDriver_1 && (
                <span
                  className="link-like"
                  disabled={
                    userRole !== USER_ROLES.ROLE_TU &&
                    userRole !== USER_ROLES.ROLE_BOSSES &&
                    userRole !== USER_ROLES.ROLE_ADMIN
                  }
                  onClick={handleDriverUnAssign}
                >
                   <FaBan className="icon" />
                </span>
                )}
              </span>
            )}
          </div>
        )}
      </td>
      <TransportObject value={data.dateStart_1} />
      <TransportObject value={data.dateEnd_1} />
      {/*<TransportObject key={data.id} value={data.startLocation} />*/}
      <TransportObject value={data.destLocation && data.destLocation.city} />
      <TransportObject value={data.size} />
      <TransportObject value={data.weight} />
      {(true || showTools) && (
        <>
          <td>
            <Checkbox
              onClick={handleDespatchPdoApprovedClick }
              checked={data.isPdoApproved}
              // checked={pdoApproved}
              disabled={
                userRole !== USER_ROLES.ROLE_PDO && 
                userRole !== USER_ROLES.ROLE_BOSSES && 
                userRole !== USER_ROLES.ROLE_ADMIN
              }
              ariaLabel="Поставить отметку, что заявка одобрена ПДО"
            />
          </td>
          <td>
            <Checkbox
              onClick={handleDespatchOmtsApprovedClick}
              // checked={omtsApproved}
              checked={data.isOmtsApproved}
              disabled={
                userRole !== USER_ROLES.ROLE_OMTS && 
                userRole !== USER_ROLES.ROLE_BOSSES && 
                userRole !== USER_ROLES.ROLE_ADMIN
              }
              ariaLabel="Поставить отметку, что заявка одобрена ОМТС"
              // style={{ marginRight: 4 }}
            />
          </td>
        </>
      )}
      <TransportObject value={data.recipientFullName} />
      <td>
        {Boolean(true || canEdit) && (
          <button
            disabled={!canSend}
            type="button"
            className="btn btn-sm btn-warning"
            onClick={handleSendDespatchClick}

          >
            {data.status === 'AWAITING' ? 'Отправлено' : 'Отправить'}
          </button>
        )}

        {Boolean(false && data.dateforecast_confirm && data.datesendreturn_1 !== '') && (
          <span className="label label_success">{data.dateFact ? data.dateFact_1 : 'Не назнач.'}</span>
        )}
      </td>
    </tr>
  )
};

TransportRow.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  data: propTypes.despatchInfo,
  isChecked: PropTypes.bool,
  userRole: PropTypes.number.isRequired,
  showTools: PropTypes.bool,
  onCheckboxClick: PropTypes.func.isRequired,
  onDespatchPdoApproved: PropTypes.func.isRequired,
  onDespatchOmtsApproved: PropTypes.func.isRequired,
  onDespatchPdoApprovedClear: PropTypes.func.isRequired,
  onDespatchOmtsApprovedClear: PropTypes.func.isRequired,
  onTransportAssign: PropTypes.func.isRequired,
  onTransportUnAssign: PropTypes.func.isRequired,
  onDriverAssign: PropTypes.func.isRequired,
  onDriverUnAssign: PropTypes.func.isRequired,
  onSendDespatchClick: PropTypes.func.isRequired,
};

const TransportObject = ({ value }) => (
  <td className="transport-table__to">
    <span className="label label_primary">{value}</span>
  </td>
);

const TransportAssignTable = ({
  canEdit,
  userRole,
  // userId,
  data,
  group,
  onPdoApproved,
  onOmtsApproved,
  onPdoApprovedClear,
  onOmtsApprovedClear,
  onTransportAssign,
  onTransportUnAssign,
  onDriverAssign,
  onDriverUnAssign,
  onSendDespatchClick,
}) => {
  const [despatchesByAssignedCar, setDespatchesByAssignedCar] = useState(
    groupBy(data, item => item.assignedCarId),
  );

  useEffect(() => {
    const groupedDespatches = groupBy(data, item => item.assignedCarId);

    setDespatchesByAssignedCar(groupedDespatches);
  }, [data]);

  return (
    <table className="table-transport-assign table table-bordered">
      <thead>
        <tr>                        
          <th colSpan={11} className="table-transport-assign__group-td">
            <h5 className="c-white">{group === 'carAssigned' ? 'Назначена машина' : 'Не назначена'}</h5>
          </th>
        </tr>
        <tr>
          <th rowSpan="2"> </th>
          <th rowSpan="2"> </th>
          <th rowSpan="2" className="work-table__month-th" style={{ verticalAlign: 'top' }}>
            <span className="label label_default">Дата готовности к отгрузке</span>
          </th>
          <th rowSpan="2" className="work-table__month-th" style={{ verticalAlign: 'top' }}>
            <span className="label label_default">Срок проведения перевозки</span>
          </th>
          {/*<th rowSpan="2" className="work-table__month-th" style={{ verticalAlign: 'top' }}>
            <span className="label label_default">Место отправления</span>
          </th>*/}
          <th rowSpan="2" className="work-table__month-th" style={{ verticalAlign: 'top' }}>
            <span className="label label_default">Место назначения</span>
          </th>
          <th rowSpan="2" className="work-table__month-th" style={{ verticalAlign: 'top' }}>
            <span className="label label_default">Габариты (д-ш-в)</span>
          </th>
          <th rowSpan="2" className="work-table__month-th" style={{ verticalAlign: 'top' }}>
            <span className="label label_default">Вес (кг)</span>
          </th>
          <th colSpan="2" className="work-table__month-th" style={{ textAlign: 'center' }}>
            <abbr title="Подтвердили в отделах">
              <FaCheck className="icon" /> <span className="label label_default">Подтвердили</span>
            </abbr>
          </th>
          <th rowSpan="2" className="work-table__month-th" style={{ verticalAlign: 'top' }}>
            <abbr title="Получатель груза">
              <FaUser className="icon" /> <span className="label label_default">Получатель</span> 
            </abbr>
          </th>
          {/*<th className="work-table__month-th">
            <span className="label label_default">Статусы</span>
          </th>*/}
          <th rowSpan="2" className="work-table__month-th" style={{ verticalAlign: 'top' }}>
            <abbr title="Дата отправки">
              <FaUpload className="icon" /> <span className="label label_default">Отправка</span>
            </abbr>
          </th>
        </tr>
        <tr>
          <th className="work-table__month-th">
            <span className="label label_default">ПДО</span>
          </th>
          <th className="work-table__month-th">
            <span className="label label_default">ОМТС</span>
          </th>
        </tr>
      </thead>

      <tbody>
        {!!(despatchesByAssignedCar &&
          Object.keys(despatchesByAssignedCar).length) &&
          Object.keys(despatchesByAssignedCar).map((assignedCarId, i) => (
            <>
              <tr key={assignedCarId}>
                <td colSpan={11} style={{ backgroundColor: '#ffeb3b' }}>
                  <FaTruck className="icon" />
                  &nbsp;
                  {despatchesByAssignedCar[assignedCarId][0].assignedCarNumber || 'Не назнач.'} - {despatchesByAssignedCar[assignedCarId][0].assignedCarName}
                </td>
              </tr>
              {despatchesByAssignedCar[assignedCarId].map(despatch => (
                <>
                  <TransportRow
                    canEdit={canEdit}
                    userRole={userRole}
                    key={despatch.id}
                    data={despatch}
                    even={i % 2 === 0 && group === 'carAssigned'}
                    // isChecked={despatch.assignedCarId}
                    onDespatchPdoApproved={onPdoApproved}
                    onDespatchPdoApprovedClear={onPdoApprovedClear}
                    onDespatchOmtsApproved={onOmtsApproved}
                    onDespatchOmtsApprovedClear={onOmtsApprovedClear}
                    onTransportAssign={onTransportAssign}
                    onTransportUnAssign={onTransportUnAssign}
                    onDriverAssign={onDriverAssign}
                    onDriverUnAssign={onDriverUnAssign}
                    onSendDespatchClick={onSendDespatchClick}
                    // onCheckboxClick={() => handleTransportClick(despatch.id)}
                    onCheckboxClick={() => {}}
                  />
                  {!!(despatch.additionalDespatches &&
                    despatch.additionalDespatches.length) &&
                    despatch.additionalDespatches.map(addDespatch => (
                      <TransportRow
                        canEdit={canEdit}
                        userRole={userRole}
                        key={addDespatch.id}
                        data={addDespatch}
                        even={i % 2 === 0 && group === 'carAssigned'}
                        // isChecked={despatch.assignedCarId}
                        onDespatchPdoApproved={onPdoApproved}
                        onDespatchPdoApprovedClear={onPdoApprovedClear}
                        onDespatchOmtsApproved={onOmtsApproved}
                        onDespatchOmtsApprovedClear={onOmtsApprovedClear}
                        onTransportAssign={onTransportAssign}
                        onTransportUnAssign={onTransportUnAssign}
                        onDriverAssign={onDriverAssign}
                        onDriverUnAssign={onDriverUnAssign}
                        onSendDespatchClick={onSendDespatchClick}
                        // onCheckboxClick={() => handleTransportClick(addDespatch.id)}
                        onCheckboxClick={() => {}}
                      />
                    ))}
                </>
              ))}
            </>
          ))}
      </tbody>
    </table>
  );
};


TransportAssignTable.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  userRole: PropTypes.number.isRequired,
  // userId: PropTypes.number.isRequired,
  data: propTypes.despatchInfo.isRequired,
  group: PropTypes.string.isRequired,
  onPdoApproved: PropTypes.func.isRequired,
  onOmtsApproved: PropTypes.func.isRequired,
  onPdoApprovedClear: PropTypes.func.isRequired,
  onOmtsApprovedClear: PropTypes.func.isRequired,
  onTransportAssign: PropTypes.func.isRequired,
  onTransportUnAssign: PropTypes.func.isRequired,
  onDriverAssign: PropTypes.func.isRequired,
  onDriverUnAssign: PropTypes.func.isRequired,
  onSendDespatchClick: PropTypes.func.isRequired,
};

export default TransportAssignTable;
