import React from 'react';
import PropTypes from 'prop-types';

// Icons
import { FaCheck } from 'react-icons/fa';

// Styles
import './TableMtrList.scss';

const TableMtrList = ({ items }) => {
  return (
    <table className="table table-hover table-condensed table-bordered table-mtr-list">
      <thead>
        <tr className="info">
          <th>Заказчик</th>
          <th>ТИП ГПА</th>
          <th>Инв номер</th>
          <th>Объект</th>
          <th>УНС</th>
          <th>Номенклатура</th>
          <th>Чертеж 1</th>
          <th>Чертеж 2</th>
          <th>Ед. изм.</th>
          <th>Кол-во</th>
          <th>Способ пост.</th>
          <th>Дата заявки</th>
          <th>Спецификация</th>

          <th>Статус спецификации</th>
          <th>Кол-во аннулирования</th>
          <th>Кол-во резервирования</th>

          <th>Дата пост</th>
          <th>Док. пост. (з/ч на ЦС г. Москва)</th>
          <th>Док. реал. (з/ч на складе в ЕКБ)</th>
          <th>Кол-во пост.</th>
          <th>Акт</th>
          <th>№ накладной (з/ч на участке)</th>
          <th>Деталь получена</th>
          <th>Деталь включена в КС-2</th>
          <th>Деталь списана</th>
          <th>Прим.</th>
        </tr>
      </thead>

      <colgroup>
        <col style={{ width: '66px' }} />
        <col style={{ width: '90px' }} />
        <col style={{ width: '54px' }} />
        <col style={{ width: '105px' }} />
        <col style={{ width: '61px' }} />
        <col style={{ width: '132px' }} />
        <col style={{ width: '132px' }} />
        <col style={{ width: '132px' }} />
        <col style={{ width: '49px' }} />
        <col style={{ width: '38px' }} />
        <col style={{ width: '126px' }} />
        <col style={{ width: '53px' }} />
        <col style={{ width: '102px' }} />
        <col style={{ width: '101px' }} />
        <col style={{ width: '105px' }} />
        <col style={{ width: '112px' }} />
        <col style={{ width: '66px' }} />
        <col style={{ width: '78px' }} />
        <col style={{ width: '87px' }} />
        <col style={{ width: '41px' }} />
        <col style={{ width: '32px' }} />
        <col style={{ width: '77px' }} />
        <col style={{ width: '69px' }} />
        <col style={{ width: '71px' }} />
        <col style={{ width: '60px' }} />
        <col style={{ width: '47px' }} />
      </colgroup>

      <tbody>
        {items.map(item => (
          <tr key={item.id}>
            <td>{item.orderman}</td>
            <td>{item.gpa}</td>
            <td>{item.invnumber}</td>
            <td>{item.object}</td>
            <td>{item.uns}</td>
            <td>{item.name}</td>
            <td>{item.drawing1}</td>
            <td>{item.drawing1}</td>
            <td>{item.unit}</td>
            <td>{item.countplan}</td>
            <td>{item.delivery}</td>
            <td>{item.dateorder}</td>
            <td>{item.specification}</td>

            <td>{item.statusspec}</td>
            <td>{item.countnull}</td>
            <td>{item.countreserve}</td>

            <td>{item.datedelivery}</td>
            <td>{item.document}</td>
            <td>{item.documentr}</td>
            <td>{item.countfact}</td>
            <td>
              {!item.haveActFile && item.act}
              {item.haveActFile && <a href={item.haveActFile}>{item.act}</a>}
            </td>
            <td>{item.naklad}</td>
            <td className="table-mtr-list__td table-mtr-list__td_align-center">
              {item.received && <FaCheck className="icon" />}
            </td>
            <td className="table-mtr-list__td table-mtr-list__td_align-center">
              {item.included && <FaCheck className="icon" />}
            </td>
            <td className="table-mtr-list__td table-mtr-list__td_align-center">
              {item.charged && <FaCheck className="icon" />}
            </td>
            <td>{item.comment}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

TableMtrList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      orderman: PropTypes.string,
      gpa: PropTypes.string,
      invnumber: PropTypes.string,
      object: PropTypes.string,
      uns: PropTypes.string,
      name: PropTypes.string,
      drawing1: PropTypes.string,
      unit: PropTypes.string,
      countplan: PropTypes.string,
      delivery: PropTypes.string,
      dateorder: PropTypes.string,
      specification: PropTypes.string,
      statusspec: PropTypes.string,
      countnull: PropTypes.string,
      countreserve: PropTypes.string,
      datedelivery: PropTypes.string,
      document: PropTypes.string,
      documentr: PropTypes.string,
      countfact: PropTypes.string,
      haveActFile: PropTypes.string,
      act: PropTypes.string,
      naklad: PropTypes.string,
      received: PropTypes.bool,
      included: PropTypes.bool,
      charged: PropTypes.bool,
      comment: PropTypes.string,
    }),
  ),
};

TableMtrList.defaultProps = {
  items: null,
};

export default TableMtrList;
