import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import InputForm from 'components/InputForm/InputForm';
import Checkbox from 'components/Checkbox/Checkbox';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { changeRepairDocNoNeed, setRepairUnitWorkHours } from 'actions';

// Styles
import './UnitStatistics.scss';

const UnitStatistics = ({ userRole, repairId, dispatch, nar1, nar2, nar3, nar4, nar5 }) => {
  const [nar1Stat, setNar1Stat] = useState(nar1.value);
  const [nar2Stat, setNar2Stat] = useState(nar2.value);
  const [nar3Stat, setNar3Stat] = useState(nar3.value);
  const [nar4Stat, setNar4Stat] = useState(nar4.value);
  const [nar5Stat, setNar5Stat] = useState(nar5.value);

  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const submitUnitStatistics = () => {
    setIsLoading(true);

    dispatch(setRepairUnitWorkHours(repairId, nar1Stat, nar2Stat, nar3Stat, nar4Stat, nar5Stat))
      .then(() => {
        setIsLoading(false);
      })
      .catch(error => {
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        setIsLoading(false);
      });
  };

  return (
    <div className="panel panel_default">
      <div className="panel__heading">Наработка агрегата</div>
      <div className="panel__body">
        <div className="unit-statistics">
          <div className="unit-statistics__name">ГПА: наработка с начала эксплуатации</div>
          <div className="unit-statistics__body">
            <div className="unit-statistics__body-col unit-statistics__body-col_main">
              <InputForm
                type="text"
                value={nar1Stat}
                onChange={({ target }) => setNar1Stat(target.value)}
              />
              {fieldErrors && fieldErrors.nar1 && (
                <p className="unit-statistics__error">{fieldErrors.nar1}</p>
              )}
            </div>

            {(userRole === 10 || userRole === 14) && (
              <div className="unit-statistics__body-col unit-statistics__body-col_right">
                <Checkbox
                  checked={nar1.noneed}
                  onClick={() => {
                    dispatch(changeRepairDocNoNeed(repairId, 'nar1', !nar1.noneed))
                      .then(() => {})
                      .catch(() => {});
                  }}
                  ariaLabel="Отметить как необязательное поле"
                />{' '}
                Необязательное поле
              </div>
            )}
          </div>
        </div>

        <div className="unit-statistics">
          <div className="unit-statistics__name">ГПА: наработка после кап. ремонта</div>
          <div className="unit-statistics__body">
            <div className="unit-statistics__body-col unit-statistics__body-col_main">
              <InputForm
                type="text"
                value={nar2Stat}
                onChange={({ target }) => setNar2Stat(target.value)}
              />
              {fieldErrors && fieldErrors.nar2 && (
                <p className="unit-statistics__error">{fieldErrors.nar2}</p>
              )}
            </div>

            {(userRole === 10 || userRole === 14) && (
              <div className="unit-statistics__body-col unit-statistics__body-col_right">
                <Checkbox
                  checked={nar2.noneed}
                  onClick={() => {
                    dispatch(changeRepairDocNoNeed(repairId, 'nar2', !nar2.noneed))
                      .then(() => {})
                      .catch(() => {});
                  }}
                  ariaLabel="Отметить как необязательное поле"
                />{' '}
                Необязательное поле
              </div>
            )}
          </div>
        </div>

        <div className="unit-statistics">
          <div className="unit-statistics__name">ЦБН: наработка после кап. ремонта</div>
          <div className="unit-statistics__body">
            <div className="unit-statistics__body-col unit-statistics__body-col_main">
              <InputForm
                type="text"
                value={nar3Stat}
                onChange={({ target }) => setNar3Stat(target.value)}
              />
              {fieldErrors && fieldErrors.nar3 && (
                <p className="unit-statistics__error">{fieldErrors.nar3}</p>
              )}
            </div>

            {(userRole === 10 || userRole === 14) && (
              <div className="unit-statistics__body-col unit-statistics__body-col_right">
                <Checkbox
                  checked={nar3.noneed}
                  onClick={() => {
                    dispatch(changeRepairDocNoNeed(repairId, 'nar3', !nar3.noneed))
                      .then(() => {})
                      .catch(() => {});
                  }}
                  ariaLabel="Отметить как необязательное поле"
                />{' '}
                Необязательное поле
              </div>
            )}
          </div>
        </div>

        <div className="unit-statistics">
          <div className="unit-statistics__name">ГТД: наработка с начала эксплуатации</div>
          <div className="unit-statistics__body">
            <div className="unit-statistics__body-col unit-statistics__body-col_main">
              <InputForm
                type="text"
                value={nar4Stat}
                onChange={({ target }) => setNar4Stat(target.value)}
              />
              {fieldErrors && fieldErrors.nar4 && (
                <p className="unit-statistics__error">{fieldErrors.nar4}</p>
              )}
            </div>
          </div>
        </div>

        <div className="unit-statistics">
          <div className="unit-statistics__name">ГТД: наработка после кап. ремонта</div>
          <div className="unit-statistics__body">
            <div className="unit-statistics__body-col unit-statistics__body-col_main">
              <InputForm
                type="text"
                value={nar5Stat}
                onChange={({ target }) => setNar5Stat(target.value)}
              />
              {fieldErrors && fieldErrors.nar5 && (
                <p className="unit-statistics__error">{fieldErrors.nar5}</p>
              )}
            </div>
          </div>
        </div>

        {responseError && (
          <ModalError className="unit-statistics__error">{responseError}</ModalError>
        )}

        <div className="unit-statistics">
          <SubmitButton
            isLoading={isLoading}
            isLoadingText="Сохраняю..."
            clickHandler={submitUnitStatistics}
          >
            Сохранить
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};

const narTypes1 = PropTypes.shape({
  noneed: PropTypes.bool,
  value: PropTypes.string,
});

const narTypes2 = PropTypes.shape({
  value: PropTypes.string,
});

UnitStatistics.propTypes = {
  userRole: PropTypes.number.isRequired,
  repairId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  nar1: narTypes1.isRequired,
  nar2: narTypes1.isRequired,
  nar3: narTypes1.isRequired,
  nar4: narTypes2.isRequired,
  nar5: narTypes2.isRequired,
};

export default connect()(UnitStatistics);
