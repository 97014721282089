import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';

// Icons
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

// Components
import Modal from 'components/Modal/Modal';
import EditWorkFormModal from 'components/EditWorkFormModal/EditWorkFormModal';
import RemoveWorkConfirmModal from 'components/RemoveWorkConfirmModal/RemoveWorkConfirmModal';
import WorkWeek from 'components/WorkWeek/WorkWeek';

// Actions
import { getRepairWorkProgress, setRepairWorkWeek } from 'actions';

// Styles
import './TableRepairWorks.scss';

const TableRepairWorks = ({ works, canEdit, closed, repairId, dispatch }) => {
  const [editWorkItem, setEditWorkItem] = useState(null);
  const [removeWorkId, setRemoveWorkId] = useState(null);
  const [fieldErrors, setFieldErrors] = useState(null);

  const [isEditWorkActive, setEditWorkActive] = useState(false);
  const [isRemoveWorkConfirmActive, setRemoveWorkConfirmActive] = useState(false);

  const activateEditWorkFormModal = item => {
    setEditWorkItem(item);
    setEditWorkActive(true);
  };

  const deactivateEditWorkFormModal = () => {
    setEditWorkActive(false);
  };

  const activateRemoveWorkConfirmModal = id => {
    setRemoveWorkId(id);
    setRemoveWorkConfirmActive(true);
  };

  const deactivateRemoveWorkConfirmModal = () => {
    setRemoveWorkConfirmActive(false);
  };

  const submitRepairWorkWeek = (week, text) => {
    /** Записываем отредактированную работу в базу данных */
    return dispatch(setRepairWorkWeek(week, repairId, text))
      .then(() => {
        /** Запрашиваем обновленный список работ */
        return dispatch(getRepairWorkProgress(repairId));
      })
      .catch(error => {
        /** Если есть ошибки, выводим */
        if (error && error.error) {
          setFieldErrors(error.error);
        } else {
          setFieldErrors('Произошла неизвестная ошибка');
        }
      });
  };

  return (
    <table className="table table-bordered table-striped table-repair-works">
      <colgroup>
        {canEdit && (
          <React.Fragment>
            <col style={{ width: '20%' }} />
            <col style={{ width: '60%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
          </React.Fragment>
        )}

        {!canEdit && (
          <React.Fragment>
            <col style={{ width: '20%' }} />
            <col style={{ width: '70%' }} />
            <col style={{ width: '10%' }} />
          </React.Fragment>
        )}
      </colgroup>

      <thead>
        <tr>
          <th>Дата</th>
          <th>Работы</th>
          <th>+/-</th>
          {canEdit && <th> </th>}
        </tr>
      </thead>
      <tbody>
        {works.map(work => (
          <React.Fragment key={work.id || work.realdate_1 || work.week}>
            {work.week && (
              <WorkWeek
                submit={submitRepairWorkWeek}
                canEdit={canEdit}
                repairId={repairId}
                week={work.week}
                fieldErrors={fieldErrors}
              />
            )}

            {!work.realdate_1 && (
              <tr>
                <td>
                  <span className="label label_primary">{work.date_day}</span> {work.date_1}
                </td>
                <td>{work.name}</td>
                <td>
                  <span
                    className={cx('label', {
                      label_danger: work.accordance < 0,
                      label_primary: work.accordance === 0,
                      label_success: work.accordance > 0,
                    })}
                  >
                    {work.accordance}
                  </span>
                </td>

                {canEdit && (
                  <td>
                    {work.canEdit && (
                      <React.Fragment>
                        <button
                          type="button"
                          className="btn btn-primary btn-xs"
                          title="Редактировать"
                          onClick={() => {
                            activateEditWorkFormModal(work);
                          }}
                        >
                          <FaEdit className="icon" />
                        </button>{' '}
                        <button
                          type="button"
                          className="btn btn-danger btn-xs"
                          title="Удалить"
                          onClick={() => activateRemoveWorkConfirmModal(work.id)}
                        >
                          <FaTrashAlt className="icon" />
                        </button>
                      </React.Fragment>
                    )}
                  </td>
                )}
              </tr>
            )}

            {work.realdate_1 && !closed && (
              <tr>
                <td className="table-repair-works__no-report">
                  <span className="label label_primary">{work.date_day}</span> {work.realdate_1}
                </td>
                <td className="table-repair-works__no-report">Не добавлено работ</td>
                <td className="table-repair-works__no-report">&nbsp;</td>
                {canEdit && <td className="table-repair-works__no-report">&nbsp;</td>}
              </tr>
            )}
          </React.Fragment>
        ))}

        {isEditWorkActive && (
          <Modal title="Редактирование работы" closeHandler={deactivateEditWorkFormModal}>
            <EditWorkFormModal
              closeHandler={deactivateEditWorkFormModal}
              editWorkItem={editWorkItem}
              repairId={repairId}
            />
          </Modal>
        )}

        {isRemoveWorkConfirmActive && canEdit && (
          <Modal title="Удаление работы" closeHandler={deactivateRemoveWorkConfirmModal}>
            <RemoveWorkConfirmModal
              repairId={repairId}
              removeWorkId={removeWorkId}
              closeHandler={deactivateRemoveWorkConfirmModal}
            />
          </Modal>
        )}
      </tbody>
    </table>
  );
};

TableRepairWorks.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  repairId: PropTypes.number.isRequired,
  closed: PropTypes.bool.isRequired,
  works: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      canEdit: PropTypes.bool,
      realdate_1: PropTypes.string,
      date_day: PropTypes.string,
      date_1: PropTypes.string,
      name: PropTypes.string,
      accordance: PropTypes.number,
      week: PropTypes.shape({
        canEdit: PropTypes.bool,
        dates: PropTypes.string,
        name: PropTypes.string,
        week: PropTypes.number,
      }),
    }),
  ).isRequired,
};

export default connect()(TableRepairWorks);
