import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import TextArea from 'components/TextArea/TextArea';
import InputDate from 'components/InputDate/InputDate';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import ModalError from 'components/ModalError/ModalError';

// Actions
import { editRepairAdditionalWork } from 'actions';

// Helpers
import { formatDateYYYYmmDD } from 'helpers/formatDateYYYYmmDD';

const EditAdditionalWorkModal = ({ closeHandler, dispatch, repairId, work }) => {
  const [workName, setWorkName] = useState(work.name);
  const [workDate, setWorkDate] = useState(new Date(work.date));
  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [responseError, setResponseError] = useState(null);

  const submitEditAdditionalWork = () => {
    /** Показываем прелоадер на кнопку */
    setIsLoading(true);

    /** Записываем отредактированную работу в базу данных */
    dispatch(editRepairAdditionalWork(repairId, work.id, workName, formatDateYYYYmmDD(workDate)))
      .then(() => {
        /** Скрываем прелоадер на кнопку */
        setIsLoading(false);

        /** Закрываем модалку */
        closeHandler();
      })
      .catch(error => {
        /** Если есть ошибки, выводим */
        if (error && error.errorData && Object.keys(error.errorData).length) {
          setFieldErrors(error.errorData);
        } else if (error && error.error) {
          setResponseError(error.error);
        } else {
          setResponseError('Произошла неизвестная ошибка');
        }

        /** Скрываем прелоадер на кнопку */
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <ModalHeader title="Редактирование работы" />

      <ModalBody>
        <FormGroup title="Работы" error={fieldErrors.name}>
          <TextArea value={workName} onChange={({ target }) => setWorkName(target.value)} />
        </FormGroup>

        <FormGroup title="Дата" error={fieldErrors.date}>
          <InputDate selectedDate={workDate} onChange={date => setWorkDate(date)} />
        </FormGroup>
      </ModalBody>

      {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

      <ModalFooter closeHandler={closeHandler}>
        <button type="button" className="btn btn-default" onClick={closeHandler}>
          Закрыть
        </button>

        <SubmitButton
          isLoading={isLoading}
          isLoadingText="Сохраняю..."
          isValid={workName === '' || !workDate}
          clickHandler={submitEditAdditionalWork}
        >
          Сохранить
        </SubmitButton>
      </ModalFooter>
    </React.Fragment>
  );
};

EditAdditionalWorkModal.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  repairId: PropTypes.number.isRequired,
  work: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    date: PropTypes.string,
  }).isRequired,
};

export default connect()(EditAdditionalWorkModal);
