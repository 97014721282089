import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Icons
import { FaFilter, FaTimes } from 'react-icons/fa';

// Components
import Modal from 'components/Modal/Modal';
import GpaFiltersModal from 'components/GpaFiltersModal/GpaFiltersModal';

import './WorkTableFilter.scss';

const WorkTableFilter = ({
  orderManToPeriod,
  gpas,
  types,
  statuses,
  userRole,
  isFilterActive,
  master,
  nu,
  repairFilter,
  handleSelectFilterOptions,
  handleResetFilter,
}) => {
  const [isGpaFiltersActive, setGpaFiltersActive] = useState(false);

  const activateGpaFiltersModal = () => {
    setGpaFiltersActive(true);
  };

  const deactivateGpaFiltersModal = () => {
    setGpaFiltersActive(false);
  };

  return (
    <div className="work-table-filter">
      <button
        type="button"
        className="work-table-filter__btn btn btn-primary"
        onClick={activateGpaFiltersModal}
      >
        Фильтры <FaFilter className="icon" />
      </button>

      {isFilterActive && (
        <button
          type="button"
          className="work-table-filter__btn btn btn-danger"
          onClick={handleResetFilter}
        >
          Сбросить фильтрацию <FaTimes className="icon" />
        </button>
      )}

      {isGpaFiltersActive && (
        <Modal title="Подобрать по параметрам" closeHandler={deactivateGpaFiltersModal}>
          <GpaFiltersModal
            closeHandler={deactivateGpaFiltersModal}
            orderManToPeriod={orderManToPeriod}
            gpas={gpas}
            types={types}
            statuses={statuses}
            userRole={userRole}
            isFilterActive={isFilterActive}
            master={master}
            nu={nu}
            repairFilter={repairFilter}
            handleSelectFilterOptions={handleSelectFilterOptions}
            handleResetFilter={handleResetFilter}
          />
        </Modal>
      )}
    </div>
  );
};

WorkTableFilter.propTypes = {
  orderManToPeriod: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  gpas: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  statuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userRole: PropTypes.number.isRequired,
  isFilterActive: PropTypes.bool.isRequired,
  master: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  nu: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  repairFilter: PropTypes.shape({}).isRequired,
  handleSelectFilterOptions: PropTypes.func.isRequired,
  handleResetFilter: PropTypes.func.isRequired,
};

export default WorkTableFilter;
